import cache from '../utils/cache'
import { http } from '../utils/http'
import { IMerchandiser } from './types/merchandiser'
import BaseService from './BaseService'

class MerchandiserService extends BaseService {
  constructor() {
    super('merchandiser')
  }

  fetchById(merchandiserId: string): Promise<IMerchandiser> {
    return new Promise((resolve, reject) => {
      const cacheKey = `merchandiser_${merchandiserId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .get(`merchandiser/read_details/?_id=${merchandiserId}`)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<IMerchandiser[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `merchandisers_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`merchandiser/read_by_manufacturer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<IMerchandiser[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `merchandisers_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`merchandiser/read_by_status`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  signup(merchandiser: IMerchandiser): Promise<any> {
    const formData = new FormData()
    formData.append('hash', 'password') // default

    Object.keys(merchandiser).forEach((key) => {
      formData.append(key, merchandiser[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('merchandiser/signup', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }

  update(merchandiser: IMerchandiser): Promise<any> {
    const formData = new FormData()

    Object.keys(merchandiser).forEach((key) => {
      formData.append(key, merchandiser[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('merchandiser/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve()
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }
}

export default MerchandiserService
