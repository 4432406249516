import cache from '../utils/cache'
import { http } from '../utils/http'
import { ISchedule } from './types/schedule'

class ScheduleService {
  fetchById(scheduleId: string): Promise<ISchedule> {
    return new Promise((resolve, reject) => {
      const cacheKey = `schedule_${scheduleId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`schedule/read/?_id=${scheduleId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<ISchedule[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `schedules_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`schedule/read_by_manufacturer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<ISchedule[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `schedules_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`schedule/read_by_status`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(schedule: ISchedule): Promise<string> {
    const formData = new FormData()

    Object.keys(schedule).forEach((key) => {
      formData.append(key, schedule[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('schedule/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  createBulk(schedules: ISchedule[]): Promise<any> {
    const formData = new FormData()

    schedules.forEach((schedule) => {
      formData.append('name[]', schedule.name)
      formData.append('group[]', schedule.group)
      formData.append('location[]', schedule.location)
      formData.append('period[]', schedule.period.toString())
      formData.append('status[]', 'active')
      formData.append('retailer_id[]', schedule.retailer_id)
      formData.append('retail_location_id[]', schedule.retail_location_id)
      formData.append('manufacturer_id[]', schedule.manufacturer_id)
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('schedule/create_bulk', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(schedule: ISchedule): Promise<any> {
    const formData = new FormData()

    Object.keys(schedule).forEach((key) => {
      formData.append(key, schedule[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('schedule/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default ScheduleService
