import cache from '../utils/cache'
import { http } from '../utils/http'
import { ITicket, ITicketLog, TicketStatus as Status } from './types/ticket'
import { TreeType } from './types/tree'
import BaseService from './BaseService'

class TicketService extends BaseService {
  constructor() {
    super('tickets')
  }

  private extractTicketRegionState(data: any[]): any {
    if (data && data.length) {
      data = data.map((ticket) => {
        const retailLocation = ticket.merchandiser.retail_location

        let state = ''
        let region = ''

        if (retailLocation.branches) {
          const branch = retailLocation.branches.find(
            (branch) => branch.tree.type === TreeType.State
          )
          state = branch.tree.name
          region = branch.tree.parent_obj.name
        }

        return { ...ticket, state, region }
      })
    }

    return data
  }

  fetch(): Promise<ITicket[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('tickets')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('ticket/read_all').then(({ data }) => {
            if (data.code === 200) {
              const tickets = this.extractTicketRegionState(data.data)
              cache.set('tickets', tickets)
              resolve(tickets)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchFlags(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('ticket_flags')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('ticket/read_flags_dict').then(({ data }) => {
            if (data.code === 200) {
              cache.set('ticket_flags', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(ticketId: string): Promise<ITicket> {
    return new Promise((resolve, reject) => {
      const cacheKey = `ticket_${ticketId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`ticket/read/?_id=${ticketId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<ITicket[]> {
    const formData = new FormData()
    formData.append('recipient', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `tickets_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`ticket/read_by_recipient`, formData).then(({ data }) => {
            if (data.code === 200) {
              const tickets = this.extractTicketRegionState(data.data)
              cache.set('tickets', tickets)
              resolve(tickets)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: Status): Promise<ITicket[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `tickets_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`ticket/read_by_status`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  updateStatus(details: any): Promise<any> {
    const formData = new FormData()

    formData.append('_id', details._id)
    formData.append('status', details.status)

    return new Promise((resolve, reject) => {
      try {
        http.post('ticket/set_status', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  fetchLogs(ticketId: string): Promise<ITicketLog[]> {
    return new Promise((resolve, reject) => {
      const cacheKey = `ticket_logs_${ticketId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .get(`ticket_log/read_by_ticket/?ticket_id=${ticketId}`)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  createLog(ticketLog: ITicketLog): Promise<string> {
    const formData = new FormData()

    Object.keys(ticketLog).forEach((key) => {
      formData.append(key, ticketLog[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('ticket_log/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  updateLog(ticketLog: ITicketLog): Promise<any> {
    const formData = new FormData()

    Object.keys(ticketLog).forEach((key) => {
      formData.append(key, ticketLog[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('ticket_log/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default TicketService
