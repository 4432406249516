import React from 'react'

import { Link, useHistory } from 'react-router-dom'
import { Container, Card, Button } from 'react-bootstrap'

import Header from 'src/components/Layout/Header/Header'
import RetailerForm from 'src/components/Retailer/RetailerForm'

import RetailersUpload from 'src/components/Retailer/RetailersUpload'
import usePermission from 'src/hooks/usePermission'
import useQueryParams from 'src/hooks/useQueryParams'

import Error401 from 'src/pages/Error/401.page'

import { route } from 'src/utils/routes'

const RetailerCreate: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const type = useQueryParams('type')

  if (!userCan('create_retailer')) return <Error401 />

  return (
    <>
      <Header
        title={type === 'bulk' ? 'Upload Bulk' : 'New Retailer'}
        backText="Retailers"
        onBack={() => history.push(route('retailers'))}
      />
      <div className="page-container">
        <Container>
          <Card className={type === 'bulk' ? 'centered' : null}>
            <Card.Header>
              <Card.Title>
                {type === 'bulk' ? 'Upload File' : 'Retailer Details'}
              </Card.Title>

              {type !== 'bulk' && userCan('create_retailer') && (
                <div>
                  <Link to={`${route('retailer_create')}?type=bulk`}>
                    <Button size="sm">Create Bulk</Button>
                  </Link>
                </div>
              )}
            </Card.Header>
            <Card.Body className="pt-0">
              {type === 'bulk' ? <RetailersUpload /> : <RetailerForm />}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default RetailerCreate
