import { IManufacturer } from './manufacturer'
import { IRetailer } from './retailer'
import { IRetailLocation } from './retailLocation'

export enum LocationInStore {
  Primary = 'primary',
  Secondary = 'secondary',
  Warehouse = 'warehouse',
  Competition = 'competition',
}

export enum Task {
  Assortment = 'assortment',
  PVisibility = 'primary-visibility',
  SVisibility = 'secondary-visibility',
  StockTracking = 'tracking-stock',
  PriceTracking = 'tracking-price',
  ExpiryTracking = 'tracking-life',
  CIntelligence = 'competitive-intelligence',
}

export enum Period {
  Daily = 'daily',
  Weekly = 'weekly',
  BiWeekly = 'bi-weekly',
  TwiceWeekly = 'twice-weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
}

export interface ISchedule {
  _id?: string
  name: string
  group: Task
  location: LocationInStore
  period: number
  status: 'active' | 'inactive'

  retailer_id: string
  retailer?: IRetailer

  retail_location_id: string
  retail_location?: IRetailLocation

  manufacturer_id: string
  manufactuerer: IManufacturer

  readonly created?: string
}
