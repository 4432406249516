import React from 'react'
import { SET_REPORT_FILTERS } from '../actionTypes'

import { Period } from 'src/services/types/schedule'
import { TreeType } from 'src/services/types/tree'

interface IState {
  entity: 'store' | 'merchandiser'
  task: string
  tasks: any[]
  date: Date
  start_date: Date
  end_date: Date

  period: Period
  threshold: number

  retailer: any
  retail_location: any
  retail_locations: any[]
  tree_type: TreeType
  categories: any[]
  products: any[]
  competition: any[]
}

interface IAction {
  type: string
  payload: IState
}

const initialState: IState = {
  task: '',
  tasks: [],
  entity: 'merchandiser',
  date: new Date(),
  start_date: null,
  end_date: null,
  period: Period.Weekly,
  threshold: 10,
  retailer: null,
  retail_location: null,
  retail_locations: [],
  tree_type: TreeType.State,
  categories: [],
  products: [],
  competition: [],
}

const reportFiltersReducer: React.Reducer<IState, IAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_REPORT_FILTERS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default reportFiltersReducer
