import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'
import { Container, Table, Card, Form, Button, Badge } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { userCan } from 'src/store/user/userActions'
import ConfigurationService from 'src/services/ConfigurationService'

import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { IConfiguration } from 'src/services/types/configuration'

import { IUser } from 'src/services/types/user'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'
import NotFound from 'src/components/Error/NotFound'
import { BsPlus } from 'react-icons/bs'

const configurationService = new ConfigurationService()

interface IProps {
  /** User from state.user */
  user: IUser
  /** User permissions checker */
  userCan: (permission: string) => boolean
}

const Configurations: React.FC<IProps> = ({ user, userCan }) => {
  const _isMounted = React.useRef(false)

  // indicators
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // All configurations
  const [configurations, setConfigurations] = useState<IConfiguration[]>()
  // Configurations based on searchTerm and/or status
  const [filteredConfigurations, setfilteredConfigurations] =
    useState<IConfiguration[]>()

  const [searchTerm, setSearchTerm] = useState<string>()
  const [page, setPage] = useState(1)

  useEffect(() => {
    _isMounted.current = true

    if (userCan('view_configurations')) {
      configurationService
        .fetchByManufacturer(user.manufacturer_id)
        .then((configurations) => {
          if (_isMounted.current && configurations.length) {
            setConfigurations(configurations)
            setfilteredConfigurations(configurations)
          }
          setIsLoading(false)
        })
    }

    return () => {
      _isMounted.current = false
    }
  }, [userCan, user.manufacturer_id])

  const handleSearch = _.debounce((query) => {
    setPage(1)
    if (!query) setfilteredConfigurations(configurations)
    else {
      setfilteredConfigurations(
        configurations.filter((configuration) => {
          return (
            configuration.title.search(new RegExp(query, 'i')) >= 0 ||
            configuration.status.search(new RegExp(query, 'i')) >= 0
          )
        })
      )
    }
  }, 150)

  /**
   * Get the configurations to be shown on the current page
   */
  const paginatedConfigurations = React.useMemo(() => {
    if (filteredConfigurations) {
      return paginate(filteredConfigurations, page)
    } else return []
  }, [page, filteredConfigurations])

  if (!userCan('view_configurations')) return <Error401 />

  return (
    <>
      <Header title="Configurations" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    {filteredConfigurations
                      ? `${filteredConfigurations.length} ${pluralize(
                          'configuration',
                          filteredConfigurations.length
                        )}`
                      : '0 configurations'}
                  </div>
                  <div className="ml-3">
                    <Form.Control
                      size="sm"
                      type="text"
                      name="searchTerm"
                      value={searchTerm || ''}
                      className="search"
                      style={{ minWidth: '250px' }}
                      placeholder="Search by name or status"
                      onChange={({ target }) => {
                        setSearchTerm(target.value)
                        handleSearch(target.value)
                      }}
                      disabled={isLoading || !filteredConfigurations}
                    />
                  </div>
                </div>

                {userCan('create_configuration') && (
                  <div>
                    <Link to={route('configuration_create')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create Configuration
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {!isLoading && paginatedConfigurations.length ? (
                <>
                  <div className="table-wrapper padded">
                    <Table hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="sticky">Title</th>
                          <th>Description</th>
                          <th>Status</th>
                          {/* <th>Products</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedConfigurations.map((configuration, index) => (
                          <tr key={configuration._id}>
                            <td className="align-middle text-center">
                              {index + 1 + (page - 1) * 10}
                            </td>
                            <td className="align-middle sticky">
                              {configuration.title}
                            </td>
                            <td className="align-middle">
                              {configuration.description}
                            </td>
                            <td className="align-middle">
                              <Badge
                                className="text-capitalize"
                                variant={
                                  configuration.status === 'active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {configuration.status}
                              </Badge>
                            </td>
                            <td className="align-middle">
                              <Link
                                to={route('configuration_update', {
                                  id: configuration._id,
                                })}
                                className="action-link"
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    className="my-4"
                    perPage={10}
                    totalItems={filteredConfigurations.length}
                    currentPage={page}
                    onChange={(page) => setPage(page)}
                  />
                </>
              ) : null}

              {!isLoading && !paginatedConfigurations.length ? (
                <div className="my-5">
                  <NotFound summary="No configurations found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect(
  (state: IState) => ({
    user: state.user,
  }),
  { userCan }
)(Configurations)
