import { AUTH, SET_USER, SET_GLOBAL } from '../actionTypes'
import UserService from '../../services/UserService'

import { setToken } from '../../utils/http'

const userService = new UserService()

/**
 * Check user permisisons
 *
 * @param permission - Ex. view_brands
 */
export const userCan = (permission: string) => (_, getState: any) => {
  const permissions = getState().user.permissions
  return permissions.includes(permission)
}

/**
 * The only get user function needed in this app
 *
 * Sits on top of the user service to fetch a user
 * and assign permission
 */
export const getUser =
  () =>
  (dispatch: any, getState: any): Promise<any> => {
    const user = getState().user
    const token = getState().auth.token
    setToken(token)

    return new Promise((resolve) => {
      // Check if persisted token exists in authReducer

      if (token && !user._id) {
        // Set global loader
        dispatch({ type: SET_GLOBAL, payload: { isLoading: true } })

        userService
          .fetchUser(token)
          .then((user) => {
            dispatch({
              type: SET_USER,
              payload: user,
            })
          })
          .catch(() => {
            // On fail, just clear token to avoid repeat
            dispatch({ type: AUTH, payload: { token: null } })
          })
          .finally(() => {
            dispatch({ type: SET_GLOBAL, payload: { isLoading: false } })
          })
      } else {
        dispatch({ type: SET_GLOBAL, payload: { isLoading: false } })
      }
      resolve()
    })
  }
