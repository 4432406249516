import React from 'react'

import { connect } from 'react-redux'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Card, Button } from 'react-bootstrap'

import { userCan } from 'src/store/user/userActions'
import { useQuery, route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import TargetForm from 'src/components/Target/TargetForm'
import TargetsUpload from 'src/components/Target/TargetsUpload'

interface IProps extends RouteComponentProps {
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const TargetCreate: React.FC<IProps> = ({ history, user, userCan }) => {
  const type = useQuery().get('type')

  if (!userCan('create_target')) return <Error401 />

  return (
    <>
      <Header
        title={type === 'bulk' ? 'Upload Targets' : 'Create Target'}
        backText="Targets"
        onBack={() => history.push(route('targets'))}
      />
      <div className="page-container">
        <Container>
          <Card className={type === 'bulk' ? 'centered' : null}>
            <Card.Header className="w-100 d-flex align-items-center">
              <Card.Title>
                {type === 'bulk' ? 'Upload Targets' : 'Target Details'}
              </Card.Title>

              {type !== 'bulk' && userCan('create_target') && (
                <div>
                  <Link to={`${route('target_create')}?type=bulk`}>
                    <Button size="sm">Create Bulk</Button>
                  </Link>
                </div>
              )}
            </Card.Header>
            <Card.Body className="pt-0">
              {type === 'bulk' ? <TargetsUpload /> : <TargetForm user={user} />}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(TargetCreate)
)
