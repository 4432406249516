import React from 'react'

import { Formik, Field, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SearchSelect from 'src/components/Form/SearchSelect'
import useMounted from 'src/hooks/useMounted'

import { ISchedule, Period } from 'src/services/types/schedule'

import { route } from 'src/utils/routes'
import { computeTTL, undoComputeTTL } from 'src/utils/date'

import RetailerService from 'src/services/RetailerService'
import RetailLocationService from 'src/services/RetailLocationService'
import ScheduleService from 'src/services/ScheduleService'

const scheduleService = new ScheduleService()
const retailerService = new RetailerService()
const retailLocationService = new RetailLocationService()
interface IProps {
  user: any
  type?: 'create' | 'update'
  schedule?: ISchedule
  updateSchedule?: (updatedValues: any) => void
}

const ScheduleForm: React.FC<IProps> = ({
  user,
  type,
  schedule,
  updateSchedule,
}) => {
  const { addToast } = useToasts()
  const history = useHistory()
  const isMounted = useMounted()

  const [retailers, setRetailers] = React.useState<any[]>([])
  const [retailLocations, setRetailLocations] = React.useState<any[]>([])

  React.useEffect(() => {
    retailerService
      .fetchByManufacturer(user.manufacturer_id)
      .then((retailers) => {
        const activeRetailers = retailers
          .filter((retailer) => retailer.status === 'active')
          .map((retailer) => ({
            value: retailer._id,
            label: retailer.name,
          }))
        isMounted.current && setRetailers(activeRetailers)
      })

    retailLocationService
      .fetchByManufacturer(user.manufacturer_id)
      .then((locations) => {
        const activeLocations = locations
          .filter((location) => location.status === 'active')
          .map((location) => ({
            value: location._id,
            label: location.name,
            retailer_id: location.retailer_id,
          }))
        isMounted.current && setRetailLocations(activeLocations)
      })
  }, [user.manufacturer_id, isMounted])

  const formConfig = {
    initialValues: {
      retailer: schedule
        ? {
            value: schedule.retailer._id,
            label: schedule.retailer.name,
          }
        : null,
      retail_location: schedule
        ? {
            value: schedule.retail_location._id,
            label: schedule.retail_location.name,
          }
        : null,
      name: schedule ? schedule.name : '',
      period: schedule ? undoComputeTTL(schedule.period) : '',
      group: schedule ? schedule.group : '',
      location: schedule ? schedule.location : '',
      status: schedule ? schedule.status : 'active',
    },
    validationSchema: Yup.object({
      retailer: Yup.string().required('Retailer field is required.').nullable(),
      retail_location: Yup.string()
        .required('Retail store field is required.')
        .nullable(),
      name: Yup.string().required('Name field is required.'),
      period: Yup.string().required('Period field is required.'),
      group: Yup.string().required('Group field is required.'),
      location: Yup.string().required('Location field is required.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      let finalValues: any = {}

      if (type === 'update') {
        finalValues._id = schedule._id
      }

      finalValues = {
        ...finalValues,
        name: values.name,
        period: computeTTL(values.period),
        group: values.group,
        location: values.location,
        status: values.status,

        retailer_id: values.retailer.value,
        retail_location_id: values.retail_location.value,
        manufacturer_id: user.manufacturer_id,
      }

      scheduleService[type]({ ...finalValues })
        .then((scheduleId) => {
          setSubmitting(false)
          // resetForm()

          if (type === 'update') {
            addToast('Schedule successfully updated.', {
              appearance: 'success',
            })
            updateSchedule({ ...finalValues })
          } else {
            addToast('Schedule successfully created.', {
              appearance: 'success',
            })
            history.push(route('schedule_update', { id: scheduleId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        ...formik
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* Schedule status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {/* Retailer */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Retailer *</Form.Label>
              <Form.Text className="text-muted">Retailer</Form.Text>
            </Col>
            <Col md={6}>
              <SearchSelect
                name="retailer"
                options={retailers}
                value={values.retailer}
                placeholder="Select retailer"
                onBlur={() => {
                  formik.setFieldTouched('retailer', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('retailer', selected)
                }}
                isInvalid={touched.retailer && errors.retailer ? true : false}
                isDisabled={!retailers.length}
              />
              <ErrorMessage
                name="retailer"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Location */}
          {/* List depends on retailer value */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Retail Store *</Form.Label>
              <Form.Text className="text-muted">
                Retailer physical location
              </Form.Text>
            </Col>
            <Col md={6}>
              <SearchSelect
                name="retail_location"
                options={
                  values.retailer && retailLocations
                    ? retailLocations.filter((location) => {
                        return location.retailer_id === values.retailer.value
                      })
                    : []
                }
                value={values.retail_location}
                placeholder="Select retail store"
                onBlur={() => {
                  formik.setFieldTouched('retail_location', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('retail_location', selected)
                }}
                isInvalid={
                  touched.retail_location && errors.retail_location
                    ? true
                    : false
                }
                isDisabled={!values.retailer}
              />
              <ErrorMessage
                name="retail_location"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Name */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">Schedule indentifier</Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                name="name"
                value={values.name}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.name && errors.name ? true : false}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Schedule task group */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Group *</Form.Label>
              <Form.Text className="text-muted">Select task group</Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                as="select"
                name="group"
                value={values.group}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.group && errors.group ? true : false}
              >
                <option value="">Select group</option>
                <option value="assortment">Assortment</option>
                <option value="primary-visibility">P. Visibility</option>
                <option value="secondary-visibility">S. Visibility</option>
                <option value="tracking-stock">Stock Check</option>
                <option value="tracking-price">Price Tracking</option>
                <option value="tracking-life">Expiry Tracking</option>
                <option value="competitive-intelligence">
                  Competitive Intelligence
                </option>
              </Form.Control>
              <ErrorMessage
                name="group"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Schedule task location */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Location *</Form.Label>
              <Form.Text className="text-muted">Location in store</Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                as="select"
                name="location"
                value={values.location}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.location && errors.location ? true : false}
              >
                <option value="">Select location</option>
                <option value="primary">Primary</option>
                <option value="secondary">Secondary</option>
                <option value="warehouse">Warehouse</option>
                <option value="competition">Competition</option>
              </Form.Control>
              <ErrorMessage
                name="location"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Schedule period */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Period *</Form.Label>
              <Form.Text className="text-muted">
                How often should this task happen?
              </Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                as="select"
                name="period"
                value={values.period}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.period && errors.period ? true : false}
              >
                <option value="">Select period</option>
                <option value={Period.Daily}>Daily</option>
                <option value={Period.TwiceWeekly}>Twice Weekly</option>
                <option value={Period.Weekly}>Weekly</option>
                <option value={Period.BiWeekly}>Bi-weekly</option>
                <option value={Period.Monthly}>Monthly</option>
              </Form.Control>
              <ErrorMessage
                name="period"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Schedule'
            ) : (
              'Update Schedule'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

ScheduleForm.defaultProps = {
  type: 'create',
}

export default ScheduleForm
