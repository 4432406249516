import React from 'react'

import { connect } from 'react-redux'
import { Container, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import RetailerService from 'src/services/RetailerService'
import { IRetailer } from 'src/services/types/retailer'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import RetailerForm from 'src/components/Retailer/RetailerForm'

const retailerService = new RetailerService()

interface IProps extends RouteComponentProps {
  match: any
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const RetailerUpdate: React.FC<IProps> = ({ match, history, userCan }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [retailer, setRetailer] = React.useState<IRetailer>()

  React.useEffect(() => {
    if (userCan('update_retailer')) {
      retailerService
        .fetchById(match.params.id)
        .then((retailer) => setRetailer(retailer))
        .finally(() => setIsLoading(false))
    }
  }, [userCan, match.params.id])

  if (!userCan('update_retailer')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Retailer'
            : retailer
            ? `${retailer.name}`
            : 'Retailer not found'
        }
        backText="Retailers"
        onBack={() => history.push(route('retailers'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Retailer Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              {!isLoading && retailer ? (
                <RetailerForm
                  type="update"
                  retailer={retailer}
                  updateRetailer={(updatedValues) => {
                    setRetailer({ ...retailer, ...updatedValues })
                  }}
                />
              ) : null}

              {!isLoading && !retailer ? (
                <div className="my-5">
                  <NotFound summary="Retailer not found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(RetailerUpdate)
)
