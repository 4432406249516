import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'
import { Container, Table, Card, Form, Button, Badge } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs'
import { connect } from 'react-redux'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'

import { userCan } from 'src/store/user/userActions'
import ProductService from 'src/services/ProductService'
import { paginate } from 'src/utils/filters'
import { formatCurrency } from 'src/utils/money'
import { route } from 'src/utils/routes'
import { asset } from 'src/utils/file'
import { IProduct } from 'src/services/types/product'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'
import NotFound from 'src/components/Error/NotFound'
import ProductImage from 'src/components/Image/ProductImage'

const productService = new ProductService()

interface IProps extends RouteComponentProps {
  user: any
  userCan: (permission: string) => boolean
}

const Products: React.FC<IProps> = ({ user, userCan, history }) => {
  // indicators
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // All products
  const [products, setProducts] = useState<IProduct[]>()
  // Products based on searchTerm and/or status
  const [sortedProducts, setSortedProducts] = useState<IProduct[]>()

  const [searchTerm, setSearchTerm] = useState<string>()
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (userCan('view_products')) {
      setIsLoading(true)
      productService
        .fetchByManufacturer(user.manufacturer_id)
        .then((products) => {
          setProducts(products)
          setSortedProducts(products)
          setIsLoading(false)
        })
    }
  }, [userCan, user.manufacturer_id])

  const handleSearch = _.debounce((query) => {
    setPage(1)
    if (!query) setSortedProducts(products)
    else {
      setSortedProducts(
        products.filter((product) => {
          return (
            product.name.search(new RegExp(query, 'i')) >= 0 ||
            product.status.search(new RegExp(query, 'i')) >= 0
          )
        })
      )
    }
  }, 150)

  /**
   * Get the products to be shown on the current page
   */
  const paginatedProducts = React.useMemo(() => {
    if (sortedProducts) {
      return paginate(sortedProducts, page)
    } else return []
  }, [page, sortedProducts])

  if (!userCan('view_products')) return <Error401 />

  return (
    <>
      <Header title="Products" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    {sortedProducts
                      ? `${sortedProducts.length} ${pluralize(
                          'product',
                          sortedProducts.length
                        )}`
                      : '0 products'}
                  </div>
                  <div className="ml-3">
                    <Form.Control
                      size="sm"
                      type="text"
                      name="searchTerm"
                      value={searchTerm || ''}
                      className="search"
                      style={{ minWidth: '220px' }}
                      placeholder="Search by name or status"
                      onChange={({ target }) => {
                        setSearchTerm(target.value)
                        handleSearch(target.value)
                      }}
                      disabled={isLoading || !sortedProducts}
                    />
                  </div>
                </div>

                {userCan('create_product') && (
                  <div>
                    <Link to={route('product_create')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create Product
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {!isLoading && paginatedProducts.length ? (
                <>
                  <div className="table-wrapper padded">
                    <Table className="table-fit" hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">--</th>
                          <th>Name</th>
                          <th>Status</th>
                          {/* <th>Brand</th> */}
                          <th className="text-right">Case Count</th>
                          <th className="text-right">Size (ML)</th>
                          <th className="text-right">Price (₦)</th>
                          <th className="text-right">Price (RRP)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedProducts.map((product, index) => (
                          <tr
                            key={product._id}
                            className="cursor-pointer"
                            onClick={() =>
                              history.push(
                                route('product_update', {
                                  id: product._id,
                                })
                              )
                            }
                          >
                            <td className="align-middle text-center">
                              {index + 1 + (page - 1) * 10}
                            </td>
                            <td className="align-middle text-center">
                              <div className="d-flex justify-content-center">
                                <ProductImage
                                  src={
                                    product.upload
                                      ? asset(product.upload.path)
                                      : false
                                  }
                                  roundedCircle
                                />
                              </div>
                            </td>
                            <td className="align-middle">
                              <span
                                className="text-truncated"
                                title={product.name}
                              >
                                {product.name}
                              </span>
                            </td>
                            <td className="align-middle">
                              <Badge
                                className="text-capitalize"
                                variant={
                                  product.status === 'active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {product.status}
                              </Badge>
                            </td>
                            <td className="align-middle text-right">
                              {product.case_count}
                            </td>
                            <td className="align-middle text-currency text-right">
                              {product.size}
                            </td>
                            <td className="align-middle text-currency font-weight-bold text-right">
                              {formatCurrency(product.price, true)}
                            </td>
                            <td className="align-middle text-currency text-right">
                              {product.price_rrp
                                ? formatCurrency(product.price_rrp, true)
                                : '-'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    className="my-4"
                    perPage={10}
                    totalItems={sortedProducts.length}
                    currentPage={page}
                    onChange={(page) => setPage(page)}
                  />
                </>
              ) : null}

              {!isLoading && !paginatedProducts.length ? (
                <div className="my-5">
                  <NotFound summary="No products found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(Products)
)
