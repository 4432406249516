import cache from '../utils/cache'
import { http } from '../utils/http'
import { IRetailLocation } from './types/retailLocation'

class RetailLocationService {
  fetchById(retailLocationId: string): Promise<IRetailLocation> {
    return new Promise((resolve, reject) => {
      const cacheKey = `retail_location_${retailLocationId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .get(`retail_location/read/?_id=${retailLocationId}`)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<IRetailLocation[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `retail_locations_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post('retail_location/read_by_manufacturer', formData)
            .then(({ data }) => {
              if (data.code === 200 && data.data.length) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(retail_location: IRetailLocation): Promise<any> {
    const formData = new FormData()

    Object.keys(retail_location).forEach((key) => {
      formData.append(key, retail_location[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('retail_location/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  createBulk(locations: IRetailLocation[]): Promise<any> {
    const formData = new FormData()

    locations.forEach((location) => {
      formData.append('name[]', location.name)
      formData.append('status[]', location.status)

      formData.append('retailer_id[]', location.retailer_id)
      formData.append('manufacturer_id[]', location.manufacturer_id)
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('retail_location/create_bulk', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(retailLocation: IRetailLocation): Promise<any> {
    const formData = new FormData()

    Object.keys(retailLocation).forEach((key) => {
      formData.append(key, retailLocation[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('retail_location/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  /**
   * Create map location of retai location
   *
   * @param location
   */
  createLocation(location: any, retailLocationId): Promise<any> {
    let formData = new FormData()

    Object.keys(location).forEach((key) => {
      formData.append(key, location[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('location/create', formData).then(({ data }) => {
          if (data.code === 200) {
            /**
             * If location create is successful, create the
             * pairing with this retail location
             */
            formData = new FormData()
            formData.append('location_id', data.data)
            formData.append('retail_location_id', retailLocationId)

            http
              .post('location_pair/create', formData)
              .then(({ data }) => {
                if (data.code === 200) resolve()
                else reject({ message: data.message })
              })
              .finally(() => cache.reset())
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  /**
   * Update map location of retail location
   *
   * @param location
   */
  updateLocation(location: any): Promise<any> {
    const formData = new FormData()

    Object.keys(location).forEach((key) => {
      formData.append(key, location[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('location/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default RetailLocationService
