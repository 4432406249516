import React from 'react'

import { Form } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'

import useDebounce from 'src/hooks/useDebounce'

interface IProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  disabled?: boolean
  delay?: number
  width?: '100' | 'auto'
}

const SearchInput: React.FC<IProps> = ({
  value,
  onChange,
  placeholder,
  disabled,
  delay,
  width,
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>(value)
  const debouncedSearchTerm = useDebounce(searchTerm, delay)

  const searchTermRef = React.useRef(debouncedSearchTerm)

  React.useEffect(() => {
    if (searchTermRef.current !== debouncedSearchTerm) {
      searchTermRef.current = debouncedSearchTerm
      onChange(debouncedSearchTerm)
    }
  }, [debouncedSearchTerm, onChange])

  return (
    <div className="d-flex align-items-center">
      <Form.Control
        size="sm"
        type="text"
        name="searchTerm"
        value={searchTerm || ''}
        className={`search w-${width}`}
        placeholder={placeholder}
        onChange={({ target }) => {
          setSearchTerm(target.value)
        }}
        disabled={disabled}
      />

      {/* Reset for search term only */}
      {searchTerm ? (
        <span
          className="ml-1 cursor-pointer text-muted"
          onClick={() => setSearchTerm('')}
        >
          <BsX size={20} />
        </span>
      ) : null}
    </div>
  )
}

SearchInput.defaultProps = {
  placeholder: 'Search...',
  disabled: false,
  delay: 750,
  width: 'auto',
}

export default SearchInput
