import React from 'react'
import usePermission from 'src/hooks/usePermission'

import styles from './Sidebar.module.scss'

interface IProps {
  permission: string
  title: string
}

const SidebarSection: React.FC<IProps> = ({ permission, title, children }) => {
  const { userCan } = usePermission()

  return userCan(permission) ? (
    <>
      <span className={styles.sectionTitle}>{title}</span>
      {children}
    </>
  ) : null
}

export default SidebarSection
