import cache from '../utils/cache'
import { http } from '../utils/http'
import { IChannel } from './types/channel'

class ChannelService {
  fetch(): Promise<IChannel[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('channels')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('channel/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('channels', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(channelId: string): Promise<IChannel> {
    return new Promise((resolve, reject) => {
      const cacheKey = `channel_${channelId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`channel/read/?_id=${channelId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<IChannel[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `channels_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`channel/read_by_status`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(channel: IChannel): Promise<string> {
    const formData = new FormData()

    Object.keys(channel).forEach((key) => {
      formData.append(key, channel[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('channel/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(channel: IChannel): Promise<any> {
    const formData = new FormData()

    Object.keys(channel).forEach((key) => {
      formData.append(key, channel[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('channel/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default ChannelService
