import React from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import ProtectedRoute from './components/ProtectedRoute'
import Sidebar from './components/Layout/Sidebar/Sidebar'
import NoConnection from './components/Error/NoConnection'

import { routes } from 'src/config/routes'
import usePermission from 'src/hooks/usePermission'

import { fetchNotifications } from 'src/store/notification/notificationActions'
import { getUser } from 'src/store/user/userActions'
import { IState } from 'src/store'

import './assets/sass/app.scss'

const SuspenseLoader: React.FC = () => (
  <div className="w-100 d-flex justify-content-center py-5">
    <figure className="spinner primary" />
  </div>
)

const App: React.FC = () => {
  const dispatch = useDispatch()
  const { addToast, removeAllToasts } = useToasts()

  const { isLoading } = useSelector((state: IState) => state.global)
  const user = useSelector((state: IState) => state.user)
  const isAuthenticated = useSelector((state: IState) => !!state.auth.token)
  const sidebarOpen = useSelector((state: IState) => state.config.sidebarOpen)

  const { userCan } = usePermission()

  const [online, setOnline] = React.useState(navigator.onLine)

  React.useEffect(() => {
    dispatch(getUser())

    window.addEventListener('online', () => {
      dispatch(getUser()) // reload user when we're back online.
      setOnline(true)
      removeAllToasts()
    })
    window.addEventListener('offline', () => {
      addToast(`You're not connected to the internet.`, {
        appearance: 'error',
        autoDismiss: false,
      })
    })
  }, [dispatch, isAuthenticated, addToast, removeAllToasts])

  React.useEffect(() => {
    if (isAuthenticated && user.role) {
      userCan('view_tickets') && dispatch(fetchNotifications())
    }
  }, [dispatch, user, userCan, isAuthenticated])

  return (
    <BrowserRouter>
      <div className="app">
        {!online ? (
          <div className="text-center w-100 mt-5 pt-5 px-4">
            <div className="mt-5"></div>
            <NoConnection
              title="No Network Connection"
              summary="You don't have an active internet connection. Please reconnect and try again."
            />
          </div>
        ) : isLoading ? (
          <SuspenseLoader />
        ) : (
          <>
            <Sidebar />

            <main
              className={`page-wrapper ${
                !isAuthenticated
                  ? ''
                  : !sidebarOpen
                  ? 'with-mini-sidebar'
                  : 'with-sidebar'
              }`}
            >
              <React.Suspense fallback={<SuspenseLoader />}>
                <Switch>
                  {/* Load routes */}
                  {routes.map((route) => {
                    const routeProps = {
                      path: route.path,
                      component: route.component,
                      exact: route.exact,
                    }

                    return !route.requiresAuth ? (
                      <Route key={route.id} {...routeProps} />
                    ) : (
                      <ProtectedRoute key={route.id} {...routeProps} />
                    )
                  })}
                </Switch>
              </React.Suspense>
            </main>
          </>
        )}
      </div>
    </BrowserRouter>
  )
}

export default App
