import React from 'react'
import Unauthorized from 'src/components/Error/Unauthotized'

interface IProps {
  type?: 'page' | 'content'
}

const Error401: React.FC<IProps> = ({ type }) => {
  const classes = type === 'page' ? 'my-5 pt-5' : 'my-5 px-5'
  return (
    <div className={classes}>
      <Unauthorized
        title="Unauthorized!"
        summary={`You don't have the permission to access this ${type}. That's all we know.`}
      />
    </div>
  )
}

Error401.defaultProps = {
  type: 'page',
}

export default Error401
