import React from 'react'

import { connect } from 'react-redux'
import { Table, Card } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import { userCan } from 'src/store/user/userActions'
import ConfigurationService from 'src/services/ConfigurationService'
import { IConfiguration } from 'src/services/types/configuration'

import RetailLocationPicker from 'src/components/Configuration/RetailLocationPicker'
import NotFound from 'src/components/Error/NotFound'

const configurationService = new ConfigurationService()

interface IProps {
  user: any
  configuration: IConfiguration
}

const ConfigurationItems: React.FC<IProps> = ({ user, configuration }) => {
  const _isMounted = React.useRef<any>(false)
  const { addToast } = useToasts()

  /** indicators */
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)
  /** Store the item ID of item being deleted. */
  const [isDeleting, setIsDeleting] = React.useState<any>(false)

  const [locations, setLocations] = React.useState<any[]>()
  const [lastUpdated, setLastUpdated] = React.useState<number>()

  /**
   * It's important to manage memory leaks
   * when components have been unmounted.
   */
  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  React.useEffect(() => {
    if (!isDeleting) {
      setIsUpdating(true)

      configurationService
        .fetchRetailLocations(user.manufacturer_id, configuration._id)
        .then((locations) => {
          if (_isMounted.current) {
            setLocations(locations)
            setIsLoading(false)
            setIsUpdating(false)
          }
        })
    }
  }, [user.manufacturer_id, configuration._id, lastUpdated, isDeleting])

  const addLocations = React.useCallback(
    (locations: any) => {
      locations = locations.map((location) => location.value)

      return new Promise((resolve, reject) => {
        configurationService
          .addRetailLocation(configuration._id, locations)
          .then(() => {
            setLastUpdated(Date.now())

            addToast(`Retail store added to configuration`, {
              appearance: 'success',
            })

            resolve()
          })
          .catch((error) => {
            addToast(error.mesage, { appearance: 'error' })
            reject(error)
          })
      })
    },
    [addToast, configuration._id]
  )

  const deleteItem = React.useCallback(
    (locationId) => {
      return new Promise((resolve, reject) => {
        setIsDeleting(locationId)

        configurationService
          .removeRetailLocation(locationId)
          .then(() => {
            setIsDeleting(false)

            addToast(`Retail store removed from configuration`, {
              appearance: 'success',
            })

            resolve()
          })
          .catch((error) => {
            setIsDeleting(false)

            addToast(error.mesage, { appearance: 'error' })
            reject(error)
          })
      })
    },
    [addToast]
  )

  return (
    <Card>
      <Card.Header>
        <Card.Title>{configuration.title} (Retail Stores)</Card.Title>
      </Card.Header>
      <Card.Header className="odd">
        <RetailLocationPicker
          user={user}
          configuration={configuration}
          selectedItems={locations}
          addLocations={addLocations}
        />
      </Card.Header>
      <Card.Body className={isUpdating ? 'disabled' : ''}>
        {!isLoading && locations && locations.length ? (
          <div className="table-wrapper bordered">
            <Table className="table-fit" bordered hover responsive>
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th className="sticky">Store</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {locations.map((location: any, index: number) => (
                  <tr key={index}>
                    <td className="text-center align-middle">{index + 1}</td>
                    <td className="align-middle sticky">
                      {/* Add span to enable text truncate */}
                      <span title={location.retail_location.name}>
                        {location.retail_location.name}
                      </span>
                    </td>
                    <td>
                      {isDeleting === location._id ? (
                        <figure className="spinner small primary" />
                      ) : (
                        <span
                          className="text-danger action-link cursor-pointer"
                          onClick={() => deleteItem(location._id)}
                        >
                          Remove
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : !isLoading && (!locations || !locations.length) ? (
          <div className="my-5">
            <NotFound summary="No retail locations found for this configuration" />
          </div>
        ) : (
          <div className="d-flex justify-content-center py-5">
            <figure className="spinner primary" />
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default connect(() => ({}), { userCan })(ConfigurationItems)
