import Ticket from 'src/pages/Ticket/Ticket.page'
import Tickets from 'src/pages/Ticket/Tickets.page'

import { IRoute } from '.'

const ticketRoutes: IRoute[] = [
  {
    id: 'tickets',
    path: '/tickets',
    component: Tickets,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'ticket_view',
    path: '/ticket/:id',
    component: Ticket,
    requiresAuth: true,
    exact: true,
  },
]

export default ticketRoutes
