import cache from '../utils/cache'
import { http } from '../utils/http'
import { IForum, IForumImage } from './types/forum'

class ForumService {
  fetch(): Promise<IForum[]> {
    return new Promise((resolve, reject) => {
      const cacheKey = `forums`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`forum/read_all`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(forumId: string): Promise<IForum> {
    return new Promise((resolve, reject) => {
      const cacheKey = `forum_${forumId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`forum/read/?_id=${forumId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<IForum[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `forums_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`forum/read_by_manufacturer`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  /**
   * Fetch images from forum
   *
   * @param params
   */
  fetchImagesByParams(params: any): Promise<IForumImage[]> {
    const formData = new FormData()
    formData.append('forum_id', params.forum_id)

    formData.append('key[]', 'retail_location')
    formData.append('value[]', params.retail_location_id)

    formData.append('key[]', 'category')
    formData.append('value[]', params.category_name)

    formData.append('key[]', 'group')
    formData.append('value[]', params.group)

    formData.append('key[]', 'from_timestamp')
    formData.append('value[]', params.from_timestamp)

    formData.append('key[]', 'to_timestamp')
    formData.append('value[]', params.to_timestamp)

    return new Promise((resolve, reject) => {
      const cacheKey = `forum_images_${params.retail_location_id}_${params.category_name}_${params.group}_${params.from_timestamp}_${params.to_timestamp}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`forum_image/read_by_forum_parameter`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(forum: IForum): Promise<any> {
    const formData = new FormData()

    Object.keys(forum).forEach((key) => {
      formData.append(key, forum[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('forum/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(forum: IForum): Promise<any> {
    const formData = new FormData()

    Object.keys(forum).forEach((key) => {
      formData.append(key, forum[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('forum/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default ForumService
