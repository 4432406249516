import React from 'react'

import { Container, Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { withRouter, useHistory, useParams } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import Header from 'src/components/Layout/Header/Header'
import TreeForm from 'src/components/Tree/TreeForm'

import { ITree } from 'src/services/types/tree'
import Error401 from 'src/pages/Error/401.page'

import TreeService from 'src/services/TreeService'
import { userCan } from 'src/store/user/userActions'
import { IState } from 'src/store'
import { route } from 'src/utils/routes'
import usePermission from 'src/hooks/usePermission'
import useMounted from 'src/hooks/useMounted'

const treeService = new TreeService()

const TreeUpdate: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const { id: treeId } = useParams()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [tree, setTree] = React.useState<ITree>()

  React.useEffect(() => {
    const fetchTree = (): void => {
      treeService
        .fetchById(treeId)
        .then((tree) => {
          isMounted.current && setTree(tree)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('update_tree') && fetchTree()
  }, [userCan, treeId, isMounted])

  if (!userCan('update_tree')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading ? 'Location' : tree ? `${tree.name}` : 'Location not found'
        }
        backText="Locations"
        onBack={() => history.push(route('trees'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Location Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              {!isLoading && tree ? (
                <TreeForm
                  type="update"
                  tree={tree}
                  updateTree={(updatedValues) => {
                    setTree({ ...tree, ...updatedValues })
                  }}
                />
              ) : null}

              {!isLoading && !tree ? (
                <div className="my-5">
                  <NotFound summary="Tree not found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(TreeUpdate)
)
