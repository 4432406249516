import React from 'react'
import styles from './Spinner.module.scss'

interface IProps {
  size?: 'sm' | 'md' | 'lg'
  variant?: 'grey' | 'primary' | 'white'
  button?: boolean
}

const Spinner: React.FC<IProps> = ({ size, variant, button }) => (
  <figure
    className={`${styles.spinner} ${styles[size]} ${styles[variant]} ${
      button ? styles.button : ''
    }`}
  />
)

Spinner.defaultProps = {
  size: 'md',
  variant: 'grey',
  button: false,
}

export default Spinner
