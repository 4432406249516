/**
 * Flexible image container for forums.
 */

import React from 'react'

import './ForumImage.scss'

interface IProps {
  src: string
  title?: string
}

const ForumImage: React.FC<IProps> = ({ src, children, ...props }) => {
  return (
    <>
      <div
        className="forumImage"
        style={{
          backgroundImage: `url(${src})`,
        }}
        {...props}
      >
        {children ? <div className="forumImage__meta">{children}</div> : null}
      </div>
    </>
  )
}

export default ForumImage
