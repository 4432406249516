import { IManufacturer } from './manufacturer'

export enum TreeType {
  Country = 'COUNNTRY',
  Region = 'REGION',
  State = 'STATE',
}
export interface ITree {
  _id?: string
  name: string
  type: TreeType
  parent: string
  parent_obj: ITree
  status: 'active' | 'inactive'
  manufacturer: IManufacturer
  manufacturer_id?: string
}
