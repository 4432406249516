import BaseService from './BaseService'
import { TreeType } from 'src/services/types/tree'

class BranchService extends BaseService {
  constructor() {
    super('branch')
  }

  fetchStateBranchByRetailLocation(retailLocationId: string): Promise<any> {
    const formData = new FormData()
    formData.append('retail_location_id', retailLocationId)

    return new Promise((resolve, reject) => {
      const cacheKey = `branches_retail_location_${retailLocationId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          this.http
            .post(`branch/read_by_retail_location`, formData)
            .then(({ data }) => {
              if (data.code === 200 && data.data.length) {
                const branch = data.data.find(
                  (branch) => branch.tree.type === TreeType.State
                )

                this.cache.set(cacheKey, branch)
                resolve(branch)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default BranchService
