export const docs = `# ClearBlue Manual v1.0

![header image](./docs_images/header.png)

This is a brief manual to using the ClearBlue platform's applications: [mobile](https://play.google.com/store/apps/details?id=com.suplias.weshelfie) and [web](https://clearblue.weshelfie.com).

## Version

This document is at version 1.0.

## Contents

1. [The ClearBlue Platform](#the-clearblue-platform)
 
2. [The Web Application](#the-web-application)
	* [Dashboard](#dashboard)
	* [Users and Permissions](#users-and-permissions)
	* [Brands](#brands)
	* [Categories](#categories)
	* [Products](#products)
	* [Competition](#competition)
	* [Retailers](#retailers)
	* [Retail Stores](#retail-stores)
	* [Merchandisers](#merchandisers)
	* [Tickets](#tickets)
	* [Schedules](#schedules)
	* [Targets](#targets)
	* [Locations](#locations)
	* [Configuration](#configuration)
	* [Reports](#reports)
	* [Activity](#activity)
	* [Attendance](#attendance)
	* [Sales](#sales)
	* [Sales Summary](#sales-summary)
	* [Inventory](#inventory)
	* [Out of Stock](#out-of-stock)
	* [Out of Stock Summary](#out-of-stock-summary)
	* [Assortment](#assortment)
	* [Visibility](#visibility)
	* [Expiry](#expiry)
	* [Pricing](#pricing)
	* [Average Pricing](#average-pricing)
	* [Perfect Store Reports](#perfect-store)

3. [The Merchandiser Mobile Application](#the-merchandiser-mobile-application)
	* [Home](#home)
	* [My Tasks](#my-tasks)
	* [Low Stock Report](#low-stock-report)
	* [My Inbox](#my-inbox)
	* [Shipments and Returns](#shipments-and-returns)
	* [Feedback](#feedback)
	* [Shelfie](#shelfie)

4. [Support](#support)

	
## The ClearBlue Platform
ClearBlue offers a smarter way to track insights from modern retail stores.

The platform features a [mobile application for merchandisers](https://play.google.com/store/apps/details?id=com.suplias.weshelfie) and a [web application](https://clearblue.weshelfie.com) for administrative, regular and client users.

## The Web Application

The ClearBlue web application is used by administrative users to set up and configure SKUs, retail stores, merchandisers and task schedules as well as view reports.

### Dashboard

![dashboard menu](./docs_images/menu-dashboard.png)

The ClearBlue dashboard shows a summary of metrics for the selected month and is available to all users of the web application. As the dashboard shows live data, it might take a little while to calculate the metrics to be displayed. It is cached for 6 hours to make this faster. Thus metrics for any month will be recalculated if the last time it was viewed/calculated is older than 6 hours.

![dashboard date](./docs_images/dashboard-date.png)

The dashboard is divided into sections as follows:

#### Sales
![dashboard sales](./docs_images/dashboard-sales.png)

The dashboard sales metric shows secondary (sell out), primary (sell in) and the target for the selected month. Pie charts for the sales per region and across categories are also available.

One would note that secondary sales may show a negative value. The list of stores which cause this value can be seen using the "See Erring Stores" link.

Also note that only positive sales values contribute to the data shown in the pie charts. Any other values will be ignored.

One may wonder why these negative values come up. Please refer to the [Sales](#sales) section.

#### On Shelf Availability
![dashboard osa](./docs_images/dashboard-osa.png)

On Shelf Availability metric shows graphs per region and across categories of SKUs stocked in the retail stores. Its values are a percentage of SKUs stocked against all SKUs.

#### Location On Shelf
![dashboard eye level](./docs_images/dashboard-eye-level.png)

The Location On Shelf metric shows graphs per region and across categories of category placements (eye level or not) in the retail stores.

#### Share Of Shelf
![dashboard shelf share](./docs_images/dashboard-shelf-share.png)

Share Of Shelf metric shows graphs per region and across categories of category share of shelf in the retail stores.

#### Merchandiser Effectiveness
![dashboard merch](./docs_images/dashboard-merch.png)

The Merchandiser Effectiveness metric shows percentage completion values for retail location scheduled tasks. It also shows these values across region and state.

### Users and Permissions
The ClearBlue web application allows for use by three user types: client, regular and administrative users.

The table below shows the roles and the allowed actions where:

* View: is the ability of a user to see information on their profile
* Export: ability of a user to export reports, lists and similar
* Modify (and Upload): ability of a user to modify data such as brands, SKUs, stores or merchandisers and upload especially templates like targets and schedules.


Role          | View        | Export     | Modify (and Upload)
:------------ |:-----------:|:----------:| :----------:
Client        | V           | X          | X
Regular       | V           | V          | X
Administrator | V           | V          | V

To know your current role as a user or see other details or change your password, use the "Edit" link on the side menu as shown below.

![user profile](./docs_images/user-profile.png)

### Brands

![brand menu](./docs_images/menu-brands.png)

New brands can be created by clicking the "Create Brand" button while existing brands can be modified by clicking the "Edit" link.
![brands](./docs_images/brands.png)

Brands can be set active or inactive, have the assigned images or names changed also.

![brands](./docs_images/brands-edit.png)

### Categories

![categories menu](./docs_images/menu-categories.png)

New categories can be created by clicking the "Create Category" button while existing categories can be modified by clicking the "Edit" link.

![categories](./docs_images/categories.png)

When editing a category, its status and name can be changed alongside the products and competition assigned to that category.

![category products](./docs_images/categories-edit.png)

Previous assigned products or competition can be removed and new ones added using the drop down as shown and clicking the "Add" button.

![category products](./docs_images/categories-edit-3.png)

### Products

![products menu](./docs_images/menu-products.png)

New products can be created by clicking the "Create Product" button while existing products can be modified by clicking the product.

![products](./docs_images/products.png)

When editing a product, its status, name and other properties can be changed.

![products edit](./docs_images/products-edit.png)

Please pay attention to the case count property which is used to resolve cases to number of units. If improperly entered, inventory values from shipments, returns and store checks could be erroneous. Also, the price and the recommended retail price (RRP) are used to calculate secondary sales and inventory values (for expiry reports) and should be entered appropriately.

### Competition

![competition menu](./docs_images/menu-competition.png)

Products of competition can be created by clicking the "Create Competition" button while existing competition can be modified by clicking.

![competition](./docs_images/competition.png)

When editing competition, its status, name and other properties can be changed.

![competition edit](./docs_images/competition-edit.png)

Please pay attention to the size property which is used for prize per size calculations.

### Retailers

![retailers menu](./docs_images/menu-retailers.png)

Retailers, also called "chains", are a group of retail stores of a common brand or management. Retailers can have one or several retail locations created on the application.

![retailers](./docs_images/retailers.png)

Retailers are created by clicking the "Create Retailer" button. Existing retailers can be modified by following the "Edit" link.

![retailers create](./docs_images/retailer-create.png)

You would notice that on the "New Retailer" page, the "Create Bulk" button allows you to upload retailers un bulk using a template.

![retailers bulk](./docs_images/retailers-bulk.png)

While editing a retailer, you can set its status to inactive (from active) or change its name.

![retailers edit](./docs_images/retailers-edit.png)

### Retail Stores

Retail stores (also called retail locations) are the actual store locations.

![retailer stores menu](./docs_images/menu-retail-stores.png)

Retail stores are created by clicking the "Create Retail Location" button. Existing retail stores can be modified by following the "Edit" link.

![retailer stores](./docs_images/retail-stores.png)

When a retail store is being modified, you can set up, change or disable its location (for geofencing purposes) and also specifiy its state.

![retailer stores edit](./docs_images/retail-stores-edit.png)

Retail stores can also be searched or filtered by status or by retailer.

![retailer stores filter](./docs_images/retail-stores-filter.png)

Retail store schedules and targets are explained in the [schedule](#schedules) and [target](#targets) sections.

### Merchandisers

![merchandisers menu](./docs_images/menu-merchandisers.png)

Merchandisers are created by clicking the "Create Merchandiser" button or using the "Add to Store" link on an existing merchandiser (to associate the merchandiser with another store).

![merchandisers](./docs_images/merchandisers.png)

Note that when editing a merchandiser, one can change their password and even see the mobile app version they currently use (for support cases especially).

![merchandisers edit](./docs_images/merchandisers-edit.png)


### Tickets

![tickets menu](./docs_images/menu-tickets.png)

Tickets are support messages sent by the merchandisers in the various categories: technical, store among other issues.

![tickets](./docs_images/tickets.png)

One can see the senders, store names and the body of the submitted tickets and close them, write a supporting note for internal direction or similar.

![tickets details](./docs_images/tickets-details.png)

### Schedules

![schedules menu](./docs_images/menu-schedules.png)

Schedules determine the various tasks the merchandisers will be prompted to do in the stores.

![schedules](./docs_images/schedules.png)

One can see the schedule period - how often it should be done, the task group type for example, tracking stock, and the location in the store the task should be done (primary/home, secondary shelves or at the back store).

![schedules edit](./docs_images/schedules-edit.png)

Note that schedules can be clicked to modify their properties, including setting them inactive.

For most instances, schedules will be uploaded in bulk using the template gotten when you click "Create Schedule" and "Create Bulk".

![schedules upload](./docs_images/schedules-upload.png)

### Targets

![targets menu](./docs_images/menu-targets.png)

Targets set for retail stores for each month the expected sales value among others.

![targets](./docs_images/targets.png)

Targets will show the retailer, store and month and value set. Important also is the tag, which determines the target type e.g SALES.

![targets edit](./docs_images/targets-edit.png)

Targets can be modified, changing the store they are applied to or their month and year.

For most instances, targets will be uploaded in bulk using the template gotten when you click "Create Target" and "Create Bulk".

![targets upload](./docs_images/targets-upload.png)

### Locations

![locations menu](./docs_images/menu-locations.png)

Retail stores are arranged in a location structure: in states, which are then in regions all in a country. This is all set up in locations.

![targets](./docs_images/locations.png)

Locations have a type and a parent. This is usually obvious when creating or modifying them.

![targets](./docs_images/locations-create.png)

For instance, a new country has no need for a parent. But creating a new region or state will need its parent (country or region respectively) to be specified.

![targets](./docs_images/locations-edit.png)

### Configuration

![locations menu](./docs_images/menu-config.png)

The configuration is used to set up must stock SKUs for especially competition and enable retail stores. New retail stores when created must be added to an existing configuration as a final step.


### Reports

The ClearBlue application has a number of reports for which parameters must be specified first. These parameters define the contexts of the generated report. You will also note that when parameters are set for a single report, they will persist unto the next report so that you do not have to re-enter parameters.

The report parameters include:

#### Retail Stores

![retail store filter](./docs_images/filter-retail-store.png)

Reports which require one to specify the retail store(s) will show a filter one can modify. A search can be used to pull up one or more desired stores to select. Selecting all is also an option (though we would not recommend that as the report might take a while to be generated). While you can manually pick which stores to include in your report, you can also use the other tabs to help pick your retail stores:

* retailer, which will select all the stores of a chain
* channel, selecting all stores of the selected channel or channels
* state, selecting all stores in a desired state or states and
* region, picking out all the stores in a region

#### Tasks

![task filter](./docs_images/filter-task.png)

Some reports can be filtered by merchandiser tasks. Select all will, as the name implies, select all. Or else one can pick one or more tasks such as assortment, primary visibility or price check among others.

#### Entity

![entity filter](./docs_images/filter-entity.png)

Some reports can be toggled by entity. For instance, sales summary can be shown by retail store or by merchandiser.

#### Category

![category filter](./docs_images/filter-category.png)

Some reports such as visibility are category based. Such reports will allow you to select the categories for which to generate reports for. Make a custom selection or select all.

#### Product

![product filter](./docs_images/filter-product.png)

Some reports are product based. Such reports will allow you to select the products for which to generate reports for. All products can be selected or a few in a custom manner or by category which will select all products in the selected category or categories.

#### Competition

![competition filter](./docs_images/filter-competition.png)

Some reports are competition product based. All competition can be selected or a few in a custom manner or by category which will select all competition in the selected category or categories.

#### Date

Reports typically require one of two types of date filters:

* the simple date filter requires you to pick a month and a year
![date filter](./docs_images/filter-date-1.png)

* the date range filter requires a start date and an end date
![date filter](./docs_images/filter-date-2.png)

#### Period

Note that most times, a date filter will be followed closely by a period filter. A period is defined in this context as the time interval a particular date range should be split into.

![period filter](./docs_images/filter-period.png)

Therefore, a visibility report for October 2020 with a selected weekly period will show visibility entries once a week for October.

#### Threshold

![threshold filter](./docs_images/filter-threshold.png)

The threshold filter, especially for pricing reports, is a value which sets the minimum price deviation (percentage) from the recommended retail price the report will show.

### Activity

The activity report shows tasks performed in a retail store or by a manufacturer. Its parameters include:

* entity: the context of the report: merchandiser or retail store
* tasks: the tasks for which to display activity reports for. One can select all or a single activity such as secondary visibility or competitive intelligence
* retail stores: retail store data to include in the report
* date: the month and year to return activities for
* period: the interval for which to show the report. Most price tasks are done every two weeks and such "bi-weekly" would be a good period for such a report.

The results for the merchandiser entity is as show below, with tasks marked as done or not in the selected period.

![activity report](./docs_images/report-activity-merch-1.png) 

There is also a graph view available for visual summaries. You will note that tasks are colour coded and filters for both tasks and dates are shown on the right to narrow things down.

![activity report](./docs_images/report-activity-merch-2.png)

The report can also be exported for users with the appropriate role permissions.

For the retail store entity context, the report and graph is also similar.
![activity report](./docs_images/report-activity-store-1.png)
![activity report](./docs_images/report-activity-store-2.png)  

### Attendance

The attendance report tracks the time merchandisers clock in daily at work (on the app) and shows adherence to the set schedule.

As such, the report parameters are then:

* tasks: merchandiser activities to follow
* retail stores: a selection of retail stores to show attendance for (some merchandisers rove, if any of their stores are not specified, they would not appear on the report)
* date: the month and year
* period: obviously, clock in timestamps will only be shown for a daily period as looking at weekly or other periods will give aggregated attendance data for such interval.

The attendance report shows the merchandiser name, the retail store and, for daily periods, the time they clocked in. Tasks will also be shown if they are scheduled (non-scheduled tasks will be identified as "-"), performed or not performed.

![attendance report](./docs_images/report-attendance.png)

Date filters are also available to narrow down the data. The report can also be exported.

### Sales Summary

A sales summary, as the name implies, shows a single line item for the sales made.

The report parameters include:

* entity: sales summary can be shown in one of two contexts: by merchandiser or by retail store
* retail stores: the list of retail stores to include in the report
* products: selecting all which have the sales be a sum of all products. One can choose to show a summary of sales of a single category or of a single product.
* date: the month and year

You will notice that the report shows, for a retail store entity, the store name, the sales (month to date), targets if they exist and the percentage achieved. You can also see primary sales (sales in) if it has been entered for the store. 

![sales summary report](./docs_images/report-sales-summary-store.png)

For a merchandiser entity context, you will notice that sales entries for all of a merchandiser's stores are summed up.

![sales summary report](./docs_images/report-sales-summary-merch.png)

This report is also exportable. One should note that the sales summary for the current month for a merchandiser is what is shown on the merchandiser app.


### Inventory

An inventory report will show the state of the inventory of a store by week alongside shipments received and stock returned.

Note that the inventory is a sum of stock counts of the primary/home shelf, secondary/gondola/free standing units and the warehouse/back store. This is in addition to shipments received within the week less returns made.

The report parameters are:

* retail stores: the selection of stores one is interested in
* products: the selection of products to show inventory for
* date: month and year

The inventory report will show for each week of the month the quantity of stock in the store, its value, stock supplied and returned.

![inventory report](./docs_images/report-inventory.png)

This report, like most others is also exportable.

### Sales

A sales report shows sales per week of products in a store or stores. It is one layer above an inventory report and thus has the same parameters

Sales is basically the value of a change in inventory from week to week. What this means is that for week 1, sales will always be zero such that the sales for week two is then the difference in the inventory of week 1 and week 2. This would imply that sales will be positive if the inventory in week 2 is less than of week 1.

![sales report](./docs_images/report-sales-1.png)

If inventory increases from a week to its corresponding week, there has to be an accompanying shipment or return entry or else sales will be reported as negative (and displayed in red).

![sales report](./docs_images/report-sales-2.png)

Stock check tasks have been scheduled for once every week to take snapshots for these reasons. Shipments and returns can be entered at any time and can be back dated so reports can be accurate.

### Out of Stock Summary

The out of stock summary reports shows products and the days at which they are projected to go out of stock. It is a layer above the sales report and thus has the same parameters as the sales and inventor reports.

![out of stock report](./docs_images/report-out-of-stock-1.png)

The report shows appopriately colour coded projected out of stock durations (in days).

![out of stock report](./docs_images/report-out-of-stock-2.png)

### Out of Stock

As the out of stock summary report shows a current snapshot of the state of a store or stores, the out of stock report shows for each week of the month, the projections for that week.

### Assortment

The assortment reports of products and competition shows the stock status in-store: if a product is stocked or not by the retail store.

Its parameters are thus:

* retail stores: a selection of stores we want the report to cover
* products: a selection of products or competition
* date range: a start and end date to define the report
* period: interval for which to show assortment

The report will show products (or competition) and if they are stocked or not by the store during that period (day, week or otherwise).

![assortment report](./docs_images/report-assortment-1.png)

If no entries have been recorded, it will also be shown.

![assortment report](./docs_images/report-assortment-2.png)

### Visibility

Visibility reports (primary, secondary or of competition) will show details of the visibility questions (including images when they exist) asked of categories.

The parameters include:

* retail stores: a selection of retail stores to show
* categories: category selection for the report
* date range
* period

The report will show for each store: the category and the details depending on the report.

![visibility report](./docs_images/report-viz-1.png)

Clicking any entry on the primary or secondary visibility reports will show more details:

![visibility report](./docs_images/report-viz-2.png)

Note that this image can be downloaded also.

![visibility report](./docs_images/report-viz-3.png)


### Expiry

The expiry report shows stock in stores and a measure of their closeness to expiry.

The report parameters are:

* retail stores
* products
* date range
* period


The report will show quantity of stock in various stages of nearness to expiry as well as the value of stock which would soon be lost.

![expiry report](./docs_images/report-expiry.png)

### Pricing

The pricing report shows recorded price of stock and deviation from the recommended retail price.

Its parameters are:

* retail stores
* products
* date range
* period: note that a bi-weekly period works best
* threshold: the minimum deviation percentage of recorded price from the recommended. Note that threshold can be negative too (to show prices less than the recommended)

The report shows the product, recommended retail price and the recorded prices, price per size and the deviation as a percentage.

![pricing report](./docs_images/report-pricing.png)

Note that only deviations greater or equal to the provided threshold will show.

### Average Pricing

An average pricing report will show the average price of a stock and a graph of its trend.

Its parameters include:

* retail stores: a selection of stores to be used to calculate the average
* products: product selection
* date range
* period: price checks are done every two weeks, a bi-weekly period is thus recommended


The report will show an average over the selected stores for the period, including the maximum and minimum prices.

![average pricing report](./docs_images/report-avg-pricing-1.png)

A trend graph is also available to see visually as prices rise and dip.

![average pricing report](./docs_images/report-avg-pricing-2.png)


### Perfect Store Reports

The perfect store reports are several measures of a store's closeness to the ideal in on shelf availability (OSA), secondary shelf and eye level (location on shelf - LOS) placements as well as share of shelf.

The report parameters are elements which have been previously explained.

The Perfect Store (OSA) report shows SKUs in stores versus required SKUs.

![perfect store report](./docs_images/report-perfect-osa.png)

The Perfect Store (Secondary Shelf) report shows stores with a free standing unit (FSU) versus total stores selected.

![perfect store report](./docs_images/report-perfect-sec.png)

The Perfect Store (LOS) report shows stores with eyelevel category presence versus total stores selected.

![perfect store report](./docs_images/report-perfect-eye-level.png)

The Perfect Store (Share of Shelf) report shows, by category, store share of shelf versus total category shelf.

![perfect store report](./docs_images/report-perfect-shelf-share.png)

## The Merchandiser Mobile Application

The ClearBlue mobile app for merchandisers is an Android app available for versions above 6.0, enabling merchandisers perform their tasks as well as record shipments, returns and fill surveys.

### Home

![mobile home](./docs_images/mobile-home.jpeg)

The ClearBlue app home shows easily all actions available to the merchandiser. From the name of the store "A Sample Store" in this example to the current date, secondary (sell out) and primary (sell in) sales values among other details.

It is also important to note the top right hand information button which shows the current app version.

![mobile version](./docs_images/mobile-version.jpeg)

Most times, merchandisers fail to update their applications and as such each support request by a merchandiser needs to begin with the key question: what version of the app are you using?

### My Tasks

The app home screen shows the "My Tasks" menu and the count of tasks yet to be done by the merchandiser. Please note that merchandisers have to be in their store location or else an "Out of Zone" message will be displayed. 

The task summary page is as shown below. Do well to note the "Last synced" date at the bottom of the task summary page. If during the course of a merchandiser's use of the app, the network connection falters, tasks will be saved offline and then synchronised as soon as connection is re-established. This synchronisation can also be triggered manually by dragging the task summary screen downwards.

![mobile tasks](./docs_images/mobile-tasks.jpeg)

You will note that tasks are in categories:

* primary shelf
* gondola / free standing unit (FSU)
* back store
* competitive intelligence

It is important to note that before any tasks for the day is done, merchandisers would need to start the day. 

![mobile start](./docs_images/mobile-start.jpeg)

The tasks are usually by category and are self descriptive.

Tasks done on the primary shelf include shelf check counts, primary visibility and price checks.

![mobile task primary](./docs_images/mobile-task-pri.jpeg)

For the gondola/FSU, if they exist in the store, shelf check counts and secondary visibility can be done for the categories.

![mobile task fsu](./docs_images/mobile-task-fsu.jpeg)

For the back store, stock check counts and expiry tracking are the tasks to be done. One will note that in instances where expiry tracking and stock check are scheduled together, they will appear as a single entry (as expiry tracking incorporates stock check also).

![mobile task back store](./docs_images/mobile-task-back.jpeg)

### Low Stock Report

As stock checks are done by the merchandiser, SKUs identified from these records to be running low will be shown in the low stock report.

The low stock report is only to prompt the merchandiser.

### My Inbox

The "My Inbox" menu leads to surveys which are to be answered either by the merchandiser or to be filled by them on behalf of customers.

![mobile inbox](./docs_images/mobile-inbox.jpeg)

Starting one of the surveys which show the questions and enable the merchandiser answer accordingly. Surveys for merchandisers will cease to be shown once they are answered. Shopper surveys will keep being displayed until turned off.

![mobile survey](./docs_images/mobile-survey.jpeg)

### Shipments and Returns


![mobile shipment and returns](./docs_images/mobile-shipment-returns.jpeg)

As stock is delivered to the store (and some returns made), the merchandiser needs to record these events accurately when they happen. Note that shipments and returns can be entered even when the merchandiser is not physically present in the store.

![mobile shipment date](./docs_images/mobile-shipment-date.jpeg)

Of primary interest is the date the shipment was made. The merchandiser has to enter as accurately as possible when the shipment was received into the store. By default, the current date will be used. The merchandiser can then back date the shipment especially for instances when they were not in the store during delivery.

![mobile shipment date](./docs_images/mobile-shipment-progress.jpeg)

Shipments and returns are easy to enter. A category is first selected and then the actual cases and units shipped or returned are entered.

![mobile shipment date](./docs_images/mobile-shipment-details.jpeg)

Merchandisers sometime forget to click the submit button which concludes the shipment or return. If submission is not done, the values entered are ignored.

![mobile shipment date](./docs_images/mobile-return-date.jpeg)


### Feedback

![mobile feedback](./docs_images/mobile-feedback.jpeg)

Merchandisers are certain to run into issues in their store. The feedback mechanism is present to help them submit complaints, quality issues and the like.

![mobile feedback details](./docs_images/mobile-feedback-detail.jpeg)

Having them describe the issue accurately, taking pictures where necessary and then submitting the feedback is all that is needed.

### Shelfie

Merchandisers can take a peek into the images their colleagues upload from other retail stores from the Shelfie menu. Primary and secondary visibility entries for categories and stores are shown.

![mobile shelfie](./docs_images/mobile-shelfie.jpeg)

## Support

For any questions or suggestions, please send an email to [support@suplias.com](mailto:support@suplias.com).
`
