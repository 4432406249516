import React from 'react'

import { Nav, Image } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { BsChevronDoubleLeft } from 'react-icons/bs'

import SidebarSection from './SidebarSection'
import NavOverlay from './NavOverlay'

import { Role } from 'src/config/roles'

import usePermission from 'src/hooks/usePermission'

import { setConfig } from 'src/store/config/configActions'

import { asset } from 'src/utils/file'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import styles from './Sidebar.module.scss'

import Logo from 'src/assets/images/shelfie-icon-rounded.png'
import SidebarHead from './SidebarHead'
import {
  FiCheckSquare,
  FiBarChart2,
  FiGrid,
  FiImage,
  FiLayers,
  FiGitMerge,
  FiBriefcase,
  FiUsers,
  FiGitPullRequest,
  FiMap,
  FiTarget,
  FiSettings,
  FiCalendar,
  FiBox,
  FiMessageSquare,
  FiUser,
  FiHome,
  FiFileText,
} from 'react-icons/fi'

const Sidebar: React.FC = () => {
  const dispatch = useDispatch()
  const { userCan } = usePermission()

  const { user, auth, config } = useSelector((state: IState) => state)
  const isAuthenticated = !!auth.token
  const sidebarOpen = config.sidebarOpen

  const toggleSidebar = (): void => {
    dispatch(setConfig({ sidebarOpen: !sidebarOpen }))
  }

  const AppLogo = React.useMemo(() => {
    return user.role !== Role.RootAdmin && user?.manufacturer?.upload
      ? asset(user?.manufacturer?.upload?.path)
      : Logo
  }, [user])

  return (
    <>
      {isAuthenticated && (
        <>
          <aside
            className={`${styles.sidebar} ${sidebarOpen ? styles.open : ''}`}
          >
            <div className={styles.closeIcon} onClick={toggleSidebar}>
              <BsChevronDoubleLeft size={14} />
            </div>

            <SidebarHead user={user} />

            <Nav className="mr-auto flex-column mt-2">
              {/* Insights & Data */}
              <SidebarSection
                permission="view_sidebar_dashboard"
                title="Insights & Data"
              >
                <Nav.Link as={NavLink} to={route('dashboard')} exact>
                  <FiGrid />
                  <span>Dashboard</span>
                </Nav.Link>
                {userCan('view_reports') && (
                  <Nav.Link as={NavLink} to={route('reports')}>
                    <FiBarChart2 />
                    <span>Reports</span>
                  </Nav.Link>
                )}
                {userCan('view_forums') && (
                  <Nav.Link as={NavLink} to={route('forums')}>
                    <FiImage />
                    <span>Community</span>
                  </Nav.Link>
                )}
                {userCan('view_surveys') && (
                  <Nav.Link as={NavLink} to={route('surveys')}>
                    <FiCheckSquare />
                    <span>Surveys</span>
                  </Nav.Link>
                )}
              </SidebarSection>

              {/* Admin */}
              <SidebarSection permission="view_sidebar_admin" title="Admin">
                {userCan('view_channels') && (
                  <Nav.Link as={NavLink} to={route('channels')}>
                    <FiGitMerge />
                    <span>Channels</span>
                  </Nav.Link>
                )}
                {userCan('view_manufacturers') && (
                  <Nav.Link as={NavLink} to={route('manufacturers')}>
                    <FiBriefcase />
                    <span>Organizations</span>
                  </Nav.Link>
                )}
                {userCan('view_staff') && (
                  <Nav.Link as={NavLink} to={route('staff')}>
                    <FiUsers />
                    <span>Staff</span>
                  </Nav.Link>
                )}
              </SidebarSection>

              {/* Products */}
              <SidebarSection
                permission="view_sidebar_products"
                title="Products"
              >
                {userCan('view_brands') && (
                  <Nav.Link as={NavLink} to={route('brands')}>
                    <FiLayers />
                    <span>Brands</span>
                  </Nav.Link>
                )}
                {userCan('view_categories') && (
                  <Nav.Link as={NavLink} to={route('categories')}>
                    <FiGitPullRequest />
                    <span>Categories</span>
                  </Nav.Link>
                )}
                {userCan('view_products') && (
                  <Nav.Link as={NavLink} to={route('products')}>
                    <FiBox />
                    <span>My Products</span>
                  </Nav.Link>
                )}
                {userCan('view_competition') && (
                  <Nav.Link
                    as={NavLink}
                    to={route('competition')}
                    title="Competition Products"
                  >
                    <FiBox />
                    <span>Comp. Products</span>
                  </Nav.Link>
                )}
              </SidebarSection>

              {/* Outlets */}
              <SidebarSection permission="view_sidebar_outlets" title="Outlets">
                {userCan('view_retailers') && (
                  <Nav.Link as={NavLink} to={route('retailers')}>
                    <FiHome />
                    <span>Retailers</span>
                  </Nav.Link>
                )}
                {userCan('view_retail_locations') && (
                  <Nav.Link as={NavLink} to={route('retail_locations')}>
                    <FiHome />
                    <span>Retail Stores</span>
                  </Nav.Link>
                )}
                {userCan('view_merchandisers') && (
                  <Nav.Link as={NavLink} to={route('merchandisers')}>
                    <FiUser />
                    <span>Merchandisers</span>
                  </Nav.Link>
                )}
                {userCan('view_tickets') && (
                  <Nav.Link as={NavLink} to={route('tickets')}>
                    <FiMessageSquare />
                    <span>Tickets</span>
                  </Nav.Link>
                )}
              </SidebarSection>

              <SidebarSection
                permission="view_sidebar_settings"
                title="Settings"
              >
                {userCan('view_schedules') && (
                  <Nav.Link as={NavLink} to={route('schedules')}>
                    <FiCalendar />
                    <span>Schedules</span>
                  </Nav.Link>
                )}
                {userCan('view_targets') && (
                  <Nav.Link as={NavLink} to={route('targets')}>
                    <FiTarget />
                    <span>Targets</span>
                  </Nav.Link>
                )}
                {userCan('view_trees') && (
                  <Nav.Link as={NavLink} to={route('trees')}>
                    <FiMap />
                    <span>Locations</span>
                  </Nav.Link>
                )}
                {userCan('view_configurations') && (
                  <Nav.Link as={NavLink} to={route('configurations')}>
                    <FiSettings />
                    <span>Config</span>
                  </Nav.Link>
                )}
              </SidebarSection>

              <div className="mt-4"></div>
              <Nav.Link as={NavLink} to={route('docs')} exact>
                <FiFileText />
                <span>Documentation</span>
              </Nav.Link>

              <div className="my-5"></div>
              <div className="my-5"></div>
            </Nav>
          </aside>

          {/* Mini sidebar */}
          <aside
            className={`${styles.sidebar} ${styles.mini} ${
              sidebarOpen ? '' : styles.open
            }`}
          >
            <div className={styles.header}>
              <Image
                alt="App Logo"
                className={`${styles.logo} cursor-pointer`}
                src={AppLogo}
                onClick={toggleSidebar}
                roundedCircle
              />
            </div>

            <Nav className="mr-auto flex-column">
              <div className="mt-3"></div>
              <Nav.Link as={NavLink} to={route('dashboard')} exact>
                <NavOverlay icon={<FiGrid />} title="Dashboard" />
              </Nav.Link>

              {userCan('view_channels') && (
                <Nav.Link as={NavLink} to={route('channels')}>
                  <NavOverlay icon={<FiGitMerge />} title="Channels" />
                </Nav.Link>
              )}
              {userCan('view_manufacturers') && (
                <Nav.Link as={NavLink} to={route('manufacturers')}>
                  <NavOverlay icon={<FiBriefcase />} title="Organizations" />
                </Nav.Link>
              )}
              {userCan('view_staff') && (
                <Nav.Link as={NavLink} to={route('staff')}>
                  <NavOverlay icon={<FiUser />} title="Staff" />
                </Nav.Link>
              )}

              {/* Staff routes */}
              {userCan('view_reports') && (
                <Nav.Link as={NavLink} to={route('reports')}>
                  <NavOverlay icon={<FiBarChart2 />} title="Reports" />
                </Nav.Link>
              )}
              {userCan('view_forums') && (
                <Nav.Link as={NavLink} to={route('forums')}>
                  <NavOverlay icon={<FiImage />} title="Community" />
                </Nav.Link>
              )}
              {userCan('view_surveys') && (
                <Nav.Link as={NavLink} to={route('surveys')}>
                  <NavOverlay icon={<FiCheckSquare />} title="Surveys" />
                </Nav.Link>
              )}
              {userCan('view_tickets') && (
                <Nav.Link as={NavLink} to={route('tickets')}>
                  <NavOverlay icon={<FiMessageSquare />} title="Tickets" />
                </Nav.Link>
              )}
            </Nav>
          </aside>
        </>
      )}
    </>
  )
}

export default Sidebar
