import React, { useEffect, useState } from 'react'

import pluralize from 'pluralize'
import { Container, Table, Card, Button, Badge } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import SearchInput from 'src/components/Form/SearchInput'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'

import useGlobalFilters from 'src/hooks/useGlobalFilters'
import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'

import { ISurvey } from 'src/services/types/survey'
import Error401 from 'src/pages/Error/401.page'

import SurveyService from 'src/services/SurveyService'
import { IState } from 'src/store'

import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'

const surveyService = new SurveyService()

const Surveys: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const user = useSelector((state: IState) => state.user)

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [surveys, setSurveys] = useState<ISurvey[]>()
  const { filters, setFilters } = useGlobalFilters('surveys')

  useEffect(() => {
    if (userCan('view_surveys')) {
      surveyService
        .fetchByManufacturer(user.manufacturer_id)
        .then((surveys) => {
          isMounted.current && setSurveys(surveys)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }
  }, [isMounted, userCan, user.manufacturer_id])

  const filteredSurveys = React.useMemo(() => {
    let filtered = surveys || []

    if (filtered.length) {
      if (filters.search) {
        const query_parts = filters.search.split(':').map((part) => part.trim())

        const query = filters.search
        filtered = filtered.filter((survey) => {
          if (query_parts?.length === 2 && survey?.[query_parts[0]]) {
            const [accessor, query] = query_parts
            return basicSearch(survey?.[accessor], query)
          }
          return (
            basicSearch(survey.title, query) ||
            basicSearch(survey.status, query) ||
            basicSearch(survey.type, query)
          )
        })
      }
    }

    return filtered
  }, [surveys, filters.search])

  const paginatedSurveys = React.useMemo(() => {
    return filteredSurveys ? paginate(filteredSurveys, filters.page) : []
  }, [filters.page, filteredSurveys])

  if (!userCan('view_surveys')) return <Error401 />

  return (
    <>
      <Header title="Surveys" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    {filteredSurveys
                      ? `${filteredSurveys.length} ${pluralize(
                          'survey',
                          filteredSurveys.length
                        )}`
                      : '0 surveys'}
                  </div>
                  <div className="ml-3">
                    <SearchInput
                      placeholder="Search surveys *"
                      value={filters.search}
                      onChange={(search) => setFilters({ search, page: 1 })}
                      disabled={isLoading || !filteredSurveys}
                    />
                  </div>
                </div>

                {userCan('create_survey') && (
                  <div>
                    <Link to={route('survey_create')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create Survey
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}

              {!isLoading && !paginatedSurveys.length ? (
                <div className="my-5">
                  <NotFound summary="No surveys found" />
                </div>
              ) : null}

              {!isLoading && paginatedSurveys.length ? (
                <>
                  <div className="table-wrapper padded">
                    <Table className="table-fit" hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th>Type</th>
                          <th className="sticky">Title</th>
                          <th>Status</th>
                          <th>Channel</th>
                          <th>Questions</th>
                          <th>Responses</th>
                          <th>Summary</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedSurveys.map((survey, index) => (
                          <tr key={survey._id}>
                            <td className="align-middle text-center">
                              {index + 1 + (filters.page - 1) * 10}
                            </td>
                            <td className="align-middle">
                              <Badge className={`flag flag--${survey.type}`}>
                                {survey.type}
                              </Badge>
                            </td>
                            <td className="align-middle sticky">
                              {/* Span enables truncating of text */}
                              <span>{survey.title}</span>
                            </td>
                            <td className="align-middle">
                              <Badge
                                className="text-capitalize"
                                variant={
                                  survey.status === 'active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {survey.status}
                              </Badge>
                            </td>
                            <td className="align-middle">
                              {survey.channel_details
                                ? survey.channel_details.name
                                : 'All Channels'}
                            </td>
                            <td className="align-middle text-center">
                              {survey.question_count
                                ? survey.question_count
                                : 0}
                            </td>
                            <td className="align-middle text-center">
                              {survey.answer_count
                                ? (
                                    survey.answer_count / survey.question_count
                                  ).toFixed()
                                : 0}
                            </td>
                            <td className="align-middle">
                              <Link
                                to={route('survey_update', {
                                  id: survey._id,
                                })}
                                className="action-link"
                              >
                                Edit
                              </Link>
                              &nbsp; &middot; &nbsp;
                              <Link
                                to={route('survey_summary', {
                                  id: survey._id,
                                })}
                                className="action-link"
                              >
                                View Summary
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </>
              ) : null}

              <Pagination
                className="my-4"
                totalItems={filteredSurveys.length}
                currentPage={filters.page}
                onChange={(page) => setFilters({ page })}
              />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Surveys
