import React from 'react'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'

const TableHead: React.FC<any> = ({ header, order, className, ...rest }) => {
  className = order
    ? `active ${className || ''}`
    : `sortable ${className || ''}`
  return (
    <th className={className.trim()} {...rest}>
      <div className="d-flex align-items-center no-justify-content-between">
        <span>{header}</span>
        <div className="d-flex flex-column">
          <BsCaretUpFill
            size={8}
            className={order === 'desc' ? 'active' : ''}
          />
          <BsCaretDownFill
            size={8}
            className={order === 'asc' ? 'active' : ''}
          />
        </div>
      </div>
    </th>
  )
}

export default TableHead
