import { SET_NOTIFICATIONS } from '../actionTypes'
import { Role } from 'src/config/roles'
import { ITicket, TicketStatus as Status } from 'src/services/types/ticket'
import TicketService from 'src/services/TicketService'

const ticketService = new TicketService()

export const fetchNotifications =
  () => async (dispatch: any, getState: any) => {
    const user = getState().user
    dispatch({ type: SET_NOTIFICATIONS, payload: { isLoading: true } })

    let open_tickets: ITicket[] = []

    /**
     * Fetch open tickets
     */
    await ticketService.fetchByStatus(Status.Open).then((tickets) => {
      if (user.role === Role.RootAdmin) {
        open_tickets = tickets
      } else {
        open_tickets = tickets.filter(
          (ticket) => ticket.recipient === user.manufacturer_id
        )
      }
    })

    dispatch({
      type: SET_NOTIFICATIONS,
      payload: {
        isLoading: false,
        openTickets: open_tickets,
      },
    })
  }
