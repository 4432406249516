import React from 'react'

import { InputGroup } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { timestampToDate } from 'src/utils/date'

interface IProps {
  /** Unix timestamp */
  start: number
  /** Unix timestamp */
  end: number
  onChange: (start: number, end: number) => void
}

const DateRangeInput: React.FC<IProps> = ({ start, end, onChange }) => {
  const startDate = start ? new Date(timestampToDate(start, false)) : null
  const endDate = end ? new Date(timestampToDate(end, false)) : null

  return (
    <InputGroup className="w-auto">
      <DatePicker
        selected={startDate}
        onChange={(newStartDate: Date) => {
          const newEndDate = newStartDate > endDate ? null : endDate
          onChange(newStartDate.getTime() / 1000, newEndDate?.getTime() / 1000)
        }}
        startDate={startDate}
        endDate={endDate}
        maxDate={new Date()}
        placeholderText="Start date"
        dateFormat="dd/MM/yyyy"
        className="form-control form-control-sm date"
      />
      <DatePicker
        selected={endDate}
        onChange={(newEndDate: Date) => {
          onChange(startDate.getTime() / 1000, newEndDate.getTime() / 1000)
        }}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        maxDate={new Date()}
        disabled={!startDate}
        placeholderText="End date"
        dateFormat="dd/MM/yyyy"
        className="form-control form-control-sm date"
      />
    </InputGroup>
  )
}

export default DateRangeInput
