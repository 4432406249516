export enum Role {
  RootAdmin = 'root_admin',
  Admin = 'admin',
  Sales = 'sales',
  Client = 'client',
}

export const root_admin = Role.RootAdmin

export const admin = Role.Admin
export const sales = Role.Sales
export const client = Role.Client

export const allStaff = [admin, sales, client]

export const everyone = [root_admin, ...allStaff]
