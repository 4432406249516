import React from 'react'

import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Card } from 'react-bootstrap'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import ManufacturerForm from 'src/components/Manuafacturer/ManufacturerForm'

interface IProps extends RouteComponentProps {
  userCan: (permission: string) => boolean
}

const ManufacturerCreate: React.FC<IProps> = ({ userCan, history }) => {
  if (!userCan('create_manufacturer')) return <Error401 />

  return (
    <>
      <Header
        title="New Organization"
        backText="Organizations"
        onBack={() => history.push(route('manufacturers'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Organization Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <ManufacturerForm />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect(() => ({}), { userCan })(withRouter(ManufacturerCreate))
