import React from 'react'

import { connect } from 'react-redux'
import { Container, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import ConfigurationService from 'src/services/ConfigurationService'
import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IConfiguration } from 'src/services/types/configuration'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import ConfigurationForm from 'src/components/Configuration/ConfigurationForm'
import ConfigurationMustStock from 'src/components/Configuration/ConfigurationMustStock'
import ConfigurationRetailLocations from 'src/components/Configuration/ConfigurationRetailLocations'

const configurationService = new ConfigurationService()

interface IProps extends RouteComponentProps {
  match: any
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const ConfigurationUpdate: React.FC<IProps> = ({
  match,
  history,
  user,
  userCan,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [configuration, setConfiguration] = React.useState<IConfiguration>()

  React.useEffect(() => {
    if (userCan('update_configuration')) {
      configurationService
        .fetchById(match.params.id)
        .then((configuration) => setConfiguration(configuration))
        .finally(() => setIsLoading(false))
    }
  }, [userCan, match.params.id])

  if (!userCan('update_configuration')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Configuration'
            : configuration
            ? `${configuration.title}`
            : 'Configuration not found'
        }
        backText="Configurations"
        onBack={() => history.push(route('configurations'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Configuration Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              {!isLoading && configuration ? (
                <ConfigurationForm
                  user={user}
                  type="update"
                  configuration={configuration}
                  updateConfiguration={(updatedValues) => {
                    setConfiguration({ ...configuration, ...updatedValues })
                  }}
                />
              ) : null}

              {!isLoading && !configuration ? (
                <div className="my-5">
                  <NotFound summary="Configuration not found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>

          {!isLoading && configuration ? (
            <>
              <div className="mt-3">
                <ConfigurationMustStock
                  type="product"
                  user={user}
                  configuration={configuration}
                />
              </div>

              <div className="mt-3">
                <ConfigurationMustStock
                  type="competition"
                  user={user}
                  configuration={configuration}
                />
              </div>

              <div className="mt-3">
                <ConfigurationRetailLocations
                  user={user}
                  configuration={configuration}
                />
              </div>
            </>
          ) : null}
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(ConfigurationUpdate)
)
