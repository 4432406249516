import React from 'react'

import pluralize from 'pluralize'
import { Container, Table, Card, Button, Badge } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs'
import { connect, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import SearchInput from 'src/components/Form/SearchInput'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'

import useGlobalFilters from 'src/hooks/useGlobalFilters'
import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'

import { IRetailer } from 'src/services/types/retailer'
import Error401 from 'src/pages/Error/401.page'

import { userCan } from 'src/store/user/userActions'
import RetailerService from 'src/services/RetailerService'
import { IState } from 'src/store'

import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'

const retailerService = new RetailerService()

const Retailers: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const user = useSelector((state: IState) => state.user)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [retailers, setRetailers] = React.useState<IRetailer[]>()

  const { filters, setFilters } = useGlobalFilters('retailers')

  React.useEffect(() => {
    const fetchRetailers = (): void => {
      retailerService
        .fetchByManufacturer(user.manufacturer_id)
        .then((retailers) => {
          isMounted.current && setRetailers(retailers)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_retailers') && fetchRetailers()
  }, [userCan, user.manufacturer_id, isMounted])

  const filteredRetailers = React.useMemo(() => {
    let filtered = retailers || []

    if (filtered.length) {
      if (filters.search) {
        const query = filters.search
        filtered = filtered.filter((retailer) => {
          return (
            basicSearch(retailer.name, query) ||
            basicSearch(retailer.status, query)
          )
        })
      }
    }

    return filtered
  }, [retailers, filters.search])

  const paginatedRetailers = React.useMemo(() => {
    return filteredRetailers ? paginate(filteredRetailers, filters.page) : []
  }, [filters.page, filteredRetailers])

  if (!userCan('view_retailers')) return <Error401 />

  return (
    <>
      <Header title="Retailers" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    {filteredRetailers
                      ? `${filteredRetailers.length} ${pluralize(
                          'retailer',
                          filteredRetailers.length
                        )}`
                      : '0 retailers'}
                  </div>
                  <div className="ml-3">
                    <SearchInput
                      placeholder="Search retailers"
                      value={filters.search}
                      onChange={(search) => setFilters({ search, page: 1 })}
                    />
                  </div>
                </div>

                {userCan('create_retailer') && (
                  <div>
                    <Link to={route('retailer_create')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create Retailer
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}

              {!isLoading && !paginatedRetailers.length ? (
                <div className="my-5">
                  <NotFound summary="No retailers found" />
                </div>
              ) : null}

              {!isLoading && paginatedRetailers.length ? (
                <div className="table-wrapper padded">
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="sticky">Name</th>
                        <th>Status</th>
                        <th className="text-center">Stores</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedRetailers.map((retailer, index) => (
                        <tr key={retailer._id}>
                          <td>
                            {index + 1 + (filters.page - 1) * filters.per_page}
                          </td>
                          <td className="sticky">{retailer.name}</td>
                          <td className="align-middle">
                            <Badge
                              className="text-capitalize"
                              variant={
                                retailer.status === 'active'
                                  ? 'success'
                                  : 'danger'
                              }
                            >
                              {retailer.status}
                            </Badge>
                          </td>
                          <td className="text-center">
                            <Link
                              to={route('retail_locations', null, {
                                rv: retailer._id,
                                rl: retailer.name,
                              })}
                            >
                              {retailer.store_count}
                            </Link>
                          </td>
                          <td className="text-currency">
                            <Link
                              to={route('retailer_update', {
                                id: retailer._id,
                              })}
                              className="action-link"
                            >
                              Edit
                            </Link>
                            &nbsp; <b>&middot;</b> &nbsp;
                            <Link
                              to={`${route(
                                'retail_location_create'
                              )}?retailer=${retailer._id}`}
                              className="action-link"
                            >
                              Upload Stores
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : null}

              <Pagination
                className="my-4"
                perPage={filters.per_page}
                totalItems={filteredRetailers.length}
                currentPage={filters.page}
                onChange={(page) => setFilters({ page })}
              />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect(
  (state: IState) => ({
    user: state.user,
  }),
  { userCan }
)(Retailers)
