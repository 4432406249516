import React from 'react'

import pluralize from 'pluralize'
import {
  Container,
  Table,
  Card,
  Button,
  Badge,
  Dropdown,
  Form,
} from 'react-bootstrap'
import { BsPlus, BsFilter } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import PerPageSelect from 'src/components/Form/PerPageSelect'
import RetailerSelect from 'src/components/Form/RetailerSelect'
import SearchInput from 'src/components/Form/SearchInput'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'

import useGlobalFilters from 'src/hooks/useGlobalFilters'
import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'

import { IRetailLocation } from 'src/services/types/retailLocation'

import Error401 from 'src/pages/Error/401.page'

import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'

import RetailLocationService from 'src/services/RetailLocationService'
import { IState } from 'src/store'
import ExportButton from 'src/components/Export/ExportButton'

const retailLocationService = new RetailLocationService()

const RetailLocations: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const user = useSelector((state: IState) => state.user)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [locations, setLocations] = React.useState<IRetailLocation[]>()
  const { filters, setFilters, activeFilters, resetFilters } =
    useGlobalFilters('retail_locations')

  React.useEffect(() => {
    const fetchRetailLocations = (): void => {
      retailLocationService
        .fetchByManufacturer(user.manufacturer_id)
        .then((locations) => {
          isMounted.current && setLocations(locations)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_retail_locations') && fetchRetailLocations()
  }, [userCan, user.manufacturer_id, isMounted])

  const filteredLocations = React.useMemo(() => {
    let filtered = locations || []

    if (filtered.length) {
      if (filters.status !== 'all') {
        filtered = filtered.filter((location) => {
          return location.status === filters.status
        })
      }

      if (filters.search) {
        const query = filters.search
        const query_parts = filters.search.split(':').map((part) => part.trim())

        filtered = filtered.filter((location) => {
          if (query_parts?.length === 2 && location?.[query_parts[0]]) {
            const [accessor, query] = query_parts
            return basicSearch(location?.[accessor], query)
          } else {
            return (
              basicSearch(location.name, query) ||
              basicSearch(location.status, query) ||
              basicSearch(location.retailer.name, query) ||
              basicSearch(location.channel.name, query)
            )
          }
        })
      }

      if (filters.retailer && filters.retailer.value !== '*') {
        filtered = filtered.filter(
          (location) => location.retailer_id === filters.retailer.value
        )
      }
    }

    return filtered
  }, [locations, filters.status, filters.search, filters.retailer])

  const paginatedLocations = React.useMemo(() => {
    return filteredLocations
      ? paginate(filteredLocations, filters.page, filters.per_page)
      : []
  }, [filters.page, filters.per_page, filteredLocations])

  const exportableLocations = React.useMemo(() => {
    return filteredLocations.map((location) => ({
      Name: location.name,
      Status: location.status,
      Retailer: location.retailer.name,
      Channel: location.channel.name,
      'Geo-Fencing': location?.location?.status === 'active' ? 'On' : 'Off',
    }))
  }, [filteredLocations])

  if (!userCan('view_retail_locations')) return <Error401 />

  return (
    <>
      <Header
        title={
          filters.retailer && filters.retailer.value !== '*'
            ? `Retail Stores (${filters.retailer.label})`
            : 'Retail Stores'
        }
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="font-weight-bold">
                    {filteredLocations
                      ? `${filteredLocations.length} ${pluralize(
                          'retail store',
                          filteredLocations.length
                        )}`
                      : '0 retail stores'}
                  </div>
                </div>

                {userCan('create_retail_location') && (
                  <div>
                    <Link to={route('retail_location_create')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create Retail Location
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Dropdown className="filters-wrapper">
                  <Dropdown.Toggle size="sm" variant="link" id="dropdown-basic">
                    <BsFilter size={24} />
                    <span className="text-capitalize">{activeFilters}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="filter-group">
                      <span className="filter-label">Status</span>
                      <Form.Control
                        as="select"
                        size="sm"
                        value={filters.status}
                        onChange={({ target }) => {
                          setFilters({
                            status: target.value,
                            page: 1,
                          })
                        }}
                      >
                        <option value="all">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </Form.Control>
                    </div>

                    {/* Retailer */}
                    <div className="filter-group">
                      <span className="filter-label">Retailer</span>
                      <RetailerSelect
                        value={filters.retailer || null}
                        onChange={(retailer) => {
                          setFilters({
                            retailer,
                            page: 1,
                          })
                        }}
                      />
                    </div>

                    <div className="filter-group mt-4">
                      <Button
                        size="sm"
                        variant="outline-light"
                        onClick={() => resetFilters(filters)}
                      >
                        Reset
                      </Button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <div className="ml-2">
                  <SearchInput
                    placeholder="Search retail stores *"
                    value={filters.search || ''}
                    onChange={(search) => setFilters({ search, page: 1 })}
                    disabled={isLoading || !filteredLocations}
                  />
                </div>
              </div>

              <div className="d-flex align-items-center">
                <PerPageSelect
                  value={filters.per_page}
                  onChange={(per_page) => setFilters({ per_page, page: 1 })}
                />

                {userCan('export_data') ? (
                  <div className="ml-2">
                    <ExportButton
                      filename="retail-locations"
                      exportData={exportableLocations}
                      disabled={!filteredLocations}
                    />
                  </div>
                ) : null}
              </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}

              {!isLoading && !paginatedLocations.length ? (
                <div className="my-5">
                  <NotFound summary="No retail locations found" />
                </div>
              ) : null}

              {!isLoading && paginatedLocations.length ? (
                <div className="table-wrapper padded">
                  <Table className="table-fit" hover responsive>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="sticky">Name</th>
                        <th>Status</th>
                        <th>Retailer</th>
                        <th>Channel</th>
                        <th>State</th>
                        <th>Geo-Fencing</th>
                        <th>Schedules</th>
                        <th>Targets</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedLocations.map((location, index) => (
                        <tr key={location._id}>
                          <td className="text-center">
                            {index + 1 + (filters.page - 1) * filters.per_page}
                          </td>
                          <td className="sticky font-weight-bold">
                            {location.name}
                          </td>
                          <td className="align-middle">
                            <Badge
                              className="text-capitalize"
                              variant={
                                location.status === 'active'
                                  ? 'success'
                                  : 'danger'
                              }
                            >
                              {location.status}
                            </Badge>
                          </td>
                          <td>{location.retailer.name}</td>
                          <td>{location.channel.name}</td>
                          <td>
                            {location?.branches
                              ? location.branches[0].tree.name
                              : '-'}
                          </td>
                          <td className="text-center">
                            <Badge
                              variant={
                                location?.location?.status === 'active'
                                  ? 'success'
                                  : 'danger'
                              }
                            >
                              {location?.location?.status === 'active'
                                ? 'On'
                                : 'Off'}
                            </Badge>
                          </td>

                          <td>
                            <Link
                              to={route('schedules', null, {
                                rv: location.retailer._id,
                                rl: location.retailer.name,
                                rlv: location._id,
                                rll: location.name,
                              })}
                            >
                              {location?.schedule_count
                                ? `${location?.schedule_count} ${pluralize(
                                    'Schedule',
                                    location?.schedule_count
                                  )}`
                                : '0 Schedule'}
                            </Link>
                          </td>

                          <td>
                            <Link
                              to={route('targets', null, {
                                rv: location.retailer._id,
                                rl: location.retailer.name,
                                rlv: location._id,
                                rll: location.name,
                              })}
                            >
                              Targets
                            </Link>
                          </td>

                          <td>
                            <Link
                              to={route('retail_location_update', {
                                id: location._id,
                              })}
                              className="action-link"
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : null}

              <Pagination
                className="my-4"
                perPage={filters.per_page}
                totalItems={filteredLocations.length}
                currentPage={filters.page}
                onChange={(page) => setFilters({ ...filters, page })}
              />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default RetailLocations
