import Categories from 'src/pages/Category/Categories.page'
import CategoryCreate from 'src/pages/Category/CategoryCreate.page'
import CategoryUpdate from 'src/pages/Category/CategoryUpdate.page'

import { IRoute } from '.'

const categoryRoutes: IRoute[] = [
  {
    id: 'categories',
    path: '/categories',
    component: Categories,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'category_create',
    path: '/categories/create',
    component: CategoryCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'category_update',
    path: '/categories/:id/update',
    component: CategoryUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default categoryRoutes
