import Competition from 'src/pages/Competition/Competition.page'
import CompetitionCreate from 'src/pages/Competition/CompetitionCreate.page'
import CompetitionUpdate from 'src/pages/Competition/CompetitionUpdate.page'

import { IRoute } from '.'

const competitionRoutes: IRoute[] = [
  {
    id: 'competition',
    path: '/competition',
    component: Competition,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'competition_create',
    path: '/competition/create',
    component: CompetitionCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'competition_update',
    path: '/competition/:id/update',
    component: CompetitionUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default competitionRoutes
