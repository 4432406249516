import React from 'react'

import { connect } from 'react-redux'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import CategoryService from 'src/services/CategoryService'
import { ICategory } from 'src/services/types/category'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import CategoryForm from 'src/components/Category/CategoryForm'
import CategoryItems from 'src/components/Category/CategoryItems'

const categoryService = new CategoryService()

interface IProps extends RouteComponentProps {
  match: any
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const CategoryUpdate: React.FC<IProps> = ({
  match,
  history,
  user,
  userCan,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [category, setCategory] = React.useState<ICategory>()

  React.useEffect(() => {
    if (userCan('update_category')) {
      categoryService
        .fetchById(match.params.id)
        .then((category) => setCategory(category))
        .finally(() => setIsLoading(false))
    }
  }, [userCan, match.params.id])

  if (!userCan('update_category')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Category'
            : category
            ? `${category.name}`
            : 'Category not found'
        }
        backText="Categories"
        onBack={() => history.push(route('categories'))}
      />
      <div className="page-container">
        <Container>
          <Row>
            <Col md={12} className="mb-3">
              <Card>
                <Card.Header>
                  <Card.Title>Category Details</Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                  {!isLoading && category ? (
                    <CategoryForm
                      user={user}
                      type="update"
                      category={category}
                      updateCategory={(updatedValues) => {
                        setCategory({ ...category, ...updatedValues })
                      }}
                    />
                  ) : null}

                  {!isLoading && !category ? (
                    <div className="my-5">
                      <NotFound summary="Category not found" />
                    </div>
                  ) : null}

                  {isLoading && (
                    <div className="d-flex justify-content-center py-5">
                      <figure className="spinner primary" />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>

            {!isLoading && category ? (
              <>
                <Col md={12} className="mb-3">
                  <CategoryItems
                    type="product"
                    user={user}
                    category={category}
                  />
                </Col>

                <Col md={12}>
                  <CategoryItems
                    type="competition"
                    user={user}
                    category={category}
                  />
                </Col>
              </>
            ) : null}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(CategoryUpdate)
)
