import React from 'react'
import { useDispatch } from 'react-redux'
import { userCan as userCanAction } from 'src/store/user/userActions'

const usePermission = (): any => {
  const dispatch = useDispatch()

  const userCan = React.useCallback(
    (permission: string) => {
      return dispatch(userCanAction(permission)) ? true : false
    },
    [dispatch]
  )

  return { userCan }
}

export default usePermission
