import Surveys from 'src/pages/Survey/Surveys.page'
import SurveyCreate from 'src/pages/Survey/SurveyCreate.page'
import SurveyUpdate from 'src/pages/Survey/SurveyUpdate.page'
import SurveySummary from 'src/pages/Survey/Survey.page'

import { IRoute } from '.'

const surveyRoutes: IRoute[] = [
  {
    id: 'surveys',
    path: '/surveys',
    component: Surveys,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'survey_create',
    path: '/surveys/create',
    component: SurveyCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'survey_update',
    path: '/surveys/:id/update',
    component: SurveyUpdate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'survey_summary',
    path: '/surveys/:id/summary',
    component: SurveySummary,
    requiresAuth: true,
    exact: true,
  },
]

export default surveyRoutes
