import React from 'react'

import * as Papa from 'papaparse'
import pluralize from 'pluralize'
import { Button, Image } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import xlsx from 'xlsx'

import { computeTTL } from 'src/utils/date'
import { sortByKey } from 'src/utils/filters'
import { route } from 'src/utils/routes'

import UploadIcon from 'src/assets/images/icons/upload.svg'
import {
  ISchedule,
  Period,
  Task,
  LocationInStore,
} from 'src/services/types/schedule'
import { scheduleCombos } from 'src/data/schedules'
import { IUser } from 'src/services/types/user'
import useMounted from 'src/hooks/useMounted'

import ScheduleService from 'src/services/ScheduleService'
import RetailLocationService from 'src/services/RetailLocationService'

const scheduleService = new ScheduleService()
const retailLocationService = new RetailLocationService()

interface IProps {
  user: IUser
}

const SchedulesUpload: React.FC<IProps> = ({ user }) => {
  const history = useHistory()
  const isMounted = useMounted()

  const { addToast } = useToasts()
  const fileInput = React.useRef<any>()

  const [isUploading, setIsUploading] = React.useState<boolean>(false)
  const [schedules, setSchedules] = React.useState<ISchedule[]>()

  const [templateItems, setTemplateItems] = React.useState<any[]>()

  React.useEffect(() => {
    const fetchRetailLocations = (): void => {
      retailLocationService
        .fetchByManufacturer(user.manufacturer_id)
        .then((locations) => {
          /** Create a template entry for each location */
          let items_array = []

          const zero_schedule_locations = locations.filter((location) => {
            return location?.schedule_count && location?.schedule_count > 0
              ? false
              : true
          })

          zero_schedule_locations.forEach((location) => {
            scheduleCombos.forEach((combo) => {
              items_array.push({
                retailer: location.retailer.name,
                store: location.name,
                group: combo.group,
                location: combo.location,
                period: combo.period,
                retailer_id: location.retailer_id,
                retail_location_id: location._id,
              })
            })
          })

          if (isMounted.current) {
            items_array = sortByKey(items_array, 'store')
            setTemplateItems([
              {
                retailer: 'Sample Retailer',
                store: 'Sample Store',
                group: 'primary-visibility',
                location: 'primary',
                period: 'daily',
                retailer_id: '5e63dee6363ab84b4f3a9000',
                retail_location_id: '5e63dee6363ab84b4f3a9001',
              },
              ...items_array,
            ])
          }
        })
    }

    fetchRetailLocations()
  }, [isMounted, user.manufacturer_id])

  const exportTemplate = React.useCallback((items): void => {
    const fileName = 'schedules-template-all-stores'
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(items)
    const wb: xlsx.WorkBook = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(wb, ws, 'Schedules')
    xlsx.writeFile(wb, `${fileName}.xlsx`)
  }, [])

  const doUpload = (): void => {
    setIsUploading(true)

    scheduleService
      .createBulk(schedules)
      .then(() => {
        setIsUploading(false)
        addToast('Schedules uploaded successfully.', {
          appearance: 'success',
        })

        history.push(route('schedules'))
      })
      .catch((error: any) => {
        setIsUploading(false)
        addToast(error.message, { appearance: 'error' })
      })
  }

  const _parseFile = (csv: any): void => {
    Papa.parse(csv, {
      delimiter: '',
      newline: '',
      quoteChar: '"',
      escapeChar: '"',
      headers: true,
      complete: ({ data }) => {
        try {
          const schedules = []
          const allowedTasks: Task[] = [
            Task.Assortment,
            Task.PVisibility,
            Task.SVisibility,
            Task.StockTracking,
            Task.PriceTracking,
            Task.ExpiryTracking,
            Task.CIntelligence,
          ]
          const allowedLocations: LocationInStore[] = [
            LocationInStore.Primary,
            LocationInStore.Secondary,
            LocationInStore.Warehouse,
            LocationInStore.Competition,
          ]
          const allowedPeriods: Period[] = [
            Period.Daily,
            Period.Weekly,
            Period.BiWeekly,
            Period.Monthly,
          ]

          for (let i = 0; i < data.length; i++) {
            /**
             * Two things:
             * 1. Let's skip the first row, it's usuall the header
             * 2. If we have less that the expected columns, skip
             */
            if (i !== 0 && data[i].length === 7) {
              if (
                allowedTasks.includes(data[i][2]) &&
                allowedLocations.includes(data[i][3]) &&
                allowedPeriods.includes(data[i][4]) &&
                data[i][5] !== '' &&
                data[i][6] !== ''
              ) {
                schedules.push({
                  /** retailer: data[i][0] */
                  /** store: data[i][1] */
                  group: data[i][2],
                  location: data[i][3],
                  period: computeTTL(data[i][4]),
                  retailer_id: data[i][5],
                  retail_location_id: data[i][6],

                  /** Hard coded */
                  name: '-',
                  status: '-',
                  manufacturer_id: user.manufacturer_id,
                })
              }
            }

            if (i === data.length - 1) setSchedules(schedules)
          }
        } catch (error) {
          addToast(error.message, { appearance: 'error' })
          throw error
        }
      },
    })

    fileInput.current.value = null // reset file input
  }

  const handleFileChange = (event): void => {
    const files = event.target.files || event.dataTransfer.files
    if (!files.length) return

    _parseFile(files[0])
  }

  return (
    <div className="text-center my-5">
      <Image src={UploadIcon} /> <br />
      <div>
        {schedules && schedules.length ? (
          <p className="text-muted mt-2">
            <b>{schedules.length}</b> {pluralize('schedule', schedules.length)}{' '}
            found in file.
          </p>
        ) : schedules && !schedules.length ? (
          <p className="text-danger mt-2">
            No schedules found, please check the file and try again.
          </p>
        ) : (
          <p className="text-muted mt-2">Upload schedules from a .csv file</p>
        )}
      </div>
      <div className="mt-3">
        <input
          type="file"
          className="hidden-input"
          accept=".csv"
          onChange={handleFileChange}
          ref={fileInput}
        />

        <Button
          size="sm"
          variant="outline-light"
          onClick={() => fileInput.current.click()}
          disabled={isUploading}
        >
          Open CSV File
        </Button>

        <Button
          size="sm"
          variant="success"
          className="ml-2"
          onClick={() => doUpload()}
          disabled={isUploading || !schedules || !schedules.length}
        >
          {!isUploading ? (
            `Upload ${pluralize('Schedules', schedules && schedules.length)}`
          ) : (
            <figure className="spinner button white" />
          )}
        </Button>

        {templateItems && templateItems.length && (
          <div className="mt-3">
            <span
              className="small text-primary cursor-pointer"
              onClick={() => exportTemplate(templateItems)}
            >
              Download Template ({templateItems.length}{' '}
              {pluralize('Row', templateItems.length)})
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default SchedulesUpload
