import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'

import useMounted from 'src/hooks/useMounted'
import { IMerchandiser } from 'src/services/types/merchandiser'

import DeviceService from 'src/services/DeviceService'
import { timestampToDate } from 'src/utils/date'
import NotFound from '../Error/NotFound'

const deviceService = new DeviceService()

interface IProps {
  merchandiser: IMerchandiser
}

const MerchandiserDevices: React.FC<IProps> = ({ merchandiser }) => {
  const isMounted = useMounted()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [devices, setDevices] = useState<any[]>()

  useEffect(() => {
    const fetchDevices = (): any => {
      setIsLoading(true)

      deviceService
        .fetchByUser(merchandiser.user_id)
        .then((data) => {
          const devices = data?.length
            ? data.map((device) => ({
                ...device,
                meta: JSON.parse(device.meta),
              }))
            : []

          isMounted.current && setDevices(devices.reverse())
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    fetchDevices()
  }, [merchandiser.user_id, isMounted])

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center py-5">
          <figure className="spinner small" />
        </div>
      ) : (
        <>
          {devices && devices.length ? (
            <div className="table-wrapper padded">
              <Table bordered hover responsive>
                <thead></thead>
                <tbody>
                  {devices.map((device) => (
                    <tr key={device.token}>
                      <td className="font-weight-medium">
                        {timestampToDate(device.created)}
                      </td>
                      <td>
                        {device.version} ({device.meta.android_version})
                      </td>
                      <br />
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="my-5">
              <NotFound summary="No devices found for Merchandiser" />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default MerchandiserDevices
