/**
 * Handle bulk uploads from .csv file
 */

import React from 'react'

import * as Papa from 'papaparse'
import pluralize from 'pluralize'
import { Button, Image } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import UploadIcon from 'src/assets/images/icons/upload.svg'

import RetailerService from 'src/services/RetailerService'
import { IState } from 'src/store'
import { route } from 'src/utils/routes'
import { IRetailer } from 'src/services/types/retailer'

const retailerService = new RetailerService()

const RetailersUpload: React.FC = () => {
  const history = useHistory()
  const { addToast } = useToasts()
  const user = useSelector((state: IState) => state.user)
  const fileInput = React.useRef<any>()

  const [retailers, setRetailers] = React.useState<IRetailer[]>()

  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const doUpload = (): void => {
    setIsUploading(true)

    retailerService
      .createBulk(retailers)
      .then(() => {
        setIsUploading(false)
        addToast('Retailers uploaded successfully.', {
          appearance: 'success',
        })

        history.push(route('retailers'))
      })
      .catch((error: any) => {
        setIsUploading(false)
        addToast(error.message, { appearance: 'error' })
      })
  }

  const _parseFile = (csv: any): void => {
    Papa.parse(csv, {
      delimiter: '',
      newline: '',
      quoteChar: '"',
      escapeChar: '"',
      headers: true,
      complete: ({ data }) => {
        try {
          const retailers = []
          for (let i = 0; i < data.length; i++) {
            /**
             * Two things:
             * 1. Let's skip the first row, it's usuall the header
             * 2. If we have less that the expected columns, skip
             */
            if (i !== 0 && data[i].length === 2) {
              retailers.push({
                name: data[i][0],
                status: data[i][1],

                manufacturer_id: user.manufacturer_id,
              })
            }

            if (i === data.length - 1) setRetailers(retailers)
          }
        } catch (error) {
          addToast(error.message, { appearance: 'error' })
          throw error
        }
      },
    })

    fileInput.current.value = null // reset file input
  }

  const handleFileChange = (event): void => {
    const files = event.target.files || event.dataTransfer.files
    if (!files.length) return

    _parseFile(files[0])
  }

  return (
    <div className="text-center my-5">
      <Image src={UploadIcon} /> <br />
      <div>
        {retailers && retailers.length ? (
          <p className="text-muted mt-2">
            <b>{retailers.length}</b> {pluralize('retailer', retailers.length)}{' '}
            found in file.
          </p>
        ) : retailers && !retailers.length ? (
          <p className="text-danger mt-2">
            No retailers found, please check the file and try again.
          </p>
        ) : (
          <p className="text-muted mt-2">Upload retailers from a .csv file</p>
        )}
      </div>
      <div className="mt-3">
        <input
          type="file"
          className="hidden-input"
          accept=".csv"
          onChange={handleFileChange}
          ref={fileInput}
        />

        <Button
          size="sm"
          variant="outline-light"
          onClick={() => fileInput.current.click()}
          disabled={isUploading}
        >
          Open CSV File
        </Button>

        <Button
          size="sm"
          variant="success"
          className="ml-2"
          onClick={() => doUpload()}
          disabled={isUploading || !retailers || !retailers.length}
        >
          {!isUploading ? (
            `Upload ${pluralize('Retailers', retailers && retailers.length)}`
          ) : (
            <figure className="spinner button white" />
          )}
        </Button>

        <div className="mt-3">
          <a href="/retailers.csv" className="small" download="retailers.csv">
            Download Sample CSV
          </a>
        </div>
      </div>
    </div>
  )
}

export default RetailersUpload
