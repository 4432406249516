import React, { useState, useEffect } from 'react'

import { Button } from 'react-bootstrap'

import useMounted from 'src/hooks/useMounted'
import CategoryService from 'src/services/CategoryService'
import ConfigurationService from 'src/services/ConfigurationService'

import SearchSelect from 'src/components/Form/SearchSelect'

const categoryService = new CategoryService()
const configurationService = new ConfigurationService()

interface IProps {
  type?: 'product' | 'competition'
  user: any
  configuration: any
  selectedItems: any[]
  addMustStock: (category, product) => Promise<any>
}

const MustStockPicker: React.FC<IProps> = ({
  type,
  user,
  configuration,
  ...props
}) => {
  const isMounted = useMounted()

  /** Indicators , I guess that's what they are called */
  const [isAdding, setIsAdding] = useState<boolean>(false)

  const [products, setProducts] = useState<any[]>()
  const [selectedProducts, setSelectedProducts] = useState<any>()

  const [categories, setCategories] = useState<any>()
  const [category, setCategory] = useState<any>()

  useEffect(() => {
    const fetchCategories = (): void => {
      categoryService
        .fetchByManufacturer(user.manufacturer_id)
        .then((categories) => {
          const tempCategories = categories.map((category) => ({
            value: category._id,
            label: category.name,
          }))
          isMounted.current && setCategories(tempCategories)
        })
    }

    fetchCategories()
  }, [user.manufacturer_id, isMounted])

  /**
   * Fetch unattached products or comp. products
   */
  useEffect(() => {
    const fetchUnattachedProducts = (): void => {
      configurationService
        .fetchMustStockUnattached(
          type,
          user.manufacturer_id,
          configuration._id,
          category.value
        )
        .then((products) => {
          const tempProducts = products.map((product) => ({
            value: product._id,
            label: product.name,
          }))
          isMounted.current && setProducts(tempProducts)
        })
    }

    category && fetchUnattachedProducts()
  }, [
    type,
    isMounted,
    category,
    user.manufacturer_id,
    configuration._id,
    props.selectedItems,
  ])

  const addSelectedProducts = (): void => {
    setIsAdding(true)
    // const tempProduct = { ...product }

    props
      .addMustStock(category, selectedProducts)
      .then(() => {
        isMounted.current && setSelectedProducts(null)
      })
      .finally(() => {
        isMounted.current && setIsAdding(false)
      })
  }

  return (
    <div className="w-100">
      <SearchSelect
        size="sm"
        className="w-100"
        options={categories}
        value={category}
        placeholder="Select category"
        onChange={(category) => {
          setSelectedProducts(null) // reset product
          setCategory(category)
        }}
        isDisabled={!categories || !categories.length}
      />

      <SearchSelect
        size="sm"
        className="w-100 mt-2"
        options={products || []}
        value={selectedProducts}
        placeholder="Select products"
        onChange={(selected) => setSelectedProducts(selected)}
        isDisabled={!category || !products || !products.length}
        isMulti
      />

      {selectedProducts ? (
        <>
          <Button
            size="sm"
            className="mt-2"
            onClick={addSelectedProducts}
            disabled={isAdding}
            block
          >
            {isAdding ? <figure className="spinner button white" /> : 'Add'}
          </Button>
        </>
      ) : null}
    </div>
  )
}

MustStockPicker.defaultProps = {
  type: 'product',
}

export default MustStockPicker
