import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import CategoryService from 'src/services/CategoryService'

import { route } from 'src/utils/routes'
import { ICategory } from 'src/services/types/category'

const categoryService = new CategoryService()

interface IProps extends RouteComponentProps {
  user: any
  type?: string
  category?: ICategory
  updateCategory?: (updatedValues: ICategory) => void
  history: any
}

const CategoryForm: React.FC<IProps> = ({
  user,
  type,
  category,
  updateCategory,
  history,
}) => {
  const { addToast } = useToasts()
  const _isMounted = React.useRef(false)

  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const formConfig = {
    initialValues: {
      name: category ? category.name : '',
      status: category ? category.status : 'active',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      let finalValues: any = {}

      if (type === 'update') {
        finalValues._id = category._id
      }

      finalValues = {
        ...finalValues,
        name: values.name,
        status: values.status,
        manufacturer_id: user.manufacturer_id,
      }

      categoryService[type]({ ...finalValues })
        .then((categoryId) => {
          setSubmitting(false)
          // resetForm()

          if (type === 'update') {
            addToast('Category successfully updated.', {
              appearance: 'success',
            })
            updateCategory(values)
          } else {
            addToast('Category successfully created.', {
              appearance: 'success',
            })
            history.push(route('category_update', { id: categoryId }))
          }
        })
        .catch((error: any) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        ...formik
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* Category status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {/* Category name */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">Name of category</Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                name="name"
                value={values.name}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.name && errors.name ? true : false}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Category'
            ) : (
              'Update Category'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

CategoryForm.defaultProps = {
  type: 'create',
}

export default withRouter(CategoryForm)
