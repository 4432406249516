import { Task, LocationInStore, Period } from 'src/services/types/schedule'

export const scheduleCombos: {
  group: Task
  location: LocationInStore
  period: Period
}[] = [
  {
    group: Task.PVisibility,
    location: LocationInStore.Primary,
    period: Period.Daily,
  },
  {
    group: Task.StockTracking,
    location: LocationInStore.Primary,
    period: Period.Weekly,
  },
  {
    group: Task.PriceTracking,
    location: LocationInStore.Primary,
    period: Period.BiWeekly,
  },
  {
    group: Task.SVisibility,
    location: LocationInStore.Secondary,
    period: Period.Daily,
  },
  {
    group: Task.StockTracking,
    location: LocationInStore.Secondary,
    period: Period.Weekly,
  },
  {
    group: Task.ExpiryTracking,
    location: LocationInStore.Warehouse,
    period: Period.Monthly,
  },
  {
    group: Task.StockTracking,
    location: LocationInStore.Warehouse,
    period: Period.Weekly,
  },
  {
    group: Task.Assortment,
    location: LocationInStore.Competition,
    period: Period.Weekly,
  },
  {
    group: Task.CIntelligence,
    location: LocationInStore.Competition,
    period: Period.Weekly,
  },
  {
    group: Task.PriceTracking,
    location: LocationInStore.Competition,
    period: Period.Weekly,
  },
]
