import cache from '../utils/cache'
import { http } from '../utils/http'
import { IStaff } from './types/staff'

class StaffService {
  fetch(): Promise<IStaff[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('staffs')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('staff/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('staffs', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  fetchById(staffId: string): Promise<IStaff> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get(`staff_${staffId}`)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`staff/read/?_id=${staffId}`).then(({ data }) => {
            if (data.code === 200 && data.data.length) {
              cache.set(`staff_${staffId}`, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error has occured' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<IStaff[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `staffs_${status}`
      const cachedData = cache.get(cacheKey)

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`staff/read_by_status`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  signup(staff: IStaff): Promise<any> {
    const formData = new FormData()
    formData.append('hash', 'password') // default

    Object.keys(staff).forEach((key) => {
      formData.append(key, staff[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('staff/signup', formData).then(({ data }) => {
          if (data.code === 200) resolve(data.data)
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }

  update(staff: IStaff): Promise<any> {
    const formData = new FormData()

    Object.keys(staff).forEach((key) => {
      formData.append(key, staff[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('staff/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.del('staffs')
            cache.del(`staff_${staff._id}`)
            resolve({})
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error has occured' })
        throw error
      }
    })
  }
}

export default StaffService
