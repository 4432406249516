import Staff from 'src/pages/Staff/Staff.page'
import StaffSignup from 'src/pages/Staff/StaffSignup.page'
import StaffUpdate from 'src/pages/Staff/StaffUpdate.page'

import { IRoute } from '.'

const staffRoutes: IRoute[] = [
  {
    id: 'staff',
    path: '/staff',
    component: Staff,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'staff_signup',
    path: '/staff/signup',
    component: StaffSignup,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'staff_update',
    path: '/staff/:id/update',
    component: StaffUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default staffRoutes
