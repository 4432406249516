import React from 'react'

import Select from 'react-select'

import Caret from 'src/assets/images/icons/caret.svg'

interface IProps {
  name?: string
  size?: 'sm' | 'md'
  value: any
  options: any[]
  isInvalid?: boolean
  placeholder?: string
  className?: string
  onBlur?: () => void
  onChange?: (arg: any) => void
  isMulti?: boolean
  isActive?: boolean
  isFocused?: boolean
  isDisabled?: boolean
}

const colors = {
  primary: '#3b54ec',
  border: '#f1f2f3',
  background: '#fafafd',
  backgroundDark: '#f2f2f2',
  mutedText: '#808a97',
  bodyText: '#262626',
  danger: '#da1e28',
}

const SearchSelect: React.FC<IProps> = ({ size, isInvalid, ...props }) => {
  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      border: `1px solid ${isInvalid ? colors.danger : colors.border}`,
      borderRadius: size === 'sm' ? '4px' : '6px',
      paddingTop: size === 'sm' ? '0' : '2px',
      transition: 'all 0.3s',
      minHeight: size === 'sm' ? '28px' : '42px',
      minWidth: '180px',
      ':hover': {
        ...styles[':hover'],
        border: `1px solid ${colors.border}`,
        cursor: 'pointer',
      },
      ':focus': {
        ...styles[':focus'],
        border: `1px solid ${colors.primary}`,
        borderWidth: '1px',
      },
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      backgroundImage: `url(${Caret})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'calc(100% - 8px) center',
      backgroundSize: '12px',
      paddingRight: '26px !important',
      svg: {
        ...styles['svg'],
        display: 'none!important',
      },
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
    input: (styles: any) => ({
      ...styles,
      color: colors.bodyText,
    }),
    noOptionsMessage: (styles: any) => ({
      ...styles,
      color: colors.mutedText,
    }),
    menu: (styles: any) => ({
      ...styles,
      border: `1px solid ${colors.border}`,
      borderRadius: size === 'sm' ? '4px' : '6px',
      boxShadow: 'none',
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: colors.background,
      margin: '0 2px 0',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      padding: '3px 3px 2px',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? colors.backgroundDark
        : isFocused
        ? colors.background
        : null,
      color: isDisabled ? '#ccc' : colors.bodyText,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? colors.primary : colors.background),
      },
    }),
    placeholder: (styles: any) => ({
      ...styles,
      color: colors.bodyText,
      /** Adjust vertical position */
      top: size === 'sm' ? '54% !important' : '50%',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: colors.bodyText,
      /** Adjust vertical position */
      top: size === 'sm' ? '54% !important' : '50%',
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      flexWrap: 'nowrap',
      padding: '1px 8px',
    }),
  }

  return (
    <Select
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: colors.primary,
        },
      })}
      styles={customStyles}
      {...props}
    />
  )
}

export default SearchSelect
