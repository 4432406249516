import React from 'react'

import * as Papa from 'papaparse'
import pluralize from 'pluralize'
import { Button, Image } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import ProductService from 'src/services/ProductService'
import { route } from 'src/utils/routes'
import { IProduct } from 'src/services/types/product'

import UploadIcon from 'src/assets/images/icons/upload.svg'

const productService = new ProductService()

interface IProps extends RouteComponentProps {
  user: any
  brand: string // brand_id
  history: any
}

/**
 * Handle bulk uploads from .csv file
 */
const ProductsUpload: React.FC<IProps> = ({ user, brand, history }) => {
  const { addToast } = useToasts()
  const fileInput = React.useRef<any>()

  const [products, setProducts] = React.useState<IProduct[]>()

  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const doUpload = (): void => {
    setIsUploading(true)

    productService
      .createBulk(products)
      .then(() => {
        setIsUploading(false)
        addToast('Products uploaded successfully.', {
          appearance: 'success',
        })

        history.push(route('products'))
      })
      .catch((error: any) => {
        setIsUploading(false)
        addToast(error.message, { appearance: 'error' })
      })
  }

  const _parseFile = (csv: any): void => {
    Papa.parse(csv, {
      delimiter: '',
      newline: '',
      quoteChar: '"',
      escapeChar: '"',
      headers: true,
      complete: ({ data }) => {
        try {
          const products = []
          for (let i = 0; i < data.length; i++) {
            /**
             * Two things:
             * 1. Let's skip the first row, it's usuall the header
             * 2. If we have less that the expected columns, skip
             */
            if (i !== 0 && data[i].length === 7) {
              products.push({
                sku_id: data[i][0],
                name: data[i][1],
                weight: data[i][2],
                size: data[i][3],
                case_count: data[i][4],
                price: data[i][5].replace(/[\s+,]/g, ''), // remove comman and new line
                price_rrp: data[i][6].replace(/[\s+,]/g, ''), // remove comman and new line

                brand_id: brand,
                manufacturer_id: user.manufacturer_id,
              })
            }

            if (i === data.length - 1) setProducts(products)
          }
        } catch (error) {
          addToast(error.message, { appearance: 'error' })
          throw error
        }
      },
    })

    fileInput.current.value = null // reset file input
  }

  const handleFileChange = (event): void => {
    const files = event.target.files || event.dataTransfer.files
    if (!files.length) return

    _parseFile(files[0])
  }

  return (
    <div className="text-center my-5">
      <Image src={UploadIcon} /> <br />
      <div>
        {products && products.length ? (
          <p className="text-muted mt-2">
            <b>{products.length}</b> {pluralize('product', products.length)}{' '}
            found in file.
          </p>
        ) : products && !products.length ? (
          <p className="text-danger mt-2">
            No products found, please check the file and try again.
          </p>
        ) : (
          <p className="text-muted mt-2">Upload products from a .csv file</p>
        )}
      </div>
      <div className="mt-3">
        <input
          type="file"
          className="hidden-input"
          accept=".csv"
          onChange={handleFileChange}
          ref={fileInput}
        />

        <Button
          size="sm"
          variant="outline-light"
          onClick={() => fileInput.current.click()}
          disabled={isUploading}
        >
          Open CSV File
        </Button>

        <Button
          size="sm"
          variant="success"
          className="ml-2"
          onClick={() => doUpload()}
          disabled={isUploading || !products || !products.length}
        >
          {!isUploading ? (
            `Upload ${pluralize('Products', products && products.length)}`
          ) : (
            <figure className="spinner button white" />
          )}
        </Button>

        <div className="mt-3">
          <a href="/products.csv" className="small" download="products.csv">
            Download Sample CSV
          </a>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ProductsUpload)
