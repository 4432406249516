import Retailers from 'src/pages/Retailer/Retailers.page'
import RetailerCreate from 'src/pages/Retailer/RetailerCreate.page'
import RetailerUpdate from 'src/pages/Retailer/RetailerUpdate.page'
import Schedules from 'src/pages/Schedule/Schedules.page'
import Targets from 'src/pages/Target/Targets.page'
import Merchandisers from 'src/pages/Merchandiser/Merchandisers.page'

import { IRoute } from '.'

const retailerRoutes: IRoute[] = [
  {
    id: 'retailers',
    path: '/retailers',
    component: Retailers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'retailer_create',
    path: '/retailers/create',
    component: RetailerCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'retailer_update',
    path: '/retailers/:id/update',
    component: RetailerUpdate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'retailer_merchandisers',
    path: '/retailers/:id/merchandisers',
    component: Merchandisers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'retailer_schedules',
    path: '/retailers/:id/schedules',
    component: Schedules,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'retailer_targets',
    path: '/retailers/:id/targets',
    component: Targets,
    requiresAuth: true,
    exact: true,
  },
]

export default retailerRoutes
