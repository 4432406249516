import React from 'react'

import { Container } from 'react-bootstrap'
import Header from '../../components/Layout/Header/Header'

import Marked from 'react-markdown'
import { docs } from 'src/docs'

import './Docs.scss'

const About: React.FC = () => {
  const flatten = (text, child): any => {
    return typeof child === 'string'
      ? text + child
      : React.Children.toArray(child.props.children).reduce(flatten, text)
  }

  const HeadingRenderer = (props): any => {
    const children = React.Children.toArray(props.children)
    const text = children.reduce(flatten, '')
    const slug = text.toLowerCase().replace(/\W/g, '-')
    return React.createElement('h' + props.level, { id: slug }, props.children)
  }

  return (
    <>
      <Header title="Documentation" />
      <div className="page-container">
        <Container>
          <div id="docs">
            <div className="markdown-body">
              <Marked
                source={docs}
                escapeHtml={false}
                transformImageUri={(uri) => {
                  return uri.substring(1)
                }}
                renderers={{ heading: HeadingRenderer }}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default About
