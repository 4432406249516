import general from 'src/config/lang/general'

const lang = {
  general,
}

export const trans = (string: string): string => {
  if (!string) return null

  const parts = string.split('.')

  if (parts.length === 2) {
    return lang?.[parts[0]]?.[parts[1]] || null
  } else {
    return null
  }
}
