import React from 'react'
import NotFound from 'src/components/Error/NotFound'

const Error404: React.FC<any> = () => {
  return (
    <div className="my-5 pt-5 px-5">
      <NotFound
        title="Page not found!"
        summary="The page you're trying to access was not found. That's all we know."
      />
    </div>
  )
}

export default Error404
