/**
 * Store all theme config here
 */

export const colors = {
  body: '#262626',
  link: '#000',
  primary: '#3b54ec',
  primary10: 'rgba(59,84,236,0.10)',
  primaryGrey: '#9da5b0',
  secondary: '#0C1326',
  tertiaryGrey: '#C4CAD0',
  borderGrey: '#E7E8EF',
  grey: '#808A97',

  success: '#24A148',
  danger: '#da1e28',
  warning: '#F9B13D',
}
