import React from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Card } from 'react-bootstrap'

import Header from 'src/components/Layout/Header/Header'
import SurveyForm from 'src/components/Survey/SurveyForm'
import usePermission from 'src/hooks/usePermission'
import Error401 from 'src/pages/Error/401.page'
import { route } from 'src/utils/routes'

const SurveyCreate: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()

  if (!userCan('create_survey')) return <Error401 />
  return (
    <>
      <Header
        title="New Survey"
        backText="Surveys"
        onBack={() => history.push(route('surveys'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Survey Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <SurveyForm />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default SurveyCreate
