import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface IProps {
  icon: any
  title: string
}

const NavOverlay: React.FC<IProps> = ({ icon, title }) => (
  <OverlayTrigger
    placement="right"
    delay={{ show: 250, hide: 400 }}
    overlay={<Tooltip id={title}>{title}</Tooltip>}
  >
    {icon}
  </OverlayTrigger>
)

export default NavOverlay
