import React from 'react'

import { connect } from 'react-redux'
import { Container, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import BrandService from 'src/services/BrandService'
import { IBrand } from 'src/services/types/brand'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import BrandForm from 'src/components/Brand/BrandForm'

const brandService = new BrandService()

interface IProps extends RouteComponentProps {
  user: any
  userCan: (permission: string) => boolean

  match: any
}

const BrandUpdate: React.FC<IProps> = ({ user, userCan, match, history }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [brand, setBrand] = React.useState<IBrand>()

  const _isMounted = React.useRef<boolean>(false)
  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  React.useEffect(() => {
    if (userCan('update_brand')) {
      brandService
        .fetchById(match.params.id)
        .then((brand) => _isMounted.current && setBrand(brand))
        .finally(() => _isMounted.current && setIsLoading(false))
    }
  }, [userCan, match.params.id])

  if (!userCan('update_brand')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading ? 'Brand' : brand ? `${brand.name}` : 'Brand not found'
        }
        backText="Brands"
        onBack={() => history.push(route('brands'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Brand Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              {!isLoading && brand ? (
                <BrandForm
                  user={user}
                  type="update"
                  brand={brand}
                  updateBrand={(updatedValues) => {
                    setBrand({ ...brand, ...updatedValues })
                  }}
                />
              ) : null}

              {!isLoading && !brand ? (
                <div className="my-5">
                  <NotFound summary="Brand not found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(BrandUpdate)
)
