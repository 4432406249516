import React from 'react'

import * as Papa from 'papaparse'
import pluralize from 'pluralize'
import { Button, Image } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import xlsx from 'xlsx'

import TargetService from 'src/services/TargetService'
import RetailLocationService from 'src/services/RetailLocationService'
import { sortByKey } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { ITarget } from 'src/services/types/target'

import UploadIcon from 'src/assets/images/icons/upload.svg'
import useMounted from 'src/hooks/useMounted'
import { useSelector } from 'react-redux'
import { IState } from 'src/store'
import { TreeType } from 'src/services/types/tree'

const targetService = new TargetService()
const retailLocationService = new RetailLocationService()

const TargetsUpload: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const user = useSelector((state: IState) => state.user)

  const { addToast } = useToasts()
  const fileInput = React.useRef<any>()

  const [isUploading, setIsUploading] = React.useState<boolean>(false)
  const [targets, setTargets] = React.useState<ITarget[]>()

  const [templateItems, setTemplateItems] = React.useState<any[]>()

  React.useEffect(() => {
    const fetchRetailLocations = (): void => {
      retailLocationService
        .fetchByManufacturer(user.manufacturer_id)
        .then((locations) => {
          const currentDate = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
          }

          /** Create a template entry for each location */
          let items_array = []
          const tags = ['SALES', 'SALES_IN']

          locations.forEach((location) => {
            const branch = location?.branches
              ? location.branches.find(
                  (branch) => branch.tree.type === TreeType.State
                )
              : null

            tags.forEach((tag) => {
              items_array.push({
                retailer: location.retailer.name,
                store: location.name,
                tag,
                year: currentDate.year,
                month: currentDate.month,
                value: '',
                retailer_id: location.retailer_id,
                retail_location_id: location._id,
                region: branch?.tree?.parent_obj?.name || '-',
              })
            })
          })

          if (isMounted.current) {
            items_array = sortByKey(items_array, 'store')
            setTemplateItems([
              {
                retailer: 'Sample Retailer',
                store: 'Sample Store',
                tag: 'SALES',
                year: currentDate.year,
                month: currentDate.month,
                value: '400000',
                retailer_id: '5e63dee6363ab84b4f3a9000',
                retail_location_id: '5e63dee6363ab84b4f3a9001',
                region: 'Sample Region',
              },
              ...items_array,
            ])
          }
        })
    }

    fetchRetailLocations()
  }, [user.manufacturer_id, isMounted])

  const exportTemplate = React.useCallback((items): void => {
    const fileName = 'targets-template-all-stores'
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(items)
    const wb: xlsx.WorkBook = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(wb, ws, 'Targets')
    xlsx.writeFile(wb, `${fileName}.xlsx`)
  }, [])

  const doUpload = (): void => {
    setIsUploading(true)

    targetService
      .createBulk(targets, 120)
      .then(({ succeeded, failed }) => {
        setIsUploading(false)
        addToast(
          `${succeeded} targets uploaded successfully, ${failed} failed.`,
          { appearance: 'success' }
        )

        history.push(route('targets'))
      })
      .catch((error: any) => {
        setIsUploading(false)
        addToast(error.message, { appearance: 'error' })
      })
  }

  const _parseFile = (csv: any): void => {
    Papa.parse(csv, {
      delimiter: '',
      newline: '',
      quoteChar: '"',
      escapeChar: '"',
      headers: true,
      complete: ({ data }) => {
        try {
          const targets = []
          const allowedTags = ['SALES', 'SALES_IN']

          for (let i = 0; i < data.length; i++) {
            /**
             * Two things:
             * 1. Let's skip the first row, it's usuall the header
             * 2. If we have less that the expected columns, skip
             */
            if (i !== 0 && data[i].length === 9) {
              if (
                allowedTags.includes(data[i][2]) &&
                data[i][2] !== '' &&
                data[i][4] !== '' &&
                data[i][4] >= 1 &&
                data[i][4] <= 12 &&
                data[i][5] !== '' &&
                data[i][6] !== '' &&
                data[i][7] !== ''
              ) {
                targets.push({
                  /** retailer: data[i][0] */
                  /** store: data[i][1] */
                  tag: data[i][2],
                  year: data[i][3],
                  month: data[i][4],
                  /** remove comma and new line */
                  value: data[i][5].replace(/[\s+,]/g, ''),
                  retailer_id: data[i][6],
                  retail_location_id: data[i][7],

                  /** Hard coded */
                  day: '0',
                  manufacturer_id: user.manufacturer_id,
                })
              }
            }

            if (i === data.length - 1) setTargets(targets)
          }
        } catch (error) {
          addToast(error.message, { appearance: 'error' })
          throw error
        }
      },
    })

    fileInput.current.value = null // reset file input
  }

  const handleFileChange = (event): void => {
    const files = event.target.files || event.dataTransfer.files
    if (!files.length) return

    _parseFile(files[0])
  }

  return (
    <div className="text-center my-5">
      <Image src={UploadIcon} /> <br />
      <div>
        {targets && targets.length ? (
          <p className="text-muted mt-2">
            <b>{targets.length}</b> {pluralize('target', targets.length)} found
            in file.
          </p>
        ) : targets && !targets.length ? (
          <p className="text-danger mt-2">
            No targets found, please check the file and try again.
          </p>
        ) : (
          <p className="text-muted mt-2">Upload targets from a .csv file</p>
        )}
      </div>
      <div className="mt-3">
        <input
          type="file"
          className="hidden-input"
          accept=".csv"
          onChange={handleFileChange}
          ref={fileInput}
        />

        <Button
          size="sm"
          variant="outline-light"
          onClick={() => fileInput.current.click()}
          disabled={isUploading}
        >
          Open CSV File
        </Button>

        <Button
          size="sm"
          variant="success"
          className="ml-2"
          onClick={() => doUpload()}
          disabled={isUploading || !targets || !targets.length}
        >
          {!isUploading ? (
            `Upload ${pluralize('Targets', targets && targets.length)}`
          ) : (
            <figure className="spinner button white" />
          )}
        </Button>

        {templateItems && templateItems.length && (
          <div className="mt-3">
            <span
              className="small text-primary cursor-pointer"
              onClick={() => exportTemplate(templateItems)}
            >
              Download Template ({templateItems.length}{' '}
              {pluralize('Row', templateItems.length)})
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default TargetsUpload
