import {
  Role,
  root_admin,
  admin,
  sales,
  client,
  allStaff,
  everyone,
} from './roles'

interface IPermission {
  /** Unique permission identifier */
  id: string
  /** Roles tied to permission */
  roles: Role[]
}

export const permissions: IPermission[] = [
  { id: 'view_sidebar_dashboard', roles: [...everyone] },
  { id: 'view_sidebar_admin', roles: [root_admin] },
  { id: 'view_sidebar_products', roles: [...allStaff] },
  { id: 'view_sidebar_outlets', roles: [...everyone] },
  { id: 'view_sidebar_settings', roles: [...allStaff] },

  // Brands
  { id: 'view_brands', roles: [admin, sales, client] },
  { id: 'create_brand', roles: [admin] },
  { id: 'update_brand', roles: [admin] },

  // Categories
  { id: 'view_categories', roles: [admin, sales, client] },
  { id: 'create_category', roles: [admin] },
  { id: 'update_category', roles: [admin] },

  // Channels
  { id: 'view_channels', roles: [root_admin] },
  { id: 'create_channel', roles: [root_admin] },
  { id: 'update_channel', roles: [root_admin] },

  // Competition
  { id: 'view_competition', roles: [admin, sales, client] },
  { id: 'create_competition', roles: [admin] },
  { id: 'update_competition', roles: [admin] },

  // Configurations
  { id: 'view_configurations', roles: [admin, sales, client] },
  { id: 'create_configuration', roles: [admin] },
  { id: 'update_configuration', roles: [admin] },

  // Forum
  { id: 'view_forums', roles: [root_admin, admin, sales, client] },
  { id: 'create_forum', roles: [root_admin] },
  { id: 'update_forum', roles: [root_admin] },

  // Manufacturers
  { id: 'view_manufacturers', roles: [root_admin, sales, client] },
  { id: 'create_manufacturer', roles: [root_admin] },
  { id: 'update_manufacturer', roles: [root_admin] },

  // Merchandisers
  { id: 'view_merchandisers', roles: [admin, sales, client] },
  { id: 'signup_merchandiser', roles: [admin] },
  { id: 'update_merchandiser', roles: [admin] },

  // Products
  { id: 'view_products', roles: [admin, sales, client] },
  { id: 'create_product', roles: [admin] },
  { id: 'update_product', roles: [admin] },

  // Reports
  { id: 'view_reports', roles: [admin, sales, client] },
  { id: 'view_activity_report', roles: [admin, sales, client] },
  { id: 'view_attendance_report', roles: [admin, sales, client] },
  { id: 'view_sales_report', roles: [admin, sales, client] },
  { id: 'view_sales_summary_report', roles: [admin, sales, client] },
  { id: 'view_out_of_stock_report', roles: [admin, sales, client] },
  { id: 'view_out_of_stock_summary_report', roles: [admin, sales, client] },
  { id: 'view_inventory_report', roles: [admin, sales, client] },
  { id: 'view_expiry_report', roles: [admin, sales, client] },
  { id: 'view_assortment_report', roles: [admin, sales, client] },
  { id: 'view_assortment_comp_report', roles: [admin, sales, client] },
  { id: 'view_visibility_report', roles: [admin, sales, client] },
  { id: 'view_pricing_report', roles: [admin, sales, client] },
  { id: 'view_perfect_store_report', roles: [admin, sales, client] },
  { id: 'view_kpis_report', roles: [admin, sales, client] },

  // Retailers
  { id: 'view_retailers', roles: [admin, sales, client] },
  { id: 'create_retailer', roles: [admin] },
  { id: 'update_retailer', roles: [admin] },

  // Retailer locations
  { id: 'view_retail_locations', roles: [admin, sales, client] },
  { id: 'create_retail_location', roles: [admin] },
  { id: 'update_retail_location', roles: [admin] },

  // Schedules
  { id: 'view_schedules', roles: [admin, sales, client] },
  { id: 'create_schedule', roles: [admin] },
  { id: 'update_schedule', roles: [admin] },

  // Staff
  { id: 'view_staff', roles: [root_admin] },
  { id: 'create_staff', roles: [root_admin] },
  { id: 'update_staff', roles: [root_admin] },

  // Surveys
  { id: 'view_surveys', roles: [admin, sales, client] },
  { id: 'create_survey', roles: [admin] },
  { id: 'update_survey', roles: [admin] },
  { id: 'view_survey_summary', roles: [admin] },

  // Targets
  { id: 'view_targets', roles: [admin, sales, client] },
  { id: 'create_target', roles: [admin] },
  { id: 'update_target', roles: [admin] },

  // Tickets
  { id: 'view_tickets', roles: [root_admin, admin, sales, client] },
  { id: 'update_ticket', roles: [root_admin, admin, sales] },

  // Tree
  { id: 'view_trees', roles: [admin, sales, client] },
  { id: 'create_tree', roles: [admin] },
  { id: 'update_tree', roles: [admin] },

  { id: 'export_data', roles: [admin, sales] },
]
