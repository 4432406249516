import React from 'react'
import { Row, Col } from 'react-bootstrap'

interface IProps {
  title?: string
  summary: string
}

const NotFound: React.FC<IProps> = ({ title, summary }) => (
  <Row className="justify-content-center">
    <Col md={8} className="text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="57"
        height="44"
        viewBox="0 0 57 44"
        fill="#9da5b0"
      >
        <path d="M28.6 23.7L34.5 17.9 36.1 19.5 30.2 25.3 36.1 31.1 34.5 32.6 28.6 26.8 22.7 32.6 21.2 31.1 27 25.3 21.2 19.5 22.7 17.9 28.6 23.7ZM3.1 2.3L3.1 41.7 54.1 41.7 54.1 9.6 23.6 9.6 18.1 2.3 3.1 2.3ZM24.7 7.5L56.3 7.5 56.3 43.9 0.9 43.9 0.9 0.1 19.2 0.1 24.7 7.5Z" />
      </svg>
      {title ? <h5 className="mt-3">{title}</h5> : null}
      <p className={`text-muted ${!title ? 'mt-3' : ''}`}>{summary}</p>
    </Col>
  </Row>
)

export default NotFound
