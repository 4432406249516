import cache from '../utils/cache'
import { http } from '../utils/http'
import { secureRandomString } from '../utils/rand'
import { IUpload } from 'src/services/types/upload'

class UploadService {
  fetchById(uploadId: string): Promise<IUpload> {
    return new Promise((resolve, reject) => {
      const cacheKey = `upload_${uploadId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`upload/read/?_id=${uploadId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  upload(uploadData: any, fileName?: string): Promise<string> {
    fileName = fileName ? secureRandomString() : secureRandomString()

    const formData = new FormData()
    formData.append('file', uploadData.file)
    formData.append('tag', fileName)
    formData.append('title', fileName)
    formData.append('overwrite', 'true')

    return new Promise((resolve, reject) => {
      try {
        http.post('upload/upload', formData).then(({ data }) => {
          if (data.code === 200) resolve(data.data)
          // upload ID
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default UploadService
