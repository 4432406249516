import React from 'react'
import {
  ResponsiveContainer,
  PieChart,
  Legend,
  Pie,
  Cell,
  Tooltip,
} from 'recharts'
import { Form } from 'react-bootstrap'

import NotFound from 'src/components/Error/NotFound'
import { formatCurrency } from 'src/utils/money'
import useRefineChartData from 'src/hooks/dashboard/useRefineChartData'
import { colors } from 'src/config/theme'

interface IProps {
  label: string
  accessor?: string
  data: any
  valueFormat?: 'currency'
  isLoading?: boolean
}

const SectionPieChart: React.FC<IProps> = ({
  label,
  accessor,
  data,
  valueFormat,
  isLoading,
}) => {
  const [valueType, setValueType] = React.useState<string>('relative')
  const { refinedData, chartColors } = useRefineChartData(data, accessor)

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="font-weight-medium">{label}</span>
        <Form.Control
          as="select"
          size="sm"
          className="w-auto"
          value={valueType}
          onChange={({ target }) => {
            setValueType(target.value)
          }}
        >
          <option value="absolute"># Absolute</option>
          <option value="relative">% Relative</option>
        </Form.Control>
      </div>

      {isLoading && (
        <div className="d-flex justify-content-center py-5">
          <figure className="spinner" />
        </div>
      )}

      {!isLoading && !refinedData.length ? (
        <div className="my-5">
          <NotFound summary={`No ${label} data to show`} />
        </div>
      ) : null}

      {!isLoading && refinedData.length ? (
        <>
          <ResponsiveContainer height={300}>
            <PieChart onMouseEnter={() => ({})}>
              <Legend
                verticalAlign="bottom"
                iconSize={12}
                wrapperStyle={{
                  color: colors.grey,
                  fontSize: '12px',
                  lineHeight: '18px',
                  textTransform: 'capitalize',
                }}
              />
              <Tooltip
                itemStyle={{
                  textTransform: 'capitalize',
                }}
                formatter={(value) => {
                  return valueFormat === 'currency'
                    ? formatCurrency(value)
                    : value
                }}
              />
              <Pie
                data={refinedData}
                isAnimationActive={false}
                legendType="circle"
                dataKey="value"
                innerRadius="35%"
                outerRadius="70%"
                fill="#8884d8"
                label={(entry) => {
                  return valueType === 'relative'
                    ? `${(entry.percent * 100).toFixed()}%`
                    : valueFormat === 'currency'
                    ? formatCurrency(entry.payload.value)
                    : entry.payload.value
                }}
              >
                {refinedData.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={chartColors[index % chartColors.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </>
      ) : null}
    </div>
  )
}

SectionPieChart.defaultProps = {
  isLoading: false,
  accessor: 'total',
}

export default SectionPieChart
