import React from 'react'

import pluralize from 'pluralize'
import {
  Container,
  Table,
  Card,
  Button,
  Badge,
  Dropdown,
} from 'react-bootstrap'
import { BsFilter, BsPlus } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import PerPageSelect from 'src/components/Form/PerPageSelect'
import RetailerSelect from 'src/components/Form/RetailerSelect'
import RetailLocationSelect from 'src/components/Form/RetailLocationSelect'
import SearchInput from 'src/components/Form/SearchInput'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'

import useGlobalFilters from 'src/hooks/useGlobalFilters'
import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'
import { ISchedule } from 'src/services/types/schedule'
import Error401 from 'src/pages/Error/401.page'

import ScheduleService from 'src/services/ScheduleService'
import { IState } from 'src/store'

import { undoComputeTTL } from 'src/utils/date'
import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'

const scheduleService = new ScheduleService()

const Schedules: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const user = useSelector((state: IState) => state.user)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [schedules, setSchedules] = React.useState<ISchedule[]>()

  const { filters, activeFilters, setFilters, resetFilters } =
    useGlobalFilters('schedules')

  React.useEffect(() => {
    const fetchSchedules = (): void => {
      scheduleService
        .fetchByManufacturer(user.manufacturer_id)
        .then((schedules) => {
          isMounted.current && setSchedules(schedules)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_schedules') && fetchSchedules()
  }, [userCan, user.manufacturer_id, isMounted])

  const filteredSchedules = React.useMemo(() => {
    let filteredSchedules = schedules || []

    if (filteredSchedules.length) {
      if (filters.search) {
        const query = filters.search

        filteredSchedules = filteredSchedules.filter((schedule) => {
          return (
            basicSearch(schedule?.name, query) ||
            basicSearch(schedule.group, query) ||
            basicSearch(schedule.location, query) ||
            basicSearch(schedule?.retailer?.name, query) ||
            basicSearch(schedule?.retail_location?.name, query)
          )
        })
      }

      if (filters.retail_location && filters.retail_location.value !== '*') {
        filteredSchedules = filteredSchedules.filter((schedule) => {
          return schedule.retail_location_id === filters.retail_location.value
        })
      } else if (filters.retailer && filters.retailer.value !== '*') {
        filteredSchedules = filteredSchedules.filter(
          (schedule) => schedule.retailer_id === filters.retailer.value
        )
      }
    }

    return filteredSchedules
  }, [schedules, filters.search, filters.retailer, filters.retail_location])

  const paginatedSchedules = React.useMemo(() => {
    return filteredSchedules
      ? paginate(filteredSchedules, filters.page, filters.per_page)
      : []
  }, [filters.page, filters.per_page, filteredSchedules])

  if (!userCan('view_schedules')) return <Error401 />

  return (
    <>
      <Header
        title={
          filters.retailer && filters.retailer.value !== '*'
            ? `Schedules (${filters.retailer.label})`
            : 'Schedules'
        }
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="font-weight-bold">
                    {filteredSchedules
                      ? `${filteredSchedules.length} ${pluralize(
                          'schedule',
                          filteredSchedules.length
                        )}`
                      : '0 schedules'}
                  </div>
                </div>

                {userCan('create_schedule') && (
                  <div>
                    <Link to={route('schedule_create')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create Schedule
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>

            <Card.Header className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <Dropdown className="filters-wrapper">
                  <Dropdown.Toggle size="sm" variant="link" id="dropdown-basic">
                    <BsFilter size={24} />
                    <span className="text-capitalize">{activeFilters}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* Retailer */}
                    <div className="filter-group">
                      <span className="filter-label">Retailer</span>
                      <RetailerSelect
                        value={filters.retailer || null}
                        onChange={(retailer) => {
                          setFilters({
                            retailer,
                            retail_location: null,
                            page: 1,
                          })
                        }}
                      />
                    </div>

                    <div className="filter-group">
                      <span className="filter-label">Store</span>
                      <RetailLocationSelect
                        value={filters.retail_location || null}
                        onChange={(retail_location) => {
                          setFilters({
                            retail_location,
                            page: 1,
                          })
                        }}
                        retailer={filters.retailer ? filters.retailer : false}
                      />
                    </div>

                    <div className="filter-group mt-4">
                      <Button
                        size="sm"
                        variant="outline-light"
                        onClick={() => resetFilters(filters)}
                      >
                        Reset
                      </Button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <div className="ml-2">
                  <SearchInput
                    placeholder="Search schedules"
                    value={filters.search || ''}
                    onChange={(search) => setFilters({ search, page: 1 })}
                    disabled={isLoading || !filteredSchedules}
                  />
                </div>
              </div>
              <PerPageSelect
                value={filters.per_page || 10}
                onChange={(per_page) => setFilters({ per_page, page: 1 })}
              />
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}

              {!isLoading && !paginatedSchedules.length ? (
                <div className="my-5">
                  <NotFound summary="No schedules found" />
                </div>
              ) : null}

              {!isLoading && paginatedSchedules.length ? (
                <div className="table-wrapper padded">
                  <Table className="table-fit" hover responsive>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th>Retailer</th>
                        <th>Store</th>
                        <th>Name</th>
                        <th>Period</th>
                        <th>Group</th>
                        <th>Location (In Store)</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedSchedules.map((schedule, index) => (
                        <tr
                          key={schedule._id}
                          className="cursor-pointer"
                          onClick={() =>
                            history.push(
                              route('schedule_update', {
                                id: schedule._id,
                              })
                            )
                          }
                        >
                          <td className="align-middle text-center">
                            {index + 1 + (filters.page - 1) * filters.per_page}
                          </td>
                          <td className="align-middle">
                            {schedule?.retailer?.name || '-'}
                          </td>
                          <td className="align-middle">
                            {schedule?.retail_location?.name || '-'}
                          </td>
                          <td className="align-middle font-weight-bold">
                            {schedule?.name || '-'}
                          </td>
                          <td className="align-middle font-weight-bold">
                            {undoComputeTTL(schedule.period)}
                          </td>
                          <td className="align-middle font-weight-bold">
                            {schedule.group}
                          </td>
                          <td className="align-middle font-weight-bold">
                            {schedule.location}
                          </td>
                          <td className="align-middle">
                            <Badge
                              className="text-capitalize"
                              variant={
                                schedule.status === 'active'
                                  ? 'success'
                                  : 'danger'
                              }
                            >
                              {schedule.status}
                            </Badge>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : null}

              <Pagination
                className="my-4"
                perPage={filters.per_page}
                totalItems={filteredSchedules.length}
                currentPage={filters.page}
                onChange={(page) => setFilters({ ...filters, page })}
              />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Schedules
