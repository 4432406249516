import React from 'react'

import { Formik, Field, ErrorMessage } from 'formik'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'

import Logo from 'src/assets/images/favicon.png'
import DownloadButton from 'src/assets/images/landing/download-app.svg'
import Spinner from 'src/components/Core/Spinner/Spinner'

import { IUser } from 'src/services/types/user'

import UserService from 'src/services/UserService'
import { IState } from 'src/store'
import { AUTH, SET_USER } from 'src/store/actionTypes'
import { route } from 'src/utils/routes'

interface IProps {
  isAuthenticated: boolean
  dispatch: (arg) => void
}

const Login: React.FunctionComponent<IProps> = ({
  isAuthenticated,
  ...props
}) => {
  const userService = new UserService()

  const { addToast } = useToasts()

  const formConfig = {
    initialValues: {
      email: '',
      hash: '',
      remember: true,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Email field is required.'),
      hash: Yup.string().required('Enter your password'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const formData = new FormData()
      formData.append('email', values.email)
      formData.append('hash', values.hash)
      formData.append('remember', values.remember)

      userService
        .doLogin(values)
        .then((user: IUser) => {
          setSubmitting(false)
          props.dispatch({ type: SET_USER, payload: user })
          props.dispatch({ type: AUTH, payload: { token: user.token } })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
        })
    },
  }

  if (isAuthenticated) {
    return <Redirect to={route('dashboard')} />
  }

  return (
    <Container style={{ paddingTop: '80px', maxWidth: '960px' }}>
      <Row className="d-flex align-items-center">
        <Col>
          <img style={{ maxWidth: '50px' }} alt="Shelfie Logo" src={Logo} />
          <h1 className="mt-4">
            Modern retail
            <span className="text-primary"> insights.</span>
          </h1>
          <p style={{ fontSize: '20px' }}>
            WeShelfie offers a smarter way to track insights from modern retail
            stores.
          </p>
          <a href="https://play.google.com/store/apps/details?id=com.suplias.weshelfie">
            <img
              style={{ width: '150px' }}
              src={DownloadButton}
              alt="Playstore Icon"
            />
          </a>
        </Col>
        <Col>
          <Card style={{ padding: '30px' }}>
            <Card.Body>
              <h4 className="text-muted">Welcome back,</h4>
              <h4 className="mb-4">Log in to continue</h4>

              <Formik
                initialValues={formConfig.initialValues}
                validationSchema={formConfig.validationSchema}
                onSubmit={formConfig.onSubmit}
              >
                {({ errors, touched, ...formik }) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="email">
                      <Form.Label>Your Email</Form.Label>
                      <Field
                        as={Form.Control}
                        name="email"
                        type="email"
                        isInvalid={touched.email && errors.email ? true : false}
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className="invalid-feedback"
                      />
                    </Form.Group>
                    <Form.Group controlId="password">
                      <Form.Label>Your Password</Form.Label>
                      <Field
                        as={Form.Control}
                        name="hash"
                        type="password"
                        isInvalid={touched.hash && errors.hash ? true : false}
                      />
                      <ErrorMessage
                        name="hash"
                        component="span"
                        className="invalid-feedback"
                      />
                    </Form.Group>
                    <Field
                      as={Form.Check}
                      id="remember"
                      name="remember"
                      type="checkbox"
                      label="Remember me"
                      custom
                    />
                    <Button
                      type="submit"
                      variant="primary"
                      className="mt-3"
                      disabled={formik.isSubmitting || !formik.isValid}
                      block
                    >
                      {formik.isSubmitting ? (
                        <Spinner variant="white" button />
                      ) : (
                        'Log In'
                      )}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

Login.defaultProps = {
  isAuthenticated: false,
}

export default withRouter(
  connect((state: IState) => ({
    isAuthenticated: !!state.auth.token,
  }))(Login)
)
