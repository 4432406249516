import React from 'react'

import { connect } from 'react-redux'
import { Container, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import ProductService from 'src/services/ProductService'
import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IProduct } from 'src/services/types/product'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import ProductForm from 'src/components/Product/ProductForm'

const productService = new ProductService()

interface IProps extends RouteComponentProps {
  match: any
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const ProductUpdate: React.FC<IProps> = ({ match, history, user, userCan }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [product, setProduct] = React.useState<IProduct>()

  React.useEffect(() => {
    if (userCan('update_product')) {
      productService
        .fetchById(match.params.id)
        .then((product) => setProduct(product))
        .finally(() => setIsLoading(false))
    }
  }, [match.params.id, userCan])

  if (!userCan('update_product')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Product'
            : product
            ? `${product.name}`
            : 'Product not found'
        }
        backText="Products"
        onBack={() => history.push(route('products'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Product Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              {!isLoading && product ? (
                <ProductForm
                  user={user}
                  type="update"
                  product={product}
                  updateProduct={(updatedValues) => {
                    setProduct({ ...product, ...updatedValues })
                  }}
                />
              ) : null}

              {!isLoading && !product ? (
                <div className="my-5">
                  <NotFound summary="Product not found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(ProductUpdate)
)
