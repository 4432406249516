import React from 'react'
import { Row, Col } from 'react-bootstrap'

interface IProps {
  title?: string
  summary: string
}

const Unauthorized: React.FC<IProps> = ({ title, summary }) => (
  <Row className="justify-content-center">
    <Col md={8} className="text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="42"
        viewBox="0 0 32 42"
      >
        <g fill="#3b54ec">
          <path d="M28 14.5L25.6 14.5 25.6 9.8C25.6 4.6 21.4 0.2 16.1 0.1 10.8 0 6.4 4.4 6.4 9.7L6.4 14.5 4 14.5C1.8 14.5 0 16.3 0 18.5L0 37.7C0 39.9 1.8 41.7 4 41.7L28 41.7C30.2 41.7 32 39.9 32 37.7L32 18.5C32 16.3 30.2 14.5 28 14.5ZM17.4 27L17.4 33.9C17.4 34.6 16.8 35.3 16.1 35.3 15.3 35.3 14.6 34.7 14.6 33.9L14.6 27C13.5 26.4 12.7 25.2 12.8 23.8 13 22.3 14.2 21 15.8 20.9 17.6 20.8 19.2 22.3 19.2 24.1 19.2 25.4 18.5 26.5 17.4 27ZM22.8 14.5L9.2 14.5 9.2 9.7C9.2 7.9 9.9 6.2 11.2 4.9 12.5 3.6 14.2 2.9 16 2.9 17.8 2.9 19.5 3.6 20.8 4.9 22.1 6.2 22.8 7.9 22.8 9.7L22.8 14.5Z" />
        </g>
      </svg>
      {title ? <h5 className="mt-3">{title}</h5> : null}
      <p className={`text-muted ${!title ? 'mt-3' : ''}`}>{summary}</p>
    </Col>
  </Row>
)

export default Unauthorized
