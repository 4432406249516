import React from 'react'

import { connect } from 'react-redux'
import { Container, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'

import Error401 from 'src/pages/Error/401.page'
import ManufacturerService from 'src/services/ManufacturerService'
import { IManufacturer } from 'src/services/types/manufacturer'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import ManufacturerForm from 'src/components/Manuafacturer/ManufacturerForm'

const manufacturerService = new ManufacturerService()

interface IProps extends RouteComponentProps {
  userCan: (permission: string) => boolean

  match: any
}

const ManufacturerUpdate: React.FC<IProps> = ({ userCan, match, history }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [mftr, setMftr] = React.useState<IManufacturer>()

  const _isMounted = React.useRef<boolean>(false)
  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  React.useEffect(() => {
    if (userCan('update_manufacturer')) {
      manufacturerService
        .fetchById(match.params.id)
        .then((mftr) => _isMounted.current && setMftr(mftr))
        .finally(() => _isMounted.current && setIsLoading(false))
    }
  }, [userCan, match.params.id])

  if (!userCan('update_manufacturer')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Organization'
            : mftr
            ? `${mftr.name}`
            : 'Organization not found'
        }
        backText="Organizations"
        onBack={() => history.push(route('manufacturers'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Organization Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              {!isLoading && mftr ? (
                <ManufacturerForm
                  type="update"
                  manufacturer={mftr}
                  updateManufacturer={(updatedValues) => {
                    setMftr({ ...mftr, ...updatedValues })
                  }}
                />
              ) : null}

              {!isLoading && !mftr ? (
                <div className="my-5">
                  <NotFound summary="Manufacturer not found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect(() => ({}), { userCan })(withRouter(ManufacturerUpdate))
