import React from 'react'

import { Card, Image, Button, Row, Col } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs'
import { useSelector } from 'react-redux'

import NotFound from 'src/components/Error/NotFound'
import SurveyQuestionForm from 'src/components/Survey/SurveyQuestionForm'

import useMounted from 'src/hooks/useMounted'
import { ISurvey, ISurveyQuestion } from 'src/services/types/survey'

import SurveyService from 'src/services/SurveyService'
import { IState } from 'src/store'
import { asset } from 'src/utils/file'

const surveyService = new SurveyService()

interface IProps {
  survey: ISurvey
}

const SurveyQuestions: React.FC<IProps> = ({ survey }) => {
  const isMounted = useMounted()
  const user = useSelector((state: IState) => state.user)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [questions, setQuestions] = React.useState<ISurveyQuestion[]>()

  const [addQuestion, setAddQuestion] = React.useState<boolean>(false)

  /**
   * Store the question that is currently
   * being edited
   */
  const [activeQuestion, setActiveQuestion] = React.useState<ISurveyQuestion>()

  React.useEffect(() => {
    const fetchSurveyQuestions = (): void => {
      setIsLoading(true)
      surveyService
        .fetchQuestionsBySurvey(survey._id)
        .then((questions) => {
          isMounted.current && setQuestions(questions)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    !activeQuestion && !addQuestion && fetchSurveyQuestions()
  }, [isMounted, survey._id, activeQuestion, addQuestion])

  return (
    <Card>
      <Card.Header className="w-100 d-flex align-items-center justify-content-between">
        {!isLoading && addQuestion ? (
          <>
            <Card.Title>Add Question</Card.Title>

            <Button
              variant="danger"
              size="sm"
              onClick={() => setAddQuestion(false)}
            >
              Cancel
            </Button>
          </>
        ) : !isLoading && activeQuestion ? (
          <>
            <Card.Title>Edit Question</Card.Title>

            <Button
              variant="danger"
              size="sm"
              onClick={() => setActiveQuestion(null)}
            >
              Cancel
            </Button>
          </>
        ) : !isLoading && !addQuestion && !activeQuestion ? (
          <>
            <Card.Title>
              Survey Questions {questions ? `(${questions.length})` : null}
            </Card.Title>

            <Button size="sm" onClick={() => setAddQuestion(true)}>
              <BsPlus size={20} /> Add Question
            </Button>
          </>
        ) : (
          <Card.Title>Survey Questions</Card.Title>
        )}
      </Card.Header>

      <Card.Body className={addQuestion || activeQuestion ? 'pt-0' : null}>
        {!isLoading && addQuestion ? (
          <SurveyQuestionForm
            user={user}
            submitted={() => setAddQuestion(false)}
            survey={survey}
          />
        ) : !isLoading && activeQuestion ? (
          <SurveyQuestionForm
            user={user}
            type="update"
            question={activeQuestion}
            submitted={() => setActiveQuestion(null)}
            survey={survey}
          />
        ) : !isLoading && questions && questions.length ? (
          questions.map((question, qIndex) => (
            <React.Fragment key={qIndex}>
              <Row key={qIndex} className="surveyQuestion align-items-center">
                <Col md={8}>
                  <span className="surveyQuestion__title">
                    [Q{qIndex + 1}] {question.title}
                  </span>
                  <ul
                    className={`pl-4 ${
                      question.type === 'multiple-choice' ? 'mt-2' : null
                    }`}
                  >
                    {question.type === 'multiple-choice'
                      ? question.choices.map((choice, cIndex) => (
                          <li key={cIndex}>{choice.title}</li>
                        ))
                      : null}
                  </ul>
                  <span
                    className="text-primary cursor-pointer action-link"
                    onClick={() => setActiveQuestion(question)}
                  >
                    Edit question
                  </span>
                </Col>
                {question.upload ? (
                  <Col md={4}>
                    <Image src={asset(question.upload.path)} rounded fluid />
                  </Col>
                ) : null}
              </Row>
              {qIndex !== questions.length - 1 ? (
                <div className="my-4">
                  <hr />
                </div>
              ) : null}
            </React.Fragment>
          ))
        ) : null}

        {!isLoading && questions && !questions.length ? (
          <div className="my-5">
            <NotFound summary="No survey questions found" />
          </div>
        ) : null}

        {isLoading && (
          <div className="d-flex justify-content-center py-5">
            <figure className="spinner primary" />
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default SurveyQuestions
