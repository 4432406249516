import React from 'react'

import { connect } from 'react-redux'
import { Container, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import TargetService from 'src/services/TargetService'
import { ITarget } from 'src/services/types/target'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import TargetForm from 'src/components/Target/TargetForm'

const targetService = new TargetService()

interface IProps extends RouteComponentProps {
  match: any
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const TargetUpdate: React.FC<IProps> = ({ match, history, user, userCan }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [target, setTarget] = React.useState<ITarget>()

  React.useEffect(() => {
    if (userCan('update_target')) {
      targetService
        .fetchById(match.params.id)
        .then((target) => setTarget(target))
        .finally(() => setIsLoading(false))
    }
  }, [userCan, match.params.id])

  if (!userCan('update_target')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Target'
            : target
            ? `${target.retail_location.name}`
            : 'Target not found'
        }
        backText="Targets"
        onBack={() => history.push(route('targets'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Target Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              {!isLoading && target ? (
                <TargetForm
                  user={user}
                  type="update"
                  target={target}
                  updateTarget={(updatedValues) => {
                    setTarget({ ...target, ...updatedValues })
                  }}
                />
              ) : null}

              {!isLoading && !target ? (
                <div className="my-5">
                  <NotFound summary="Target not found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(TargetUpdate)
)
