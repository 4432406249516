import React from 'react'

import { Card, Container, Row, Col, Image } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import DummyGraph from 'src/assets/images/dummy-graph.png'
import DeviceVersionChart from 'src/components/Dashboard/DeviceVersionChart'
import ManufacturerDashboard from 'src/components/Dashboard/ManufacturerDashboard'
import Header from 'src/components/Layout/Header/Header'

import { Role } from 'src/config/roles'
import { IState } from 'src/store'

const Dashboard: React.FC = () => {
  const user = useSelector((state: IState) => state.user)

  return (
    <>
      <Header title="Dashboard" />
      <div id="dashboard">
        {user.role !== Role.RootAdmin ? (
          <ManufacturerDashboard />
        ) : (
          <div className="page-container">
            <Container>
              <Card>
                <Card.Header>
                  <Card.Title>Welcome to Shelfie v1.0.0</Card.Title>
                </Card.Header>
                <Card.Body>
                  You are logged in as <b>{user.name}</b>, with {` `}
                  email <b>{user.email}</b> and role <b>{user.role}</b>
                </Card.Body>
              </Card>

              <Row className="mt-3">
                <Col md={6}>
                  <DeviceVersionChart />
                </Col>

                <Col md={6}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Sales Summary</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Image src={DummyGraph} fluid rounded />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </>
  )
}

export default Dashboard
