import { ITree } from './types/tree'
import BaseService from './BaseService'

class TreeService extends BaseService {
  constructor() {
    super('tree')
  }

  fetchByManufacturer(mftrId: string): Promise<ITree[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `trees_${mftrId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post('tree/read_by_manufacturer', formData)
            .then(({ data }) => {
              if (data.code === 200) {
                const trees = data.data.map((tree: any) => {
                  tree.store_count = 0

                  if (tree.retail_locations) {
                    tree.store_count = tree.retail_locations.length
                  }

                  return tree
                })

                this.cache.set(cacheKey, trees)
                resolve(trees)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default TreeService
