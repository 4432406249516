import Terms from 'src/pages/Landing/Terms.page'
import Privacy from 'src/pages/Landing/Privacy.page'
import Download from 'src/pages/Landing/Download.page'
import Links from 'src/pages/Landing/Links.page'

import { IRoute } from '.'

const brandRoutes: IRoute[] = [
  {
    id: 'landing_terms',
    path: '/terms',
    component: Terms,
    exact: true,
  },
  {
    id: 'landing_privacy',
    path: '/privacy',
    component: Privacy,
    exact: true,
  },
  {
    id: 'landing_links',
    path: '/links',
    component: Links,
    exact: true,
  },
  {
    id: 'landing_download',
    path: '/download',
    component: Download,
    exact: true,
  },
]

export default brandRoutes
