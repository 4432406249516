import React from 'react'
import {
  ResponsiveContainer,
  PieChart,
  Legend,
  Pie,
  Cell,
  Tooltip,
} from 'recharts'
import { Card } from 'react-bootstrap'

import NotFound from 'src/components/Error/NotFound'

import useMounted from 'src/hooks/useMounted'

import DeviceService from 'src/services/DeviceService'

const deviceService = new DeviceService()

const DeviceVersionChart: React.FC = () => {
  const isMounted = useMounted()

  const [isLoading, setIsLoading] = React.useState(true)
  const [devices, setDevices] = React.useState<any>([])

  React.useEffect(() => {
    const fetchDevices = (): void => {
      setIsLoading(true)

      deviceService
        .fetch()
        .then((devices) => {
          if (typeof devices === 'object') {
            let devices_data = []

            Object.keys(devices).forEach((version) => {
              const version_parts = [
                version[0],
                Number(version[1] + version[2]),
                Number(version[3] + version[4]),
              ]

              devices_data.push({
                name: version_parts.join('.'),
                value: devices[version].length,
              })
            })

            devices_data = devices_data.sort((a, b) =>
              a.name < b.name ? -1 : a.name > b.name ? 1 : 0
            )

            isMounted.current && setDevices(devices_data)
          }
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    fetchDevices()
  }, [isMounted])

  const chartColors = [
    '#d8003c',
    '#FFBB28',
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#148e55',
  ]

  return (
    <Card>
      <Card.Header>
        <Card.Title>Device Versions</Card.Title>
      </Card.Header>
      <Card.Body>
        {!isLoading && devices.length ? (
          <>
            <ResponsiveContainer height={300}>
              <PieChart onMouseEnter={() => ({})}>
                <Legend verticalAlign="top" />
                <Tooltip />
                <Pie
                  data={devices}
                  isAnimationActive={false}
                  dataKey="value"
                  innerRadius="35%"
                  outerRadius="70%"
                  fill="#8884d8"
                  label
                >
                  {devices.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={chartColors[index % chartColors.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </>
        ) : null}

        {!isLoading && !devices.length ? (
          <div className="my-5">
            <NotFound summary="No device version data found" />
          </div>
        ) : null}

        {isLoading && (
          <div className="d-flex justify-content-center py-5">
            <figure className="spinner" />
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default DeviceVersionChart
