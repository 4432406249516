import cache from '../utils/cache'
import { http } from '../utils/http'
import { IManufacturer } from './types/manufacturer'

const defaultUploadId = process.env.REACT_APP_DEFAULT_UPLOAD_ID

class ManufacturerService {
  fetch(): Promise<IManufacturer[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('manufacturers')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('manufacturer/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('manufacturers', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(manufacturerId: string): Promise<IManufacturer> {
    return new Promise((resolve, reject) => {
      const cacheKey = `manufacturer_${manufacturerId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .get(`manufacturer/read/?_id=${manufacturerId}`)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(manufacturer: IManufacturer): Promise<string> {
    const formData = new FormData()
    formData.append('upload_id', defaultUploadId) // default

    Object.keys(manufacturer).forEach((key) => {
      formData.append(key, manufacturer[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('manufacturer/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(manufacturer: IManufacturer): Promise<any> {
    const formData = new FormData()

    Object.keys(manufacturer).forEach((key) => {
      formData.append(key, manufacturer[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('manufacturer/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  dashboard(
    type: string,
    mftrId: string,
    month: string,
    year: string
  ): Promise<any> {
    const formData = new FormData()

    formData.append('_id', mftrId)

    formData.append('month', month)
    formData.append('year', year)
    formData.append('keys[]', '')
    formData.append('values[]', '')

    return new Promise((resolve, reject) => {
      const cacheKey = `manufacturer_dashboard_${type}_${mftrId}_${month}_${year}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`manufacturer/dashboard_${type}`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
            .catch(() => {
              reject({ message: 'An unexpected error occured!' })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default ManufacturerService
