import cache from '../utils/cache'
import { http } from '../utils/http'
import { IBrand } from './types/brand'

const defaultUploadId = process.env.REACT_APP_DEFAULT_UPLOAD_ID

class BrandService {
  fetch(): Promise<IBrand[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('brands')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('brand/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('brands', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(brandId: string): Promise<IBrand> {
    return new Promise((resolve, reject) => {
      const cacheKey = `brand_${brandId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`brand/read/?_id=${brandId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<IBrand[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `brands_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`brand/read_by_manufacturer`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<IBrand[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `brands_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`brand/read_by_status`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(brand: IBrand): Promise<string> {
    const formData = new FormData()
    formData.append('upload_id', defaultUploadId) // default

    Object.keys(brand).forEach((key) => {
      formData.append(key, brand[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('brand/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(brand: IBrand): Promise<any> {
    const formData = new FormData()

    Object.keys(brand).forEach((key) => {
      formData.append(key, brand[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('brand/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default BrandService
