import Configurations from 'src/pages/Configuration/Configurations.page'
import ConfigurationCreate from 'src/pages/Configuration/ConfigurationCreate.page'
import ConfigurationUpdate from 'src/pages/Configuration/ConfigurationUpdate.page'

import { IRoute } from '.'

const configurationRoutes: IRoute[] = [
  {
    id: 'configurations',
    path: '/configurations',
    component: Configurations,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'configuration_create',
    path: '/configurations/create',
    component: ConfigurationCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'configuration_update',
    path: '/configurations/:id/update',
    component: ConfigurationUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default configurationRoutes
