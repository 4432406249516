import Brands from 'src/pages/Brand/Brands.page'
import BrandCreate from 'src/pages/Brand/BrandCreate.page'
import BrandUpdate from 'src/pages/Brand/BrandUpdate.page'
import Products from 'src/pages/Product/Products.page'

import { IRoute } from '.'

const brandRoutes: IRoute[] = [
  {
    id: 'brands',
    path: '/brands',
    component: Brands,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'brand_create',
    path: '/brands/create',
    component: BrandCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'brand_update',
    path: '/brands/:id/update',
    component: BrandUpdate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'brand_products',
    path: '/brands/:id/products',
    component: Products,
    requiresAuth: true,
    exact: true,
  },
]

export default brandRoutes
