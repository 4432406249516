import cache from '../utils/cache'
import { http } from '../utils/http'
import { IConfiguration } from './types/configuration'

class ConfigurationService {
  fetch(): Promise<IConfiguration[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('configurations')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('configuration/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('configurations', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(configurationId: string): Promise<IConfiguration> {
    return new Promise((resolve, reject) => {
      const cacheKey = `configuration_${configurationId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .get(`configuration/read/?_id=${configurationId}`)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data[0])
                resolve(data.data[0])
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<IConfiguration[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `configurations_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`configuration/read_by_manufacturer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<IConfiguration[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `configurations_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`configuration/read_by_status`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(configuration: IConfiguration): Promise<string> {
    const formData = new FormData()

    Object.keys(configuration).forEach((key) => {
      formData.append(key, configuration[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('configuration/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(configuration: IConfiguration): Promise<any> {
    const formData = new FormData()

    Object.keys(configuration).forEach((key) => {
      formData.append(key, configuration[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('configuration/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  fetchMustStock(type, mftrId: string, configId: string): Promise<any[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)
    formData.append('configuration_id', configId)

    const endpoint = type === 'product' ? 'must_stock' : 'must_stock_other'

    return new Promise((resolve, reject) => {
      const cacheKey = `must_stock_${configId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`${endpoint}/read_by_configuration`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchMustStockUnattached(
    type,
    mftrId: string,
    configId: string,
    categoryId: string
  ): Promise<any[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)
    formData.append('configuration_id', configId)
    formData.append('category_id', categoryId)

    const endpoint = type === 'product' ? 'must_stock' : 'must_stock_other'

    return new Promise((resolve, reject) => {
      const cacheKey = `must_stock_unattached_${configId}_${categoryId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(
              `${endpoint}/read_by_configuration_category_unattached`,
              formData
            )
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  /**
   * Add must stock product to configuration.
   *
   * @param details
   */
  addMustStock(details: {
    type: 'product' | 'competition'
    products: any[]
    category_id: string
    configuration_id: string
  }): Promise<any> {
    const formData = new FormData()

    details.products.forEach((productId: any) => {
      formData.append(`${details.type}_id[]`, productId)
    })

    formData.append('category_id', details.category_id)
    formData.append('configuration_id', details.configuration_id)
    formData.append('manufacturer_id', details.configuration_id)

    return new Promise((resolve, reject) => {
      try {
        http.post(`must_stock_other/create_bulk`, formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  /**
   * Deletes must stock product from configuration.
   *
   * @param details
   */
  removeMustStock(details: {
    type: 'product' | 'competition'
    must_stock_id: string
  }): Promise<any> {
    const formData = new FormData()

    formData.append('_id', details.must_stock_id)

    return new Promise((resolve, reject) => {
      try {
        http.post(`must_stock_other/delete`, formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  fetchRetailLocations(
    mftrId: string,
    configId: string,
    unattached = false
  ): Promise<any[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)
    formData.append('configuration_id', configId)

    const endpoint = `read_by_configuration${unattached ? '_unattached' : ''}`

    return new Promise((resolve, reject) => {
      const cacheKey = `retail_locations_${endpoint}_${configId}`
      const cachedData = cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          http
            .post(`retail_location_configuration/${endpoint}`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  /**
   * Add retail location(s) to configuration.
   *
   * @param configId
   * @param locations
   */
  addRetailLocation(configId: string, locations: any[]): Promise<any> {
    const formData = new FormData()

    locations.forEach((locationId: any) => {
      formData.append(`retail_location_id[]`, locationId)
    })

    formData.append('status', 'active')
    formData.append('configuration_id', configId)

    return new Promise((resolve, reject) => {
      try {
        http
          .post(`retail_location_configuration/create_bulk`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              cache.reset()
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  /**
   * Deletes must stock product from configuration.
   *
   * @param details
   */
  removeRetailLocation(locationId: string): Promise<any> {
    const formData = new FormData()

    formData.append('_id', locationId)

    return new Promise((resolve, reject) => {
      try {
        http
          .post(`retail_location_configuration/delete`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              cache.reset()
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default ConfigurationService
