import { combineReducers } from 'redux'
import authReducer from './auth/authReducer'
import configReducer from './config/configReducer'
import globalReducer from './global/globalReducer'
import notificationReducer from './notification/notifcationReducer'
import reportFiltersReducer from './reportFilters/reportFiltersReducer'
import userReducer from './user/userReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  config: configReducer,
  global: globalReducer,
  notification: notificationReducer,
  reportFilters: reportFiltersReducer,
  user: userReducer,
})

export default rootReducer
