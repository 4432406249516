import React from 'react'

import * as Papa from 'papaparse'
import pluralize from 'pluralize'
import { Button, Image } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import xlsx from 'xlsx'

import UploadIcon from 'src/assets/images/icons/upload.svg'
import useMounted from 'src/hooks/useMounted'

import { IRetailLocation } from 'src/services/types/retailLocation'

import RetailerService from 'src/services/RetailerService'
import RetailLocationService from 'src/services/RetailLocationService'

import { sortByKey } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

const retailerService = new RetailerService()
const retailLocationService = new RetailLocationService()

interface IProps {
  retailer?: string
}

const RetailLocationsUpload: React.FC<IProps> = ({ retailer }) => {
  const history = useHistory()
  const isMounted = useMounted()
  const user = useSelector((state: IState) => state.user)

  const { addToast } = useToasts()
  const fileInput = React.useRef<any>()

  const [isUploading, setIsUploading] = React.useState<boolean>(false)
  const [locations, setLocations] = React.useState<IRetailLocation[]>()

  const [templateItems, setTemplateItems] = React.useState<any[]>()

  React.useEffect(() => {
    const fetchRetailers = (): void => {
      retailerService
        .fetchByManufacturer(user.manufacturer_id)
        .then((retailers) => {
          /** Create a template entry for each location */
          let items = retailers.map((retailer) => ({
            retailer: retailer.name,
            store: '',
            status: '',
            retailer_id: retailer._id,
          }))

          if (isMounted.current) {
            items = sortByKey(items, 'store')
            setTemplateItems([
              {
                retailer: 'Sample Retailer',
                store: 'Sample Store',
                status: 'active',
                retailer_id: '5e63dee6363ab84b4f3a9000',
              },
              ...items,
            ])
          }
        })
    }

    if (!retailer) {
      fetchRetailers()
    }
  }, [isMounted, user.manufacturer_id, retailer])

  const exportTemplate = React.useCallback((items): void => {
    const fileName = 'stores-template'
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(items)
    const wb: xlsx.WorkBook = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(wb, ws, 'Targets')
    xlsx.writeFile(wb, `${fileName}.xlsx`)
  }, [])

  const doUpload = (): void => {
    setIsUploading(true)

    retailLocationService
      .createBulk(locations)
      .then(() => {
        setIsUploading(false)
        addToast('Retail locations uploaded successfully.', {
          appearance: 'success',
        })

        history.push(route('retail_locations'))
      })
      .catch((error: any) => {
        setIsUploading(false)
        addToast(error.message, { appearance: 'error' })
      })
  }

  const _parseFile = (csv: any): void => {
    Papa.parse(csv, {
      delimiter: '',
      newline: '',
      quoteChar: '"',
      escapeChar: '"',
      headers: true,
      complete: ({ data }) => {
        try {
          const locations = []
          for (let i = 0; i < data.length; i++) {
            /**
             * Two things:
             * 1. Let's skip the first row, it's usuall the header
             * 2. If we have less that the expected columns, skip
             */
            if (retailer && i !== 0 && data[i].length === 2) {
              locations.push({
                name: data[i][0],
                status: data[i][1],

                retailer_id: retailer,
                manufacturer_id: user.manufacturer_id,
              })
            } else {
              locations.push({
                /** retailer: data[i][0] */
                name: data[i][1],
                status: data[i][2],

                retailer_id: data[i][3],
                manufacturer_id: user.manufacturer_id,
              })
            }

            if (i === data.length - 1) setLocations(locations)
          }
        } catch (error) {
          addToast(error.message, { appearance: 'error' })
          throw error
        }
      },
    })

    fileInput.current.value = null // reset file input
  }

  const handleFileChange = (event): void => {
    const files = event.target.files || event.dataTransfer.files
    if (!files.length) return

    _parseFile(files[0])
  }

  return (
    <div className="text-center my-5">
      <Image src={UploadIcon} /> <br />
      <div>
        {locations && locations.length ? (
          <p className="text-muted mt-2">
            <b>{locations.length}</b>{' '}
            {pluralize('retail store', locations.length)} found in file.
          </p>
        ) : locations && !locations.length ? (
          <p className="text-danger mt-2">
            No retail stores found, please check the file and try again.
          </p>
        ) : (
          <p className="text-muted mt-2">
            Upload retail stores from a .csv file
          </p>
        )}
      </div>
      <div className="mt-3">
        <input
          type="file"
          className="hidden-input"
          accept=".csv"
          onChange={handleFileChange}
          ref={fileInput}
        />

        <Button
          size="sm"
          variant="outline-light"
          onClick={() => fileInput.current.click()}
          disabled={isUploading}
        >
          Open CSV File
        </Button>

        <Button
          size="sm"
          variant="success"
          className="ml-2"
          onClick={() => doUpload()}
          disabled={isUploading || !locations || !locations.length}
        >
          {!isUploading ? (
            `Upload ${pluralize('Retail Store', locations && locations.length)}`
          ) : (
            <figure className="spinner button white" />
          )}
        </Button>

        <div className="mt-3">
          {templateItems && templateItems.length ? (
            <span
              className="small text-primary cursor-pointer"
              onClick={() => exportTemplate(templateItems)}
            >
              Download Template ({templateItems.length}{' '}
              {pluralize('Row', templateItems.length)})
            </span>
          ) : (
            <a
              href="/retail-locations.csv"
              className="small"
              download="retail-locations.csv"
            >
              Download Sample CSV
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default RetailLocationsUpload
