import React from 'react'
import { SET_GLOBAL } from '../actionTypes'

export interface IGlobal {
  isLoading: boolean
}

interface IAction {
  type: string
  payload: IGlobal
}

const initialState: IGlobal = {
  isLoading: true,
}

const globalReducer: React.Reducer<IGlobal, IAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_GLOBAL:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default globalReducer
