import React from 'react'

import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
// import { useToasts } from 'react-toast-notifications'

import TicketService from 'src/services/TicketService'
import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { ITicket, TicketStatus } from 'src/services/types/ticket'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import TicketLogs from 'src/components/Ticket/TicketLogs'
import TicketDetails from 'src/components/Ticket/TicketDetails'
import NotFound from 'src/components/Error/NotFound'

const ticketService = new TicketService()

interface IProps extends RouteComponentProps {
  match: any
  history: any
  /** From user actions */
  userCan: (permission: string) => boolean
}

const Ticket: React.FC<IProps> = ({ history, match, userCan }) => {
  const _isMounted = React.useRef(false)

  // const { addToast } = useToasts()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [ticket, setTicket] = React.useState<ITicket>()

  /** It's important to ensure there is no memory
   * leak on unmounted components
   */
  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  /**
   * Fetch the ticket
   */
  React.useEffect(() => {
    if (userCan('view_tickets')) {
      ticketService
        .fetchById(match.params.id)
        .then((ticket) => _isMounted.current && setTicket(ticket))
        .finally(() => _isMounted.current && setIsLoading(false))
    }
  }, [userCan, match.params.id])

  const updateTicketStatus = (newStatus: TicketStatus): Promise<void> => {
    const details = {
      _id: ticket._id,
      status: newStatus,
    }

    return new Promise((resolve, reject) => {
      ticketService
        .updateStatus(details)
        .then(() => {
          setTicket({
            ...ticket,
            status: newStatus,
          })
          resolve()
        })
        .catch((error) => reject(error))
    })
  }

  if (!userCan('view_tickets')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Ticket'
            : ticket
            ? `#${ticket.created} (${ticket.merchandiser.name})`
            : 'Ticket not found'
        }
        backText="Tickets"
        onBack={() => history.push(route('tickets'))}
      />
      <div className="page-container">
        <Container>
          {isLoading ? (
            <div className="d-flex justify-content-center py-5">
              <figure className="spinner primary" />
            </div>
          ) : (
            <>
              {ticket ? (
                <Row>
                  <Col md={4}>
                    <TicketDetails
                      ticket={ticket}
                      updateTicketStatus={updateTicketStatus}
                    />
                  </Col>

                  <Col md={8}>
                    <TicketLogs ticket={ticket} />
                  </Col>
                </Row>
              ) : (
                <div className="mt-5 pt-5">
                  <NotFound
                    title="Ticket not found!"
                    summary="Check the URL and try again."
                  />
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    </>
  )
}

Ticket.propTypes = {}
export default connect(() => ({}), { userCan })(withRouter(Ticket))
