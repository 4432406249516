import React from 'react'

import pluralize from 'pluralize'
import {
  Container,
  Table,
  Card,
  Button,
  Badge,
  Tabs,
  Tab,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import SearchInput from 'src/components/Form/SearchInput'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'

import useGlobalFilters from 'src/hooks/useGlobalFilters'
import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'

import { ITree } from 'src/services/types/tree'
import Error401 from 'src/pages/Error/401.page'

import TreeService from 'src/services/TreeService'
import { IState } from 'src/store'

import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'
import { BsPlus } from 'react-icons/bs'

const treeService = new TreeService()

const Trees: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const user = useSelector((state: IState) => state.user)

  const [activeTab, setActiveTab] = React.useState<string>('*')

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [trees, setTrees] = React.useState<ITree[]>()

  const { filters, setFilters } = useGlobalFilters('trees')

  React.useEffect(() => {
    const fetchTrees = (): void => {
      treeService
        .fetchByManufacturer(user.manufacturer_id)
        .then((trees) => {
          isMounted.current && setTrees(trees)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_trees') && fetchTrees()
  }, [userCan, user.manufacturer_id, isMounted])

  const filteredTrees = React.useMemo(() => {
    let filtered = trees || []

    if (filtered.length) {
      if (filters.search) {
        const query = filters.search
        const query_parts = filters.search.split(':').map((part) => part.trim())

        filtered = filtered.filter((tree) => {
          if (query_parts?.length === 2 && tree?.[query_parts[0]]) {
            const [accessor, query] = query_parts
            return basicSearch(tree?.[accessor], query)
          } else {
            return (
              basicSearch(tree.name, query) || basicSearch(tree.status, query)
            )
          }
        })
      }

      if (activeTab !== '*') {
        filtered = filtered.filter((tree) => {
          return tree.type === activeTab
        })
      }
    }

    return filtered
  }, [trees, filters.search, activeTab])

  const paginatedTrees = React.useMemo(() => {
    return filteredTrees ? paginate(filteredTrees, filters.page) : []
  }, [filters.page, filteredTrees])

  if (!userCan('view_trees')) return <Error401 />

  return (
    <>
      <Header title="Locations" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    {filteredTrees
                      ? `${filteredTrees.length} ${pluralize(
                          'location',
                          filteredTrees.length
                        )}`
                      : '0 locations'}
                  </div>
                  <div className="ml-3">
                    <SearchInput
                      placeholder="Search locations *"
                      value={filters.search}
                      onChange={(search) => setFilters({ search, page: 1 })}
                    />
                  </div>
                </div>

                {userCan('create_tree') && (
                  <div>
                    <Link to={route('tree_create')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create Location
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Header>
              <Tabs
                id="tree-type"
                variant="pills"
                activeKey={activeTab}
                onSelect={(tab) => {
                  setFilters({ page: 1 })
                  setActiveTab(tab)
                }}
              >
                <Tab eventKey="*" title="All" />
                <Tab eventKey="COUNTRY" title="Countries" />
                <Tab eventKey="REGION" title="Regions" />
                <Tab eventKey="STATE" title="States" />
              </Tabs>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}

              {!isLoading && !paginatedTrees.length ? (
                <div className="my-5">
                  <NotFound summary="No locations found" />
                </div>
              ) : null}

              {!isLoading && paginatedTrees.length ? (
                <div className="table-wrapper padded">
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th className="sticky">Name</th>
                        <th>Type</th>
                        <th>Parent</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedTrees.map((tree, index) => (
                        <tr key={tree._id}>
                          <td>
                            {index + 1 + (filters.page - 1) * filters.per_page}
                          </td>
                          <td className="sticky">{tree.name}</td>
                          <td className="text-capitalize">{tree.type}</td>
                          <td>{tree?.parent_obj?.name || '-'}</td>
                          <td className="align-middle">
                            <Badge
                              className="text-capitalize"
                              variant={
                                tree.status === 'active' ? 'success' : 'danger'
                              }
                            >
                              {tree.status}
                            </Badge>
                          </td>

                          <td className="text-currency">
                            <Link
                              to={route('tree_update', {
                                id: tree._id,
                              })}
                              className="action-link"
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : null}

              <Pagination
                className="my-4"
                perPage={filters.per_page}
                totalItems={filteredTrees.length}
                currentPage={filters.page}
                onChange={(page) => setFilters({ page })}
              />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Trees
