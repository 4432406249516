import React, { useState, useEffect } from 'react'

import { Button } from 'react-bootstrap'

import ConfigurationService from 'src/services/ConfigurationService'

import SearchSelect from 'src/components/Form/SearchSelect'

const configurationService = new ConfigurationService()

interface IProps {
  user: any
  configuration: any
  selectedItems: any[]
  addLocations: (location) => Promise<any>
}

const RetailLocationPicker: React.FC<IProps> = ({
  user,
  configuration,
  ...props
}) => {
  const _isMounted = React.useRef<any>(false)
  // const { addToast } = useToasts()

  /** Indicators , I guess that's what they are called */
  const [isAdding, setIsAdding] = useState<boolean>(false)

  const [locations, setLocations] = useState<any[]>()
  const [selectedLocations, setSelectedLocations] = useState<any>()

  /**
   * It's important to manage memory leaks
   * when components have been unmounted.
   */
  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  /**
   * Fetch unattached products or comp. products
   */
  useEffect(() => {
    configurationService
      .fetchRetailLocations(user.manufacturer_id, configuration._id, true)
      .then((locations) => {
        const tempLocations = locations.map((location) => ({
          value: location._id,
          label: location.name,
        }))
        _isMounted.current && setLocations(tempLocations)
      })
  }, [user.manufacturer_id, configuration._id, props.selectedItems])

  const addSelectedLocations = (): void => {
    setIsAdding(true)
    // const tempProduct = { ...location }

    props
      .addLocations(selectedLocations)
      .then(() => _isMounted.current && setSelectedLocations(null))
      .finally(() => _isMounted.current && setIsAdding(false))
  }

  return (
    <div className="w-100 d-flex align-items-center">
      <SearchSelect
        size="sm"
        className="w-100"
        options={locations}
        value={selectedLocations}
        placeholder="Select retail stores"
        onChange={(selected) => setSelectedLocations(selected)}
        isDisabled={!locations || !locations.length}
        isMulti
      />

      {selectedLocations ? (
        <>
          <Button
            size="sm"
            className="ml-2"
            onClick={addSelectedLocations}
            disabled={isAdding}
          >
            {isAdding ? <figure className="spinner button white" /> : 'Add'}
          </Button>
        </>
      ) : null}
    </div>
  )
}

export default RetailLocationPicker
