import Manufacturers from 'src/pages/Manufacturer/Manufacturers.page'
import ManufacturerCreate from 'src/pages/Manufacturer/ManufacturerCreate.page'
import ManufacturerUpdate from 'src/pages/Manufacturer/ManufacturerUpdate.page'

import { IRoute } from '.'

const manufacturerRoutes: IRoute[] = [
  {
    id: 'manufacturers',
    path: '/manufacturers',
    component: Manufacturers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'manufacturer_create',
    path: '/manufacturers/signup',
    component: ManufacturerCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'manufacturer_update',
    path: '/manufacturers/:id/update',
    component: ManufacturerUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default manufacturerRoutes
