import React from 'react'
import useMounted from './useMounted'
import useQueryParams from './useQueryParams'
import { route } from 'src/utils/routes'
import { useHistory } from 'react-router'

const useGlobalFilters = (routeId: string, defaults?: any): any => {
  const history = useHistory()
  const isMounted = useMounted()

  const [filters, setFilters] = React.useState<any>({
    flag: useQueryParams('flag', '*'),
    status: useQueryParams('status', 'all'),
    page: Number(useQueryParams('page', 1)),
    per_page: Number(useQueryParams('per_page', 10)),
    search: useQueryParams('search'),
    retailer: {
      value: useQueryParams('rv', '*') || '*', // rv: retailer value
      label: useQueryParams('rl', 'All Retailers'),
    },
    retail_location: {
      value: useQueryParams('rlv', '*'), // rl: retail location value
      label: useQueryParams('rll', 'All Retail Stores'),
    },
    region: useQueryParams('region', '*'),
    state: useQueryParams('state', '*'),
    ...defaults,
  })

  React.useEffect(() => {
    const stopListening = history.listen((location) => {
      const query = new URLSearchParams(location.search)

      isMounted.current &&
        setFilters({
          flag: query.get('flag') || '*',
          status: query.get('status') || 'all',
          page: Number(query.get('page')) || 1,
          per_page: Number(query.get('per_page')) || 10,
          search: query.get('search') || null,
          retailer: {
            value: query.get('rv') || '*',
            label: query.get('rl') || 'All Retailers',
          },
          retail_location: {
            value: query.get('rlv') || '*',
            label: query.get('rll') || 'All Retail Stores',
          },
          region: query.get('region') || '*',
          state: query.get('state') || '*',
        })
    })
    return () => {
      stopListening()
    }
  }, [history, isMounted])

  const updateQueryParams = React.useCallback(
    ({ retailer, retail_location, ...params }) => {
      history.push(
        route(routeId, null, {
          ...params,
          page: params.page === 1 ? null : params.page,
          per_page: params.per_page === 10 ? null : params.per_page,
          rv: retailer?.value !== '*' ? retailer?.value : null,
          rl: retailer?.value !== '*' ? retailer?.label : null,
          rlv: retail_location?.value !== '*' ? retail_location?.value : null,
          rll: retail_location?.value !== '*' ? retail_location?.label : null,
        })
      )
    },
    [history, routeId]
  )

  const resetFilters = React.useCallback(
    (filters) => {
      updateQueryParams({
        ...filters,
        retailer: {
          value: '*',
          label: 'All Retailers',
        },
        retail_location: null,
        flag: '*',
        status: 'all',
        region: '*',
        state: '*',
        page: 1,
      })
    },
    [updateQueryParams]
  )

  const activeFilters = React.useMemo(() => {
    const activeFilters = []
    const { status, retailer, retail_location } = filters

    if (status && status !== 'all') {
      activeFilters.push(`Status (${status})`)
    }

    if (retailer && retailer.value !== '*') {
      activeFilters.push(`Retailer (${retailer.label})`)
    }

    if (retail_location && retail_location.value !== '*') {
      activeFilters.push(`Store (${retail_location.label})`)
    }

    return activeFilters.length
      ? `Filtered by: ${activeFilters.join(', ')}`
      : 'Filters'
  }, [filters])

  return {
    filters,
    activeFilters,
    setFilters: (updated) => {
      updateQueryParams({ ...filters, ...updated })
    },
    resetFilters: () => {
      resetFilters(filters)
    },
  }
}

export default useGlobalFilters
