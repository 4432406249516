import React, { useState } from 'react'

import { Table, Card, Modal, Button } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'

import BuyerTicketLogForm from './TicketLogForm'
import Pagination from 'src/components/Pagination/Pagination'
import NotFound from 'src/components/Error/NotFound'
import { colors } from 'src/config/theme'

import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'
import { ITicketLog, ITicket } from 'src/services/types/ticket'

import TicketService from 'src/services/TicketService'
import { timestampToDate } from 'src/utils/date'
import { paginate } from 'src/utils/filters'

const ticketService = new TicketService()

interface IProps {
  ticket: ITicket
}

const TicketLogs: React.FC<IProps> = ({ ticket }) => {
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isReloading, setIsReloading] = useState<boolean>(true)
  const [ticketLogs, setTicketLogs] = React.useState<ITicketLog[]>()

  const [lastReloaded, setLastReloaded] = useState<Date>()

  const [mode, setMode] = React.useState<'create' | 'update'>()

  /** Store log item being updated */
  const [activeTicketLog, setActiveTicketLog] = React.useState<ITicketLog>()

  const [page, setPage] = React.useState<number>(1)

  React.useEffect(() => {
    setIsReloading(true)

    ticketService
      .fetchLogs(ticket._id)
      .then((logs) => {
        isMounted.current && setTicketLogs(logs)
      })
      .finally(() => {
        isMounted.current && setIsLoading(false)
        isMounted.current && setIsReloading(false)
      })
  }, [ticket._id, lastReloaded, isMounted])

  const sortedTicketLogs = React.useMemo(() => {
    if (!ticketLogs) return []
    else return ticketLogs
  }, [ticketLogs])

  const paginatedTicketLogs = React.useMemo(() => {
    if (sortedTicketLogs) {
      return paginate(sortedTicketLogs, page)
    } else return []
  }, [page, sortedTicketLogs])

  /** Close 'create' or 'update' form modal */
  const closeForm = React.useCallback((doRefresh) => {
    doRefresh && setLastReloaded(new Date())
    setActiveTicketLog(null)
    setMode(null)
  }, [])

  return (
    <>
      {/* Modal for 'create' or 'update' forms */}
      <Modal
        show={mode === 'create' || activeTicketLog ? true : false}
        onHide={() => closeForm(false)}
        onEscapeKeyDown={() => closeForm(false)}
      >
        <Modal.Header>
          <Modal.Title>{mode === 'create' ? 'New' : 'Update'} Note</Modal.Title>
          <span className="cursor-pointer">
            <BsX
              color={colors.primaryGrey}
              size={32}
              onClick={() => closeForm(false)}
            />
          </span>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <BuyerTicketLogForm
            type={mode === 'update' ? 'update' : 'create'}
            ticket={ticket}
            ticketLog={mode === 'update' ? activeTicketLog : null}
            onClose={closeForm}
          />
        </Modal.Body>
      </Modal>

      <Card>
        <Card.Header>
          <Card.Title>Ticket Notes</Card.Title>

          {userCan('update_ticket') && (
            <Button size="sm" onClick={() => setMode('create')}>
              Add Note
            </Button>
          )}
        </Card.Header>
        <Card.Body className={`px-0 py-0 ${isReloading ? 'disabled' : ''}`}>
          {!isLoading && paginatedTicketLogs.length ? (
            <>
              <div className="table-wrapper padded">
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th>Date</th>
                      <th>Value</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticketLogs.map((log, index) => (
                      <tr key={log._id}>
                        <td className="align-middle text-center">
                          {index + 1 + (page - 1) * 10}
                        </td>
                        <td>{timestampToDate(log.created)}</td>
                        <td>{log.value}</td>

                        {/* Quick ticket status update */}
                        {userCan('update_buyer_ticket') && (
                          <td>
                            <span
                              className="text-primary action-link cursor-pointer"
                              onClick={() => {
                                setMode('update')
                                setActiveTicketLog(log)
                              }}
                            >
                              Edit
                            </span>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <Pagination
                className="my-4"
                perPage={10}
                totalItems={sortedTicketLogs.length}
                currentPage={page}
                onChange={(page) => setPage(page)}
              />
            </>
          ) : null}

          {!isLoading && !paginatedTicketLogs.length ? (
            <div className="my-5 text-center">
              <NotFound summary={`No ticket notes found`} />
            </div>
          ) : null}

          {isLoading && (
            <div className="d-flex justify-content-center py-5">
              <figure className="spinner primary" />
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  )
}

export default TicketLogs
