import { lazy } from 'react'
import { IRoute } from '.'

// Enable code splitting
const Reports = lazy(() => import('src/pages/Reports.page'))

const reportRoutes: IRoute[] = [
  {
    id: 'reports',
    path: '/reports',
    component: Reports,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'reports',
    path: '/reports/:type',
    component: Reports,
    requiresAuth: true,
    exact: true,
  },
]

export default reportRoutes
