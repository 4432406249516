// Schedules
import Schedules from 'src/pages/Schedule/Schedules.page'
import ScheduleCreate from 'src/pages/Schedule/ScheduleCreate.page'
import ScheduleUpdate from 'src/pages/Schedule/ScheduleUpdate.page'
import { IRoute } from '.'

const scheduleRoutes: IRoute[] = [
  {
    id: 'schedules',
    path: '/schedules',
    component: Schedules,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'schedule_create',
    path: '/schedules/create',
    component: ScheduleCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'schedule_update',
    path: '/schedules/:id/update',
    component: ScheduleUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default scheduleRoutes
