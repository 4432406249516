import React from 'react'

import Docs from 'src/pages/Docs/Docs.page'
import Dashboard from 'src/pages/Dashboard.page'
import Login from 'src/pages/Auth/Login.page'
import Profile from 'src/pages/Profile.page'
import Error404 from 'src/pages/Error/404.page'

import brandRoutes from './brandRoutes'
import categoryRoutes from './categoryRoutes'
import channelRoutes from './channelRoutes'
import configurationRoutes from './configurationRoutes'
import competitionRoutes from './competitionRoutes'
import forumRoutes from './forumRoutes'
import landingRoutes from './landingRoutes'
import manufacturerRoutes from './manufacturerRoutes'
import merchandiserRoutes from './merchandiserRoutes'
import productRoutes from './productRoutes'
import reportRoutes from './reportRoutes'
import retailerRoutes from './retailerRoutes'
import retailLocationRoutes from './retailLocationRoutes'
import scheduleRoutes from './scheduleRoutes'
import staffRoutes from './staffRoutes'
import surveyRoutes from './surveyRoutes'
import targetRoutes from './targetRoutes'
import ticketRoutes from './ticketRoutes'
import treeRoutes from './treeRoutes'

export interface IRoute {
  id: string
  path: string
  component: React.ComponentClass<any, any> | React.FC<any>
  requiresAuth?: boolean
  exact?: boolean
}

export const routes: IRoute[] = [
  {
    id: 'dashboard',
    path: '/',
    requiresAuth: true,
    component: Dashboard,
    exact: true,
  },
  {
    id: 'docs',
    path: '/docs',
    requiresAuth: true,
    component: Docs,
    exact: true,
  },
  {
    id: 'profile',
    path: '/profile',
    requiresAuth: true,
    component: Profile,
    exact: true,
  },
  {
    id: 'auth_login',
    path: '/login',
    component: Login,
    exact: true,
  },
  ...brandRoutes,
  ...categoryRoutes,
  ...channelRoutes,
  ...configurationRoutes,
  ...competitionRoutes,
  ...forumRoutes,
  ...landingRoutes,
  ...manufacturerRoutes,
  ...merchandiserRoutes,
  ...productRoutes,
  ...reportRoutes,
  ...retailerRoutes,
  ...retailLocationRoutes,
  ...scheduleRoutes,
  ...staffRoutes,
  ...surveyRoutes,
  ...targetRoutes,
  ...ticketRoutes,
  ...treeRoutes,
  {
    id: 'error_404',
    path: '*',
    component: Error404,
  },
]
