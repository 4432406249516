import React from 'react'

import pluralize from 'pluralize'
import { Card, Button, Table, Badge } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import SearchInput from 'src/components/Form/SearchInput'
import Pagination from 'src/components/Pagination/Pagination'

import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'
import { IForum } from 'src/services/types/forum'

import { paginate } from 'src/utils/filters'
import { basicSearch } from 'src/utils/search'
import { route } from 'src/utils/routes'
import ForumService from 'src/services/ForumService'
import { IState } from 'src/store'

const forumService = new ForumService()

const ForumsList: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const user = useSelector((state: IState) => state.user)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [forums, setForums] = React.useState<IForum[]>()

  const [page, setPage] = React.useState<number>(1)
  const [searchTerm, setSearchTerm] = React.useState<string>()

  React.useEffect(() => {
    const fetchForums = (): void => {
      setIsLoading(true)

      forumService
        .fetch()
        .then((forums) => {
          isMounted.current && setForums(forums)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    userCan('view_forums') && fetchForums()
  }, [isMounted, userCan, user.manufacturer_id])

  const filteredForums = React.useMemo(() => {
    setPage(1)

    let filteredForums = forums || []

    if (filteredForums.length) {
      if (searchTerm) {
        filteredForums = filteredForums.filter((forum) => {
          return (
            basicSearch(forum.name, searchTerm) ||
            basicSearch(forum.status, searchTerm)
          )
        })
      }
    }

    return filteredForums
  }, [forums, searchTerm])

  const paginatedForums = React.useMemo(() => {
    return filteredForums ? paginate(filteredForums, page) : []
  }, [page, filteredForums])

  return (
    <Card>
      <Card.Header>
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div>
              {filteredForums
                ? `${filteredForums.length} ${pluralize(
                    'community',
                    filteredForums.length
                  )}`
                : '0 communities'}
            </div>
            <div className="ml-3">
              <SearchInput
                placeholder="Search communities"
                value={searchTerm}
                onChange={setSearchTerm}
                disabled={isLoading || !filteredForums}
              />
            </div>
          </div>

          {userCan('create_forum') && (
            <div>
              <Link to={route('forum_create')}>
                <Button size="sm">
                  <BsPlus size={20} /> New Community
                </Button>
              </Link>
            </div>
          )}
        </div>
      </Card.Header>
      <Card.Body className="px-0 py-0">
        {!isLoading && paginatedForums.length ? (
          <>
            <div className="table-wrapper padded">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Name</th>
                    <th>Organization</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedForums.map((forum, index) => (
                    <tr
                      key={forum._id}
                      className="cursor-pointer"
                      onClick={() =>
                        history.push(
                          route('forum_update', {
                            id: forum._id,
                          })
                        )
                      }
                    >
                      <td className="align-middle text-center">
                        {index + 1 + (page - 1) * 10}
                      </td>
                      <td className="align-middle">{forum.name}</td>
                      <td className="align-middle">
                        {forum.manufacturer.name}
                      </td>
                      <td className="align-middle">
                        <Badge
                          className="text-capitalize"
                          variant={
                            forum.status === 'active' ? 'success' : 'danger'
                          }
                        >
                          {forum.status}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <Pagination
              className="my-4"
              perPage={10}
              totalItems={filteredForums.length}
              currentPage={page}
              onChange={(page) => setPage(page)}
            />
          </>
        ) : null}

        {!isLoading && !paginatedForums.length ? (
          <div className="my-5">
            <NotFound summary="No communities found" />
          </div>
        ) : null}

        {isLoading && (
          <div className="d-flex justify-content-center py-5">
            <figure className="spinner primary" />
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default ForumsList
