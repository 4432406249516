import React from 'react'
import { Card } from 'react-bootstrap'

import styles from './Dashboard.module.scss'

const SectionLoader: React.FC = () => {
  return (
    <Card className={`${styles.sectionLoader} mb-3`}>
      <div className={styles.sectionLeft}>
        <div className={styles.sectionHeader}></div>
        {Array.from(Array(3), (_, i) => i + 1).map((item) => (
          <div key={item} className={styles.sectionData}>
            <div className={styles.sectionData__count}></div>
            <div className={styles.sectionData__summary}></div>
          </div>
        ))}
      </div>
      <div className={styles.sectionCenter}>
        <div className={styles.sectionHeader}></div>
        <div className={styles.sectionBody}>
          <div className={styles.sectionGraph}></div>
        </div>
      </div>
      <div className={styles.sectionRight}>
        <div className={styles.sectionHeader}></div>
        <div className={styles.sectionBody}>
          <div className={styles.sectionGraph}></div>
        </div>
      </div>
    </Card>
  )
}

export default SectionLoader
