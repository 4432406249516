import React from 'react'
import { Row, Col } from 'react-bootstrap'

interface IProps {
  title?: string
  summary: string
}

const NoConnection: React.FC<IProps> = ({ title, summary }) => (
  <Row className="justify-content-center">
    <Col md={8} className="text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        height="48"
        viewBox="0 0 50 34"
      >
        <animate
          attributeName="fill"
          values="#DA1E28;#3b54ec;#F9B13D;#DA1E28;"
          dur="10s"
          repeatCount="indefinite"
        />
        <g>
          <path d="M25 0C31.3 0 36.3 3.9 38.4 9.3 38.5 9.5 38.7 9.7 38.9 9.7 45.5 10.8 50 15.2 50 21.8 50 28.8 44.3 34 37 34L37 34 11 34C4.5 34 0 30.4 0 24.2 0 18.8 4 15.3 9.3 14.5 9.5 14.5 9.7 14.3 9.8 14 11.1 5.6 17.3 0 25 0ZM25 2C18.3 2 12.9 6.8 11.7 14.4 11.6 15.5 10.7 16.3 9.6 16.5 5.1 17.2 2 19.9 2 24.2 2 29.2 5.5 32 11 32L11 32 37 32C43.2 32 48 27.7 48 21.8 48 16.3 44.3 12.6 38.6 11.7 37.7 11.6 36.9 10.9 36.6 10.1 34.7 5.3 30.4 2 25 2ZM31 12L32.4 13.4 26.1 19.7 32.4 26 31 27.4 24.7 21.1 18.4 27.4 17 26 23.3 19.7 17 13.4 18.4 12 24.7 18.3 31 12Z" />
        </g>
      </svg>
      {title ? <h5 className="mt-3">{title}</h5> : null}
      <p className={`text-muted ${!title ? 'mt-3' : ''}`}>{summary}</p>
    </Col>
  </Row>
)

export default NoConnection
