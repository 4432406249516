import React, { useEffect, useState } from 'react'

import { connect } from 'react-redux'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import { Container, Table, Card, Form, Button, Badge } from 'react-bootstrap'

import { userCan } from 'src/store/user/userActions'
import BrandService from 'src/services/BrandService'

import { asset } from 'src/utils/file'
import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'

import { IState } from 'src/store'
import { IBrand } from 'src/services/types/brand'
import { IUser } from 'src/services/types/user'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'
import NotFound from 'src/components/Error/NotFound'
import ProductImage from 'src/components/Image/ProductImage'

const brandService = new BrandService()

interface IProps {
  /** User from state.user */
  user: IUser
  /** User permissions checker */
  userCan: (permission: string) => boolean
}

const Brands: React.FC<IProps> = ({ user, userCan }) => {
  // indicators
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // All brands
  const [brands, setBrands] = useState<IBrand[]>()

  const [searchTerm, setSearchTerm] = useState<string>()
  const [page, setPage] = useState(1)

  const _isMounted = React.useRef<boolean>(false)
  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (userCan('view_brands')) {
      brandService
        .fetchByManufacturer(user.manufacturer_id)
        .then((brands) => {
          if (_isMounted.current && brands.length) {
            setBrands(brands)
          }
        })
        .finally(() => _isMounted.current && setIsLoading(false))
    }
  }, [userCan, user.manufacturer_id])

  const filteredBrands = React.useMemo(() => {
    setPage(1)
    let filteredBrands = brands || []

    if (filteredBrands.length) {
      if (searchTerm) {
        filteredBrands = filteredBrands.filter((brand) => {
          return (
            basicSearch(brand.name, searchTerm) ||
            basicSearch(brand.status, searchTerm)
          )
        })
      }
    }

    return filteredBrands
  }, [brands, searchTerm])

  /**
   * Get the brands to be shown on the current page
   */
  const paginatedBrands = React.useMemo(() => {
    if (!filteredBrands) return []
    else return paginate(filteredBrands, page)
  }, [page, filteredBrands])

  if (!userCan('view_brands')) return <Error401 />

  return (
    <>
      <Header title="Brands" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    {filteredBrands
                      ? `${filteredBrands.length} ${pluralize(
                          'brand',
                          filteredBrands.length
                        )}`
                      : '0 brands'}
                  </div>
                  <div className="ml-3">
                    <Form.Control
                      size="sm"
                      type="text"
                      name="searchTerm"
                      value={searchTerm || ''}
                      className="search"
                      style={{ minWidth: '250px' }}
                      placeholder="Search by name or status"
                      onChange={({ target }) => setSearchTerm(target.value)}
                      disabled={isLoading || !filteredBrands}
                    />
                  </div>
                </div>

                {userCan('create_brand') && (
                  <div>
                    <Link to={route('brand_create')}>
                      <Button size="sm">Create Brand</Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {!isLoading && paginatedBrands.length ? (
                <>
                  <div className="table-wrapper padded">
                    <Table hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">--</th>
                          <th className="sticky">Name</th>
                          <th>Status</th>
                          {/* <th>Products</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedBrands.map((brand, index) => (
                          <tr key={brand._id}>
                            <td className="align-middle text-center">
                              {index + 1 + (page - 1) * 10}
                            </td>
                            <td className="align-middle text-center">
                              <div className="d-flex justify-content-center">
                                <ProductImage
                                  src={
                                    brand.upload
                                      ? asset(brand.upload.path)
                                      : false
                                  }
                                  roundedCircle
                                />
                              </div>
                            </td>
                            <td className="align-middle">{brand.name}</td>
                            <td className="align-middle">
                              <Badge
                                className="text-capitalize"
                                variant={
                                  brand.status === 'active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {brand.status}
                              </Badge>
                            </td>
                            {/* <td>
                            <Link
                              to={route('brand_products', {
                                id: brand._id,
                              })}
                            >
                              Products
                            </Link>
                          </td> */}
                            <td className="align-middle">
                              <Link
                                to={route('brand_update', {
                                  id: brand._id,
                                })}
                                className="action-link"
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    className="my-4"
                    perPage={10}
                    totalItems={filteredBrands.length}
                    currentPage={page}
                    onChange={(page) => setPage(page)}
                  />
                </>
              ) : null}

              {!isLoading && !paginatedBrands.length ? (
                <div className="my-5">
                  <NotFound summary="No brands found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect(
  (state: IState) => ({
    user: state.user,
  }),
  { userCan }
)(Brands)
