import React, { useEffect, useState } from 'react'

import pluralize from 'pluralize'
import {
  Container,
  Table,
  Card,
  Form,
  Button,
  Badge,
  Dropdown,
} from 'react-bootstrap'
import { BsFilter } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'
import { Role } from 'src/config/roles'

import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'
import { ITicket } from 'src/services/types/ticket'

import Error401 from 'src/pages/Error/401.page'

import TicketService from 'src/services/TicketService'
import { IState } from 'src/store'
import { timestampToDate } from 'src/utils/date'
import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'
import useGlobalFilters from 'src/hooks/useGlobalFilters'
import SearchInput from 'src/components/Form/SearchInput'

const ticketService = new TicketService()

const Tickets: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const user = useSelector((state: IState) => state.user)

  /** Indicators */
  const [isLoading, setIsLoading] = useState<boolean>(true)

  /** All tickets */
  const [tickets, setTickets] = useState<ITicket[]>()
  const { filters, setFilters, activeFilters, resetFilters } =
    useGlobalFilters('tickets')

  const [options, setOptions] = useState<any>({
    flag: [],
    state: [],
    region: [],
  })

  useEffect(() => {
    const fetchTickets = (): void => {
      const fetchService =
        user.role === Role.RootAdmin ? 'fetch' : 'fetchByManufacturer'

      ticketService[fetchService](user.manufacturer_id)
        .then((tickets) => {
          isMounted.current && setTickets(tickets)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_tickets') && fetchTickets()
  }, [isMounted, user, userCan])

  useEffect(() => {
    const fetchFilterOptions = (): void => {
      ticketService.fetchFlags().then((flags) => {
        flags = Object.entries(flags).map(([value, label]) => {
          return { value, label }
        })

        /** Fetch states and regions from tickets */
        const states = {}
        const regions = {}

        tickets.forEach((ticket) => {
          if (ticket.state && !states[ticket.state]) {
            states[ticket.state] = {
              value: ticket.state,
              label: ticket.state,
            }
          }
          if (ticket.region && !regions[ticket.region]) {
            regions[ticket.region] = {
              value: ticket.region,
              label: ticket.region,
            }
          }
        })

        isMounted.current &&
          setOptions({
            flag: flags,
            state: Object.values(states),
            region: Object.values(regions),
          })
      })
    }

    tickets && tickets.length && fetchFilterOptions()
  }, [isMounted, tickets])

  const filteredTickets = React.useMemo(() => {
    let filtered = tickets || []

    if (filtered.length) {
      if (filters.flag && filters.flag !== '*') {
        filtered = filtered.filter((ticket) => ticket.flag === filters.flag)
      }

      if (filters.status && filters.status !== 'all') {
        filtered = filtered.filter((ticket) => ticket.status === filters.status)
      }

      if (filters.region && filters.region !== '*') {
        filtered = filtered.filter((ticket) => {
          return basicSearch(ticket?.region, filters.region)
        })
      } else if (filters.state && filters.state !== '*') {
        filtered = filtered.filter((ticket) => {
          return basicSearch(ticket?.state, filters.state)
        })
      }

      if (filters.search) {
        const query_parts = filters.search.split(':').map((part) => part.trim())

        const query = filters.search
        filtered = filtered.filter((ticket) => {
          if (query_parts?.length === 2 && ticket?.[query_parts[0]]) {
            const [accessor, query] = query_parts
            return basicSearch(ticket?.[accessor], query)
          }
          return (
            basicSearch(ticket.merchandiser.name, query) ||
            basicSearch(ticket.merchandiser.retail_location.name, query)
          )
        })
      }
    }

    return filtered
  }, [
    tickets,
    filters.flag,
    filters.status,
    filters.search,
    filters.state,
    filters.region,
  ])

  const paginatedTickets = React.useMemo(() => {
    return filteredTickets ? paginate(filteredTickets, filters.page) : []
  }, [filters.page, filteredTickets])

  if (!userCan('view_tickets')) return <Error401 />

  return (
    <>
      <Header title="Tickets" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center">
                <div>
                  {filteredTickets
                    ? `${filteredTickets.length} ${pluralize(
                        `ticket`,
                        filteredTickets.length
                      )}`
                    : `0 tickets`}
                </div>

                <div className="ml-2">
                  <SearchInput
                    placeholder="Search tickets *"
                    value={filters.search}
                    onChange={(search) => setFilters({ search, page: 1 })}
                    disabled={isLoading || !filteredTickets}
                  />
                </div>
              </div>
            </Card.Header>
            <Card.Header>
              <Dropdown className="filters-wrapper">
                <Dropdown.Toggle size="sm" variant="link" id="dropdown-basic">
                  <BsFilter size={24} />
                  <span className="text-capitalize">{activeFilters}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* Status filter */}
                  <div className="filter-group">
                    <span className="filter-label">Status</span>
                    <Form.Control
                      as="select"
                      size="sm"
                      value={filters.status}
                      onChange={({ target }) =>
                        setFilters({ status: target.value, page: 1 })
                      }
                      disabled={isLoading || !filteredTickets}
                    >
                      <option value="all">All</option>
                      <option value="open">Open</option>
                      <option value="closed">Closed</option>
                    </Form.Control>
                  </div>

                  {/* Flag, state and region filters */}
                  {['flag', 'state', 'region'].map((key) => (
                    <div key={key} className="filter-group">
                      <span className="filter-label text-capitalize">
                        {key}
                      </span>
                      <Form.Control
                        as="select"
                        size="sm"
                        value={filters[key]}
                        onChange={({ target }) =>
                          setFilters({ [key]: target.value, page: 1 })
                        }
                        disabled={isLoading || !filteredTickets}
                      >
                        <option value="*">All</option>
                        {options[key] && options[key].length
                          ? options[key].map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))
                          : null}
                      </Form.Control>
                    </div>
                  ))}

                  <div className="filter-group mt-4">
                    <Button
                      size="sm"
                      variant="outline-light"
                      title="Reset Filters"
                      onClick={resetFilters}
                    >
                      Reset
                    </Button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}

              {!isLoading && !paginatedTickets.length ? (
                <div className="my-5">
                  <NotFound summary={`No tickets found`} />
                </div>
              ) : null}

              {!isLoading && paginatedTickets.length ? (
                <div className="table-wrapper padded">
                  <Table className="table-fit" hover responsive>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>State</th>
                        <th>Region</th>
                        <th>Sender</th>
                        <th>Store</th>
                        <th>Body</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedTickets.map((ticket, index) => (
                        <tr
                          key={ticket._id}
                          className="cursor-pointer"
                          onClick={() =>
                            history.push(
                              route('ticket_view', { id: ticket._id })
                            )
                          }
                        >
                          <td className="text-center">
                            {index + 1 + (filters.page - 1) * filters.per_page}
                          </td>
                          <td>{timestampToDate(ticket.created)}</td>
                          <td>
                            <Badge className={`flag flag--${ticket.flag}`}>
                              {ticket.flag}
                            </Badge>
                          </td>
                          <td>
                            <Badge
                              className="text-capitalize"
                              variant={
                                ticket.status === 'closed'
                                  ? 'success'
                                  : 'danger'
                              }
                            >
                              {ticket.status}
                            </Badge>
                          </td>
                          <td>{ticket.state}</td>
                          <td>{ticket.region}</td>
                          <td>{ticket.merchandiser.name}</td>
                          <td>{ticket.merchandiser.retail_location.name}</td>
                          <td className="wrap">
                            <span>{ticket.body}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : null}

              <Pagination
                className="my-4"
                perPage={10}
                totalItems={filteredTickets.length}
                currentPage={filters.page}
                onChange={(page) => setFilters({ page })}
              />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Tickets
