import React from 'react'

import { Button, Image } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import { asset } from 'src/utils/file'

import ProductImage from 'src/components/Image/ProductImage'

interface IProps {
  /** 'default' for product or brand upload */
  type?: 'default' | 'survey'
  /** Existing upload? We need to show it */
  uploadPath?: string
  /** Sometimes we need to send the file on change */
  onChange?: ({ file: any }: any) => void
  /** Upload action */
  doUpload?: ({ file: any }: any) => Promise<any>
}

const ImageUploader: React.FC<IProps> = ({
  type,
  uploadPath,
  onChange,
  doUpload,
}) => {
  const { addToast } = useToasts()

  const fileInput = React.useRef<any>()

  const [uploadData, setUploadData] = React.useState<{ file: any }>()
  const [imagePreview, setImagePreview] = React.useState<any>()

  /**
   * If current image in image preview has been uploaded
   * or is in the process
   */
  const [isUploaded, setIsUploaded] = React.useState<boolean>(false)
  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const handleFileInputChange = (event): void => {
    event.preventDefault()

    const reader = new FileReader()
    const file = event.target.files[0]

    reader.onloadend = async () => {
      const type = file.type.split('/').pop().toLowerCase()
      const allowedTypes = ['jpeg', 'jpg', 'png']

      if (!allowedTypes.includes(type)) {
        addToast('Please select a valid image file.', {
          appearance: 'error',
        })
      } else if (file.size > 4024000) {
        addToast('Max Upload size is 4MB only.', {
          appearance: 'error',
        })
      } else {
        setIsUploaded(false)
        setImagePreview(reader.result)
        setUploadData({ file })
        if (onChange) {
          onChange({ file })
        }
        // do the actual upload
      }
    }

    reader.readAsDataURL(file)
  }

  return (
    <div>
      <input
        type="file"
        className="hidden-input"
        accept="image/jpg, image/jpeg, image/png"
        onChange={handleFileInputChange}
        ref={fileInput}
      />

      {uploadPath || imagePreview ? (
        <div className="mb-4">
          {type === 'default' ? (
            <ProductImage
              src={imagePreview ? imagePreview : asset(uploadPath)}
              width={120}
              roundedCircle
            />
          ) : (
            <Image
              src={imagePreview ? imagePreview : asset(uploadPath)}
              style={{ maxHeight: '250px' }}
              rounded
              fluid
            />
          )}
        </div>
      ) : null}

      <Button
        size="sm"
        variant="outline-light"
        onClick={() => fileInput.current.click()}
        disabled={isUploading}
      >
        {uploadPath ? 'Change Image' : 'Upload Image'}
      </Button>

      {doUpload ? (
        <Button
          size="sm"
          variant="success"
          className="ml-2"
          onClick={() => {
            setIsUploading(true)
            doUpload(uploadData).finally(() => {
              setIsUploaded(true)
              setIsUploading(false)
            })
          }}
          disabled={
            !uploadData || !uploadData.file || isUploading || isUploaded
          }
        >
          {!isUploading ? 'Save' : <figure className="spinner button white" />}
        </Button>
      ) : null}
    </div>
  )
}

export default ImageUploader
