import React from 'react'

import { Card, Image, ProgressBar, Button } from 'react-bootstrap'

import NotFound from 'src/components/Error/NotFound'
import { ISurvey, ISurveyQuestion } from 'src/services/types/survey'
import SurveyService from 'src/services/SurveyService'
import { asset } from 'src/utils/file'

import './SurveyAnswersSummary.scss'
import SurveyAnswers from '../SurveyAnswers'

const surveyService = new SurveyService()

interface IProps {
  survey: ISurvey
}

const SurveyAnswersSummary: React.FC<IProps> = ({ survey }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [questions, setQuestions] = React.useState<ISurveyQuestion[]>()

  const [activeQuestion, setActiveQuestion] = React.useState<ISurveyQuestion>()

  React.useEffect(() => {
    const fetchSurveyQuestionsAndAnswers = (): void => {
      surveyService.fetchQuestionsBySurvey(survey._id).then((questions) => {
        surveyService.fetchAnswersBySurvey(survey._id).then((answers) => {
          const questionsWithAnswers = questions
            .filter((question) => question.status === 'active')
            .map((question) => {
              question.answers = answers.filter(
                (answer) => answer.question_id === question._id
              )

              return question
            })

          setQuestions(questionsWithAnswers)
          setIsLoading(false)
        })
      })
    }

    fetchSurveyQuestionsAndAnswers()
  }, [survey._id])

  const collateAnswers = React.useCallback((answers, choice) => {
    if (answers.length) {
      const answersForChoice = answers.filter(
        ({ title }) => title === choice._id
      )
      return Number(
        ((answersForChoice.length / answers.length) * 100).toFixed(2)
      )
    } else {
      return 0
    }
  }, [])

  return (
    <Card>
      <Card.Header className="w-100 d-flex align-items-center justify-content-between">
        <Card.Title>
          {activeQuestion ? (
            <span>{`[Q${activeQuestion.index}] ${activeQuestion.title}`}</span>
          ) : (
            <span>
              Survey Answers {questions ? `(${questions.length})` : null}
            </span>
          )}
        </Card.Title>

        {activeQuestion ? (
          <div className="d-flex align-items center ml-3">
            <Button
              size="sm"
              variant="danger"
              className="ml-2"
              style={{ whiteSpace: 'nowrap' }}
              onClick={() => setActiveQuestion(null)}
            >
              Go Back
            </Button>
          </div>
        ) : null}
      </Card.Header>

      {!isLoading && activeQuestion ? (
        <SurveyAnswers activeQuestion={activeQuestion} />
      ) : !isLoading && questions && questions.length ? (
        questions.map((question, qIndex) => (
          <Card.Body key={qIndex}>
            <div
              className={`surveyQuestion ${
                qIndex !== questions.length - 1 ? 'mb-5' : null
              }`}
            >
              {question.upload ? (
                <div className="mb-3">
                  <Image src={asset(question.upload.path)} rounded fluid />
                </div>
              ) : null}

              <span className="surveyQuestion__title">
                [Q{qIndex + 1}] {question.title}
              </span>
              <div className="mt-2">
                {question.type === 'multiple-choice'
                  ? question.choices.map((choice, cIndex) => (
                      <div key={cIndex} className="clearfix mb-2">
                        <ProgressBar
                          striped
                          variant="success"
                          now={collateAnswers(question.answers, choice)}
                          label={`${collateAnswers(question.answers, choice)}%`}
                          // style={{ maxWidth: '80%' }}
                        />
                        <span className="float-left">{choice.title}</span>
                      </div>
                    ))
                  : null}

                {/* Answers link */}
                <span
                  className="text-primary cursor-pointer action-link"
                  onClick={() =>
                    setActiveQuestion({ ...question, index: qIndex + 1 })
                  }
                >
                  View answers
                </span>
              </div>
            </div>
          </Card.Body>
        ))
      ) : null}

      {!isLoading && questions && !questions.length ? (
        <Card.Body>
          <div className="w-100 my-5">
            <NotFound summary="No survey questions found" />
          </div>
        </Card.Body>
      ) : null}

      {isLoading && (
        <Card.Body>
          <div className="d-flex justify-content-center py-5">
            <figure className="spinner primary" />
          </div>
        </Card.Body>
      )}
    </Card>
  )
}

export default SurveyAnswersSummary
