import React from 'react'

import { Helmet } from 'react-helmet'

import Header from './Header'
import Footer from './Footer'

import '../../assets/sass/landing/app.scss'

const Layout: React.FC<any> = ({ title, hideHeader, children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || 'Home'} - WeShelfie</title>
      </Helmet>
      {!hideHeader && <Header />}
      <div className="mt-5">
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  hideHeader: false,
  hidePreloader: false,
}

export default Layout
