import React from 'react'

import { Badge, Image, Card } from 'react-bootstrap'

import { timestampToDate } from 'src/utils/date'
import { asset } from 'src/utils/file'

import { ISurvey } from 'src/services/types/survey'

interface IProps {
  survey: ISurvey
}

const SurveyDetails: React.FC<IProps> = ({ survey }) => {
  return (
    <Card>
      <Card.Header>
        <Card.Title>Survey Details</Card.Title>
      </Card.Header>
      <Card.Body>
        {survey.upload ? (
          <div className="mb-3">
            <Image src={asset(survey.upload.path)} rounded fluid />
          </div>
        ) : null}

        <h6 className="mb-1">{survey.title}</h6>
        <span className="text-muted">{survey.description}</span>

        <div className="mt-2">
          Status:{' '}
          <Badge
            className="text-capitalize"
            variant={survey.status === 'active' ? 'success' : 'danger'}
          >
            {survey.status}
          </Badge>
        </div>

        <div className="mt-1">
          Questions:{' '}
          <span className="font-weight-medium">
            {survey.question_count ? survey.question_count : 0}
          </span>
        </div>

        <div className="mt-1">
          Responses:{' '}
          <span className="font-weight-medium">
            {survey.answer_count
              ? survey.answer_count / survey.question_count
              : 0}
          </span>
        </div>

        <div className="mt-1">
          Channel:{' '}
          <span className="font-weight-medium">
            {survey.channel_details
              ? survey.channel_details.name
              : 'All Channels'}
          </span>
        </div>

        <div className="mt-1">
          Created:{' '}
          <span className="font-weight-medium">
            {timestampToDate(survey.created)}
          </span>
        </div>
      </Card.Body>
    </Card>
  )
}

export default SurveyDetails
