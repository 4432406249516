import React from 'react'
import { Card, Col, Container, Row, Table } from 'react-bootstrap'

import Layout from 'src/components/Landing/Layout'

const LinksPage: React.FC = () => {
  return (
    <Layout title="Links">
      <div style={{ height: '100vh', backgroundColor: '#F4F4FC' }}>
        <section>
          <Container className="container">
            <Row className="justify-content-center">
              <Col className="col-12 col-md-7">
                <Card>
                  <Card.Body>
                    <Table>
                      <thead>
                        <th>Platform</th>
                        <th>Test</th>
                        <th>Live</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Mobile (Pilot)</td>
                          <td>-</td>
                          <td>
                            <a
                              href="https://etc.weshelfie.com/apk/clearblue-pilot.apk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Pilot App
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Mobile</td>
                          <td>
                            <a
                              href="https://etc.weshelfie.com/apk/clearblue-test.apk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Test App
                            </a>
                          </td>
                          <td>
                            <a
                              href="https://etc.weshelfie.com/apk/clearblue-live.apk"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Live App
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default LinksPage
