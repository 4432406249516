import React from 'react'

import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

import SurveyService from 'src/services/SurveyService'
import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { ISurvey } from 'src/services/types/survey'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import SurveyDetails from 'src/components/Survey/SurveyDetails'
import SurveyResponses from 'src/components/Survey/SurveyAnswersSummary/SurveyAnswersSummary'

const surveyService = new SurveyService()

interface IProps extends RouteComponentProps {
  match: any
  history: any
  userCan: (permission: string) => boolean
}

const Survey: React.FC<IProps> = ({ history, match, userCan }) => {
  // indicators
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const [survey, setSurvey] = React.useState<ISurvey>()

  /**
   * Fetch the survey
   */
  React.useEffect(() => {
    if (userCan('view_survey_summary')) {
      surveyService
        .fetchById(match.params.id)
        .then((survey) => setSurvey(survey))
        .finally(() => setIsLoading(false))
    }
  }, [userCan, match.params.id])

  if (!userCan('view_survey_summary')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading ? 'Survey' : survey ? survey.title : 'Survey not found'
        }
        backText="Surveys"
        onBack={() => history.push(route('surveys'))}
      />
      <div className="page-container">
        <Container>
          {isLoading ? (
            <div className="d-flex justify-content-center py-5">
              <figure className="spinner primary" />
            </div>
          ) : (
            <>
              {survey ? (
                <Row>
                  <Col md={8}>
                    <SurveyResponses survey={survey} />
                  </Col>

                  <Col md={4}>
                    <SurveyDetails survey={survey} />
                  </Col>
                </Row>
              ) : (
                <div className="mt-5 pt-5">
                  <NotFound
                    title="Survey not found!"
                    summary="Check the URL and try again."
                  />
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    </>
  )
}

export default connect(() => ({}), { userCan })(withRouter(Survey))
