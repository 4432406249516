import Targets from 'src/pages/Target/Targets.page'
import TargetCreate from 'src/pages/Target/TargetCreate.page'
import TargetUpdate from 'src/pages/Target/TargetUpdate.page'

import { IRoute } from '.'

const targetRoutes: IRoute[] = [
  {
    id: 'targets',
    path: '/targets',
    component: Targets,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'target_create',
    path: '/targets/create',
    component: TargetCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'target_update',
    path: '/targets/:id/update',
    component: TargetUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default targetRoutes
