import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'

import rootReducer from 'src/store/reducers'

import { IAuth } from 'src/services/types/auth'
import { IUser } from 'src/services/types/user'
import { IConfig } from 'src/store/config/configReducer'
import { IGlobal } from 'src/store/global/globalReducer'
import { INotifications } from 'src/store/notification/notifcationReducer'

export interface IState {
  auth: IAuth
  config: IConfig
  global: IGlobal
  notification: INotifications
  reportFilters: any
  user: IUser
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'config'],
}

const persisitedReducer = persistReducer(persistConfig, rootReducer)

const bindMiddleware = (middleware): any => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(middleware))
  }
  return applyMiddleware(middleware)
}

export const store = createStore(
  persisitedReducer,
  bindMiddleware(thunkMiddleware)
)

export const persistor = persistStore(store)
