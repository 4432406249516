import Forums from 'src/pages/Forum/Forums.page'
import ForumCreate from 'src/pages/Forum/ForumCreate.page'
import ForumUpdate from 'src/pages/Forum/ForumUpdate.page'
import { IRoute } from '.'

const forumRoutes: IRoute[] = [
  {
    id: 'forums',
    path: '/forums',
    component: Forums,
    exact: true,
  },
  {
    id: 'forum_create',
    path: '/forums/create',
    component: ForumCreate,
    exact: true,
  },
  {
    id: 'forum_update',
    path: '/forums/:id/update',
    component: ForumUpdate,
    exact: true,
  },
]

export default forumRoutes
