import React from 'react'

import { Form, Badge, Image, Button, Card } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import { BsX } from 'react-icons/bs'
import { FiEdit3 } from 'react-icons/fi'

import { timestampToDate } from 'src/utils/date'
import { asset } from 'src/utils/file'

import { ITicket, TicketStatus as Status } from 'src/services/types/ticket'
import usePermission from 'src/hooks/usePermission'

interface IProps {
  ticket: ITicket
  updateTicketStatus: (newStatus: Status) => Promise<any>
}

const TicketDetails: React.FC<IProps> = ({ ticket, updateTicketStatus }) => {
  const { addToast } = useToasts()
  const { userCan } = usePermission()

  const [ticketStatus, setTicketStatus] = React.useState<Status>(ticket.status)
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)

  const _isMounted = React.useRef<boolean>(false)
  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  /**
   * Watch for order changes in props.order
   */
  React.useEffect(() => {
    setTicketStatus(ticket.status)
  }, [ticket.status])

  const updateStatus = (): void => {
    setIsSubmitting(true)

    updateTicketStatus(ticketStatus)
      .then(() => {
        setIsEditing(false)
        addToast('Ticket status updated', { appearance: 'success' })
      })
      .catch(({ message }) => {
        addToast(message, { appearance: 'error' })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Ticket Details</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="mb-4">
          {/* Ticket date */}
          <div className="mb-4">
            <div className="text-small-title">Date</div>
            <span>{timestampToDate(ticket.created)}</span>
          </div>

          <div className="mb-4">
            <div className="text-small-title">Flag</div>
            <Badge className={`text-capitalize flag flag--${ticket.flag}`}>
              {ticket.flag}
            </Badge>
          </div>

          {/* Ticket status */}
          <div className="text-small-title">Status</div>
          {isEditing ? (
            <>
              <div className="d-flex align-items-center">
                <Form.Control
                  as="select"
                  size="sm"
                  value={ticketStatus}
                  onChange={({ target }: any) => setTicketStatus(target.value)}
                >
                  <option value="open">Open</option>
                  <option value="closed">Closed</option>
                </Form.Control>
                <Button
                  type="submit"
                  size="sm"
                  className="ml-2"
                  onClick={updateStatus}
                  disabled={isSubmitting || ticket.status === ticketStatus}
                >
                  {isSubmitting ? (
                    <figure className="spinner button white" />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
              {!isSubmitting && (
                <small
                  className="d-flex align-items-center text-danger font-weight-bold cursor-pointer mt-1"
                  onClick={() => {
                    setTicketStatus(ticket.status)
                    setIsEditing(false)
                  }}
                >
                  <BsX size={18} /> Cancel
                </small>
              )}
            </>
          ) : (
            <div className="d-flex align-items-center">
              <Badge
                className="text-capitalize"
                variant={
                  ticket.status === 'closed'
                    ? 'success'
                    : ticket.status === 'open'
                    ? 'danger'
                    : 'warning'
                }
              >
                {ticket.status}
              </Badge>
              {userCan('update_ticket') && (
                <span
                  className="action-link muted ml-2"
                  onClick={() => setIsEditing(true)}
                >
                  <FiEdit3 size={14} />
                </span>
              )}
            </div>
          )}
        </div>

        {/* Merchandiser details */}
        <div className="mb-4">
          <div className="text-small-title">Merchandiser Info.</div>
          {ticket.merchandiser ? (
            <p>
              <b>{ticket.merchandiser.name}</b> <br />
              {ticket.merchandiser.phone} <br />
              {ticket.merchandiser.retail_location.name}
            </p>
          ) : null}
        </div>

        <div className="text-small-title">Body</div>
        <p className="text-muted">{ticket.body}</p>

        {ticket.upload ? (
          <div className="mt-3">
            <Image src={asset(ticket.upload.path)} rounded fluid />
          </div>
        ) : null}
      </Card.Body>
    </Card>
  )
}

export default TicketDetails
