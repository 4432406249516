import React from 'react'
import { Pagination as BootstrapPagination } from 'react-bootstrap'

interface IProps {
  className?: string
  perPage?: number
  totalItems: number
  currentPage: number
  onChange: (page: number) => void
}

const Pagination: React.FC<IProps> = ({
  perPage,
  totalItems,
  currentPage,
  onChange,
  ...props
}) => {
  const totalPages = Math.ceil(totalItems / perPage)

  const pages = React.useMemo((): any[] => {
    const delta = 2
    const left = currentPage - delta
    const right = currentPage + delta + 1
    const pages = []
    const pagesWithEllipsis = []

    for (let page = 1; page <= totalPages; page++) {
      if (page === 1 || page === totalPages || (page >= left && page < right)) {
        pages.push(page)
      }
    }

    let lastIterated
    for (const page of pages) {
      if (lastIterated) {
        if (page - lastIterated === 2) {
          pagesWithEllipsis.push(lastIterated + 1)
        } else if (page - lastIterated !== 1) {
          pagesWithEllipsis.push('...')
        }
      }
      pagesWithEllipsis.push(page)
      lastIterated = page
    }

    return pagesWithEllipsis
  }, [currentPage, totalPages])

  return (
    <>
      {pages.length > 1 ? (
        <div>
          {/* <div className="text-muted text-center small">
            Showing {currentPage * perPage - perPage + 1} to{' '}
            {currentPage * perPage} of {totalItems} entries
          </div> */}
          <BootstrapPagination {...props}>
            {/* <Pagination.First
            onClick={() => onChange(1)}
            disabled={currentPage === 1}
          /> */}
            <BootstrapPagination.Prev
              onClick={() => onChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pages.map((page, index) => {
              return page === '...' ? (
                <BootstrapPagination.Ellipsis key={index} />
              ) : (
                <BootstrapPagination.Item
                  key={index}
                  active={page === currentPage}
                  onClick={() => onChange(page)}
                >
                  {page}
                </BootstrapPagination.Item>
              )
            })}
            <BootstrapPagination.Next
              onClick={() => onChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            {/* <Pagination.Last
            onClick={() => onChange(totalPages)}
            disabled={currentPage === totalPages}
          /> */}
          </BootstrapPagination>
        </div>
      ) : null}
    </>
  )
}

Pagination.defaultProps = {
  perPage: 10,
}

export default Pagination
