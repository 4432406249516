import pluralize from 'pluralize'

import cache from '../utils/cache'
import { http } from '../utils/http'

class BaseService {
  private model: string
  protected http: any
  protected cache: any

  constructor(model: string) {
    this.model = model

    // Utils
    this.http = http
    this.cache = cache
  }

  fetch(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          http.get(`${this.model}/read_all`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const cacheKey = `${this.model}_${id}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) resolve(cachedData)
      else {
        try {
          http.get(`${this.model}/read/?_id=${id}`).then(({ data }) => {
            if (data.code === 200 && data.data.length) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<any[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `${pluralize(this.model)}_${status}`
      const cachedData = cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          http
            .post(`${this.model}/read_by_status`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  signup(model: any): Promise<any> {
    const formData = new FormData()

    Object.keys(model).forEach((key) => {
      formData.append(key, model[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post(`${this.model}/signup`, formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  create(model: any): Promise<any> {
    const formData = new FormData()

    Object.keys(model).forEach((key) => {
      formData.append(key, model[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post(`${this.model}/create`, formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(model: any): Promise<any> {
    const formData = new FormData()

    Object.keys(model).forEach((key) => {
      formData.append(key, model[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post(`${this.model}/update`, formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  delete(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('_id', id)

      try {
        http.post(`${this.model}/delete`, formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve()
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default BaseService
