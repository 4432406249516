export const formatCurrency = (
  value,
  hideCurrency = false,
  compact = false
): any => {
  if (value && typeof value === 'string') {
    value = value.replace(/[\s+,]/g, '')
  }

  if (!value || isNaN(value)) return false

  const options: any = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  if (!hideCurrency) {
    options.style = 'currency'
    options.currency = 'NGN'
  }

  if (compact) {
    options.notation = 'compact'
  }

  return new Intl.NumberFormat('en-NG', options).format(value)
}
