import React from 'react'

import { sortByKey } from 'src/utils/filters'

interface SortBy {
  sort_by: string
  order: 'asc' | 'desc'
}

const useTableSort = (data: any, { sort_by, order }: SortBy): any[] => {
  const [sortBy, setSortBy] = React.useState<any>({ sort_by, order })

  const getHeaderProps = (sort_by: string): any => {
    return {
      order: sortBy.sort_by === sort_by ? sortBy.order : null,
      onClick: (): void => {
        setSortBy({
          sort_by,
          order:
            sortBy.sort_by !== sort_by
              ? 'desc'
              : sortBy.order === 'desc'
              ? 'asc'
              : 'desc',
        })
      },
    }
  }

  /**
   * useMemo does not refresh sorted buyers when sort
   * params change. We have to track that ourselves
   **/
  const [lastSorted, setLastSorted] = React.useState<Date>(new Date())

  const sortedData = React.useMemo(() => {
    // Table sorting
    if (data && data.length && sortBy.sort_by) {
      setLastSorted(new Date())
      return sortByKey(data, sortBy.sort_by, sortBy.order)
    }

    return data
  }, [data, sortBy])

  return [{ getHeaderProps }, { data: sortedData, lastSorted }]
}

export default useTableSort
