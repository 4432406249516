import React from 'react'

import { Formik, Field, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SearchSelect from 'src/components/Form/SearchSelect'
import ImageUploader from 'src/components/Image/ImageUploader'

import useMounted from 'src/hooks/useMounted'
import { ISurvey } from 'src/services/types/survey'

import ChannelService from 'src/services/ChannelService'
import SurveyService from 'src/services/SurveyService'
import UploadService from 'src/services/UploadService'
import { IState } from 'src/store'

import { route } from 'src/utils/routes'

const surveyService = new SurveyService()
const channelService = new ChannelService()
const uploadService = new UploadService()

interface IProps {
  type?: 'create' | 'update'
  survey?: ISurvey
  updateSurvey?: (updatedValues: ISurvey) => void
}

const SurveyForm: React.FC<IProps> = ({ type, survey, updateSurvey }) => {
  const history = useHistory()
  const isMounted = useMounted()
  const { addToast } = useToasts()

  const user = useSelector((state: IState) => state.user)

  const [channels, setChannels] = React.useState<any[]>([])
  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  React.useEffect(() => {
    const fetchChannels = (): void => {
      channelService.fetch().then((channels) => {
        const activeChannels = channels
          .filter((channel) => channel.status === 'active')
          .map((channel) => ({
            value: channel._id,
            label: channel.name,
          }))

        isMounted.current &&
          setChannels([
            { value: '*', label: 'All Channels' },
            ...activeChannels,
          ])
      })
    }

    fetchChannels()
  }, [isMounted])

  const formConfig = {
    initialValues: {
      title: survey?.title || '',
      description: survey?.description || '',
      channel: survey
        ? {
            value: survey.channel_details ? survey.channel_details._id : '*',
            label: survey.channel_details
              ? survey.channel_details.name
              : 'All Channels',
          }
        : null,
      status: survey?.status || 'active',
      type: survey?.type || '',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Title field is required.')
        .min(3, 'Title must be at least 3 characters.'),
      description: Yup.string()
        .required('Description field is required.')
        .min(3, 'Description must be at least 3 characters.'),
      channel: Yup.string().required('Channel field is required.').nullable(),
      status: Yup.string().required('Status field is required.'),
      type: Yup.string().required('Type field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        title: values.title,
        description: values.description,
        status: values.status,
        type: values.type,
        channel: values.channel.value,
        manufacturer_id: user.manufacturer_id,
      }

      if (type === 'update') {
        finalValues._id = survey._id
        finalValues.upload_id = survey.upload_id
      }

      surveyService[type](finalValues)
        .then((surveyId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Survey successfully updated.', { appearance: 'success' })
            updateSurvey(finalValues)
          } else {
            addToast('Survey successfully created.', { appearance: 'success' })
            history.push(route('survey_update', { id: surveyId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const doUpload = (uploadData): Promise<any> => {
    return new Promise((resolve) => {
      uploadService
        .upload(uploadData, `survey_${survey._id}`)
        .then((uploadId) => {
          const updatedSurvey = {
            _id: survey._id,
            title: survey.title,
            description: survey.description,
            status: survey.status,
            type: survey.type,
            channel: survey.channel,
            manufacturer_id: survey.manufacturer_id,
            upload_id: uploadId,
          }

          surveyService
            .update(updatedSurvey)
            .then(() => {
              setIsUploading(false)
              addToast('Image upload successful.', {
                appearance: 'success',
              })
              updateSurvey(updatedSurvey)
              resolve()
            })
            .catch((error) => {
              setIsUploading(false)
              addToast(error.message, { appearance: 'error' })
              resolve()
            })
        })
        .catch((error) => {
          setIsUploading(false)
          addToast(error.message, { appearance: 'error' })
          resolve()
        })
    })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        ...formik
      }) => (
        <Form onSubmit={handleSubmit}>
          {type === 'update' && survey ? (
            <>
              {/* Survey status */}
              <Form.Group as={Row} className="align-items-center">
                <Col md={3} className="mb-2 mb-md-0">
                  <Form.Label>Status *</Form.Label>
                </Col>
                <Col md={6}>
                  <div className="d-flex">
                    <Field
                      as={Form.Check}
                      type="radio"
                      id="active"
                      name="status"
                      value="active"
                      label="Active"
                      checked={values.status === 'active'}
                      custom
                    />

                    <Field
                      as={Form.Check}
                      type="radio"
                      id="inactive"
                      name="status"
                      value="inactive"
                      label="Inactive"
                      checked={values.status === 'inactive'}
                      className="ml-3"
                      custom
                    />
                  </div>
                </Col>
              </Form.Group>
              <hr />

              {/* Survey image */}
              <Form.Group as={Row}>
                <Col md={3} className="mb-2 mb-md-0">
                  <Form.Label>Image</Form.Label>
                  <Form.Text className="text-muted">Survey image</Form.Text>
                </Col>
                <Col md={6}>
                  <ImageUploader
                    type="survey"
                    uploadPath={survey.upload ? survey.upload.path : null}
                    doUpload={doUpload}
                  />
                </Col>
              </Form.Group>
              <hr />
            </>
          ) : null}

          {/* Survey type */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Type *</Form.Label>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="merchandiser"
                  name="type"
                  value="merchandiser"
                  label="Merchandiser"
                  checked={values.type === 'merchandiser'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="shopper"
                  name="type"
                  value="shopper"
                  label="Shopper"
                  checked={values.type === 'shopper'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {/* Survey title */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Title *</Form.Label>
              <Form.Text className="text-muted">Title of survey</Form.Text>
            </Col>
            <Col md={6}>
              <Field
                as={Form.Control}
                name="title"
                type="text"
                isInvalid={touched.title && errors.title ? true : false}
              />
              <ErrorMessage
                name="title"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Survey description */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Description *</Form.Label>
              <Form.Text className="text-muted">Survey description</Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={
                  touched.description && errors.description ? true : false
                }
              />
              <ErrorMessage
                name="description"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Channel */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Channel *</Form.Label>
              <Form.Text className="text-muted">
                What channels can fill this survey?
              </Form.Text>
            </Col>
            <Col md={6}>
              <SearchSelect
                name="channel"
                options={channels}
                value={values.channel}
                placeholder="Select channel"
                onBlur={() => {
                  formik.setFieldTouched('channel', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('channel', selected)
                }}
                isInvalid={touched.channel && errors.channel ? true : false}
                isDisabled={!channels.length}
              />
              <ErrorMessage
                name="channel"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={
              formik.isSubmitting ||
              !formik.dirty ||
              !formik.isValid ||
              isUploading
            }
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Survey'
            ) : (
              'Update Survey'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

SurveyForm.defaultProps = {
  type: 'create',
}

export default SurveyForm
