import React from 'react'

import { Formik, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SearchSelect from 'src/components/Form/SearchSelect'

import useMounted from 'src/hooks/useMounted'

import { IRetailLocation } from 'src/services/types/retailLocation'
import BranchService from 'src/services/BranchService'
import TreeService from 'src/services/TreeService'
import { IState } from 'src/store'

const treeService = new TreeService()
const branchService = new BranchService()

interface IProps {
  retailLocation?: IRetailLocation
}

const RetailerLocationState: React.FC<IProps> = ({ retailLocation }) => {
  const isMounted = useMounted()
  const { addToast } = useToasts()

  const user = useSelector((state: IState) => state.user)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const [stateBranch, setStateBranch] = React.useState<any>()
  const [states, setStates] = React.useState<any[]>([])

  React.useEffect(() => {
    const fetchTrees = (): void => {
      treeService.fetchByManufacturer(user.manufacturer_id).then((trees) => {
        const states = trees
          .filter((tree) => tree.type === 'STATE')
          .map((retailer) => ({
            value: retailer._id,
            label: retailer.name,
          }))
        isMounted.current && setStates(states)
      })
    }

    const fetchStateBranch = (): void => {
      setIsLoading(true)

      branchService
        .fetchStateBranchByRetailLocation(retailLocation._id)
        .then((stateBranch) => {
          isMounted.current && setStateBranch(stateBranch)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    fetchTrees()
    fetchStateBranch()
  }, [user.manufacturer_id, isMounted, retailLocation._id])

  const formConfig = {
    initialValues: {
      tree: stateBranch
        ? {
            value: stateBranch.tree._id,
            label: stateBranch.tree.name,
          }
        : null,
      retail_location_id: retailLocation._id,
    },
    validationSchema: Yup.object({
      tree: Yup.string().required('Location field is required.').nullable(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      let type = 'create'

      const finalValues: any = {
        tree_id: values.tree.value,
        retail_location_id: values.retail_location_id,
      }

      if (stateBranch) {
        type = 'update'
        finalValues._id = stateBranch._id
      }

      branchService[type](finalValues, retailLocation._id)
        .then(() => {
          setSubmitting(false)

          addToast('Retail store location successfully created.', {
            appearance: 'success',
          })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center py-5">
          <figure className="spinner primary" />
        </div>
      ) : (
        <Formik
          enableReinitialize={true}
          initialValues={formConfig.initialValues}
          validationSchema={formConfig.validationSchema}
          onSubmit={formConfig.onSubmit}
        >
          {({ errors, values, touched, handleSubmit, ...formik }) => (
            <Form onSubmit={handleSubmit}>
              {/* State */}
              <Form.Group as={Row}>
                <Col md={12}>
                  <SearchSelect
                    name="tree"
                    size="sm"
                    options={states}
                    value={values.tree}
                    placeholder="Select state"
                    onBlur={() => {
                      formik.setFieldTouched('tree', true)
                    }}
                    onChange={(selected) => {
                      formik.setFieldValue('tree', selected)
                    }}
                    isInvalid={touched.tree && errors.tree ? true : false}
                    isDisabled={!states.length}
                  />
                  <ErrorMessage
                    name="tree"
                    component="span"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <hr />

              <div className="mt-4">
                <Button
                  size="sm"
                  type="submit"
                  variant="success"
                  disabled={
                    values.address ||
                    formik.isSubmitting ||
                    !formik.dirty ||
                    !formik.isValid
                  }
                >
                  {formik.isSubmitting ? (
                    <figure className="spinner button white" />
                  ) : (
                    'Update State'
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default RetailerLocationState
