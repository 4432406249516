import Merchandisers from 'src/pages/Merchandiser/Merchandisers.page'
import MerchandiserSignup from 'src/pages/Merchandiser/MerchandiserSignup.page'
import MerchandiserUpdate from 'src/pages/Merchandiser/MerchandiserUpdate.page'

import { IRoute } from '.'

const merchandiserRoutes: IRoute[] = [
  {
    id: 'merchandisers',
    path: '/merchandisers',
    component: Merchandisers,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'merchandiser_signup',
    path: '/merchandisers/signup',
    component: MerchandiserSignup,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'merchandiser_update',
    path: '/merchandisers/:id/update',
    component: MerchandiserUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default merchandiserRoutes
