export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

/**
 * Paginate array of data
 *
 * @param data
 * @param page - current page
 * @param perPage
 */
export const paginate = (data: any[], page = 1, perPage = 10): any[] => {
  const indexOfLastData = page * perPage
  const indexOfFirstData = indexOfLastData - perPage
  return data.slice(indexOfFirstData, indexOfLastData)
}

/**
 * Conver fraction to percentage
 *
 * @param number
 */
export const toPercentage = (number: any): any => {
  if (isNaN(number)) return false
  return (number / 100).toLocaleString('en', { style: 'percent' })
}

export const slugify = (input): string => {
  if (!input) {
    return
  }

  // make lower case and trim
  let slug = input.toLowerCase().trim()

  // replace invalid chars with dashes
  // slug = slug.replace(/[^a-z0-9-]/g, '-');

  slug = slug.replace(/_+/g, ' ') // Replace _ with ' '
  slug = slug.replace(/\s+/g, '-') // Replace spaces with -
  slug = slug.replace(/[^\w-]+/g, '') // Remove all non-word chars

  slug = slug.replace(/--+/g, '-') // Replace multiple - with single -
  slug = slug.replace(/^-+/, '') // Trim - from start of text
  slug = slug.replace(/-+$/, '') // Trim - from end of text

  return encodeURIComponent(slug)
}

export const undoSlugify = (slug): string => {
  const words = slug.replace(/_+/g, '-').split('-')

  for (let i = 0; i < words.length; i++) {
    const word = words[i]
    words[i] = word.charAt(0).toUpperCase() + word.slice(1)
  }

  return words.join(' ')
}

/**
 * Sort array of objects by key
 *
 * @param data
 * @param key
 * @param sort
 */
export const sortByKey = (
  data: any[],
  key: string,
  sort: 'asc' | 'desc' = 'asc'
): any[] => {
  return data.sort(function (a, b) {
    let alc: any, blc: any

    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      alc = a[key]
      blc = b[key]
    } else {
      /** Convert to string so we can run checks */
      alc = a[key] ? a[key].toString() : false
      blc = b[key] ? b[key].toString() : false

      if (alc && /^[\d,.]*$/.test(alc)) {
        // Currency format?
        alc = Number(alc.replace(/[\s+,]/g, ''))
      } else {
        alc = typeof alc === 'string' ? alc.toLowerCase() : alc
      }

      if (blc && /^[\d,.]*$/.test(blc)) {
        // Currency format?
        blc = Number(blc.replace(/[\s+,]/g, ''))
      } else {
        blc = typeof blc === 'string' ? blc.toLowerCase() : blc
      }
    }

    if (alc < blc) return sort === 'desc' ? 1 : -1
    if (alc > blc) return sort === 'desc' ? -1 : 1

    return 0
  })
}

/**
 * Split array into chunks
 *
 * @param array
 * @param chunkSize
 */
export const chunk = <T>(array: T[], chunkSize = 1): T[][] => {
  // This prevents infinite loops
  if (chunkSize < 1) throw new Error('Chunk size must be positive')

  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}
