import React from 'react'

import pluralize from 'pluralize'
import { Container, Table, Card, Button, Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { BsFilter, BsPlus } from 'react-icons/bs'
import { Link } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import PerPageSelect from 'src/components/Form/PerPageSelect'
import RetailerSelect from 'src/components/Form/RetailerSelect'
import RetailLocationSelect from 'src/components/Form/RetailLocationSelect'
import SearchInput from 'src/components/Form/SearchInput'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'

import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'
import useGlobalFilters from 'src/hooks/useGlobalFilters'

import { ITarget } from 'src/services/types/target'

import Error401 from 'src/pages/Error/401.page'

import { months } from 'src/utils/date'
import { paginate } from 'src/utils/filters'
import { formatCurrency } from 'src/utils/money'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'

import TargetService from 'src/services/TargetService'
import { IState } from 'src/store'

const targetService = new TargetService()

const Targets: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const user = useSelector((state: IState) => state.user)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  const [targets, setTargets] = React.useState<ITarget[]>()

  const { filters, activeFilters, setFilters, resetFilters } =
    useGlobalFilters('targets')

  React.useEffect(() => {
    const fetchTargets = (): void => {
      targetService
        .fetchByManufacturer(user.manufacturer_id)
        .then((targets) => {
          isMounted.current && setTargets(targets)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_targets') && fetchTargets()
  }, [userCan, user.manufacturer_id, isMounted])

  const filteredTargets = React.useMemo(() => {
    let filtered = targets || []

    if (filtered.length) {
      if (filters.retail_location && filters.retail_location.value !== '*') {
        filtered = filtered.filter((schedule) => {
          return schedule.retail_location_id === filters.retail_location.value
        })
      } else if (filters.retailer && filters.retailer.value !== '*') {
        filtered = filtered.filter(
          (schedule) => schedule.retailer_id === filters.retailer.value
        )
      }

      if (filters.search) {
        const query_parts = filters.search.split(':').map((part) => part.trim())

        filtered = filtered.filter((target) => {
          if (query_parts?.length === 2 && target?.[query_parts[0]]) {
            const [accessor, query] = query_parts
            return basicSearch(target?.[accessor], query)
          }
          return false
        })
      }
    }

    return filtered
  }, [targets, filters.retailer, filters.retail_location, filters.search])

  const paginatedTargets = React.useMemo(() => {
    return filteredTargets
      ? paginate(filteredTargets, filters.page, filters.per_page)
      : []
  }, [filters.page, filters.per_page, filteredTargets])

  if (!userCan('view_targets')) return <Error401 />

  return (
    <>
      <Header
        title={
          filters.retailer && filters.retailer.value !== '*'
            ? `Targets (${filters.retailer.label})`
            : 'Targets'
        }
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="font-weight-bold">
                  {filteredTargets
                    ? `${filteredTargets.length} ${pluralize(
                        'target',
                        filteredTargets.length
                      )}`
                    : '0 targets'}
                </div>

                {userCan('create_target') && (
                  <div>
                    <Link to={route('target_create')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create Target
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Dropdown className="filters-wrapper">
                  <Dropdown.Toggle size="sm" variant="link" id="dropdown-basic">
                    <BsFilter size={24} />
                    <span className="text-capitalize">{activeFilters}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* Retailer */}
                    <div className="filter-group">
                      <span className="filter-label">Retailer</span>
                      <RetailerSelect
                        value={filters.retailer || null}
                        onChange={(retailer) => {
                          setFilters({
                            retailer,
                            retail_location: null,
                            page: 1,
                          })
                        }}
                      />
                    </div>

                    <div className="filter-group">
                      <span className="filter-label">Store</span>
                      <RetailLocationSelect
                        value={filters.retailLocation || null}
                        onChange={(retail_location) =>
                          setFilters({
                            retail_location,
                            page: 1,
                          })
                        }
                        retailer={filters.retailer ? filters.retailer : false}
                      />
                    </div>

                    <div className="filter-group mt-4">
                      <Button
                        size="sm"
                        variant="outline-light"
                        onClick={() => resetFilters()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                <div className="ml-3">
                  <SearchInput
                    placeholder="Search targets *"
                    value={filters.search}
                    onChange={(search) => setFilters({ search })}
                  />
                </div>
              </div>

              <PerPageSelect
                value={filters.per_page || 10}
                onChange={(per_page) => setFilters({ per_page, page: 1 })}
              />
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}

              {!isLoading && !paginatedTargets.length ? (
                <div className="my-5">
                  <NotFound summary="No targets found" />
                </div>
              ) : null}

              {!isLoading && paginatedTargets.length ? (
                <div className="table-wrapper padded">
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="sticky">Retailer</th>
                        <th>Store</th>
                        <th>Tag</th>
                        <th>Month</th>
                        <th>Value (₦)</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedTargets.map((target, index) => (
                        <tr key={target._id}>
                          <td className="align-middle text-center">
                            {index + 1 + (filters.page - 1) * filters.per_page}
                          </td>
                          <td className="align-middle">
                            {target.retailer.name}
                          </td>
                          <td className="align-middle">
                            {target.retail_location.name}
                          </td>
                          <td>{target.tag}</td>
                          <td className="align-middle font-weight-bold">
                            {`${months[target.month - 1]} ${target.year}`}
                          </td>
                          <td className="align-middle text-currency font-weight-bold">
                            {formatCurrency(target.value, true)}
                          </td>

                          <td className="align-middle">
                            <Link
                              to={route('target_update', {
                                id: target._id,
                              })}
                              className="action-link"
                            >
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : null}

              <Pagination
                className="my-4"
                perPage={filters.per_page}
                totalItems={filteredTargets.length}
                currentPage={filters.page}
                onChange={(page) => setFilters({ page })}
              />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Targets
