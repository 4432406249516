import React from 'react'

import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Card } from 'react-bootstrap'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import BrandForm from 'src/components/Brand/BrandForm'

interface IProps extends RouteComponentProps {
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const BrandCreate: React.FC<IProps> = ({ history, user, userCan }) => {
  if (!userCan('create_brand')) return <Error401 />

  return (
    <>
      <Header
        title="New Brand"
        backText="Brands"
        onBack={() => history.push(route('brands'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Brand Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <BrandForm user={user} />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(BrandCreate)
)
