import React from 'react'

import { Formik, Field } from 'formik'
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap'
import Autocomplete from 'react-google-autocomplete'
import { useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import { IRetailLocation } from 'src/services/types/retailLocation'
import RetailLocationService from 'src/services/RetailLocationService'
import { IState } from 'src/store'

const retailLocationService = new RetailLocationService()

interface IProps {
  retailLocation?: IRetailLocation
  updateRetailLocation?: (updatedValues: IRetailLocation) => void
}

const RetailerLocationAddress: React.FC<IProps> = ({
  retailLocation,
  updateRetailLocation,
}) => {
  const { location } = retailLocation

  const { addToast } = useToasts()

  const user = useSelector((state: IState) => state.user)

  const formConfig = {
    initialValues: {
      address: '',
      name: location ? location.name : '-',
      status: location ? location.status : 'active',
      latitude: location ? location.latitude : '',
      longitude: location ? location.longitude : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Address is required.'),
      status: Yup.string().required('Status is required.'),
      latitude: Yup.number().required('Latitude field is required.'),
      longitude: Yup.number().required('Longitude field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      let type = 'createLocation'

      const finalValues: any = {
        name: values.name,
        status: values.status,
        latitude: values.latitude,
        longitude: values.longitude,
        manufacturer_id: user.manufacturer_id,
      }

      if (location) {
        type = 'updateLocation'
        finalValues._id = location._id
      }

      retailLocationService[type]({ ...finalValues }, retailLocation._id)
        .then(() => {
          setSubmitting(false)

          addToast('Retail store address successfully created.', {
            appearance: 'success',
          })
          updateRetailLocation({
            ...retailLocation,
            location: { ...location, ...finalValues },
          })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        ...formik
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Row}>
            <Col>
              <span className="text-muted">
                Add an address to this retail store to enable geo-fencing.
              </span>
            </Col>
          </Form.Group>

          {/* Retailer location address */}
          <Form.Group as={Row}>
            <Col md={12}>
              {values.latitude && values.longitude ? (
                <div className="mb-2">
                  <small className="text-muted text-uppercase font-weight-bold">
                    Address
                  </small>
                  <br />
                  <b>{values.name}</b> <br />
                  <small className="text-primary">
                    LAT: {values.latitude.toFixed(4)}, LNG:{' '}
                    {values.longitude.toFixed(4)}
                  </small>
                </div>
              ) : null}

              <Autocomplete
                className="form-control form-control-sm"
                placeholder="Search address"
                onPlaceSelected={(place) => {
                  formik.setFieldValue('address', '')
                  formik.setFieldValue('name', place.formatted_address)

                  formik.setFieldValue(
                    'latitude',
                    place.geometry.location.lat()
                  )

                  formik.setFieldValue(
                    'longitude',
                    place.geometry.location.lng()
                  )
                }}
                options={{
                  types: ['establishment'],
                  componentRestrictions: { country: 'ng' },
                }}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <InputGroup className="mt-2">
                <Field
                  as={Form.Control}
                  size="sm"
                  name="latitude"
                  type="number"
                  placeholder="Latitude"
                  isInvalid={touched.latitude && errors.latitude ? true : false}
                />
                <Field
                  as={Form.Control}
                  size="sm"
                  name="longitude"
                  type="number"
                  placeholder="Longitude"
                  isInvalid={
                    touched.longitude && errors.longitude ? true : false
                  }
                />
              </InputGroup>
            </Col>
          </Form.Group>
          <hr />

          {/* Retailer location address status */}
          {location ? (
            <>
              <Form.Group as={Row} className="align-items-center">
                <Col md={12}>
                  <div className="d-flex">
                    <Field
                      as={Form.Check}
                      type="radio"
                      id="active-address"
                      name="status"
                      value="active"
                      label="Active"
                      checked={values.status === 'active'}
                      custom
                    />

                    <Field
                      as={Form.Check}
                      type="radio"
                      id="inactive-address"
                      name="status"
                      value="inactive"
                      label="Inactive"
                      checked={values.status === 'inactive'}
                      className="ml-3"
                      custom
                    />
                  </div>
                </Col>
              </Form.Group>
              <hr />
            </>
          ) : null}

          <div className="mt-4">
            <Button
              size="sm"
              type="submit"
              variant="success"
              disabled={
                values.address ||
                formik.isSubmitting ||
                !formik.dirty ||
                !formik.isValid
              }
            >
              {formik.isSubmitting ? (
                <figure className="spinner button white" />
              ) : location ? (
                'Update Address'
              ) : (
                'Add Address'
              )}
            </Button>

            {location &&
            values.name !== location.name &&
            !formik.isSubmitting ? (
              <Button
                size="sm"
                type="submit"
                variant="outline-light"
                className="ml-2"
                onClick={() => {
                  formik.setFieldValue('address', '')
                  formik.setFieldValue('name', location.name)
                  formik.setFieldValue('latitude', location.latitude)
                  formik.setFieldValue('longitude', location.longitude)
                }}
              >
                Cancel
              </Button>
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default RetailerLocationAddress
