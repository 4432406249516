export const SET_CONFIG = 'SET_CONFIG'
export const SET_GLOBAL = 'SET_GLOBAL'
export const SET_REPORT_FILTERS = 'SET_REPORT_FILTERS'

export const AUTH = 'AUTH'
export const DEAUTH = 'DEAUTH'
export const SET_USER = 'SET_USER'
export const UNSET_USER = 'UNSET_USER'

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
