import React from 'react'

import { Container } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import ForumImages from 'src/components/Forum/ForumImages'
import ForumsList from 'src/components/Forum/ForumsList'
import Header from 'src/components/Layout/Header/Header'

import usePermission from 'src/hooks/usePermission'

import Error401 from 'src/pages/Error/401.page'

import { IState } from 'src/store'
import { Role } from 'src/config/roles'

const Forum: React.FC = () => {
  const { userCan } = usePermission()
  const user = useSelector((state: IState) => state.user)

  if (!userCan('view_forums')) return <Error401 />

  return (
    <>
      <Header title="Community" />
      <div className="page-container">
        <Container>
          {user.role === Role.RootAdmin ? <ForumsList /> : <ForumImages />}
        </Container>
      </div>
    </>
  )
}

export default Forum
