import React from 'react'
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap'

import { Formik, Field, ErrorMessage } from 'formik'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SearchSelect from 'src/components/Form/SearchSelect'

import useMounted from 'src/hooks/useMounted'
import { IMerchandiser } from 'src/services/types/merchandiser'
import { IUser } from 'src/services/types/user'

import MerchandiserService from 'src/services/MerchandiserService'
import RetailerService from 'src/services/RetailerService'
import RetailLocationService from 'src/services/RetailLocationService'
import { route } from 'src/utils/routes'

const merchandiserService = new MerchandiserService()
const retailerService = new RetailerService()
const retailLocationService = new RetailLocationService()

interface IProps extends RouteComponentProps {
  user: IUser
  type?: 'signup' | 'update'
  merchandiser?: IMerchandiser
  updateMerchandiser?: (updatedValues: IMerchandiser) => void
  /** To prefill signup form */
  namePrefill?: string
  phonePrefill?: number
}

const MerchandiserForm: React.FC<IProps> = ({
  user,
  type,
  merchandiser,
  updateMerchandiser,
  phonePrefill,
  namePrefill,
}) => {
  const history = useHistory()
  const isMounted = useMounted()
  const { addToast } = useToasts()

  const [retailers, setRetailers] = React.useState<any[]>([])
  const [retailLocations, setRetailLocations] = React.useState<any[]>([])

  React.useEffect(() => {
    const fetchRetailers = (): void => {
      retailerService
        .fetchByManufacturer(user.manufacturer_id)
        .then((retailers) => {
          const activeRetailers = retailers
            .filter((retailer) => retailer.status === 'active')
            .map((retailer) => ({
              value: retailer._id,
              label: retailer.name,
            }))
          isMounted.current && setRetailers(activeRetailers)
        })
    }

    const fetchRetailLocations = (): void => {
      retailLocationService
        .fetchByManufacturer(user.manufacturer_id)
        .then((locations) => {
          const activeLocations = locations
            .filter((location) => location.status === 'active')
            .map((location) => ({
              value: location._id,
              label: location.name,
              retailer_id: location.retailer_id,
            }))
          isMounted.current && setRetailLocations(activeLocations)
        })
    }

    fetchRetailers()
    fetchRetailLocations()
  }, [isMounted, user.manufacturer_id])

  const formConfig = {
    initialValues: {
      status: merchandiser?.status || 'active',
      phone: merchandiser
        ? merchandiser.phone
        : phonePrefill
        ? phonePrefill
        : '',
      name: merchandiser ? merchandiser.name : namePrefill ? namePrefill : '',
      identifier: merchandiser?.identifier || '',
      retailer: merchandiser
        ? {
            value: merchandiser.retailer._id,
            label: merchandiser.retailer.name,
          }
        : '',
      retail_location: merchandiser
        ? {
            value: merchandiser.retail_location._id,
            label: merchandiser.retail_location.name,
          }
        : '',
      hash: '',
    },
    validationSchema: Yup.object({
      identifier: Yup.string()
        .required('Identifier field is required.')
        .min(3, 'Identifier must be at least 3 characters.'),
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      phone: Yup.string()
        .required('Phone number field is required.')
        .matches(
          /^[0][7-9][0-1][0-9]{8}$/,
          'Phone number must be valid. Ex. 08174381261'
        ),
      status: Yup.string().required('Status field is required.'),
      retailer: Yup.string().required('Retailer field is required.'),
      retail_location: Yup.string().required('Retail store field is required.'),
      hash:
        type === 'signup'
          ? Yup.string().required('Password field is required')
          : Yup.string(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        identifier: values.identifier,
        phone: values.phone,
        name: values.name,
        status: values.status,
        retailer_id: values.retailer.value,
        retail_location_id: values.retail_location.value,
        manufacturer_id: user.manufacturer_id,
      }

      if (type === 'signup') {
        finalValues.hash = values.hash
      } else {
        finalValues._id = merchandiser._id
        finalValues.user_id = merchandiser.user_id
      }

      merchandiserService[type]({ ...finalValues })
        .then((merchandiser) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Merchandiser successfully updated.', {
              appearance: 'success',
            })
            updateMerchandiser({ ...finalValues })
          } else {
            addToast('Merchandiser successfully signed up.', {
              appearance: 'success',
            })
            history.push(route('merchandiser_update', { id: merchandiser._id }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          {/* Merchandiser status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={type === 'update' ? 4 : 3} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={type === 'update' ? 8 : 6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {/* Merchandiser phone */}
          <Form.Group as={Row}>
            <Col md={type === 'update' ? 4 : 3} className="mb-2 mb-md-0">
              <Form.Label>Phone *</Form.Label>
              <Form.Text className="text-muted">
                {`Merchandiser's phone number`}
              </Form.Text>
            </Col>
            <Col md={type === 'update' ? 8 : 6}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="code">+234</InputGroup.Text>
                </InputGroup.Prepend>
                <Field
                  as={Form.Control}
                  name="phone"
                  type="text"
                  isInvalid={touched.phone && errors.phone ? true : false}
                  // readOnly={type === 'update' || phonePrefill ? true : false}
                />
              </InputGroup>
              <ErrorMessage
                name="phone"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Merchandisers name */}
          <Form.Group as={Row}>
            <Col md={type === 'update' ? 4 : 3} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">Name of merchandiser</Form.Text>
            </Col>
            <Col md={type === 'update' ? 8 : 6}>
              <Field
                as={Form.Control}
                name="name"
                type="text"
                isInvalid={touched.name && errors.name ? true : false}
                readOnly={namePrefill ? true : false}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Merchandisers unique identifier */}
          <Form.Group as={Row}>
            <Col md={type === 'update' ? 4 : 3} className="mb-2 mb-md-0">
              <Form.Label>Identifier *</Form.Label>
              <Form.Text className="text-muted">
                Unique ID of merchandiser
              </Form.Text>
            </Col>
            <Col md={type === 'update' ? 8 : 6}>
              <Field
                as={Form.Control}
                name="identifier"
                type="text"
                placeholder="Ex. tony-spar-opebi"
                isInvalid={
                  touched.identifier && errors.identifier ? true : false
                }
                readOnly={type === 'update'}
              />
              <ErrorMessage
                name="identifier"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Retailer */}
          <Form.Group as={Row}>
            <Col md={type === 'update' ? 4 : 3} className="mb-2 mb-md-0">
              <Form.Label>Retailer *</Form.Label>
              <Form.Text className="text-muted">Retailer</Form.Text>
            </Col>
            <Col md={type === 'update' ? 8 : 6}>
              <SearchSelect
                name="retailer"
                options={retailers}
                value={values.retailer}
                placeholder="Select retailer"
                onBlur={() => {
                  formik.setFieldTouched('retailer', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('retailer', selected)
                }}
                isInvalid={touched.retailer && errors.retailer ? true : false}
                isDisabled={!retailers.length}
              />
              <ErrorMessage
                name="retailer"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Location */}
          {/* List depends on retailer value */}
          <Form.Group as={Row}>
            <Col md={type === 'update' ? 4 : 3} className="mb-2 mb-md-0">
              <Form.Label>Retail Store *</Form.Label>
              <Form.Text className="text-muted">
                Retailer physical location
              </Form.Text>
            </Col>
            <Col md={type === 'update' ? 8 : 6}>
              <SearchSelect
                name="retail_location"
                options={
                  values.retailer && retailLocations
                    ? retailLocations.filter((location) => {
                        return location.retailer_id === values.retailer.value
                      })
                    : []
                }
                value={values.retail_location}
                placeholder="Select retail store"
                onBlur={() => {
                  formik.setFieldTouched('retail_location', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('retail_location', selected)
                }}
                isInvalid={
                  touched.retail_location && errors.retail_location
                    ? true
                    : false
                }
                isDisabled={!values.retailer}
              />
              <ErrorMessage
                name="retail_location"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* User password */}
          {type === 'signup' && (
            <>
              <Form.Group as={Row}>
                <Col md={3} className="mb-2 mb-md-0">
                  <Form.Label>Password *</Form.Label>
                  <Form.Text className="text-muted">
                    Set secure password
                  </Form.Text>
                </Col>
                <Col md={6}>
                  <Field
                    as={Form.Control}
                    name="hash"
                    type="password"
                    isInvalid={touched.hash && errors.hash ? true : false}
                  />
                  <ErrorMessage
                    name="hash"
                    component="span"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <hr />
            </>
          )}

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'signup' ? (
              'Create Merchandiser'
            ) : (
              'Update Merchandiser'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

MerchandiserForm.defaultProps = {
  type: 'signup',
}

export default withRouter(MerchandiserForm)
