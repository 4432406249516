import React, { useEffect, useState } from 'react'

import pluralize from 'pluralize'
import { Container, Table, Card, Button, Badge } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import SearchInput from 'src/components/Form/SearchInput'
import ProductImage from 'src/components/Image/ProductImage'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'

import useMounted from 'src/hooks/useMounted'

import { IManufacturer } from 'src/services/types/manufacturer'

import Error401 from 'src/pages/Error/401.page'

import ManufacturerService from 'src/services/ManufacturerService'
import { IState } from 'src/store'

import { asset } from 'src/utils/file'
import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'
import usePermission from 'src/hooks/usePermission'

const manufacturerService = new ManufacturerService()

const Manufacturers: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const user = useSelector((state: IState) => state.user)

  // indicators
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // All manufacturers
  const [mftrs, setMftrs] = useState<IManufacturer[]>()

  const [searchTerm, setSearchTerm] = useState<string>()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const fetchManufacturers = (): void => {
      manufacturerService
        .fetch()
        .then((mftrs) => {
          isMounted.current && setMftrs(mftrs)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    if (userCan('view_manufacturers')) fetchManufacturers()
  }, [isMounted, userCan, user.manufacturer_id])

  const filteredMftrs = React.useMemo(() => {
    setPage(1)
    let filteredMftrs = mftrs || []

    if (filteredMftrs.length) {
      if (searchTerm) {
        filteredMftrs = filteredMftrs.filter((mftr) => {
          return (
            basicSearch(mftr.name, searchTerm) ||
            basicSearch(mftr.status, searchTerm)
          )
        })
      }
    }

    return filteredMftrs
  }, [mftrs, searchTerm])

  /**
   * Get the manufacturers to be shown on the current page
   */
  const paginatedManufacturers = React.useMemo(() => {
    return filteredMftrs ? paginate(filteredMftrs, page) : []
  }, [page, filteredMftrs])

  if (!userCan('view_manufacturers')) return <Error401 />

  return (
    <>
      <Header title="Organizations" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    {filteredMftrs
                      ? `${filteredMftrs.length} ${pluralize(
                          'manufacturer',
                          filteredMftrs.length
                        )}`
                      : '0 manufacturers'}
                  </div>
                  <div className="ml-3">
                    <SearchInput
                      placeholder="Search organizations"
                      value={searchTerm}
                      onChange={setSearchTerm}
                      disabled={isLoading || !filteredMftrs}
                    />
                  </div>
                </div>

                {userCan('create_manufacturer') && (
                  <div>
                    <Link to={route('manufacturer_create')}>
                      <Button size="sm">Create Manufacturer</Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {!isLoading && paginatedManufacturers.length ? (
                <>
                  <div className="table-wrapper padded">
                    <Table hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">--</th>
                          <th>Name</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedManufacturers.map((manufacturer, index) => (
                          <tr
                            key={manufacturer._id}
                            className="cursor-pointer"
                            onClick={() =>
                              history.push(
                                route('manufacturer_update', {
                                  id: manufacturer._id,
                                })
                              )
                            }
                          >
                            <td className="align-middle text-center">
                              {index + 1 + (page - 1) * 10}
                            </td>
                            <td className="align-middle text-center">
                              <div className="d-flex justify-content-center">
                                <ProductImage
                                  src={
                                    manufacturer.upload
                                      ? asset(manufacturer.upload.path)
                                      : false
                                  }
                                  roundedCircle
                                />
                              </div>
                            </td>
                            <td className="align-middle">
                              {manufacturer.name}
                            </td>
                            <td className="align-middle">
                              <Badge
                                className="text-capitalize"
                                variant={
                                  manufacturer.status === 'active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {manufacturer.status}
                              </Badge>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    className="my-4"
                    perPage={10}
                    totalItems={filteredMftrs.length}
                    currentPage={page}
                    onChange={(page) => setPage(page)}
                  />
                </>
              ) : null}

              {!isLoading && !paginatedManufacturers.length ? (
                <div className="my-5">
                  <NotFound summary="No manufacturers found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Manufacturers
