import { IMerchandiser } from './merchandiser'
import { IUpload } from './upload'

export enum TicketStatus {
  Open = 'open',
  Closed = 'closed',
}
export interface ITicket {
  _id?: string

  flag: string
  status: TicketStatus
  body: string
  sender: string
  recipient: string

  /** Added for easy filtering */
  state: string
  region: string

  readonly merchandiser?: IMerchandiser

  readonly upload?: IUpload

  readonly created: string
}

export interface ITicketLog {
  _id?: string

  /** Tag such as state */
  tag: string
  /** Current state or response */
  value: string

  ticket?: ITicket

  readonly created: string
}
