import React from 'react'

import { connect } from 'react-redux'
import { Table, Card } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import MustStockPicker from 'src/components/Configuration/MustStockPicker'
import NotFound from 'src/components/Error/NotFound'
import useMounted from 'src/hooks/useMounted'
import { IConfiguration } from 'src/services/types/configuration'
import { userCan } from 'src/store/user/userActions'
import ConfigurationService from 'src/services/ConfigurationService'

const configurationService = new ConfigurationService()

interface IProps {
  type?: 'product' | 'competition'
  user: any
  configuration: IConfiguration
}

const ConfigurationItems: React.FC<IProps> = ({
  type,
  user,
  configuration,
}) => {
  const isMounted = useMounted()
  const { addToast } = useToasts()

  /** indicators */
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)
  /** Store the item ID of item being deleted. */
  const [isDeleting, setIsDeleting] = React.useState<any>(false)

  const [mustStockItems, setMustStockItems] = React.useState<any[]>()
  const [lastUpdated, setLastUpdated] = React.useState<number>()

  React.useEffect(() => {
    if (!isDeleting) {
      setIsUpdating(true)

      configurationService
        .fetchMustStock(type, user.manufacturer_id, configuration._id)
        .then((items) => {
          if (isMounted.current) {
            setMustStockItems(items)
            setIsLoading(false)
            setIsUpdating(false)
          }
        })
    }
  }, [
    type,
    user.manufacturer_id,
    configuration._id,
    lastUpdated,
    isDeleting,
    isMounted,
  ])

  const addMustStock = React.useCallback(
    (category, products) => {
      products = products.map((product) => product.value)

      return new Promise((resolve, reject) => {
        const details = {
          type,
          products,
          category_id: category.value,
          configuration_id: configuration._id,
        }

        configurationService
          .addMustStock(details)
          .then(() => {
            setLastUpdated(Date.now())

            addToast(`Product added to configuration`, {
              appearance: 'success',
            })

            resolve()
          })
          .catch((error) => {
            addToast(error.mesage, { appearance: 'error' })
            reject(error)
          })
      })
    },
    [addToast, type, configuration._id]
  )

  const deleteMustStock = React.useCallback(
    (mustStockId) => {
      return new Promise((resolve, reject) => {
        setIsDeleting(mustStockId)

        const details = {
          type,
          must_stock_id: mustStockId,
        }

        configurationService
          .removeMustStock(details)
          .then(() => {
            setIsDeleting(false)

            addToast(`Product removed from configuration`, {
              appearance: 'success',
            })

            resolve()
          })
          .catch((error) => {
            setIsDeleting(false)

            addToast(error.mesage, { appearance: 'error' })
            reject(error)
          })
      })
    },
    [addToast, type]
  )

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          {configuration.title} (
          {type === 'product'
            ? 'Must Stock Products'
            : 'Must Stock Products (Competition)'}
          )
        </Card.Title>
      </Card.Header>
      <Card.Header className="odd">
        <MustStockPicker
          type={type}
          user={user}
          configuration={configuration}
          selectedItems={mustStockItems}
          addMustStock={addMustStock}
        />
      </Card.Header>
      <Card.Body className={isUpdating ? 'disabled' : ''}>
        {!isLoading && mustStockItems && mustStockItems.length ? (
          <div className="table-wrapper bordered">
            <Table className="table-fit" bordered hover responsive>
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>{type === 'product' ? 'Product' : 'Comp. Product'}</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {mustStockItems?.length
                  ? mustStockItems.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className="text-center align-middle">
                          {index + 1}
                        </td>
                        <td className="align-middle sticky">
                          {/* Add span to enable text truncate */}
                          <span title={item[type].name}>{item[type].name}</span>
                        </td>
                        <td className="align-middle">{item.category.name}</td>
                        <td>
                          {isDeleting === item._id ? (
                            <figure className="spinner small primary" />
                          ) : (
                            <span
                              className="text-danger action-link cursor-pointer"
                              onClick={() => deleteMustStock(item._id)}
                            >
                              Remove
                            </span>
                          )}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          </div>
        ) : !isLoading && (!mustStockItems || !mustStockItems.length) ? (
          <div className="my-5">
            <NotFound summary="No must stock products found for this configuration" />
          </div>
        ) : (
          <div className="d-flex justify-content-center py-5">
            <figure className="spinner primary" />
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

ConfigurationItems.defaultProps = {
  type: 'product',
}

export default connect(() => ({}), { userCan })(ConfigurationItems)
