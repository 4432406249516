import React from 'react'

import { connect } from 'react-redux'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import MerchandiserService from 'src/services/MerchandiserService'
import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IMerchandiser } from 'src/services/types/merchandiser'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import MerchandiserForm from 'src/components/Merchandiser/MerchandiserForm'
import UserPasswordForm from 'src/components/User/UserPasswordForm'
import MerchandiserDevices from 'src/components/Merchandiser/MerchandiserDevices'

const merchandiserService = new MerchandiserService()

interface IProps extends RouteComponentProps {
  match: any
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const MerchandiserUpdate: React.FC<IProps> = ({
  match,
  history,
  user,
  userCan,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [merchandiser, setMerchandiser] = React.useState<IMerchandiser>()

  React.useEffect(() => {
    if (userCan('update_merchandiser')) {
      merchandiserService
        .fetchById(match.params.id)
        .then((merchandiser) => setMerchandiser(merchandiser))
        .finally(() => setIsLoading(false))
    }
  }, [userCan, match.params.id])

  if (!userCan('update_merchandiser')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Merchandiser'
            : merchandiser
            ? `${merchandiser.name}`
            : 'Merchandiser not found'
        }
        backText="Merchandisers"
        onBack={() => history.push(route('merchandisers'))}
      />
      <div className="page-container">
        <Container>
          {!isLoading && merchandiser ? (
            <Row>
              <Col md={8}>
                <Card>
                  <Card.Header>
                    <Card.Title>Merchandiser Details</Card.Title>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <MerchandiserForm
                      user={user}
                      type="update"
                      merchandiser={merchandiser}
                      updateMerchandiser={(updatedValues) => {
                        setMerchandiser({ ...merchandiser, ...updatedValues })
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>Change Password</Card.Title>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <UserPasswordForm user={merchandiser} />
                  </Card.Body>
                </Card>

                <Card className="mt-3">
                  <Card.Header>
                    <Card.Title>Mobile Devices</Card.Title>
                  </Card.Header>
                  <Card.Body className="px-0 py-0 contained">
                    <MerchandiserDevices merchandiser={merchandiser} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}

          {!isLoading && !merchandiser ? (
            <div className="my-5">
              <NotFound
                title="Merchandiser not found"
                summary="Check the URL and try again."
              />
            </div>
          ) : null}

          {isLoading && (
            <div className="d-flex justify-content-center py-5">
              <figure className="spinner primary" />
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(MerchandiserUpdate)
)
