import React from 'react'

import { Button, Dropdown } from 'react-bootstrap'
import { BsFilter } from 'react-icons/bs'

import CategorySelect from 'src/components/Form/CategorySelect'
import DateRangeInput from 'src/components/Form/DateRangeInput'
import RetailLocationSelect from 'src/components/Form/RetailLocationSelect'
import SearchSelect from 'src/components/Form/SearchSelect'

interface IProps {
  filters
  setFilters: (filters: any) => void
  onChange: () => void
  isLoading: boolean
}

const ForumFilters: React.FC<IProps> = ({
  filters,
  setFilters,
  onChange,
  isLoading,
}) => {
  return (
    <>
      <Dropdown className="filters-wrapper">
        <Dropdown.Toggle size="sm" variant="link" id="dropdown-basic">
          <BsFilter size={24} />
          Filters
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {/* Retail store filter */}
          <div className="filter-group">
            <label>Retail store</label>
            <RetailLocationSelect
              value={filters.store}
              onChange={(store) => setFilters({ ...filters, store })}
            />
          </div>

          {/* Category filter */}
          <div className="filter-group">
            <label>Category</label>
            <CategorySelect
              value={filters.category}
              onChange={(category) => setFilters({ ...filters, category })}
            />
          </div>

          {/* Task filter */}
          <div className="filter-group">
            <label>Task</label>
            <SearchSelect
              size="sm"
              options={[
                {
                  value: '*',
                  label: 'All Tasks',
                },
                {
                  value: 'primary-visibility',
                  label: 'Primary Visibility',
                },
                {
                  value: 'secondary-visibility',
                  label: 'Secondary Visibility',
                },
              ]}
              value={filters.group}
              placeholder="Select group"
              onChange={(group) => setFilters({ ...filters, group })}
              isActive={filters.group && filters.group.value !== '*'}
            />
          </div>

          {/* Date filter */}
          <div className="filter-group">
            <label>Period</label>
            <DateRangeInput
              start={filters.from_timestamp}
              end={filters.to_timestamp}
              onChange={(from_timestamp, to_timestamp) =>
                setFilters({ ...filters, from_timestamp, to_timestamp })
              }
            />
          </div>

          <div className="d-flex justify-content-between filter-group mt-4">
            {/* Reset for search term only */}
            <Button
              size="sm"
              variant="outline-light"
              onClick={() => {
                setFilters({
                  ...filters,
                  store: null,
                  category: null,
                  group: null,
                })
              }}
              disabled={
                !filters.group &&
                !filters.store &&
                !filters.category &&
                !filters.from_timestamp &&
                !filters.to_timestamp
              }
            >
              Reset
            </Button>

            <Button
              size="sm"
              onClick={onChange}
              disabled={
                (!filters.group && !filters.store && !filters.category) ||
                !filters.from_timestamp ||
                !filters.to_timestamp ||
                isLoading
              }
            >
              Refresh
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default ForumFilters
