import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import { connect } from 'react-redux'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import { Container, Table, Card, Form, Button, Badge } from 'react-bootstrap'

import { userCan } from 'src/store/user/userActions'
import CategoryService from 'src/services/CategoryService'
import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { ICategory } from 'src/services/types/category'
import { IUser } from 'src/services/types/user'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'
import NotFound from 'src/components/Error/NotFound'

const categoryService = new CategoryService()

interface IProps {
  /** User from state.user */
  user: IUser
  /** User permissions checker */
  userCan: (permission: string) => boolean
}

const Categories: React.FC<IProps> = ({ user, userCan }) => {
  // indicators
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // All categories
  const [categories, setCategories] = useState<ICategory[]>()
  // Categories based on searchTerm and/or status
  const [sortedCategories, setSortedCategories] = useState<ICategory[]>()

  const [searchTerm, setSearchTerm] = useState<string>()
  const [page, setPage] = useState(1)

  const _isMounted = React.useRef<boolean>(false)
  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (userCan('view_categories')) {
      categoryService
        .fetchByManufacturer(user.manufacturer_id)
        .then((categories) => {
          if (_isMounted.current && categories.length) {
            setCategories(categories)
            setSortedCategories(categories)
          }
          setIsLoading(false)
        })
    }
  }, [userCan, user.manufacturer_id])

  const handleSearch = _.debounce((query: string) => {
    setPage(1)
    if (!query) setSortedCategories(categories)
    else {
      setSortedCategories(
        categories.filter((category) => {
          return (
            category.name.search(new RegExp(query, 'i')) >= 0 ||
            category.status.search(new RegExp(query, 'i')) >= 0
          )
        })
      )
    }
  }, 150)

  /**
   * Get the categories to be shown on the current page
   */
  const paginatedCategories = React.useMemo(() => {
    if (sortedCategories) {
      return paginate(sortedCategories, page)
    } else return []
  }, [page, sortedCategories])

  if (!userCan('view_categories')) return <Error401 />

  return (
    <>
      <Header title="Categories" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    {sortedCategories
                      ? `${sortedCategories.length} ${pluralize(
                          'category',
                          sortedCategories.length
                        )}`
                      : '0 categories'}
                  </div>
                  <div className="ml-3">
                    <Form.Control
                      size="sm"
                      type="text"
                      name="searchTerm"
                      value={searchTerm || ''}
                      className="search"
                      style={{ minWidth: '250px' }}
                      placeholder="Search by name or status"
                      onChange={({ target }) => {
                        setSearchTerm(target.value)
                        handleSearch(target.value)
                      }}
                      disabled={isLoading || !sortedCategories}
                    />
                  </div>
                </div>

                {userCan('create_category') && (
                  <div>
                    <Link to={route('category_create')}>
                      <Button size="sm">Create Category</Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {!isLoading && paginatedCategories.length ? (
                <>
                  <div className="table-wrapper padded">
                    <Table hover responsive>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className="sticky">Name</th>
                          <th>Status</th>
                          {/* <th>Products</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedCategories.map((category, index) => (
                          <tr key={category._id}>
                            <td className="align-middle">
                              {index + 1 + (page - 1) * 10}
                            </td>
                            <td className="align-middle">{category.name}</td>
                            <td className="align-middle">
                              <Badge
                                className="text-capitalize"
                                variant={
                                  category.status === 'active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {category.status}
                              </Badge>
                            </td>
                            {/* <td>
                            <Link
                              to={route('category_products', {
                                id: category._id,
                              })}
                            >
                              Products
                            </Link>
                          </td> */}
                            <td className="align-middle">
                              <Link
                                to={route('category_update', {
                                  id: category._id,
                                })}
                                className="action-link"
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>{' '}
                  <Pagination
                    className="my-4"
                    perPage={10}
                    totalItems={sortedCategories.length}
                    currentPage={page}
                    onChange={(page) => setPage(page)}
                  />
                </>
              ) : null}

              {!isLoading && !paginatedCategories.length ? (
                <div className="my-5">
                  <NotFound summary="No categories found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect(
  (state: IState) => ({
    user: state.user,
  }),
  { userCan }
)(Categories)
