import cache from '../utils/cache'
import { http } from '../utils/http'
import { ITarget } from './types/target'
import { chunk } from 'src/utils/filters'

class TargetService {
  fetchById(targetId: string): Promise<ITarget> {
    return new Promise((resolve, reject) => {
      const cacheKey = `target_${targetId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`target/read/?_id=${targetId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<ITarget[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `targets_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`target/read_by_manufacturer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<ITarget[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `targets_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`target/read_by_status`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(target: ITarget): Promise<string> {
    const formData = new FormData()

    Object.keys(target).forEach((key) => {
      formData.append(key, target[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('target/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  createBulk(targets: ITarget[], chunkSize = 125): Promise<any> {
    const chunks = chunk<ITarget>(targets, chunkSize)

    return new Promise((resolve) => {
      let current = 0
      let succeeded = 0
      let failed = 0

      for (const chunk of chunks) {
        const formData = new FormData()

        chunk.forEach((target) => {
          formData.append('tag[]', target.tag)
          formData.append('year[]', target.year.toString())
          formData.append('month[]', target.month.toString())
          formData.append('day[]', target.day.toString())
          formData.append('value[]', target.value.toString())
          formData.append('retailer_id[]', target.retailer_id)
          formData.append('retail_location_id[]', target.retail_location_id)
          formData.append('manufacturer_id[]', target.manufacturer_id)
        })

        http
          .post('target/create_bulk', formData)
          .then(() => {
            succeeded += chunk.length
          })
          .catch(() => {
            failed += 1 * chunk.length
          })
          .finally(() => {
            current++

            if (current === chunks.length) {
              cache.reset()
              resolve({ succeeded, failed })
            }
          })
      }
    })
  }

  update(target: ITarget): Promise<any> {
    const formData = new FormData()

    Object.keys(target).forEach((key) => {
      formData.append(key, target[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('target/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default TargetService
