import React from 'react'

import { connect } from 'react-redux'
import { Container, Card } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import ScheduleService from 'src/services/ScheduleService'
import { ISchedule } from 'src/services/types/schedule'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import ScheduleForm from 'src/components/Schedule/ScheduleForm'

const scheduleService = new ScheduleService()

interface IProps extends RouteComponentProps {
  match: any
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const ScheduleUpdate: React.FC<IProps> = ({
  match,
  history,
  user,
  userCan,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [schedule, setSchedule] = React.useState<ISchedule>()

  React.useEffect(() => {
    if (userCan('update_schedule')) {
      scheduleService
        .fetchById(match.params.id)
        .then((schedule) => setSchedule(schedule))
        .finally(() => setIsLoading(false))
    }
  }, [userCan, match.params.id])

  if (!userCan('update_schedule')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Schedule'
            : schedule
            ? `${schedule.retail_location.name}`
            : 'Schedule not found'
        }
        backText="Schedules"
        onBack={() => history.push(route('schedules'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Schedule Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              {!isLoading && schedule ? (
                <ScheduleForm
                  user={user}
                  type="update"
                  schedule={schedule}
                  updateSchedule={(updatedValues) => {
                    setSchedule({ ...schedule, ...updatedValues })
                  }}
                />
              ) : null}

              {!isLoading && !schedule ? (
                <div className="my-5">
                  <NotFound summary="Schedule not found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(ScheduleUpdate)
)
