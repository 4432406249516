import React from 'react'

import { useSelector } from 'react-redux'

import SearchSelect from './SearchSelect'
import useMounted from 'src/hooks/useMounted'

import CategoryService from 'src/services/CategoryService'
import { IState } from 'src/store'

const categoryService = new CategoryService()

interface IProps {
  /** Current value */
  value: any
  /** Callback on value change */
  onChange: (value: any) => void
  /** Other props */
  className?: string
  isMulti?: boolean
  isDisabled?: boolean
}

const CategorySelect: React.FC<IProps> = ({ value, onChange, ...props }) => {
  const isMounted = useMounted()
  const user = useSelector((state: IState) => state.user)

  const [categoryOptions, setCategoryOptions] = React.useState<any[]>()

  React.useEffect(() => {
    const fetchCategories = (): void => {
      categoryService
        .fetchByManufacturer(user.manufacturer_id)
        .then((categories: any) => {
          if (isMounted.current) {
            const tempCategories = categories
              .filter((cat) => cat.status === 'active')
              .map((cat) => ({
                value: cat.name,
                label: cat.name,
              }))

            setCategoryOptions([
              { value: '*', label: 'All Categories' },
              ...tempCategories,
            ])
          }
        })
    }

    fetchCategories()
  }, [user.manufacturer_id, isMounted])

  return (
    <SearchSelect
      size="sm"
      options={categoryOptions}
      value={value}
      placeholder="Select category"
      onChange={(selected) => onChange(selected)}
      isActive={value && value.value !== '*' ? true : false}
      isDisabled={!categoryOptions || !categoryOptions.length}
      {...props}
    />
  )
}

export default CategorySelect
