import React from 'react'

import { Formik, ErrorMessage } from 'formik'
import { Form, Row, Col, InputGroup, Button } from 'react-bootstrap'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SearchSelect from 'src/components/Form/SearchSelect'

import TargetService from 'src/services/TargetService'
import RetailerService from 'src/services/RetailerService'
import RetailLocationService from 'src/services/RetailLocationService'

import { route } from 'src/utils/routes'
import { months } from 'src/utils/date'
import { ITarget } from 'src/services/types/target'
import useMounted from 'src/hooks/useMounted'

const targetService = new TargetService()
const retailerService = new RetailerService()
const retailLocationService = new RetailLocationService()

interface IProps extends RouteComponentProps {
  user: any
  type?: 'create' | 'update'
  target?: ITarget
  updateTarget?: (updatedValues: any) => void
  history: any
}

const TargetForm: React.FC<IProps> = ({
  user,
  type,
  target,
  updateTarget,
  history,
}) => {
  const isMounted = useMounted()
  const { addToast } = useToasts()

  const [retailers, setRetailers] = React.useState<any[]>([])
  const [retailLocations, setRetailLocations] = React.useState<any[]>([])

  React.useEffect(() => {
    const fetchRetailers = (): void => {
      retailerService
        .fetchByManufacturer(user.manufacturer_id)
        .then((retailers) => {
          const activeRetailers = retailers
            .filter((retailer) => retailer.status === 'active')
            .map((retailer) => ({
              value: retailer._id,
              label: retailer.name,
            }))
          isMounted.current && setRetailers(activeRetailers)
        })
    }

    const fetchRetailLocations = (): void => {
      retailLocationService
        .fetchByManufacturer(user.manufacturer_id)
        .then((locations) => {
          const activeLocations = locations
            .filter((location) => location.status === 'active')
            .map((location) => ({
              value: location._id,
              label: location.name,
              retailer_id: location.retailer_id,
            }))
          isMounted.current && setRetailLocations(activeLocations)
        })
    }

    fetchRetailers()
    fetchRetailLocations()
  }, [user.manufacturer_id, isMounted])

  const formConfig = {
    initialValues: {
      retailer: target
        ? {
            value: target.retailer._id,
            label: target.retailer.name,
          }
        : null,
      retail_location: target
        ? {
            value: target.retail_location._id,
            label: target.retail_location.name,
          }
        : null,
      tag: target ? target.tag : 'SALES',
      year: target ? target.year : '2020',
      month: target ? target.month : '1',
      day: target ? target.day : 0,
      value: target ? target.value : '',
    },
    validationSchema: Yup.object({
      retailer: Yup.string().required('Retailer field is required.').nullable(),
      retail_location: Yup.string()
        .required('Retail store field is required.')
        .nullable(),
      year: Yup.number().required('Year field is required.'),
      month: Yup.number().required('Month field is required.'),
      value: Yup.number().required('Value field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        tag: values.tag,
        year: values.year,
        month: values.month,
        day: values.day,
        value: values.value,
        retailer_id: values.retailer.value,
        retail_location_id: values.retail_location.value,
        manufacturer_id: user.manufacturer_id,
      }

      if (type === 'update') {
        finalValues._id = target._id
      }

      targetService[type]({ ...finalValues })
        .then((targetId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Target successfully updated.', { appearance: 'success' })
            updateTarget({
              tag: values.tag,
              month: values.month,
              year: values.year,
            })
          } else {
            addToast('Target successfully created.', { appearance: 'success' })
            history.push(route('target_update', { id: targetId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        ...formik
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* Retailer */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Retailer *</Form.Label>
              <Form.Text className="text-muted">Retailer</Form.Text>
            </Col>
            <Col md={6}>
              <SearchSelect
                name="retailer"
                options={retailers}
                value={values.retailer}
                placeholder="Select retailer"
                onBlur={() => {
                  formik.setFieldTouched('retailer', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('retailer', selected)
                }}
                isInvalid={touched.retailer && errors.retailer ? true : false}
                isDisabled={!retailers.length}
              />
              <ErrorMessage
                name="retailer"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Location */}
          {/* List depends on retailer value */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Retail Store *</Form.Label>
              <Form.Text className="text-muted">
                Retailer physical location
              </Form.Text>
            </Col>
            <Col md={6}>
              <SearchSelect
                name="retail_location"
                options={
                  values.retailer && retailLocations
                    ? retailLocations.filter((location) => {
                        return location.retailer_id === values.retailer.value
                      })
                    : []
                }
                value={values.retail_location}
                placeholder="Select retail store"
                onBlur={() => {
                  formik.setFieldTouched('retail_location', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('retail_location', selected)
                }}
                isInvalid={
                  touched.retail_location && errors.retail_location
                    ? true
                    : false
                }
                isDisabled={!values.retailer}
              />
              <ErrorMessage
                name="retail_location"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Tag */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Tag *</Form.Label>
              <Form.Text className="text-muted">Sales or Sales In</Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                as="select"
                name="tag"
                value={values.tag}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.tag && errors.tag ? true : false}
              >
                <option value="SALES">Sales</option>
                <option value="SALES_IN">Sales In</option>
              </Form.Control>
              <ErrorMessage
                name="tag"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Target date */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Date *</Form.Label>
              <Form.Text className="text-muted">Year & month</Form.Text>
            </Col>
            <Col md={6}>
              <InputGroup>
                <Form.Control
                  as="select"
                  name="year"
                  value={values.year}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.year && errors.year ? true : false}
                >
                  <option value="">Select year</option>
                  <option value="2020">2020</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                </Form.Control>
                <Form.Control
                  as="select"
                  name="month"
                  value={values.month}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.month && errors.month ? true : false}
                >
                  <option value="">Select month</option>
                  {months.map((month, index) => (
                    <option key={index} value={index + 1}>
                      {month}
                    </option>
                  ))}
                </Form.Control>
              </InputGroup>
              <ErrorMessage
                name="month"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Value */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Value *</Form.Label>
              <Form.Text className="text-muted">Actual target (₦)</Form.Text>
            </Col>
            <Col md={6}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="currency">₦</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  name="value"
                  value={values.value}
                  type="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isInvalid={touched.value && errors.value ? true : false}
                />
              </InputGroup>
              <ErrorMessage
                name="value"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* {JSON.stringify(errors)} */}

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Target'
            ) : (
              'Update Target'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

TargetForm.defaultProps = {
  type: 'create',
}

export default withRouter(TargetForm)
