import React from 'react'

import { Nav, Navbar } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { BsInboxFill, BsChevronRight } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { colors } from 'src/config/theme'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import styles from './Header.module.scss'

interface IProps {
  title: string
  metaTitle?: string
  backText?: string
  onBack?: () => void
}

const Header: React.FC<IProps> = ({ title, metaTitle, backText, onBack }) => {
  const history = useHistory()

  const sidebarOpen = useSelector((state: IState) => state.config.sidebarOpen)
  const notifications = useSelector((state: IState) => state.notification)

  return (
    <>
      <header
        className={`${styles.header} ${
          !sidebarOpen ? styles.withMiniSidebar : ''
        }`}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{metaTitle ? metaTitle : title} - WeShelfie</title>
        </Helmet>
        <Navbar bg="white" expand="lg">
          <div className="d-flex align-items-center">
            <div
              className={`${styles.breadcrumb} ${
                onBack && backText ? styles.open : ''
              }`}
            >
              <h6 className={`${styles.title} ${styles.link}`} onClick={onBack}>
                {backText}
              </h6>
              <BsChevronRight color={colors.primaryGrey} size={14} />
            </div>

            <h6
              className={`${styles.title} ${
                onBack && backText ? styles.hasBack : ''
              }`}
            >
              {title}
            </h6>
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto d-flex align-items-center justify-content-end">
              <Nav.Link href="#" className="d-none d-md-block">
                <div
                  className={styles.notifications}
                  onClick={() => {
                    history.push(route('tickets'))
                  }}
                  title="Inbox"
                >
                  <BsInboxFill color={colors.primaryGrey} size={26} />
                  {!notifications.isLoading && (
                    <span className={styles.notificationsBadge}>
                      {notifications.openTickets.length}
                    </span>
                  )}
                </div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>

      <div className={styles.pad}></div>
    </>
  )
}

export default Header
