import React from 'react'

import { Formik, FieldArray, Field, ErrorMessage } from 'formik'
import { IoIosRemoveCircle, IoMdRadioButtonOff } from 'react-icons/io'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SurveyService from 'src/services/SurveyService'
import UploadService from 'src/services/UploadService'

import { colors } from 'src/config/theme'
import { ISurvey, ISurveyQuestion } from 'src/services/types/survey'

import ImageUploader from 'src/components/Image/ImageUploader'
import { BsPlus } from 'react-icons/bs'

const surveyService = new SurveyService()
const uploadService = new UploadService()

interface IProps extends RouteComponentProps {
  /** From react router */
  history: any
  /** Use from state.user */
  user: any
  /** Action type, defaults to 'create' */
  type?: 'create' | 'update'
  /** When action is 'update', we need a question */
  question?: ISurveyQuestion
  /** We'll need the survey details too */
  survey: ISurvey
  /** After the form has been successfully submitted */
  submitted: () => void
}

const SurveyQuestionForm: React.FC<IProps> = ({
  user,
  type,
  question,
  submitted,
  survey,
}) => {
  const { addToast } = useToasts()
  const _isMounted = React.useRef(false)

  React.useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const formConfig: any = {
    initialValues: {
      status: question ? question.status : 'active',
      type: question ? question.type : 'question',
      index: question
        ? question.index
        : survey.question_count
        ? survey.question_count + 1
        : 1,
      title: question ? question.title : '',
      choices: question ? question.choices : [],
      upload: null,
    },
    validationSchema: Yup.object({
      status: Yup.string().required('Status field is required.'),
      type: Yup.string().required('Type field is required.'),
      index: Yup.number().required('Number field is required.'),
      title: Yup.string()
        .required('Title field is required.')
        .min(3, 'Title must be at least 3 characters.'),
      choices: Yup.array().when('type', {
        is: 'multiple-choice',
        then: Yup.array()
          .of(
            Yup.object().shape({
              title: Yup.string().required('Option text is required'),
              index: Yup.number().required('Index is required'),
            })
          )
          .required('Options are is required.')
          .min(2, 'Options cannot be less than 2')
          .max(6, 'Options cannot be more than 6'),
        otherwise: Yup.array().nullable(),
      }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      let finalValues: any = {}

      const submitCallback = (uploadId: string): void => {
        if (type === 'update') {
          finalValues._id = question._id
        }

        finalValues = {
          ...finalValues,
          status: values.status,
          type: values.type,
          index: values.index,
          title: values.title,
          upload_id: uploadId,

          survey_id: survey._id,
          manufacturer_id: user.manufacturer_id,
        }

        const choices =
          values.type === 'multiple-choice' ? values.choices : null

        surveyService[`${type}Question`]({ ...finalValues }, choices)
          .then(() => {
            setSubmitting(false)
            // resetForm()

            addToast(`Question successfully ${type}d.`, {
              appearance: 'success',
            })
            submitted()
          })
          .catch((error) => {
            setSubmitting(false)
            addToast(error.message, { appearance: 'error' })
          })
      }

      if (values.upload) {
        // console.log(values.upload)
        uploadService
          .upload(values.upload)
          .then((uploadId) => submitCallback(uploadId))
          .catch(() =>
            submitCallback(type === 'create' ? '-' : question.upload_id)
          )
      } else {
        // console.log(type === 'create' ? '-' : question.upload_id)
        submitCallback(type === 'create' ? '-' : question.upload_id)
      }
    },
  }

  return (
    <Formik
      // enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        ...formik
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* Question title */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Question *</Form.Label>
              <Form.Text className="text-muted">Question details</Form.Text>
            </Col>
            <Col md={6}>
              {type === 'update' ? (
                <div className="d-flex mb-3">
                  <Field
                    as={Form.Check}
                    type="radio"
                    id="active"
                    name="status"
                    value="active"
                    label="Active"
                    checked={values.status === 'active'}
                    custom
                  />

                  <Field
                    as={Form.Check}
                    type="radio"
                    id="inactive"
                    name="status"
                    value="inactive"
                    label="Inactive"
                    checked={values.status === 'inactive'}
                    className="ml-3"
                    custom
                  />
                </div>
              ) : null}

              {/* Question image */}
              <div className="mb-3">
                <ImageUploader
                  type="survey"
                  uploadPath={
                    question && question.upload ? question.upload.path : null
                  }
                  onChange={(data) => formik.setFieldValue('upload', data)}
                />
              </div>

              <Form.Control
                name="title"
                value={values.title}
                type="text"
                placeholder="Enter question here"
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.title && errors.title ? true : false}
              />
              <ErrorMessage
                name="title"
                component="span"
                className="invalid-feedback"
              />

              {type === 'create' ? (
                <div className="d-flex mt-3">
                  <Field
                    as={Form.Check}
                    type="radio"
                    id="question"
                    name="type"
                    value="question"
                    label="Single answer"
                    checked={values.type === 'question'}
                    custom
                  />

                  <Field
                    as={Form.Check}
                    type="radio"
                    id="multiple-choice"
                    name="type"
                    value="multiple-choice"
                    label="Multiple choice"
                    checked={values.type === 'multiple-choice'}
                    className="ml-3"
                    custom
                  />
                </div>
              ) : null}

              {values.type === 'multiple-choice' ? (
                <div className="mt-3">
                  {typeof errors.choices === 'string' ? (
                    <span className="d-block invalid-feedback mb-2">
                      {errors.choices}
                    </span>
                  ) : null}

                  <FieldArray
                    name="choices"
                    render={(arrayHelpers) => (
                      <div>
                        {values.choices.map((option, index) => (
                          <div key={index} className="mb-1">
                            <div className="d-flex align-items-center">
                              <IoMdRadioButtonOff
                                color={colors.tertiaryGrey}
                                size={24}
                              />

                              <Field
                                as={Form.Control}
                                type="text"
                                name={`choices[${index}].title`}
                                placeholder={`Option ${index + 1}`}
                                size="sm"
                                className="ml-2"
                              />

                              {/* Remove option */}
                              {type === 'create' ? (
                                <span
                                  className="text-danger cursor-pointer ml-2"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <IoIosRemoveCircle size={24} />
                                </span>
                              ) : null}
                            </div>

                            {errors.choices &&
                            errors.choices.length &&
                            errors.choices[index] &&
                            errors.choices[index].title ? (
                              <span
                                style={{ marginLeft: '28px' }}
                                className="invalid-feedback"
                              >
                                {errors.choices[index].title}
                              </span>
                            ) : null}
                          </div>
                        ))}

                        {/* {JSON.stringify(errors.options)} */}

                        {type === 'create' && values.choices.length < 6 ? (
                          <Button
                            size="sm"
                            variant="outline-light"
                            className="mt-3"
                            onClick={() =>
                              arrayHelpers.push({
                                title: '',
                                index: values.choices.length + 1,
                                status: 'active',
                              })
                            }
                          >
                            <BsPlus size={20} /> Add option
                          </Button>
                        ) : null}
                      </div>
                    )}
                  />
                </div>
              ) : null}
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Add Question'
            ) : (
              'Update Question'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

SurveyQuestionForm.defaultProps = {
  type: 'create',
}

export default withRouter(SurveyQuestionForm)
