import React from 'react'

import { Container, Row, Col, Card } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import StaffForm from 'src/components/Staff/StaffForm'
import UserPasswordForm from 'src/components/User/UserPasswordForm'

import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'
import { IStaff } from 'src/services/types/staff'
import Error401 from 'src/pages/Error/401.page'

import StaffService from 'src/services/StaffService'
import { route } from 'src/utils/routes'

const staffService = new StaffService()

const StaffUpdate: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const { id: staffId } = useParams()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [staff, setStaff] = React.useState<IStaff>()

  React.useEffect(() => {
    const fetchStaff = (): void => {
      staffService
        .fetchById(staffId)
        .then((staff) => {
          isMounted.current && setStaff(staff)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('update_staff') && fetchStaff()
  }, [userCan, staffId, isMounted])

  if (!userCan('update_staff')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading ? 'Staff' : staff ? `${staff.name}` : 'Staff not found'
        }
        backText="Staff"
        onBack={() => history.push(route('staff'))}
      />
      <div className="page-container">
        <Container>
          {!isLoading && staff ? (
            <Row>
              <Col md={8}>
                <Card>
                  <Card.Header>
                    <Card.Title>Staff Details</Card.Title>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <StaffForm
                      type="update"
                      staff={staff}
                      updateStaff={(updatedValues) => {
                        setStaff({ ...staff, ...updatedValues })
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>Change Password</Card.Title>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <UserPasswordForm user={staff} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}

          {!isLoading && !staff ? (
            <div className="my-5">
              <NotFound
                title="Staff not found"
                summary="Check the URL and try again."
              />
            </div>
          ) : null}

          {isLoading && (
            <div className="d-flex justify-content-center py-5">
              <figure className="spinner primary" />
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default StaffUpdate
