import React from 'react'

import { useHistory } from 'react-router-dom'
import { Container, Card } from 'react-bootstrap'

import Header from 'src/components/Layout/Header/Header'
import TreeForm from 'src/components/Tree/TreeForm'

import usePermission from 'src/hooks/usePermission'
import useQueryParams from 'src/hooks/useQueryParams'

import Error401 from 'src/pages/Error/401.page'

import { route } from 'src/utils/routes'

const TreeCreate: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()
  const type = useQueryParams('type')

  if (!userCan('create_tree')) return <Error401 />

  return (
    <>
      <Header
        title={type === 'bulk' ? 'Upload Bulk' : 'New Location'}
        backText="Locations"
        onBack={() => history.push(route('trees'))}
      />
      <div className="page-container">
        <Container>
          <Card className={type === 'bulk' ? 'centered' : null}>
            <Card.Header>
              <Card.Title>
                {type === 'bulk' ? 'Upload File' : 'Location Details'}
              </Card.Title>

              {/* {type !== 'bulk' && userCan('create_tree') && (
                <div>
                  <Link to={`${route('tree_create')}?type=bulk`}>
                    <Button size="sm">Create Bulk</Button>
                  </Link>
                </div>
              )} */}
            </Card.Header>
            <Card.Body className="pt-0">
              {type !== 'bulk' && <TreeForm />}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default TreeCreate
