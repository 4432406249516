import React from 'react'

import { Link, useHistory } from 'react-router-dom'
import { Container, Card, Button } from 'react-bootstrap'

import Header from 'src/components/Layout/Header/Header'
import RetailLocationForm from 'src/components/RetailLocation/RetailLocationForm'
import RetailLocationsUpload from 'src/components/RetailLocation/RetailLocationsUpload'

import Error401 from 'src/pages/Error/401.page'

import usePermission from 'src/hooks/usePermission'
import useQueryParams from 'src/hooks/useQueryParams'

import { route } from 'src/utils/routes'

const RetailLocationCreate: React.FC = () => {
  const history = useHistory()
  const type = useQueryParams('type')
  const retailer = useQueryParams('retailer')

  const { userCan } = usePermission()

  if (!userCan('create_retail_location')) return <Error401 />

  return (
    <>
      <Header
        title={
          (retailer && retailer !== '') || type === 'bulk'
            ? 'Upload Bulk'
            : 'New Retail Store'
        }
        backText="Retail Stores"
        onBack={() => history.push(route('retail_locations'))}
      />
      <div className="page-container">
        <Container>
          <Card className={retailer && retailer !== '' ? 'centered' : null}>
            <Card.Header className="w-100 d-flex align-items-center">
              <Card.Title>
                {retailer && retailer !== ''
                  ? 'Upload File'
                  : 'Retail Store Details'}
              </Card.Title>

              {type !== 'bulk' && userCan('create_retail_location') && (
                <div>
                  <Link to={`${route('retail_location_create')}?type=bulk`}>
                    <Button size="sm">Create Bulk</Button>
                  </Link>
                </div>
              )}
            </Card.Header>
            <Card.Body className="pt-0">
              {retailer && retailer !== '' ? (
                <RetailLocationsUpload retailer={retailer} />
              ) : type === 'bulk' ? (
                <RetailLocationsUpload />
              ) : (
                <RetailLocationForm />
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default RetailLocationCreate
