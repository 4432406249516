import React, { useEffect, useState } from 'react'

import pluralize from 'pluralize'
import { Container, Table, Card, Button, Badge } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import SearchInput from 'src/components/Form/SearchInput'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'

import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'

import { IStaff } from 'src/services/types/staff'

import Error401 from 'src/pages/Error/401.page'

import StaffService from 'src/services/StaffService'

import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'

import { IState } from 'src/store'
import { trans } from 'src/utils/lang'

const staffService = new StaffService()

const Staff: React.FC<any> = () => {
  const history = useHistory()
  const user = useSelector((state: IState) => state.user)

  const isMounted = useMounted()
  const { userCan } = usePermission()

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [staff, setStaff] = useState<IStaff[]>()

  const [searchTerm, setSearchTerm] = useState<string>()
  const [page, setPage] = useState(1)

  useEffect(() => {
    const fetchStaff = (): void => {
      staffService
        .fetch()
        .then((staff) => {
          isMounted.current && setStaff(staff)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    if (userCan('view_staff')) fetchStaff()
  }, [isMounted, userCan, user.manufacturer_id])

  const filteredStaff = React.useMemo(() => {
    setPage(1)
    let filteredStaff = staff || []

    if (filteredStaff.length) {
      if (searchTerm) {
        filteredStaff = filteredStaff.filter((staff) => {
          return (
            basicSearch(staff.name, searchTerm) ||
            basicSearch(staff.status, searchTerm) ||
            basicSearch(staff.role, searchTerm)
          )
        })
      }
    }

    return filteredStaff
  }, [staff, searchTerm])

  const paginatedStaff = React.useMemo(() => {
    return filteredStaff ? paginate(filteredStaff, page) : []
  }, [page, filteredStaff])

  if (!userCan('view_staff')) return <Error401 />
  return (
    <>
      <Header title="Staff" />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div>
                    {filteredStaff
                      ? `${filteredStaff.length} ${pluralize(
                          'staff account',
                          filteredStaff.length
                        )}`
                      : '0 staff accounts'}
                  </div>
                  <div className="ml-3">
                    <SearchInput
                      placeholder="Search staff"
                      value={searchTerm}
                      onChange={setSearchTerm}
                      disabled={isLoading || !filteredStaff}
                    />
                  </div>
                </div>

                {userCan('create_staff') && (
                  <div>
                    <Link to={route('staff_signup')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create Staff
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {!isLoading && paginatedStaff.length ? (
                <>
                  <div className="table-wrapper padded">
                    <Table hover responsive>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th>Name</th>
                          <th>Organization</th>
                          <th>Role</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedStaff.map((staff, index) => (
                          <tr
                            key={staff._id}
                            className="cursor-pointer"
                            onClick={() =>
                              history.push(
                                route('staff_update', {
                                  id: staff._id,
                                })
                              )
                            }
                          >
                            <td className="align-middle text-center">
                              {index + 1 + (page - 1) * 10}
                            </td>
                            <td className="align-middle">{staff.name}</td>
                            <td className="align-middle">
                              {staff.manufacturer.name}
                            </td>
                            <td className="align-middle">
                              {trans(`general.${staff.role}`)}
                            </td>
                            <td className="align-middle">
                              <Badge
                                className="text-capitalize"
                                variant={
                                  staff.status === 'active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {staff.status}
                              </Badge>
                            </td>
                            <td className="align-middle">
                              <Link
                                to={route('staff_update', {
                                  id: staff._id,
                                })}
                                className="action-link"
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    className="my-4"
                    perPage={10}
                    totalItems={filteredStaff.length}
                    currentPage={page}
                    onChange={(page) => setPage(page)}
                  />
                </>
              ) : null}

              {!isLoading && !paginatedStaff.length ? (
                <div className="my-5">
                  <NotFound summary="No staff found" />
                </div>
              ) : null}

              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Staff
