import React from 'react'

import { Container, Row, Col, Card } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import ForumForm from 'src/components/Forum/ForumForm'

import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'
import { IForum } from 'src/services/types/forum'
import Error401 from 'src/pages/Error/401.page'
import { route } from 'src/utils/routes'

import ForumService from 'src/services/ForumService'

const forumService = new ForumService()

const ForumUpdate: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const { id: forumId } = useParams()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [forum, setForum] = React.useState<IForum>()

  React.useEffect(() => {
    const fetchForum = (): void => {
      forumService
        .fetchById(forumId)
        .then((forum) => {
          isMounted.current && setForum(forum)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    if (userCan('update_forum')) {
      fetchForum()
    }
  }, [userCan, forumId, isMounted])

  if (!userCan('update_forum')) return <Error401 />
  return (
    <>
      <Header
        title={
          isLoading ? 'Forum' : forum ? `${forum.name}` : 'Forum not found'
        }
        backText="Community"
        onBack={() => history.push(route('forums'))}
      />
      <div className="page-container">
        <Container>
          {!isLoading && forum ? (
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>Forum Details</Card.Title>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <ForumForm
                      type="update"
                      forum={forum}
                      updateForum={(updatedValues) => {
                        setForum({ ...forum, ...updatedValues })
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}

          {!isLoading && !forum ? (
            <div className="my-5">
              <NotFound
                title="Forum not found"
                summary="Check the URL and try again."
              />
            </div>
          ) : null}

          {isLoading && (
            <div className="d-flex justify-content-center py-5">
              <figure className="spinner primary" />
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default ForumUpdate
