import React from 'react'
import { SET_NOTIFICATIONS } from '../actionTypes'
import { ITicket } from 'src/services/types/ticket'

export interface INotifications {
  isLoading: boolean
  openTickets: ITicket[]
}

interface IAction {
  type: string
  payload: INotifications
}

const initialState: INotifications = {
  isLoading: true,
  openTickets: [],
}

const configReducer: React.Reducer<INotifications, IAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default configReducer
