import React from 'react'

import { Formik, Field, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import UserService from 'src/services/UserService'

const userService = new UserService()

interface IProps {
  user: any
  type?: 'update' | 'change'
}

const UserPasswordForm: React.FC<IProps> = ({ user, type }) => {
  const { addToast } = useToasts()

  const formConfig = {
    initialValues: {
      hash_old: '',
      hash_new: '',
      hash_new_confirm: '',
    },
    validationSchema: Yup.object({
      hash_old:
        type === 'change'
          ? Yup.string().required('Password field is required')
          : Yup.string(),
      hash_new: Yup.string().required('Password field is required'),
      hash_new_confirm: Yup.string()
        .required('Password confirmation is required')
        .oneOf([Yup.ref('hash_new'), null], 'Passwords must match'),
    }),
    onSubmit: (values, { resetForm, setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        user_id: user.user_id,
        hash_new: values.hash_new,
      }

      if (type === 'change') {
        finalValues.hash_old = values.hash_old
      }

      userService
        .updatePassword(type, { ...finalValues })
        .then(() => {
          setSubmitting(false)
          resetForm()

          addToast('Password successfully updated.', {
            appearance: 'success',
          })
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          {/* User new password */}
          <Form.Group as={Row}>
            <Col md={12} className="mb-2">
              <Form.Label>New Password *</Form.Label>
              <Form.Text className="text-muted">Set secure password</Form.Text>
            </Col>
            <Col md={12}>
              <Field
                as={Form.Control}
                name="hash_new"
                type="password"
                isInvalid={touched.hash_new && errors.hash_new ? true : false}
              />
              <ErrorMessage
                name="hash_new"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* User new password (Confirm) */}
          <Form.Group as={Row}>
            <Col md={12} className="mb-2">
              <Form.Label>Confirm Password *</Form.Label>
              <Form.Text className="text-muted">Confirm new password</Form.Text>
            </Col>
            <Col md={12}>
              <Field
                as={Form.Control}
                name="hash_new_confirm"
                type="password"
                isInvalid={
                  touched.hash_new_confirm && errors.hash_new_confirm
                    ? true
                    : false
                }
              />
              <ErrorMessage
                name="hash_new_confirm"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            size="sm"
            variant="success"
            type="submit"
            className="mt-2"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'change' ? (
              'Change Password'
            ) : (
              'Update Password'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

UserPasswordForm.defaultProps = {
  type: 'update',
}

export default UserPasswordForm
