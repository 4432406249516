import React from 'react'

import useFetch from '../useFetch'
import ManufacturerService from 'src/services/ManufacturerService'

const manufacturerService = new ManufacturerService()

const useFetchDashboard = (user, date): any => {
  React.useEffect(() => {
    console.log()
  })

  const year = date.getFullYear().toString() || ''
  const month = (date.getMonth() + 1).toString() || ''

  const sharedParams = [user.manufacturer_id, month, year]

  const [sales, isLoadingSales] = useFetch(manufacturerService, 'dashboard', [
    'sales',
    ...sharedParams,
  ])

  const [osa, isLoadingOsa] = useFetch(manufacturerService, 'dashboard', [
    'osa',
    ...sharedParams,
  ])

  const [los, isLoadingLos] = useFetch(manufacturerService, 'dashboard', [
    'los',
    ...sharedParams,
  ])

  const [sos, isLoadingSos] = useFetch(manufacturerService, 'dashboard', [
    'sos',
    ...sharedParams,
  ])

  const [effectiveness, isLoadingEffectiveness] = useFetch(
    manufacturerService,
    'dashboard',
    ['effectiveness', ...sharedParams]
  )

  return {
    sales: { isLoading: isLoadingSales, data: sales },
    osa: { isLoading: isLoadingOsa, data: osa },
    los: { isLoading: isLoadingLos, data: los },
    sos: { isLoading: isLoadingSos, data: sos },
    effectiveness: { isLoading: isLoadingEffectiveness, data: effectiveness },
  }
}

export default useFetchDashboard
