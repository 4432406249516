import React from 'react'

import { Container, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import Header from 'src/components/Layout/Header/Header'
import ChannelForm from 'src/components/Channel/ChannelForm'
import usePermission from 'src/hooks/usePermission'
import Error401 from 'src/pages/Error/401.page'
import { route } from 'src/utils/routes'

const ChannelCreate: React.FC = () => {
  const history = useHistory()
  const { userCan } = usePermission()

  if (!userCan('create_channel')) return <Error401 />

  return (
    <>
      <Header
        title="New Channel"
        backText="Channels"
        onBack={() => history.push(route('channels'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Channel Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <ChannelForm />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default ChannelCreate
