import Channels from 'src/pages/Channel/Channels.page'
import ChannelCreate from 'src/pages/Channel/ChannelCreate.page'
import ChannelUpdate from 'src/pages/Channel/ChannelUpdate.page'

import { IRoute } from '.'

const channelRoutes: IRoute[] = [
  {
    id: 'channels',
    path: '/channels',
    component: Channels,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'channel_create',
    path: '/channels/create',
    component: ChannelCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'channel_update',
    path: '/channels/:id/update',
    component: ChannelUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default channelRoutes
