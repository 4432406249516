import React from 'react'

import { Dropdown } from 'react-bootstrap'
import { BsChevronExpand, BsPersonFill, BsToggleOff } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

// import { asset } from 'src/utils/file'
import { trans } from 'src/utils/lang'
import { Role } from 'src/config/roles'
import { route } from 'src/utils/routes'

import { doLogout } from 'src/store/auth/authActions'

import styles from './SidebarHead.module.scss'

// import Logo from 'src/assets/images/shelfie-icon-rounded.png'

interface IProps {
  user: any
}

const SidebarHead: React.FC<IProps> = ({ user }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const CustomToggle: any = React.forwardRef<any, any>(
    ({ children, onClick }, ref) => {
      return (
        <div
          ref={ref}
          onClick={(event) => {
            event.preventDefault()
            onClick(event)
          }}
        >
          {children}
        </div>
      )
    }
  )
  CustomToggle.displayName = 'CustomToggle'

  const organization = React.useMemo(() => {
    return user.role === Role.RootAdmin ? 'Suplias' : user?.manufacturer?.name
  }, [user])

  // const avatar = React.useMemo(() => {
  //   return user.role !== Role.RootAdmin && user?.manufacturer?.upload
  //     ? asset(user?.manufacturer?.upload?.path)
  //     : Logo
  // }, [user])

  return (
    <div className={styles.header}>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <div className={styles.org} title={organization}>
            <span className={styles.avatar}>
              {organization?.charAt(0) || 'S'}
            </span>
            <span className={styles.name}>{organization}</span>
            <BsChevronExpand />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Header>
            <div className={styles.user}>
              <span className={`${styles.name} text-truncate`}>
                {user.name}
              </span>
              <span className={`${styles.email} text-truncate`}>
                {user.email}
              </span>
              <span className={`${styles.role} text-truncate`}>
                {trans(`general.${user.role}`)}
              </span>
            </div>
          </Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => {
              history.push(route('profile'))
            }}
          >
            <BsPersonFill size={16} /> View Profile
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="text-danger"
            onClick={() => dispatch(doLogout())}
          >
            <BsToggleOff size={16} /> Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default SidebarHead
