import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import SurveyForm from 'src/components/Survey/SurveyForm'
import SurveyQuestions from 'src/components/Survey/SurveyQuestions'
import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'
import { ISurvey } from 'src/services/types/survey'
import Error401 from 'src/pages/Error/401.page'
import SurveyService from 'src/services/SurveyService'
import { route } from 'src/utils/routes'

const surveyService = new SurveyService()

const SurveyUpdate: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const { id: surveyId } = useParams()

  const [survey, setSurvey] = React.useState<ISurvey>()
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    const fetchSurvey = (): void => {
      surveyService
        .fetchById(surveyId)
        .then((survey) => {
          isMounted.current && setSurvey(survey)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('update_category') && fetchSurvey()
  }, [userCan, surveyId, isMounted])

  if (!userCan('update_category')) return <Error401 />
  return (
    <>
      <Header
        title={
          isLoading ? 'Survey' : survey ? `${survey.title}` : 'Survey not found'
        }
        backText="Surveys"
        onBack={() => history.push(route('surveys'))}
      />
      <div className="page-container">
        <Container>
          <Row>
            <Col md={12} className="mb-3">
              <Card>
                <Card.Header>
                  <Card.Title>Survey Details</Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                  {isLoading && (
                    <div className="d-flex justify-content-center py-5">
                      <figure className="spinner primary" />
                    </div>
                  )}

                  {!isLoading && !survey ? (
                    <div className="my-5">
                      <NotFound summary="Survey not found" />
                    </div>
                  ) : null}

                  {!isLoading && survey ? (
                    <SurveyForm
                      type="update"
                      survey={survey}
                      updateSurvey={(updatedValues) => {
                        setSurvey({ ...survey, ...updatedValues })
                      }}
                    />
                  ) : null}
                </Card.Body>
              </Card>
            </Col>

            {!isLoading && survey ? (
              <Col md={12} className="mb-3">
                <SurveyQuestions survey={survey} />
              </Col>
            ) : null}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default SurveyUpdate
