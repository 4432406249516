import React from 'react'

import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Card } from 'react-bootstrap'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import StaffForm from 'src/components/Staff/StaffForm'

interface IProps extends RouteComponentProps {
  user: any
  userCan: (permission: string) => boolean
}

const StaffSignup: React.FC<IProps> = ({ userCan, history }) => {
  if (!userCan('create_staff')) return <Error401 />

  return (
    <>
      <Header
        title="New Staff"
        backText="Staff"
        onBack={() => history.push(route('staff'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Staff Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <StaffForm />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(StaffSignup)
)
