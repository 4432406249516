import React from 'react'
import { Card, Container } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { BsClockHistory } from 'react-icons/bs'
import { useSelector } from 'react-redux'

import ErringReportModal from './ErringReportModal'
import SectionBarChart from './SectionBarChart'
import SectionLoader from './SectionLoader'
import SectionPieChart from './SectionPieChart'

import useFetchDashboard from 'src/hooks/dashboard/useFetchDashboard'

import { IState } from 'src/store'
import { timestampToDate, timestampToTime } from 'src/utils/date'

import styles from './Dashboard.module.scss'

const ManufacturerDashboard: React.FC = () => {
  const user = useSelector((state: IState) => state.user)

  const [date, setDate] = React.useState(new Date())
  const [showErringReport, setShowErringReport] = React.useState<boolean>(false)

  const { sales, osa, sos, los, effectiveness } = useFetchDashboard(user, date)

  const isLoading = React.useMemo(() => {
    return (
      sales.isLoading ||
      osa.isLoading ||
      los.isLoading ||
      sos.isLoading ||
      effectiveness.isLoading
    )
  }, [sales, osa, los, sos, effectiveness])

  return (
    <>
      <div className={styles.filterBar}>
        <Container className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <BsClockHistory size={16} />
            <span className="mx-2">Showing for:</span>

            <DatePicker
              selected={date}
              onChange={(date) => {
                setDate(date)
              }}
              placeholderText="Set date"
              dateFormat="MMMM, yyyy"
              maxDate={new Date()}
              className="form-control form-control-sm"
              showMonthYearPicker
              disabled={isLoading}
            />
          </div>
        </Container>
      </div>

      <div className="page-container">
        <Container>
          {/* Sales */}
          {sales.isLoading ? (
            <SectionLoader />
          ) : sales.data ? (
            <>
              <Card className={`${styles.section} mb-3`}>
                <div className={styles.sectionLeft}>
                  <div>
                    <span className={styles.sectionHeader}>Sales</span>

                    {['achieved', 'sales', 'sales_in', 'targets'].map(
                      (accessor) => (
                        <div key={accessor} className={styles.sectionData}>
                          <h5
                            className="text-currency"
                            title={sales.data[accessor].value}
                          >
                            {sales.data[accessor].value}
                          </h5>
                          <span>{sales.data[accessor].title}</span>

                          {accessor === 'sales' &&
                          sales.data.sales.erring.total < 0 ? (
                            <div
                              className="text-small text-danger cursor-pointer"
                              onClick={() => setShowErringReport(true)}
                            >
                              [See Erring Stores]
                            </div>
                          ) : null}
                        </div>
                      )
                    )}
                  </div>

                  <span className={styles.lastUpdated}>
                    Updated: {timestampToDate(sales.data.timestamp)} @{' '}
                    {timestampToTime(sales.data.timestamp)}
                  </span>
                </div>

                <div className={styles.sectionCenter}>
                  <SectionPieChart
                    label="Sales (Region)"
                    data={sales.data.sales.regions}
                    valueFormat="currency"
                  />
                </div>

                <div className={styles.sectionRight}>
                  <SectionPieChart
                    label="Sales (Category)"
                    data={sales.data.sales.categories}
                    valueFormat="currency"
                  />
                </div>
              </Card>

              <ErringReportModal
                erringData={sales.data.sales.erring}
                erringReportOpen={showErringReport}
                closeErringReport={() => setShowErringReport(false)}
              />
            </>
          ) : null}

          {/* On Shelf Availability */}
          {osa.isLoading ? (
            <SectionLoader />
          ) : osa.data ? (
            <Card className={`${styles.section} mb-3`}>
              <div className={styles.sectionLeft}>
                <div>
                  <span
                    className={`${styles.sectionHeader} ${styles.colorOne}`}
                  >
                    On Shelf Availability
                  </span>

                  {['assortment'].map((accessor) => (
                    <div key={accessor} className={styles.sectionData}>
                      <h5
                        className="text-currency"
                        title={osa.data[accessor].value}
                      >
                        {osa.data[accessor].value}
                      </h5>
                      <span>{osa.data[accessor].title}</span>
                    </div>
                  ))}
                </div>

                <span className={styles.lastUpdated}>
                  Updated: {timestampToDate(osa.data.timestamp)} @{' '}
                  {timestampToTime(osa.data.timestamp)}
                </span>
              </div>

              <div className={styles.sectionCenter}>
                <SectionBarChart
                  label="On Shelf Availability (Region)"
                  name="On Shelf Availability (%)"
                  data={osa.data.assortment.regions}
                />
              </div>

              <div className={styles.sectionRight}>
                <SectionBarChart
                  label="On Shelf Availability (Category)"
                  name="On Shelf Availability (%)"
                  data={osa.data.assortment.categories}
                />
              </div>
            </Card>
          ) : null}

          {/* Share Of Shelf */}
          {sos.isLoading ? (
            <SectionLoader />
          ) : sos.data ? (
            <Card className={`${styles.section} mb-3`}>
              <div className={styles.sectionLeft}>
                <div>
                  <span
                    className={`${styles.sectionHeader} ${styles.colorThree}`}
                  >
                    Share Of Shelf
                  </span>

                  {['shelf_share'].map((accessor) => (
                    <div key={accessor} className={styles.sectionData}>
                      <h5
                        className="text-currency"
                        title={sos.data[accessor].value}
                      >
                        {sos.data[accessor].value}
                      </h5>
                      <span>{sos.data[accessor].title}</span>
                    </div>
                  ))}
                </div>

                <span className={styles.lastUpdated}>
                  Updated: {timestampToDate(sos.data.timestamp)} @{' '}
                  {timestampToTime(sos.data.timestamp)}
                </span>
              </div>

              <div className={styles.sectionCenter}>
                <SectionBarChart
                  label="Share Of Shelf (Region)"
                  name="Share Of Shelf (%)"
                  data={sos.data.shelf_share.regions}
                />
              </div>

              <div className={styles.sectionRight}>
                <SectionBarChart
                  label="Share Of Shelf (Category)"
                  name="Share Of Shelf (%)"
                  data={sos.data.shelf_share.categories}
                />
              </div>
            </Card>
          ) : null}

          {/* Location On Shelf */}
          {los.isLoading ? (
            <SectionLoader />
          ) : los.data ? (
            <Card className={`${styles.section} mb-3`}>
              <div className={styles.sectionLeft}>
                <div>
                  <span
                    className={`${styles.sectionHeader} ${styles.colorTwo}`}
                  >
                    Location On Shelf
                  </span>

                  {['eye_level'].map((accessor) => (
                    <div key={accessor} className={styles.sectionData}>
                      <h5
                        className="text-currency"
                        title={los.data[accessor].value}
                      >
                        {los.data[accessor].value}
                      </h5>
                      <span>{los.data[accessor].title}</span>
                    </div>
                  ))}
                </div>

                <span className={styles.lastUpdated}>
                  Updated: {timestampToDate(los.data.timestamp)} @{' '}
                  {timestampToTime(los.data.timestamp)}
                </span>
              </div>

              <div className={styles.sectionCenter}>
                <SectionBarChart
                  label="Location On Shelf (Region)"
                  name="Eye Level (%)"
                  data={los.data.eye_level.regions}
                />
              </div>

              <div className={styles.sectionRight}>
                <SectionBarChart
                  label="Location On Shelf (Category)"
                  name="Eye Level (%)"
                  data={los.data.eye_level.categories}
                />
              </div>
            </Card>
          ) : null}

          {/* Effectiveness */}
          {effectiveness.isLoading ? (
            <SectionLoader />
          ) : effectiveness.data ? (
            <Card className={`${styles.section} mb-3`}>
              <div className={styles.sectionLeft}>
                <div>
                  <span
                    className={`${styles.sectionHeader} ${styles.colorFour}`}
                  >
                    Effectiveness
                  </span>
                  <div className={styles.sectionData}>
                    <h5
                      className="text-currency"
                      title={effectiveness.data.attendance.value}
                    >
                      {effectiveness.data.attendance.value}
                    </h5>
                    <span>{effectiveness.data.attendance.title}</span>
                  </div>
                </div>

                <span className={styles.lastUpdated}>
                  Updated: {timestampToDate(effectiveness.data.timestamp)} @{' '}
                  {timestampToTime(effectiveness.data.timestamp)}
                </span>
              </div>

              <div className={styles.sectionCenter}>
                <SectionBarChart
                  label="Merchandiser Effectiveness (Region)"
                  name="Merchandiser Effectiveness (%)"
                  data={effectiveness.data.attendance.regions}
                />
              </div>

              <div className={styles.sectionRight}>
                <SectionBarChart
                  label="Merchandiser Effectiveness (State)"
                  name="Merchandiser Effectiveness (%)"
                  data={effectiveness.data.attendance.states}
                />
              </div>
            </Card>
          ) : null}
        </Container>
      </div>
    </>
  )
}

export default ManufacturerDashboard
