import Trees from 'src/pages/Tree/Trees.page'
import TreeCreate from 'src/pages/Tree/TreeCreate.page'
import TreeUpdate from 'src/pages/Tree/TreeUpdate.page'
import { IRoute } from '.'

const treeRoutes: IRoute[] = [
  {
    id: 'trees',
    path: '/trees',
    component: Trees,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'tree_create',
    path: '/trees/create',
    component: TreeCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'tree_update',
    path: '/trees/:id/update',
    component: TreeUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default treeRoutes
