import React from 'react'

import { Container, Row, Col, Card } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import Header from 'src/components/Layout/Header/Header'
import RetailLocationForm from 'src/components/RetailLocation/RetailLocationForm'
import RetailLocationAddress from 'src/components/RetailLocation/RetaiLocationAddress'
import RetailLocationBranch from 'src/components/RetailLocation/RetailLocationState'

import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'

import { IRetailLocation } from 'src/services/types/retailLocation'
import Error401 from 'src/pages/Error/401.page'

import RetailLocationService from 'src/services/RetailLocationService'
import { route } from 'src/utils/routes'

const retailLocationService = new RetailLocationService()

const RetailLocationUpdate: React.FC = () => {
  const history = useHistory()
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const { id: retailLocationId } = useParams()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [retailLocation, setRetailLocation] = React.useState<IRetailLocation>()

  React.useEffect(() => {
    const fetchRetailLocation = (): void => {
      retailLocationService
        .fetchById(retailLocationId)
        .then((location) => {
          isMounted.current && setRetailLocation(location)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('update_retail_location') && fetchRetailLocation()
  }, [userCan, retailLocationId, isMounted])

  if (!userCan('update_retail_location')) return <Error401 />

  return (
    <>
      <Header
        title={
          isLoading
            ? 'Retail Store'
            : retailLocation
            ? `${retailLocation.name}`
            : 'Retail store not found'
        }
        backText="Retail Stores"
        onBack={() => history.push(route('retail_locations'))}
      />
      <div className="page-container">
        <Container>
          <Row>
            <Col md={8}>
              <Card className="sticky-top">
                <Card.Header>
                  <Card.Title>Retail Store Details</Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                  {!isLoading && retailLocation ? (
                    <RetailLocationForm
                      type="update"
                      retailLocation={retailLocation}
                      updateRetailLocation={(updatedValues) => {
                        setRetailLocation({
                          ...retailLocation,
                          ...updatedValues,
                        })
                      }}
                    />
                  ) : null}

                  {!isLoading && !retailLocation ? (
                    <div className="my-5">
                      <NotFound summary="Retail store not found" />
                    </div>
                  ) : null}

                  {isLoading && (
                    <div className="d-flex justify-content-center py-5">
                      <figure className="spinner primary" />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mt-3 mt-md-0">
                <Card.Header>
                  <Card.Title>Retail Store Address</Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                  {!isLoading && retailLocation ? (
                    <RetailLocationAddress
                      retailLocation={retailLocation}
                      updateRetailLocation={(updatedValues) => {
                        setRetailLocation({
                          ...retailLocation,
                          ...updatedValues,
                        })
                      }}
                    />
                  ) : null}

                  {isLoading && (
                    <div className="d-flex justify-content-center py-5">
                      <figure className="spinner primary" />
                    </div>
                  )}
                </Card.Body>
              </Card>

              <Card className="mt-3">
                <Card.Header>
                  <Card.Title>Retail Store State</Card.Title>
                </Card.Header>
                <Card.Body className="pt-0">
                  {isLoading && (
                    <div className="d-flex justify-content-center py-5">
                      <figure className="spinner primary" />
                    </div>
                  )}

                  {!isLoading && retailLocation ? (
                    <RetailLocationBranch retailLocation={retailLocation} />
                  ) : null}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default RetailLocationUpdate
