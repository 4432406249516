import RetailLocations from 'src/pages/RetailLocation/RetailLocations.page'
import RetailLocationCreate from 'src/pages/RetailLocation/RetailLocationCreate.page'
import RetailLocationUpdate from 'src/pages/RetailLocation/RetailLocationUpdate.page'

import { IRoute } from '.'

const retailLocationRoutes: IRoute[] = [
  {
    id: 'retail_locations',
    path: '/retail-locations',
    component: RetailLocations,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'retail_location_create',
    path: '/retail-locations/create',
    component: RetailLocationCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'retail_location_update',
    path: '/retail-locations/:id/update',
    component: RetailLocationUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default retailLocationRoutes
