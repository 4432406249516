import cache from '../utils/cache'
import { http } from '../utils/http'
import { IUser } from './types/user'
import { permissions } from '../config/permissions'

class UserService {
  /**
   * Filter out user permissions base on user role.
   * Map to retain only permisison id.
   */
  _setPermissions(userRole: string): string[] {
    const userPermissions = permissions
      .filter((permission: any) => {
        return permission.roles.includes(userRole)
      })
      .map((permission: any) => permission.id)

    return userPermissions
  }

  doLogin(credentials: {
    email: string
    hash: string
    remember: string
  }): Promise<IUser> {
    const formData = new FormData()
    formData.append('email', credentials.email)
    formData.append('hash', credentials.hash)
    formData.append('remember', credentials.remember)

    return new Promise((resolve, reject) => {
      try {
        http.post('administrator/login_delegate', formData).then(({ data }) => {
          if (data.code === 200) {
            const { token } = data.data
            localStorage.setItem('token', JSON.stringify(token))

            const user = data.data.profile
            const role = data.data.role
            const permissions = this._setPermissions(role)

            resolve({ ...user, role, permissions, token })
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unepected error occured!' })
        throw error
      }
    })
  }

  /**
   * Revoke user auth token and logout
   */
  doLogout(): Promise<IUser> {
    return new Promise((resolve) => {
      http.get(`auth/revoke`).finally(() => {
        cache.reset()
        resolve()
      })
    })
  }

  /**
   * Fetch user (with token) and assign user permissions
   */
  fetchUser(token: string): Promise<IUser> {
    return new Promise((resolve, reject) => {
      try {
        http
          .get(`administrator/read_delegate?token=${token}`)
          .then(async ({ data }) => {
            if (data.code === 200) {
              const user = data.data.profile
              const role = data.data.role
              const permissions = this._setPermissions(role)

              resolve({ ...user, role, permissions })
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unepected error occured!' })
        throw error
      }
    })
  }

  /**
   * Change user password.
   * Type 'update' doese not require old password,
   * 'change' requires ir
   *
   * @param type
   * @param credentials
   */
  updatePassword(type, credentials: any): Promise<IUser> {
    const formData = new FormData()
    formData.append('_id', credentials.user_id)

    if (type === 'update') {
      formData.append('hash', credentials.hash_new)
    } else if (type === 'change') {
      formData.append('hash_old', credentials.hash_old)
      formData.append('hash_new', credentials.hash_new)
    }

    return new Promise((resolve, reject) => {
      try {
        http.post(`user/${type}_auth`, formData).then(({ data }) => {
          if (data.code === 200) resolve()
          else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unepected error occured!' })
        throw error
      }
    })
  }
}

export default UserService
