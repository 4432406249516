import React from 'react'

import { Card, Container, Row, Col } from 'react-bootstrap'
import { BsArrowUpRight } from 'react-icons/bs'
import { useSelector } from 'react-redux'

import NotFound from 'src/components/Error/NotFound'
import ForumImage from 'src/components/Forum/ForumImage/ForumImage'

import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'
import { IForum, IForumImage } from 'src/services/types/forum'

import { timestampToDate } from 'src/utils/date'
import { asset } from 'src/utils/file'
import ForumService from 'src/services/ForumService'
import { IState } from 'src/store'
import { paginate } from 'src/utils/filters'
import Pagination from '../Pagination/Pagination'
import useFetch from 'src/hooks/useFetch'
import ForumFilters from './ForumFilters'
import Spinner from '../Core/Spinner/Spinner'

const forumService = new ForumService()

const ForumsImages: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()

  const user = useSelector((state: IState) => state.user)

  const [forum, setForum] = React.useState<IForum>()
  const [forumImages, setForumImages] = React.useState<IForumImage[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const [filters, setFilters] = React.useState<any>({
    group: null,
    store: null,
    category: null,
    from_timestmap: null,
    to_timestamp: null,
    page: 1,
    perPage: 12,
  })

  // const [page, setPage] = React.useState<number>(1)
  // const [perPage, setPerPage] = React.useState<number>(10)

  const [forums] = useFetch<IForum[]>(
    forumService,
    'fetchByManufacturer',
    [user.manufacturer_id],
    userCan('view_forums')
  )

  React.useEffect(() => {
    forums?.length && setForum(forums[0])
  }, [forums])

  const fetchImages = (): void => {
    setIsLoading(true)

    const params = {
      forum_id: forum?._id,
      retail_location_id: filters.store?.value || '*',
      category_name: filters.category?.value || '*',
      group: filters.group?.value || '*',
      from_timestamp: filters.from_timestamp,
      to_timestamp: filters.to_timestamp,
    }

    forumService
      .fetchImagesByParams(params)
      .then((images: IForumImage[]) => {
        isMounted.current && setForumImages(images)
      })
      .finally(() => {
        isMounted.current && setIsLoading(false)
      })
  }

  const paginatedImages = React.useMemo(() => {
    return forumImages
      ? paginate(forumImages, filters.page, filters.perPage)
      : []
  }, [forumImages, filters.page, filters.perPage])

  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <ForumFilters
          filters={filters}
          setFilters={setFilters}
          onChange={fetchImages}
          isLoading={isLoading}
        />
      </Card.Header>
      <Card.Body>
        {isLoading && (
          <div className="d-flex justify-content-center py-5">
            <Spinner size="sm" />
          </div>
        )}

        {!isLoading && !forumImages.length ? (
          <div className="my-5">
            <NotFound summary="No community images found for this query" />
          </div>
        ) : null}

        {!isLoading && forumImages.length ? (
          <Container>
            <Row>
              {paginatedImages.map((image, index) => (
                <Col key={index} md={4} lg={3}>
                  <ForumImage src={asset(image.upload.path)} title={image.name}>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="font-weight-bold">
                        {image?.merchandiser?.retail_location.name || '-'}
                      </span>

                      <a
                        href={asset(image.upload.path)}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <BsArrowUpRight size={18} />
                      </a>
                    </div>
                    <span className="text-muted small">
                      {timestampToDate(image.timestamp)}
                    </span>

                    {/* Stats */}
                    {/* <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <IoMdThumbsUp size={20} />
                        <span>{image.stats.like}</span>
                      </div>

                      <div className="d-flex align-items-center ml-2">
                        <IoMdThumbsDown size={20} />
                        <span>{image.stats.like}</span>
                      </div>
                    </div> */}
                  </ForumImage>
                </Col>
              ))}
            </Row>
          </Container>
        ) : null}

        <Pagination
          className="mt-4"
          perPage={filters.perPage}
          totalItems={forumImages.length}
          currentPage={filters.page}
          onChange={(page) => setFilters({ ...filters, page })}
        />
      </Card.Body>
    </Card>
  )
}

export default ForumsImages
