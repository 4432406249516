import { IRetailer } from './types/retailer'
import BaseService from './BaseService'

class RetailerService extends BaseService {
  constructor() {
    super('retailer')
  }

  fetch(): Promise<IRetailer[]> {
    return new Promise((resolve, reject) => {
      super
        .fetch()
        .then((retailers) => {
          const tempRetailers = retailers.map((retailer: any) => {
            retailer.store_count = 0

            if (retailer.retail_locations) {
              retailer.store_count = retailer.retail_locations.length
            }

            return retailer
          })

          resolve(tempRetailers)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  fetchByManufacturer(mftrId: string): Promise<IRetailer[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `retailers_${mftrId}`
      const cachedData = this.cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http
            .post('retailer/read_by_manufacturer', formData)
            .then(({ data }) => {
              if (data.code === 200) {
                const retailers = data.data.map((retailer: any) => {
                  retailer.store_count = 0

                  if (retailer.retail_locations) {
                    retailer.store_count = retailer.retail_locations.length
                  }

                  return retailer
                })

                this.cache.set(cacheKey, retailers)
                resolve(retailers)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  createBulk(retailers: IRetailer[]): Promise<any> {
    const formData = new FormData()

    retailers.forEach((retailer) => {
      formData.append('name[]', retailer.name)
      formData.append('status[]', retailer.status)
      formData.append('manufacturer_id[]', retailer.manufacturer_id)
    })

    return new Promise((resolve, reject) => {
      try {
        this.http.post('retailer/create_bulk', formData).then(({ data }) => {
          if (data.code === 200) {
            this.cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default RetailerService
