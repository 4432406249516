import React from 'react'

import { Formik, ErrorMessage, Field } from 'formik'
import { Form, Row, Col, Button, InputGroup } from 'react-bootstrap'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SearchSelect from 'src/components/Form/SearchSelect'
import { Role } from 'src/config/roles'
import useMounted from 'src/hooks/useMounted'
import { IStaff } from 'src/services/types/staff'
import StaffService from 'src/services/StaffService'
import ManufacturerService from 'src/services/ManufacturerService'
import { route } from 'src/utils/routes'
import { trans } from 'src/utils/lang'

const staffService = new StaffService()
const manufacturerService = new ManufacturerService()

interface IProps extends RouteComponentProps {
  type?: 'signup' | 'update' | 'view'
  staff?: any
  updateStaff?: (updatedValues: IStaff) => void
}

const StaffForm: React.FC<IProps> = ({ type, staff, updateStaff }) => {
  const history = useHistory()
  const isMounted = useMounted()
  const { addToast } = useToasts()

  // manufacturers
  const [mftrs, setMftrs] = React.useState<any[]>()

  React.useEffect(() => {
    const fetchManufacturers = (): void => {
      manufacturerService.fetch().then((mftrs) => {
        const tempMftrs = mftrs.map((mftr) => ({
          value: mftr._id,
          label: mftr.name,
        }))
        isMounted.current && setMftrs(tempMftrs)
      })
    }

    fetchManufacturers()
  }, [isMounted])

  const formConfig = {
    initialValues: {
      name: staff ? staff.name : '',
      phone: staff ? staff.phone : '',
      email: staff ? staff.email : '',
      status: staff ? staff.status : 'active',
      role: staff ? staff.role : '',
      manufacturer: staff
        ? {
            value: staff.manufacturer._id,
            label: staff.manufacturer.name,
          }
        : '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Store name field is required.')
        .min(3, 'Store name must be at least 3 characters.'),
      phone: Yup.string()
        .required('Phone number field is required.')
        .matches(
          /^[0][7-9][0-1][0-9]{8}$/,
          'Phone number must be valid. Ex. 08174381261'
        ),
      email: Yup.string()
        .required('Email field is required.')
        .email('Email must be valid.'),
      role: Yup.string().required('Role field is required.'),
      status: Yup.string().required('Status field is required.'),
      manufacturer: Yup.string().required('Organization field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        status: values.status,
        role: values.role,
        manufacturer_id: values.manufacturer.value,
      }

      if (staff) {
        finalValues._id = staff._id
        finalValues.user_id = staff.user_id
      }

      staffService[type](finalValues)
        .then((staff) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Staff successfully updated.', { appearance: 'success' })
            updateStaff({ ...finalValues })
          } else {
            addToast('Staff successfully created.', { appearance: 'success' })
            history.push(route('staff_update', { id: staff._id }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          {/* Manufacturer */}
          {type === 'signup' ? (
            <>
              <Form.Group as={Row}>
                <Col md={3} className="mb-2 mb-md-0">
                  <Form.Label>Organization *</Form.Label>
                  <Form.Text className="text-muted">
                    Staff organization
                  </Form.Text>
                </Col>
                <Col md={6}>
                  <SearchSelect
                    name="manufacturer"
                    options={mftrs}
                    value={values.manufacturer}
                    placeholder="Select organization"
                    onBlur={() => {
                      formik.setFieldTouched('manufacturer', true)
                    }}
                    onChange={(selected) => {
                      formik.setFieldValue('manufacturer', selected)
                    }}
                    isInvalid={
                      touched.manufacturer && errors.manufacturer ? true : false
                    }
                    isDisabled={!mftrs || !mftrs.length}
                  />
                  <ErrorMessage
                    name="manufacturer"
                    component="span"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <hr />
            </>
          ) : null}

          {/* Staff status */}
          {type !== 'view' ? (
            <>
              <Form.Group as={Row} className="align-items-center">
                <Col md={type === 'signup' ? 3 : 4} className="mb-2 mb-md-0">
                  <Form.Label>Status *</Form.Label>
                </Col>
                <Col md={type === 'signup' ? 6 : 8}>
                  <div className="d-flex">
                    <Field
                      as={Form.Check}
                      type="radio"
                      id="active"
                      name="status"
                      value="active"
                      label="Active"
                      checked={values.status === 'active'}
                      custom
                    />

                    <Field
                      as={Form.Check}
                      type="radio"
                      id="inactive"
                      name="status"
                      value="inactive"
                      label="Inactive"
                      checked={values.status === 'inactive'}
                      className="ml-3"
                      custom
                    />
                  </div>
                </Col>
              </Form.Group>
              <hr />
            </>
          ) : null}

          {/* Staff phone */}
          <Form.Group as={Row}>
            <Col md={type === 'signup' ? 3 : 4} className="mb-2 mb-md-0">
              <Form.Label>Phone *</Form.Label>
              <Form.Text className="text-muted">{`Staff's phone number`}</Form.Text>
            </Col>
            <Col md={type === 'signup' ? 6 : 8}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="code">+234</InputGroup.Text>
                </InputGroup.Prepend>
                <Field
                  as={Form.Control}
                  name="phone"
                  type="text"
                  isInvalid={touched.phone && errors.phone ? true : false}
                  readOnly={type === 'update' || type === 'view'}
                />
              </InputGroup>
              <ErrorMessage
                name="phone"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Staff email */}
          <Form.Group as={Row}>
            <Col md={type === 'signup' ? 3 : 4} className="mb-2 mb-md-0">
              <Form.Label>Email *</Form.Label>
              <Form.Text className="text-muted">
                {`Staff email address`}
              </Form.Text>
            </Col>
            <Col md={type === 'signup' ? 6 : 8}>
              <Field
                as={Form.Control}
                name="email"
                type="text"
                isInvalid={touched.email && errors.email ? true : false}
                readOnly={type === 'update' || type === 'view'}
              />
              <ErrorMessage
                name="email"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Staff name */}
          <Form.Group as={Row}>
            <Col md={type === 'signup' ? 3 : 4} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">Staff full name</Form.Text>
            </Col>
            <Col md={type === 'signup' ? 6 : 8}>
              <Field
                as={Form.Control}
                name="name"
                type="text"
                isInvalid={touched.name && errors.name ? true : false}
                readOnly={type === 'view'}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Staff role */}
          <Form.Group as={Row}>
            <Col md={type === 'signup' ? 3 : 4} className="mb-2 mb-md-0">
              <Form.Label>Role *</Form.Label>
              <Form.Text className="text-muted">Staff role</Form.Text>
            </Col>
            <Col md={type === 'signup' ? 6 : 8}>
              <Form.Control
                as="select"
                name="role"
                value={values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={touched.name && errors.name ? true : false}
                readOnly={type === 'view'}
              >
                <option value="">Select role</option>
                <option value={Role.Admin}>
                  {trans(`general.${Role.Admin}`)}
                </option>
                <option value={Role.Sales}>
                  {trans(`general.${Role.Sales}`)}
                </option>
                <option value={Role.Client}>
                  {trans(`general.${Role.Client}`)}
                </option>
              </Form.Control>
              <ErrorMessage
                name="role"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {type !== 'view' ? (
            <Button
              type="submit"
              variant="success"
              className="mt-4"
              disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            >
              {formik.isSubmitting ? (
                <figure className="spinner button white" />
              ) : type === 'signup' ? (
                'Create Staff'
              ) : (
                'Update Staff'
              )}
            </Button>
          ) : null}
        </Form>
      )}
    </Formik>
  )
}

StaffForm.defaultProps = {
  type: 'signup',
}

export default withRouter(StaffForm)
