import React from 'react'

import { Formik, Field, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import ImageUploader from 'src/components/Image/ImageUploader'

import { IManufacturer } from 'src/services/types/manufacturer'

import ManufacturerService from 'src/services/ManufacturerService'
import UploadService from 'src/services/UploadService'

import { route } from 'src/utils/routes'

const manufacturerService = new ManufacturerService()
const uploadService = new UploadService()

interface IProps extends RouteComponentProps {
  type?: string
  manufacturer?: IManufacturer
  updateManufacturer?: (updatedValues: IManufacturer) => void
}

const ManufacturerForm: React.FC<IProps> = ({
  type,
  manufacturer,
  updateManufacturer,
}) => {
  const history = useHistory()
  const { addToast } = useToasts()

  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  const formConfig = {
    initialValues: {
      name: manufacturer?.name || '',
      status: manufacturer?.status || 'active',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        name: values.name,
        status: values.status,
      }

      if (type === 'update') {
        finalValues._id = manufacturer._id
        finalValues.upload_id = manufacturer.upload_id
      }

      manufacturerService[type]({ ...finalValues })
        .then((manufacturerId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Manufacturer successfully updated.', {
              appearance: 'success',
            })
            updateManufacturer(finalValues)
          } else {
            addToast('Manufacturer successfully created.', {
              appearance: 'success',
            })
            history.push(route('manufacturer_update', { id: manufacturerId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const doUpload = (uploadData): Promise<any> => {
    return new Promise((resolve) => {
      uploadService
        .upload(uploadData, `manufacturer_${manufacturer._id}`)
        .then((uploadId) => {
          const updatedManufacturer = {
            _id: manufacturer._id,
            name: manufacturer.name,
            status: manufacturer.status,
            upload_id: uploadId,
          }

          manufacturerService
            .update(updatedManufacturer)
            .then(() => {
              setIsUploading(false)

              updateManufacturer(updatedManufacturer)
              addToast('Image upload successful.', {
                appearance: 'success',
              })
              updateManufacturer(updatedManufacturer)
              resolve()
            })
            .catch((error) => {
              setIsUploading(false)
              addToast(error.message, { appearance: 'error' })
              resolve()
            })
        })
        .catch((error) => {
          setIsUploading(false)
          addToast(error.message, { appearance: 'error' })
          resolve()
        })
    })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          {/* Manufacturer status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {type === 'update' && manufacturer ? (
            <>
              {/* Image */}
              <Form.Group as={Row}>
                <Col md={3} className="mb-2 mb-md-0">
                  <Form.Label>Image</Form.Label>
                  <Form.Text className="text-muted">
                    Organization logo
                  </Form.Text>
                </Col>
                <Col md={6}>
                  <ImageUploader
                    uploadPath={
                      manufacturer.upload ? manufacturer.upload.path : null
                    }
                    doUpload={doUpload}
                  />
                </Col>
              </Form.Group>
              <hr />
            </>
          ) : null}

          {/* Manufacturer name */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">Name of Organization</Form.Text>
            </Col>
            <Col md={6}>
              <Field
                as={Form.Control}
                name="name"
                type="text"
                isInvalid={touched.name && errors.name ? true : false}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={
              formik.isSubmitting ||
              !formik.dirty ||
              !formik.isValid ||
              isUploading
            }
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Organization'
            ) : (
              'Update Organization'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

ManufacturerForm.defaultProps = {
  type: 'create',
}

export default withRouter(ManufacturerForm)
