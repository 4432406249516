/**
 * Helper functions for date
 */

import { Period } from 'src/services/types/schedule'

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

/**
 *
 * @param type - Period Type
 * @param quantity - Period Quantity
 */
export const computeTTL = (type: Period, quantity = 1): string => {
  switch (type) {
    case Period.TwiceWeekly:
      return (302400 * quantity).toString()
    case Period.Weekly:
      return (604800 * quantity).toString()
    case Period.BiWeekly:
      return (1209600 * quantity).toString()
    case Period.Monthly:
      return (2592000 * quantity).toString()
    case Period.Quarterly:
      return (7776000 * quantity).toString()
    default:
      return (86400 * quantity).toString()
  }
}

/**
 *
 * @param period - Period
 */
export const undoComputeTTL = (period: number): Period => {
  switch (period) {
    case 302400:
      return Period.TwiceWeekly
    case 604800:
      return Period.Weekly
    case 1209600:
      return Period.BiWeekly
    case 2592000:
      return Period.Monthly
    case 7776000:
      return Period.Quarterly
    default:
      return Period.Daily
  }
}

export const timestampToDate = (timestamp: any, onlyMonthYear = false): any => {
  if (!timestamp) return

  const date = new Date(timestamp * 1000)
  const year = date.getFullYear()
  const month = months[date.getMonth()]
  const day = date.getDate()
  const time = onlyMonthYear ? `${month} ${year}` : `${month} ${day}, ${year}`

  return time
}

export const timestampToTime = (timestamp: any, format = '12h'): string => {
  if (!timestamp) return

  const date = new Date(timestamp * 1000)
  let hours = date.getHours()
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  if (format === '12h') {
    const ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours || 12

    return `${hours}:${minutes}${ampm}`
  }

  return `${hours}:${minutes}:${seconds}`
}

/**
 * Return date in format DDMMYYYY for excel
 * export filename
 *
 * @param date
 */
export const dateForFilename = (date): string => {
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()

  return `${day}${month}${year}`
}
