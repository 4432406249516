import React from 'react'

const useRefineChartData = (data, accessor, showNegatives = true): any => {
  const refinedData = React.useMemo(() => {
    let refined = []

    if (data && typeof data === 'object') {
      refined = Object.values(data).map((item: any) => ({
        name: item.name,
        value: Number(item[accessor].toFixed(2)),
      }))

      /** Filter out zero and negative values */
      if (showNegatives) {
        refined = refined.filter((item: any) => {
          return item.value > 0
        })
      }
    }

    return refined
  }, [data, accessor, showNegatives])

  const chartColors = [
    '#FF8042',
    '#0088FE',
    '#00C49F',
    '#148e55',
    '#7856FF',
    '#FF7557',
    '#CA80DC',
    '#71BEF4',
    '#B2586E',
    '#FFB27A',
    '#5E5CB6',
  ]

  return {
    refinedData,
    chartColors,
  }
}

export default useRefineChartData
