import cache from '../utils/cache'
import { http } from '../utils/http'
import BaseService from './BaseService'

class DeviceService extends BaseService {
  constructor() {
    super('device')
  }

  fetch(): Promise<any> {
    const formData = new FormData()
    formData.append('key', 'version')

    return new Promise((resolve, reject) => {
      const cacheKey = `devices`
      const cachedData = cache.get(cacheKey)

      if (cachedData) resolve(cachedData)
      else {
        try {
          http.post(`device/read_all_dict`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByUser(userId): Promise<any> {
    const formData = new FormData()
    formData.append('user_id', userId)

    return new Promise((resolve, reject) => {
      const cacheKey = `user_device_${userId}`
      const cachedData = this.cache.get(cacheKey)

      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          this.http.post(`device/read_by_user`, formData).then(({ data }) => {
            if (data.code === 200) {
              this.cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }
}

export default DeviceService
