import React from 'react'

import { Formik, Field, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import ChannelService from 'src/services/ChannelService'

import { route } from 'src/utils/routes'
import { IChannel } from 'src/services/types/channel'

const channelService = new ChannelService()

interface IProps {
  type?: string
  channel?: IChannel
  updateChannel?: (updatedValues: IChannel) => void
}

const ChannelForm: React.FC<IProps> = ({ type, channel, updateChannel }) => {
  const { addToast } = useToasts()
  const history = useHistory()

  const formConfig = {
    initialValues: {
      name: channel?.name || '',
      description: channel?.description || '',
      status: channel?.status || 'active',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      description: Yup.string()
        .required('Description field is required.')
        .min(3, 'Description must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        ...values,
      }

      if (type === 'update') {
        finalValues._id = channel._id
      }

      channelService[type](finalValues)
        .then((channelId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Channel successfully updated.', { appearance: 'success' })
            updateChannel(finalValues)
          } else {
            addToast('Channel successfully created.', { appearance: 'success' })
            history.push(route('channel_update', { id: channelId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        ...formik
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* Channel status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {/* Channel name */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">Name of channel</Form.Text>
            </Col>
            <Col md={6}>
              <Field
                as={Form.Control}
                name="name"
                type="text"
                isInvalid={touched.name && errors.name ? true : false}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Channel description */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Description *</Form.Label>
              <Form.Text className="text-muted">
                Description of channel
              </Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={values.description}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={
                  touched.description && errors.description ? true : false
                }
              />
              <ErrorMessage
                name="description"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Channel'
            ) : (
              'Update Channel'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

ChannelForm.defaultProps = {
  type: 'create',
}

export default ChannelForm
