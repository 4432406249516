import React from 'react'

import pluralize from 'pluralize'
import { Card, Table } from 'react-bootstrap'

import ExportButton from '../Export/ExportButton'
import NotFound from 'src/components/Error/NotFound'
import DateRangeInput from '../Form/DateRangeInput'
import { timestampToDate, timestampToTime } from 'src/utils/date'
import { paginate } from 'src/utils/filters'
import Pagination from '../Pagination/Pagination'
import useTableSort from 'src/hooks/useTableSort'
import TableHead from '../Table/TableHead'

interface IProps {
  activeQuestion: any
}

const SurveyAnswers: React.FC<IProps> = ({ activeQuestion }) => {
  const tableRef = React.useRef<any>()

  const [filters, setFilters] = React.useState<any>({
    start_date: null,
    end_date: null,
  })
  const [page, setPage] = React.useState<number>(1)
  const perPage = 25

  const filteredAnswers = React.useMemo(() => {
    setPage(1)

    let filtered = activeQuestion?.answers || []

    if (filtered) {
      const { start_date, end_date } = filters
      if (start_date && end_date) {
        filtered = filtered.filter((answer) => {
          return (
            answer.created > start_date && answer.created < end_date + 86400
          )
        })
      }
    }

    return filtered
  }, [activeQuestion, filters])

  const [columnActions, sortedAnswers] = useTableSort(filteredAnswers, {
    sort_by: 'created',
    order: 'desc',
  })

  const paginatedAnswers = React.useMemo(() => {
    return sortedAnswers.data ? paginate(sortedAnswers.data, page, perPage) : []
  }, [sortedAnswers, page])

  const findQuestionAnswer = React.useCallback((answer, choices) => {
    answer = choices.find((choice) => choice._id === answer)
    return answer.title
  }, [])

  const exportableAnswers = React.useMemo(() => {
    return filteredAnswers
      ? filteredAnswers.map(({ merchandiser, ...answer }) => ({
          Date: timestampToDate(answer.created),
          Time: timestampToTime(answer.created),
          Answer:
            activeQuestion.type === 'multiple-choice'
              ? findQuestionAnswer(answer.title, activeQuestion.choices)
              : answer.title,
          Name: merchandiser?.name,
          Phone: merchandiser?.phone,
          Store: merchandiser?.retail_location?.name,
          Channel: merchandiser?.retail_location?.channel.name,
        }))
      : []
  }, [filteredAnswers, findQuestionAnswer, activeQuestion])

  return (
    <>
      <Card.Header className="w-100 d-flex align-items-center justify-content-between">
        {`${filteredAnswers.length} ${pluralize(
          'answer',
          filteredAnswers.length
        )}`}

        <div className="d-flex align-items-center">
          <DateRangeInput
            start={filters.start_date}
            end={filters.end_date}
            onChange={(start_date, end_date) => {
              setFilters({ ...filters, start_date, end_date })
            }}
          />

          {activeQuestion ? (
            <div className="d-flex align-items center ml-3">
              <ExportButton
                filename="survey-answers"
                exportData={exportableAnswers}
                disabled={!filteredAnswers.length}
              />
            </div>
          ) : null}
        </div>
      </Card.Header>
      <Card.Body className="px-0 py-0">
        <div className="table-wrapper padded">
          <Table ref={tableRef} className="table-fit" bordered hover responsive>
            <thead>
              <tr>
                <th className="text-center">#</th>

                <TableHead
                  header="Date"
                  {...columnActions.getHeaderProps('created')}
                />

                <TableHead
                  header="Time"
                  {...columnActions.getHeaderProps('created')}
                />

                <th>Answer</th>

                <TableHead
                  header="Name"
                  {...columnActions.getHeaderProps('name')}
                />

                <TableHead
                  header="Phone"
                  {...columnActions.getHeaderProps('phone')}
                />

                <th>Store</th>
                <th>Channel</th>
              </tr>
            </thead>
            <tbody>
              {paginatedAnswers.length
                ? paginatedAnswers.map(({ merchandiser, ...answer }, index) => (
                    <tr key={answer._id}>
                      <td className="align-middle text-center">
                        {index + 1 + (page - 1) * perPage}
                      </td>
                      <td className="align-middle">
                        {timestampToDate(answer.created)}
                      </td>
                      <td className="align-middle">
                        {timestampToTime(answer.created)}
                      </td>
                      <td className="align-middle font-weight-bold">
                        {activeQuestion.type === 'multiple-choice'
                          ? findQuestionAnswer(
                              answer.title,
                              activeQuestion.choices
                            )
                          : answer.title}
                      </td>
                      <td className="align-middle">{merchandiser?.name}</td>
                      <td className="align-middle">{merchandiser?.phone}</td>
                      <td className="align-middle">
                        {merchandiser?.retail_location?.name}
                      </td>
                      <td className="align-middle">
                        {merchandiser?.retail_location.channel?.name}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>

          <Pagination
            className="my-4"
            perPage={perPage}
            totalItems={filteredAnswers.length}
            currentPage={page}
            onChange={(page) => setPage(page)}
          />

          {!paginatedAnswers.length ? (
            <div className="my-5">
              <NotFound summary="No answers found." />
            </div>
          ) : null}
        </div>
      </Card.Body>
    </>
  )
}

export default SurveyAnswers
