import React, { useState, useEffect } from 'react'

import { Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import ProductService from 'src/services/ProductService'
import CompetitionService from 'src/services/CompetitionService'

import SearchSelect from 'src/components/Form/SearchSelect'
import useMounted from 'src/hooks/useMounted'

const productService = new ProductService()
const competitionService = new CompetitionService()

interface IProps {
  type?: 'product' | 'competition'
  user: any
  selectedItems: any[]
  addItem: (item) => Promise<any>
}

const CategoryItemPicker: React.FC<IProps> = ({ type, user, ...props }) => {
  const { addToast } = useToasts()
  const isMounted = useMounted()

  // Indicators , I guess that's what they are called
  const [isAdding, setIsAdding] = useState<boolean>(false) // for adding a product

  const [product, setProduct] = useState<any>()
  const [products, setProducts] = useState<any[]>()

  /**
   * Fetch unattached products or comp. products
   */
  useEffect(() => {
    const service = type === 'product' ? productService : competitionService

    service.fetchByManufacturer(user.manufacturer_id, true).then((products) => {
      const tempProducts = products.map((product) => ({
        value: product._id,
        label: product.name,
      }))
      isMounted.current && setProducts(tempProducts)
    })
  }, [type, user.manufacturer_id, props.selectedItems, isMounted])

  /**
   * Select product + prevent duplicate selections
   *
   * @param product
   */
  const handleProductSelect = (product: any): void => {
    const selectedProducts = props.selectedItems.map((product) => product._id)

    console.log(product)
    if (selectedProducts.includes(product.value)) {
      addToast('Product already exists in this category.', {
        appearance: 'error',
      })
    } else {
      setProduct(product)
    }
  }

  const addProduct = (): void => {
    setIsAdding(true)

    props
      .addItem(product)
      .then(() => {
        isMounted.current && setProduct(null)
      })
      .finally(() => {
        isMounted.current && setIsAdding(false)
      })
  }

  return (
    <div className="w-100 d-flex align-items-center">
      <SearchSelect
        size="sm"
        className="w-100"
        options={products}
        value={product}
        placeholder="Add product"
        onChange={handleProductSelect}
        isDisabled={!products || !products.length}
      />
      {product ? (
        <>
          <Button
            size="sm"
            className="ml-2"
            onClick={addProduct}
            disabled={isAdding}
          >
            {isAdding ? <figure className="spinner button white" /> : 'Add'}
          </Button>
        </>
      ) : null}
    </div>
  )
}

CategoryItemPicker.defaultProps = {
  type: 'product',
}

export default CategoryItemPicker
