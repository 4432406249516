import React from 'react'
import { Form } from 'react-bootstrap'

interface IProps {
  value: number
  onChange: (value: number) => void
  options?: number[]
  disabled?: boolean
}

const PerPageSelect: React.FC<IProps> = ({
  value,
  onChange,
  options,
  disabled,
}) => (
  <div className="d-flex align-items-center">
    <Form.Control
      as="select"
      size="sm"
      name="perPage"
      value={value || 10}
      className="w-auto"
      onChange={({ target }) => {
        onChange(Number(target.value))
      }}
      disabled={disabled}
    >
      {options && options.length
        ? options.map((perPage, index) => (
            <option key={index} value={perPage}>
              {perPage} per page
            </option>
          ))
        : null}
    </Form.Control>
  </div>
)

PerPageSelect.defaultProps = {
  options: [10, 20, 50, 100, 200],
  disabled: false,
}

export default PerPageSelect
