import React from 'react'

import { Formik, Field, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SearchSelect from 'src/components/Form/SearchSelect'

import useMounted from 'src/hooks/useMounted'
import { IRetailLocation } from 'src/services/types/retailLocation'

import ChannelService from 'src/services/ChannelService'
import RetailerService from 'src/services/RetailerService'
import RetailLocationService from 'src/services/RetailLocationService'
import { IState } from 'src/store'
import { route } from 'src/utils/routes'

const channelService = new ChannelService()
const retailerService = new RetailerService()
const retailLocationService = new RetailLocationService()

interface IProps extends RouteComponentProps {
  type?: string
  retailLocation?: IRetailLocation
  updateRetailLocation?: (updatedValues: IRetailLocation) => void
}

const RetailerLocationForm: React.FC<IProps> = ({
  type,
  retailLocation,
  updateRetailLocation,
}) => {
  const history = useHistory()
  const isMounted = useMounted()
  const { addToast } = useToasts()
  const user = useSelector((state: IState) => state.user)

  const [channels, setChannels] = React.useState<any[]>([])
  const [retailers, setRetailers] = React.useState<any[]>([])

  React.useEffect(() => {
    const fetchRetailers = (): void => {
      retailerService
        .fetchByManufacturer(user.manufacturer_id)
        .then((retailers) => {
          const activeRetailers = retailers
            .filter((retailer) => retailer.status === 'active')
            .map((retailer) => ({
              value: retailer._id,
              label: retailer.name,
            }))
          isMounted.current && setRetailers(activeRetailers)
        })
    }

    const fetchChannels = (): void => {
      channelService.fetch().then((channels) => {
        const activeChannels = channels
          .filter((channel) => channel.status === 'active')
          .map((channel) => ({
            value: channel._id,
            label: channel.name,
          }))
        isMounted.current && setChannels(activeChannels)
      })
    }

    fetchRetailers()
    fetchChannels()
  }, [user.manufacturer_id, isMounted])

  const formConfig = {
    initialValues: {
      retailer: retailLocation
        ? {
            value: retailLocation.retailer._id,
            label: retailLocation.retailer.name,
          }
        : null,
      channel: retailLocation
        ? {
            value: retailLocation.channel._id,
            label: retailLocation.channel.name,
          }
        : null,
      name: retailLocation ? retailLocation.name : '',
      status: retailLocation ? retailLocation.status : 'active',
    },
    validationSchema: Yup.object({
      retailer: Yup.string().required('Retailer field is required.').nullable(),
      channel: Yup.string().required('Channel field is required.').nullable(),
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      const finalValues: any = {
        name: values.name,
        status: values.status,
        channel_id: values.channel.value,
        retailer_id: values.retailer.value,
        manufacturer_id: user.manufacturer_id,
      }

      if (type === 'update' && retailLocation) {
        finalValues._id = retailLocation._id
      }

      retailLocationService[type]({ ...finalValues })
        .then((locationId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Retail store successfully updated.', {
              appearance: 'success',
            })
            updateRetailLocation(finalValues)
          } else {
            addToast('Retail store successfully created.', {
              appearance: 'success',
            })
            history.push(route('retail_location_update', { id: locationId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          {/* Retail location status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={4} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={8}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {/* Retailer location name */}
          <Form.Group as={Row}>
            <Col md={4} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">
                Name of retail location
              </Form.Text>
            </Col>
            <Col md={8}>
              <Field
                as={Form.Control}
                name="name"
                type="text"
                isInvalid={touched.name && errors.name ? true : false}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Retailer */}
          <Form.Group as={Row}>
            <Col md={4} className="mb-2 mb-md-0">
              <Form.Label>Retailer *</Form.Label>
              <Form.Text className="text-muted">Retail chain</Form.Text>
            </Col>
            <Col md={8}>
              <SearchSelect
                name="retailer"
                options={retailers}
                value={values.retailer}
                placeholder="Select retailer"
                onBlur={() => {
                  formik.setFieldTouched('retailer', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('retailer', selected)
                }}
                isInvalid={touched.retailer && errors.retailer ? true : false}
                isDisabled={!retailers.length}
              />
              <ErrorMessage
                name="retailer"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Channel */}
          <Form.Group as={Row}>
            <Col md={4} className="mb-2 mb-md-0">
              <Form.Label>Channel *</Form.Label>
              <Form.Text className="text-muted">
                Retail location channel
              </Form.Text>
            </Col>
            <Col md={8}>
              <SearchSelect
                name="channel"
                options={channels}
                value={values.channel}
                placeholder="Select channel"
                onBlur={() => {
                  formik.setFieldTouched('channel', true)
                }}
                onChange={(selected) => {
                  formik.setFieldValue('channel', selected)
                }}
                isInvalid={touched.channel && errors.channel ? true : false}
                isDisabled={!channels.length}
              />
              <ErrorMessage
                name="channel"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Retail Location'
            ) : (
              'Update Retail Location'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

RetailerLocationForm.defaultProps = {
  type: 'create',
}

export default withRouter(RetailerLocationForm)
