import React from 'react'

import { connect } from 'react-redux'
import { Table, Card } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import { userCan } from 'src/store/user/userActions'
import CategoryService from 'src/services/CategoryService'
import ProductService from 'src/services/ProductService'
import CompetitionService from 'src/services/CompetitionService'
import { IProduct } from 'src/services/types/product'
import { ICategory } from 'src/services/types/category'

import CategoryItemPicker from 'src/components/Category/CategoryItemPicker'
import NotFound from 'src/components/Error/NotFound'

const categoryService = new CategoryService()
const productService = new ProductService()
const competitionService = new CompetitionService()

interface IProps {
  type?: 'product' | 'competition'
  user: any
  category: ICategory
}

const CategoryItems: React.FC<IProps> = ({ type, user, category }) => {
  const _isMounted = React.useRef<any>(false)
  const { addToast } = useToasts()

  // indicators
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false)
  const [isDeleting, setIsDeleting] = React.useState<any>(false) // holds itemId

  const [products, setProducts] = React.useState<IProduct[]>()
  const [lastUpdated, setLastUpdated] = React.useState<number>()

  /**
   * It's important to manage memory leaks
   * when components have been unmounted.
   */
  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  React.useEffect(() => {
    if (!isDeleting) {
      setIsUpdating(true)
      const service = type === 'product' ? productService : competitionService

      service.fetchByCategory(category._id).then((products) => {
        if (_isMounted.current) {
          setProducts(products)
          setIsLoading(false)
          setIsUpdating(false)
        }
      })
    }
  }, [type, category, lastUpdated, isDeleting])

  const addItem = React.useCallback(
    (product): Promise<void> => {
      return new Promise((resolve, reject) => {
        const details = {
          type,
          products: [product.value],
          category_id: category._id,
          manufacturer_id: user.manufacturer_id,
        }

        categoryService
          .addProduct(details)
          .then(() => {
            setLastUpdated(Date.now())

            const typeText =
              type === 'product' ? 'Product' : 'Competition product'
            addToast(`${typeText} added to category`, {
              appearance: 'success',
            })

            resolve()
          })
          .catch((error) => {
            addToast(error.mesage, { appearance: 'error' })
            reject(error)
          })
      })
    },
    [addToast, type, category, user.manufacturer_id]
  )

  const deleteItem = React.useCallback(
    (itemId): Promise<void> => {
      return new Promise((resolve, reject) => {
        setIsDeleting(itemId)

        const details = {
          type,
          item_id: itemId,
        }

        categoryService
          .removeProduct(details)
          .then(() => {
            setIsDeleting(false)

            const typeText = type === 'product' ? 'Product' : 'Competition'
            addToast(`${typeText} removed from category`, {
              appearance: 'success',
            })

            resolve()
          })
          .catch((error) => {
            setIsDeleting(false)

            addToast(error.mesage, { appearance: 'error' })
            reject(error)
          })
      })
    },
    [addToast, type]
  )

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          {category.name} {type === 'product' ? 'Products' : 'Competition'}
        </Card.Title>
      </Card.Header>
      <Card.Header className="odd">
        <CategoryItemPicker
          type={type}
          user={user}
          selectedItems={products}
          addItem={addItem}
        />
      </Card.Header>
      <Card.Body className={isUpdating ? 'disabled' : ''}>
        {!isLoading && products && products.length ? (
          <div className="table-wrapper bordered">
            <Table className="table-fit" hover responsive>
              <thead>
                <tr>
                  <th className="text-center">#</th>
                  <th>Product</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product: IProduct, index: any) => (
                  <tr key={index}>
                    <td className="text-center align-middle">{index + 1}</td>
                    <td className="align-middle sticky">
                      {/* Add span to enable text truncate */}
                      <span title={product.name}>{product.name}</span>
                    </td>
                    <td>
                      {isDeleting === product.pair ? (
                        <figure className="spinner small primary" />
                      ) : (
                        <span
                          className="text-danger action-link cursor-pointer"
                          onClick={() => deleteItem(product.pair)}
                        >
                          Remove
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : !isLoading && (!products || !products.length) ? (
          <div className="my-5">
            <NotFound summary="No products found in this category" />
          </div>
        ) : (
          <div className="d-flex justify-content-center py-5">
            <figure className="spinner primary" />
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

CategoryItems.defaultProps = {
  type: 'product',
}

export default connect(() => ({}), { userCan })(CategoryItems)
