import React from 'react'

import { connect } from 'react-redux'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Card, Button } from 'react-bootstrap'

import { userCan } from 'src/store/user/userActions'
import { useQuery, route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import ScheduleForm from 'src/components/Schedule/ScheduleForm'
import SchedulesUpload from 'src/components/Schedule/SchedulesUpload'

interface IProps extends RouteComponentProps {
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const ScheduleCreate: React.FC<IProps> = ({ history, user, userCan }) => {
  const type = useQuery().get('type')

  if (!userCan('create_schedule')) return <Error401 />

  return (
    <>
      <Header
        title={type === 'bulk' ? 'Upload Bulk' : 'New Schedule'}
        backText="Schedules"
        onBack={() => history.push(route('schedules'))}
      />
      <div className="page-container">
        <Container>
          <Card className={type === 'bulk' ? 'centered' : null}>
            <Card.Header className="w-100 d-flex align-items-center">
              <Card.Title>
                {type === 'bulk' ? 'Upload File' : 'Schedule Details'}
              </Card.Title>

              {type !== 'bulk' && userCan('create_schedule') && (
                <div>
                  <Link to={`${route('schedule_create')}?type=bulk`}>
                    <Button size="sm">Create Bulk</Button>
                  </Link>
                </div>
              )}
            </Card.Header>
            <Card.Body className="pt-0">
              {type === 'bulk' ? (
                <SchedulesUpload user={user} />
              ) : (
                <ScheduleForm user={user} />
              )}
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(ScheduleCreate)
)
