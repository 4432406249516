import React from 'react'
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Bar,
  YAxis,
  XAxis,
  Tooltip,
} from 'recharts'

import NotFound from 'src/components/Error/NotFound'
import useRefineChartData from 'src/hooks/dashboard/useRefineChartData'
import { colors } from 'src/config/theme'

interface IProps {
  label: string
  name?: string
  accessor?: string
  data: any
  unit?: 'percentage' | 'scalar' | 'currency'
  isLoading?: boolean
}

const SectionBarChart: React.FC<IProps> = ({
  label,
  name,
  accessor,
  data,
  unit,
  isLoading,
}) => {
  const { refinedData } = useRefineChartData(data, accessor)

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span className="font-weight-medium">{label}</span>
      </div>

      {isLoading && (
        <div className="d-flex justify-content-center py-5">
          <figure className="spinner" />
        </div>
      )}

      {!isLoading && !refinedData.length ? (
        <div className="my-5">
          <NotFound summary={`No ${label} data to show`} />
        </div>
      ) : null}

      {!isLoading && refinedData.length ? (
        <>
          <ResponsiveContainer height={300}>
            <BarChart
              margin={{
                top: 20,
                right: 20,
                left: -20,
                bottom: 80,
              }}
              data={refinedData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                tick={{
                  fill: colors.grey,
                  fontSize: '10px',
                  lineHeight: '23px',
                  textTransform: 'capitalize',
                }}
                tickLine={false}
                interval={0}
                angle={-45}
                textAnchor="end"
              />
              <YAxis
                tick={{
                  fill: colors.grey,
                  fontSize: '10px',
                  textTransform: 'capitalize',
                }}
                tickLine={false}
              />
              <Tooltip
                itemStyle={{
                  textTransform: 'capitalize',
                }}
              />
              <Bar
                dataKey="value"
                fill="#3b54ec"
                legendType="line"
                unit={unit === 'scalar' ? '' : '%'}
                name={name}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : null}
    </div>
  )
}

SectionBarChart.defaultProps = {
  name: 'Value',
  isLoading: false,
  accessor: 'pc',
  unit: 'percentage',
}

export default SectionBarChart
