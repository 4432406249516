import cache from '../utils/cache'
import { http } from '../utils/http'
import { ICategory } from './types/category'

class CategoryService {
  fetch(): Promise<ICategory[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('categories')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('category/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('categories', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(categoryId: string): Promise<ICategory> {
    return new Promise((resolve, reject) => {
      const cacheKey = `category_${categoryId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`category/read/?_id=${categoryId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<ICategory[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `categories_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`category/read_by_manufacturer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<ICategory[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `categories_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`category/read_by_status`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(category: ICategory): Promise<string> {
    const formData = new FormData()

    Object.keys(category).forEach((key) => {
      formData.append(key, category[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('category/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(category: ICategory): Promise<any> {
    const formData = new FormData()

    Object.keys(category).forEach((key) => {
      formData.append(key, category[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('category/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  /**
   * Add product or competition product to category.
   * Details contains type: 'product' or 'competition'
   *
   * @param details
   */
  addProduct(details: any): Promise<any> {
    const formData = new FormData()

    details.products.forEach((productId: string) => {
      formData.append(`${details.type}_id[]`, productId)
    })

    formData.append('status', 'active')
    formData.append('category_id', details.category_id)
    formData.append('manufacturer_id', details.manufacturer_id)

    return new Promise((resolve, reject) => {
      try {
        http
          .post(`category_${details.type}/add_bulk`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              cache.reset()
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  /**
   * Deletes product or competition product from category.
   * Details contains type: 'product' or 'competition'
   *
   * @param details
   */
  removeProduct(details: any): Promise<any> {
    const formData = new FormData()

    formData.append('_id', details.item_id)

    return new Promise((resolve, reject) => {
      try {
        http
          .post(`category_${details.type}/delete`, formData)
          .then(({ data }) => {
            if (data.code === 200) {
              cache.reset()
              resolve(data.data)
            } else reject({ message: data.message })
          })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default CategoryService
