import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'

import usePermission from 'src/hooks/usePermission'
import useTableSort from 'src/hooks/useTableSort'

import { formatCurrency } from 'src/utils/money'
import ExportButton from '../Export/ExportButton'
import TableHead from '../Table/TableHead'

interface IProps {
  erringData: any
  erringReportOpen: boolean
  closeErringReport: () => void
}

const ErringReportModal: React.FC<IProps> = ({
  erringData,
  erringReportOpen,
  closeErringReport,
}) => {
  const { userCan } = usePermission()
  const tableRef = React.useRef<any>()

  const refinedData = React.useMemo(() => {
    let refined = []

    if (erringData.total < 0) {
      refined = Object.values(erringData.retail_locations)
    }

    return refined
  }, [erringData])

  const [columnActions, sortedData] = useTableSort(refinedData, {
    sort_by: 'total',
    order: 'desc',
  })

  return (
    <Modal
      show={erringReportOpen}
      onHide={closeErringReport}
      onEscapeKeyDown={closeErringReport}
    >
      <Modal.Header>
        <Modal.Title>Erring Stores</Modal.Title>
        <div className="d-flex align-items-center">
          {userCan('export_data') ? (
            <ExportButton filename="erring-stores" tableRef={tableRef} />
          ) : null}
          <Button
            size="sm"
            variant="outline-light"
            className="ml-2"
            onClick={closeErringReport}
          >
            Close
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body className="px-0 py-0">
        <div className="table-wrapper padded">
          <Table ref={tableRef} hover responsive bordered>
            <thead>
              <tr>
                <th>#</th>
                <TableHead
                  header="Name"
                  {...columnActions.getHeaderProps('name')}
                />
                <TableHead
                  header="Amount (₦)"
                  {...columnActions.getHeaderProps('total')}
                />
              </tr>
            </thead>
            <tbody>
              {refinedData.length
                ? sortedData.data.map((store, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{store.name}</td>
                      <td className="text-danger">
                        {formatCurrency(store.total, true)}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ErringReportModal
