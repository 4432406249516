import React from 'react'

import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import TicketService from 'src/services/TicketService'

import { ITicket, ITicketLog } from 'src/services/types/ticket'

const ticketService = new TicketService()

interface IProps {
  type?: 'create' | 'update'
  ticketLog?: ITicketLog
  ticket: ITicket
  onClose?: (refreshData: boolean) => void
}

const TicketLogForm: React.FC<IProps> = ({
  type,
  ticketLog,
  ticket,
  onClose,
}) => {
  const { addToast } = useToasts()
  const _isMounted = React.useRef<boolean>(false)

  /** It's important to ensure there is no memory
   * leak on unmounted components
   */
  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const formConfig = {
    initialValues: {
      tag: ticketLog ? ticketLog.tag : 'note',
      value: ticketLog ? ticketLog.value : '',
    },
    validationSchema: Yup.object({
      tag: Yup.string().required('Tag field is required.').nullable(),
      value: Yup.string().required('Note field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)

      let finalValues: any = {}

      if (type === 'update') {
        finalValues._id = ticketLog._id
      }

      finalValues = {
        ...finalValues,
        tag: values.tag,
        value: values.value,
        ticket_id: ticket._id,
      }

      // remember type is 'create' or 'update'.
      ticketService[`${type}Log`]({ ...finalValues })
        .then(() => {
          setSubmitting(false)

          // remember type is 'create' or 'update'... gerrrit? ;)
          addToast(`Ticket note successfully ${type}d.`, {
            appearance: 'success',
          })
          onClose(true)
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          {/* Tag */}
          {type === 'create' ? (
            <>
              <Form.Group as={Row}>
                <Col md={4} className="mb-2 mb-md-0">
                  <Form.Label>Tag *</Form.Label>
                  <Form.Text className="text-muted">Ticket log tag</Form.Text>
                </Col>
                <Col md={8}>
                  <Form.Control
                    as="select"
                    name="tag"
                    value={values.tag}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value="" label="Select tag" />
                    <option value="note" label="Note" />
                  </Form.Control>
                  <ErrorMessage
                    name="tag"
                    component="span"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <hr />
            </>
          ) : null}

          {/* Ticket body */}
          <Form.Group as={Row}>
            <Col md={4} className="mb-2 mb-md-0">
              <Form.Label>Note *</Form.Label>
              <Form.Text className="text-muted">Ticket note</Form.Text>
            </Col>
            <Col md={8}>
              <Form.Control
                as="textarea"
                rows={3}
                name="value"
                value={values.value}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                isInvalid={touched.value && errors.value ? true : false}
              />
              <ErrorMessage
                name="value"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-3 float-right"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Add Note'
            ) : (
              'Update Note'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

TicketLogForm.defaultProps = {
  type: 'create',
}

export default TicketLogForm
