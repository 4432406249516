import React from 'react'

import { Formik, Field, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { BsTrash } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SearchSelect from 'src/components/Form/SearchSelect'

import useMounted from 'src/hooks/useMounted'
import { ITree } from 'src/services/types/tree'

import TreeService from 'src/services/TreeService'
import { IState } from 'src/store'
import { route } from 'src/utils/routes'

const treeService = new TreeService()

interface IProps {
  type?: string
  tree?: ITree
  updateTree?: (updatedValues: ITree) => void
}

const TreeForm: React.FC<IProps> = ({ type, tree, updateTree }) => {
  const history = useHistory()
  const isMounted = useMounted()
  const { addToast } = useToasts()
  const user = useSelector((state: IState) => state.user)

  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

  const [trees, setTrees] = React.useState<any[]>([])

  React.useEffect(() => {
    const fetchTrees = (): void => {
      treeService.fetchByManufacturer(user.manufacturer_id).then((trees) => {
        const tempTrees = trees.map((tree) => ({
          value: tree._id,
          label: tree.name,
          type: tree.type,
        }))
        setTrees(tempTrees)
      })
    }

    fetchTrees()
  }, [user.manufacturer_id, isMounted])

  const formConfig = {
    initialValues: {
      type: tree?.type || '',
      name: tree?.name || '',
      status: tree?.status || 'active',
      parent: tree?.parent_obj
        ? {
            value: tree.parent_obj._id,
            label: tree.parent_obj.name,
          }
        : null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
      type: Yup.string().required('Type field is required.'),
      parent: Yup.string()
        .nullable()
        .when('type', {
          is: (value) => value === 'REGION' || value === 'STATE',
          then: Yup.string().required('Parent location field is required'),
          otherwise: Yup.string().nullable(),
        }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        name: values.name,
        status: values.status,
        type: values.type,
        parent: values.parent?.value || '-',
        manufacturer_id: user.manufacturer_id,
      }

      if (type === 'update' && tree) {
        finalValues._id = tree._id
      }

      treeService[type]({ ...finalValues })
        .then((treeId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Location successfully updated.', {
              appearance: 'success',
            })
            updateTree(finalValues)
          } else {
            addToast('Location successfully created.', {
              appearance: 'success',
            })
            history.push(route('tree_update', { id: treeId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const deleteTree = (): void => {
    setIsDeleting(true)

    treeService
      .delete(tree._id)
      .then(() => {
        history.push(route('trees'))
      })
      .catch((error) => {
        setIsDeleting(false)
        addToast(error.message, { appearance: 'error' })
        throw error
      })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleBlur, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          {/* Tree status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {/* Tree type */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Type *</Form.Label>
              <Form.Text className="text-muted">Tree type</Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                as="select"
                name="type"
                value={values.type}
                onChange={(event) => {
                  formik.setFieldValue('type', event.target.value)
                  formik.setFieldValue('parent', null)
                }}
                onBlur={handleBlur}
                isInvalid={touched.type && errors.type ? true : false}
              >
                <option value="">Select type</option>
                <option value="COUNTRY">Country</option>
                <option value="REGION">Region</option>
                <option value="STATE">State</option>
              </Form.Control>
              <ErrorMessage
                name="type"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Tree name */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">Name of tree</Form.Text>
            </Col>
            <Col md={6}>
              <Field
                as={Form.Control}
                name="name"
                value={values.name}
                type="text"
                isInvalid={touched.name && errors.name ? true : false}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Parent */}
          {values.type && values.type !== 'COUNTRY' ? (
            <>
              <Form.Group as={Row}>
                <Col md={3} className="mb-2 mb-md-0">
                  <Form.Label>
                    {values.type === 'STATE'
                      ? 'Region'
                      : values.type === 'REGION'
                      ? 'Country'
                      : 'Parent'}{' '}
                    *
                  </Form.Label>
                  <Form.Text className="text-muted">Parent location</Form.Text>
                </Col>
                <Col md={6}>
                  <SearchSelect
                    name="parent"
                    options={trees.filter((tree) => {
                      return values.type === 'REGION'
                        ? tree.type === 'COUNTRY'
                        : values.type === 'STATE'
                        ? tree.type === 'REGION'
                        : false
                    })}
                    value={values.parent}
                    placeholder="Select parent"
                    onBlur={() => {
                      formik.setFieldTouched('parent', true)
                    }}
                    onChange={(selected) => {
                      formik.setFieldValue('parent', selected)
                    }}
                    isInvalid={touched.parent && errors.parent ? true : false}
                    isDisabled={!trees.length}
                  />
                  <ErrorMessage
                    name="parent"
                    component="span"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <hr />
            </>
          ) : null}

          <div className="d-flex justify-content-between mt-4">
            <Button
              type="submit"
              variant="success"
              disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
            >
              {formik.isSubmitting ? (
                <figure className="spinner button white" />
              ) : type === 'create' ? (
                'Create Location'
              ) : (
                'Update Location'
              )}
            </Button>

            {type === 'update' && (
              <Button
                variant="danger"
                disabled={formik.isSubmitting || true}
                onClick={() => deleteTree()}
              >
                {isDeleting ? (
                  <figure className="spinner button white" />
                ) : (
                  <>
                    <BsTrash size={16} /> Delete
                  </>
                )}
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

TreeForm.defaultProps = {
  type: 'create',
}

export default TreeForm
