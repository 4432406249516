import React from 'react'

import { Formik, Field, ErrorMessage } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup'

import SearchSelect from 'src/components/Form/SearchSelect'
import useMounted from 'src/hooks/useMounted'

import { IForum } from 'src/services/types/forum'
import ForumService from 'src/services/ForumService'
import ManufacturerService from 'src/services/ManufacturerService'
import { route } from 'src/utils/routes'

const forumService = new ForumService()
const manufacturerService = new ManufacturerService()

interface IProps {
  type?: string
  forum?: IForum
  updateForum?: (updatedValues: IForum) => void
}

const ForumForm: React.FC<IProps> = ({ type, forum, updateForum }) => {
  const { addToast } = useToasts()
  const history = useHistory()
  const isMounted = useMounted()

  // manufacturers
  const [mftrs, setMftrs] = React.useState<any[]>()

  /** Fetch all manufacturers */
  React.useEffect(() => {
    const fetchManufacturers = (): void => {
      manufacturerService.fetch().then((mftrs) => {
        const tempMftrs = mftrs.map((mftr) => ({
          value: mftr._id,
          label: mftr.name,
        }))
        isMounted.current && setMftrs(tempMftrs)
      })
    }

    fetchManufacturers()
  }, [isMounted])

  const formConfig = {
    initialValues: {
      name: forum ? forum.name : '',
      status: forum ? forum.status : 'active',
      manufacturer: forum
        ? {
            value: forum.manufacturer._id,
            label: forum.manufacturer.name,
          }
        : '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
      manufacturer: Yup.string().required('Organization field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      const finalValues: any = {
        name: values.name,
        status: values.status,
        manufacturer_id: values.manufacturer.value,
      }

      if (type === 'update') {
        finalValues._id = forum._id
      }

      forumService[type]({ ...finalValues })
        .then((forumId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Forum successfully updated.', { appearance: 'success' })
            updateForum(finalValues)
          } else {
            addToast('Forum successfully created.', { appearance: 'success' })
            history.push(route('forum_update', { id: forumId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({ errors, touched, values, handleSubmit, ...formik }) => (
        <Form onSubmit={handleSubmit}>
          {/* Manufacturer */}
          {type === 'create' ? (
            <>
              <Form.Group as={Row}>
                <Col md={3} className="mb-2 mb-md-0">
                  <Form.Label>Organization *</Form.Label>
                  <Form.Text className="text-muted">
                    Forum organization
                  </Form.Text>
                </Col>
                <Col md={6}>
                  <SearchSelect
                    name="manufacturer"
                    options={mftrs}
                    value={values.manufacturer}
                    placeholder="Select organization"
                    onBlur={() => {
                      formik.setFieldTouched('manufacturer', true)
                    }}
                    onChange={(selected) => {
                      formik.setFieldValue('manufacturer', selected)
                    }}
                    isInvalid={
                      touched.manufacturer && errors.manufacturer ? true : false
                    }
                    isDisabled={!mftrs || !mftrs.length}
                  />
                  <ErrorMessage
                    name="manufacturer"
                    component="span"
                    className="invalid-feedback"
                  />
                </Col>
              </Form.Group>
              <hr />
            </>
          ) : null}

          {/* Channel status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {/* Channel name */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">Name of forum</Form.Text>
            </Col>
            <Col md={6}>
              <Field
                as={Form.Control}
                name="name"
                type="text"
                isInvalid={touched.name && errors.name ? true : false}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Commnunity'
            ) : (
              'Update Commnunity'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

ForumForm.defaultProps = {
  type: 'create',
}

export default ForumForm
