import React from 'react'

import pluralize from 'pluralize'
import { Container, Table, Card, Button, Badge } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import NotFound from 'src/components/Error/NotFound'
import PerPageSelect from 'src/components/Form/PerPageSelect'
import RetailerSelect from 'src/components/Form/RetailerSelect'
import SearchInput from 'src/components/Form/SearchInput'
import Header from 'src/components/Layout/Header/Header'
import Pagination from 'src/components/Pagination/Pagination'

import useGlobalFilters from 'src/hooks/useGlobalFilters'
import useMounted from 'src/hooks/useMounted'
import usePermission from 'src/hooks/usePermission'

import { IMerchandiser } from 'src/services/types/merchandiser'
import Error401 from 'src/pages/Error/401.page'

import MerchandiserService from 'src/services/MerchandiserService'
import { IState } from 'src/store'

import { timestampToDate } from 'src/utils/date'
import { paginate } from 'src/utils/filters'
import { route } from 'src/utils/routes'
import { basicSearch } from 'src/utils/search'

const merchandiserService = new MerchandiserService()

const Merchandisers: React.FC = () => {
  const isMounted = useMounted()
  const { userCan } = usePermission()
  const user = useSelector((state: IState) => state.user)

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [merchandisers, setMerchandisers] = React.useState<IMerchandiser[]>()

  const { filters, setFilters } = useGlobalFilters('merchandisers')

  React.useEffect(() => {
    const fetchMerchandisers = (): void => {
      merchandiserService
        .fetchByManufacturer(user.manufacturer_id)
        .then((merchandisers) => {
          isMounted.current && setMerchandisers(merchandisers)
        })
        .finally(() => {
          isMounted.current && setIsLoading(false)
        })
    }

    userCan('view_merchandisers') && fetchMerchandisers()
  }, [userCan, user.manufacturer_id, isMounted])

  const filteredMerchandisers = React.useMemo(() => {
    let filtered = merchandisers || []

    if (filtered.length) {
      if (filters.search) {
        const query = filters.search
        filtered = filtered.filter((user) => {
          return (
            basicSearch(user.name, query) ||
            basicSearch(user.phone, query) ||
            basicSearch(user.retail_location.name, query) ||
            basicSearch(user.retail_location.retailer.name, query) ||
            basicSearch(timestampToDate(user.created), query)
          )
        })
      }

      if (filters.retailer && filters.retailer.value !== '*') {
        filtered = filtered.filter(
          (user) => user.retailer_id === filters.retailer.value
        )
      }
    }

    return filtered
  }, [merchandisers, filters.search, filters.retailer])

  /**
   * Roving merchandisers have multiple accounts tied
   * to a single phone number.
   *
   * The code below groups the user accounts by phone.
   * I'm yet to figure out the best way to display this groupded data.
   */
  const groupedMerchandisers = React.useMemo((): any[] => {
    if (!filteredMerchandisers) return []
    else {
      const grouped = {}
      filteredMerchandisers.forEach((user) => {
        if (!grouped[user.phone]) {
          grouped[user.phone] = {
            name: user.name,
            phone: user.phone,
            retail_location_count: 1,
            retail_locations: [
              {
                ...user.retail_location,
                user_id: user._id,
                identifier: user.identifier,
                created: user.created,
                status: user.status,
              },
            ],
          }
        } else {
          grouped[user.phone].retail_location_count += 1
          grouped[user.phone].retail_locations.push({
            ...user.retail_location,
            user_id: user._id,
            identifier: user.identifier,
            created: user.created,
            status: user.status,
          })
        }
      })

      return Object.values(grouped)
    }
  }, [filteredMerchandisers])

  const paginatedMerchandisers = React.useMemo((): any[] => {
    return groupedMerchandisers
      ? paginate(groupedMerchandisers, filters.page, filters.per_page)
      : []
  }, [filters.page, filters.per_page, groupedMerchandisers])

  if (!userCan('view_merchandisers')) return <Error401 />

  return (
    <>
      <Header
        title={
          filters.retailer && filters.retailer.value !== '*'
            ? `Merchandisers (${filters.retailer.label})`
            : 'Merchandisers'
        }
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="font-weight-bold">
                  {filteredMerchandisers
                    ? `${groupedMerchandisers.length} ${pluralize(
                        'merchandiser',
                        groupedMerchandisers.length
                      )} (${filteredMerchandisers.length} ${pluralize(
                        'stores',
                        filteredMerchandisers.length
                      )})`
                    : '0 merchandisers'}
                </div>

                {userCan('signup_merchandiser') && (
                  <div>
                    <Link to={route('merchandiser_signup')}>
                      <Button size="sm">
                        <BsPlus size={20} /> Create New
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Header className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <SearchInput
                  placeholder="Search merchandisers"
                  value={filters.search}
                  onChange={(search) => setFilters({ search, page: 1 })}
                  disabled={isLoading || !filteredMerchandisers}
                />

                <div className="ml-2">
                  <RetailerSelect
                    value={filters.retailer || null}
                    onChange={(retailer) => setFilters({ retailer })}
                  />
                </div>
              </div>

              <PerPageSelect
                value={filters.per_page}
                onChange={(per_page) => setFilters({ per_page, page: 1 })}
              />
            </Card.Header>
            <Card.Body className="px-0 py-0">
              {isLoading && (
                <div className="d-flex justify-content-center py-5">
                  <figure className="spinner primary" />
                </div>
              )}

              {!isLoading && !paginatedMerchandisers.length ? (
                <div className="my-5">
                  <NotFound summary="No merchandisers found" />
                </div>
              ) : null}

              {!isLoading && paginatedMerchandisers.length ? (
                <div className="table-wrapper padded">
                  <Table className="table-fit" bordered hover responsive>
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="sticky">Name</th>
                        <th>Phone</th>
                        <th>Retailer</th>
                        <th>Store</th>
                        <th>Geo-Fencing</th>
                        <th>Status</th>
                        <th>Identifier</th>
                        <th>Created</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedMerchandisers.map((user, uIndex) => {
                        return user.retail_locations.map((location, lIndex) => (
                          <tr key={lIndex}>
                            {lIndex === 0 ? (
                              <>
                                <td
                                  rowSpan={user.retail_locations.length}
                                  className="text-center"
                                >
                                  {uIndex +
                                    1 +
                                    (filters.page - 1) * filters.per_page}
                                </td>
                                <td
                                  rowSpan={user.retail_locations.length}
                                  className="font-weight-medium sticky"
                                >
                                  {user.name}
                                </td>
                                <td
                                  rowSpan={user.retail_locations.length}
                                  className="font-weight-medium"
                                >
                                  {user.phone}
                                </td>
                              </>
                            ) : null}

                            <td className="align-middle">
                              {location.retailer.name}
                            </td>
                            <td className="align-middle">{location.name}</td>
                            <td className="text-center">
                              <Badge
                                variant={
                                  location?.location?.status === 'active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {location?.location?.status === 'active'
                                  ? 'On'
                                  : 'Off'}
                              </Badge>
                            </td>
                            <td className="align-middle">
                              <Badge
                                className="text-capitalize"
                                variant={
                                  location.status === 'active'
                                    ? 'success'
                                    : 'danger'
                                }
                              >
                                {location.status}
                              </Badge>
                            </td>
                            <td className="align-middle">
                              {location.identifier}
                            </td>

                            <td className="align-middle">
                              {timestampToDate(location.created)}
                            </td>
                            <td className="align-middle">
                              <Link
                                to={route('merchandiser_update', {
                                  id: location.user_id,
                                })}
                                className="action-link"
                              >
                                Edit
                              </Link>
                              &nbsp; &middot; &nbsp;
                              <Link
                                to={`${route('merchandiser_signup')}?name=${
                                  user.name
                                }&phone=${user.phone}`}
                                className="action-link"
                              >
                                Add to Store
                              </Link>
                            </td>
                          </tr>
                        ))
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : null}

              <Pagination
                className="my-4"
                perPage={filters.per_page}
                totalItems={groupedMerchandisers.length}
                currentPage={filters.page}
                onChange={(page) => setFilters({ page })}
              />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default Merchandisers
