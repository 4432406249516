import React from 'react'
import DefaultUpload from 'src/assets/images/default-upload.jpg'

interface IProps {
  src: any
  width?: number
  height?: number
  rounded?: boolean
  roundedCircle?: boolean
}

const ImageContainer: React.FC<IProps> = ({
  src,
  width,
  height,
  rounded,
  roundedCircle,
}) => (
  <div
    style={{
      backgroundImage: `url(${src ? src : DefaultUpload})`,
      backgroundColor: '#fff',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: `${width}px`,
      height: `${height ? height : width}px`,
      borderRadius: roundedCircle ? '100px' : rounded ? '6px' : '0',
    }}
  ></div>
)

ImageContainer.defaultProps = {
  width: 40,
  rounded: false,
  roundedCircle: false,
}

export default ImageContainer
