import React from 'react'

// import PropTypes from 'prop-types'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import BrandService from 'src/services/BrandService'
import UploadService from 'src/services/UploadService'

import { route } from 'src/utils/routes'
import { IBrand } from 'src/services/types/brand'

import ImageUploader from 'src/components/Image/ImageUploader'

const brandService = new BrandService()
const uploadService = new UploadService()

interface IProps extends RouteComponentProps {
  user: any
  type?: string
  brand?: IBrand
  updateBrand?: (updatedValues: IBrand) => void
  history: any
}

const BrandForm: React.FC<IProps> = ({
  user,
  type,
  brand,
  updateBrand,
  history,
}) => {
  const { addToast } = useToasts()
  const _isMounted = React.useRef(false)

  const [isUploading, setIsUploading] = React.useState<boolean>(false)

  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const formConfig = {
    initialValues: {
      name: brand ? brand.name : '',
      status: brand ? brand.status : 'active',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('Name field is required.')
        .min(3, 'Name must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      let finalValues: any = {}

      if (type === 'update') {
        finalValues._id = brand._id
        finalValues.upload_id = brand.upload_id
      }

      finalValues = {
        ...finalValues,
        name: values.name,
        status: values.status,
        manufacturer_id: user.manufacturer_id,
      }

      brandService[type]({ ...finalValues })
        .then((brandId) => {
          setSubmitting(false)
          // resetForm()

          if (type === 'update') {
            addToast('Brand successfully updated.', { appearance: 'success' })
            updateBrand(values)
          } else {
            addToast('Brand successfully created.', { appearance: 'success' })
            history.push(route('brand_update', { id: brandId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  const doUpload = (uploadData): Promise<void> => {
    return new Promise((resolve) => {
      uploadService
        .upload(uploadData, `brand_${brand._id}`)
        .then((uploadId) => {
          const updatedBrand = {
            _id: brand._id,
            name: brand.name,
            status: brand.status,
            manufacturer_id: brand.manufacturer_id,
            upload_id: uploadId,
          }

          brandService
            .update(updatedBrand)
            .then(() => {
              setIsUploading(false)

              updateBrand(updatedBrand)
              addToast('Image upload successful.', {
                appearance: 'success',
              })
              updateBrand(updatedBrand)
              resolve()
            })
            .catch((error) => {
              setIsUploading(false)
              addToast(error.message, { appearance: 'error' })
              resolve()
            })
        })
        .catch((error) => {
          setIsUploading(false)
          addToast(error.message, { appearance: 'error' })
          resolve()
        })
    })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        ...formik
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* Brand status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {type === 'update' && brand ? (
            <>
              {/* Image */}
              <Form.Group as={Row}>
                <Col md={3} className="mb-2 mb-md-0">
                  <Form.Label>Image</Form.Label>
                  <Form.Text className="text-muted">Brand logo</Form.Text>
                </Col>
                <Col md={6}>
                  <ImageUploader
                    uploadPath={brand.upload ? brand.upload.path : null}
                    doUpload={doUpload}
                  />
                </Col>
              </Form.Group>
              <hr />
            </>
          ) : null}

          {/* Brand name */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Name *</Form.Label>
              <Form.Text className="text-muted">Name of brand</Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                name="name"
                value={values.name}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.name && errors.name ? true : false}
              />
              <ErrorMessage
                name="name"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={
              formik.isSubmitting ||
              !formik.dirty ||
              !formik.isValid ||
              isUploading
            }
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Brand'
            ) : (
              'Update Brand'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

BrandForm.defaultProps = {
  type: 'create',
}

export default withRouter(BrandForm)
