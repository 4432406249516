import React from 'react'

import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Container, Card } from 'react-bootstrap'

import { userCan } from 'src/store/user/userActions'
import { route } from 'src/utils/routes'
import { IState } from 'src/store'

import Error401 from 'src/pages/Error/401.page'
import Header from 'src/components/Layout/Header/Header'
import ConfigurationForm from 'src/components/Configuration/ConfigurationForm'

interface IProps extends RouteComponentProps {
  history: any
  user: any
  userCan: (permission: string) => boolean
}

const ConfigurationCreate: React.FC<IProps> = ({ history, user, userCan }) => {
  if (!userCan('create_configuration')) return <Error401 />

  return (
    <>
      <Header
        title="New Configuration"
        backText="Configurations"
        onBack={() => history.push(route('configurations'))}
      />
      <div className="page-container">
        <Container>
          <Card>
            <Card.Header>
              <Card.Title>Configuration Details</Card.Title>
            </Card.Header>
            <Card.Body className="pt-0">
              <ConfigurationForm user={user} />
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  )
}

export default connect((state: IState) => ({ user: state.user }), { userCan })(
  withRouter(ConfigurationCreate)
)
