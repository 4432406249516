import React from 'react'

import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Formik, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useToasts } from 'react-toast-notifications'

import ConfigurationService from 'src/services/ConfigurationService'

import { route } from 'src/utils/routes'
import { IConfiguration } from 'src/services/types/configuration'

const configurationService = new ConfigurationService()

interface IProps extends RouteComponentProps {
  user: any
  type?: string
  configuration?: IConfiguration
  updateConfiguration?: (updatedValues: IConfiguration) => void
  history: any
}

const ConfigurationForm: React.FC<IProps> = ({
  user,
  type,
  configuration,
  updateConfiguration,
  history,
}) => {
  const { addToast } = useToasts()
  const _isMounted = React.useRef(false)

  React.useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const formConfig = {
    initialValues: {
      title: configuration ? configuration.title : '',
      description: configuration ? configuration.description : '',
      status: configuration ? configuration.status : 'active',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required('Title field is required.')
        .min(3, 'Title must be at least 3 characters.'),
      description: Yup.string()
        .required('Description field is required.')
        .min(3, 'Description must be at least 3 characters.'),
      status: Yup.string().required('Status field is required.'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true)
      let finalValues: any = {}

      if (type === 'update') {
        finalValues._id = configuration._id
      }

      finalValues = {
        ...finalValues,
        title: values.title,
        description: values.description,
        status: values.status,
        manufacturer_id: user.manufacturer_id,
      }

      configurationService[type]({ ...finalValues })
        .then((configurationId) => {
          setSubmitting(false)

          if (type === 'update') {
            addToast('Configuration successfully updated.', {
              appearance: 'success',
            })
            updateConfiguration(values)
          } else {
            addToast('Configuration successfully created.', {
              appearance: 'success',
            })
            history.push(route('configuration_update', { id: configurationId }))
          }
        })
        .catch((error) => {
          setSubmitting(false)
          addToast(error.message, { appearance: 'error' })
          throw error
        })
    },
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formConfig.initialValues}
      validationSchema={formConfig.validationSchema}
      onSubmit={formConfig.onSubmit}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        ...formik
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* Configuration status */}
          <Form.Group as={Row} className="align-items-center">
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Status *</Form.Label>
            </Col>
            <Col md={6}>
              <div className="d-flex">
                <Field
                  as={Form.Check}
                  type="radio"
                  id="active"
                  name="status"
                  value="active"
                  label="Active"
                  checked={values.status === 'active'}
                  custom
                />

                <Field
                  as={Form.Check}
                  type="radio"
                  id="inactive"
                  name="status"
                  value="inactive"
                  label="Inactive"
                  checked={values.status === 'inactive'}
                  className="ml-3"
                  custom
                />
              </div>
            </Col>
          </Form.Group>
          <hr />

          {/* Configuration title */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Title *</Form.Label>
              <Form.Text className="text-muted">
                Title of configuration
              </Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                name="title"
                value={values.title}
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.title && errors.title ? true : false}
              />
              <ErrorMessage
                name="title"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          {/* Configuration description */}
          <Form.Group as={Row}>
            <Col md={3} className="mb-2 mb-md-0">
              <Form.Label>Description *</Form.Label>
              <Form.Text className="text-muted">
                Configuration description
              </Form.Text>
            </Col>
            <Col md={6}>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={
                  touched.description && errors.description ? true : false
                }
              />
              <ErrorMessage
                name="description"
                component="span"
                className="invalid-feedback"
              />
            </Col>
          </Form.Group>
          <hr />

          <Button
            type="submit"
            variant="success"
            className="mt-4"
            disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
          >
            {formik.isSubmitting ? (
              <figure className="spinner button white" />
            ) : type === 'create' ? (
              'Create Configuration'
            ) : (
              'Update Configuration'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

ConfigurationForm.defaultProps = {
  type: 'create',
}

export default withRouter(ConfigurationForm)
