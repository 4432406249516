import Products from 'src/pages/Product/Products.page'
import ProductCreate from 'src/pages/Product/ProductCreate.page'
import ProductUpdate from 'src/pages/Product/ProductUpdate.page'

import { IRoute } from '.'

const productRoutes: IRoute[] = [
  {
    id: 'products',
    path: '/products',
    component: Products,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'product_create',
    path: '/products/create',
    component: ProductCreate,
    requiresAuth: true,
    exact: true,
  },
  {
    id: 'product_update',
    path: '/products/:id/update',
    component: ProductUpdate,
    requiresAuth: true,
    exact: true,
  },
]

export default productRoutes
