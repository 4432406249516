import React from 'react'

import { Button } from 'react-bootstrap'
import { BsArrowUpRight } from 'react-icons/bs'
import xlsx from 'xlsx'

import { slugify } from 'src/utils/filters'

interface IProps {
  label?: string
  filename: string
  tableRef?: any
  exportData?: any[]
  disabled?: boolean
}

const ExportButton: React.FC<IProps> = ({
  label,
  filename,
  tableRef,
  exportData,
  disabled,
}) => {
  if (!exportData && !tableRef) throw new Error('Export data required')

  const exportToExcel = (): void => {
    const ws: xlsx.WorkSheet = exportData
      ? xlsx.utils.json_to_sheet(exportData)
      : xlsx.utils.table_to_sheet(tableRef.current)

    const wb: xlsx.WorkBook = xlsx.utils.book_new()
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1')
    xlsx.writeFile(wb, `${slugify(filename)}.xlsx`)
  }

  return (
    <Button
      size="sm"
      variant="outline-light"
      title="Download as Excel"
      disabled={disabled || (!tableRef && !exportData)}
      onClick={exportToExcel}
    >
      <BsArrowUpRight size={18} /> {label || 'Export'}
    </Button>
  )
}

export default ExportButton
