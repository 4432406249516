import cache from '../utils/cache'
import { http } from '../utils/http'
import { ISurvey, ISurveyQuestion, ISurveyAnswer } from './types/survey'

const defaultUploadId = process.env.REACT_APP_DEFAULT_UPLOAD_ID

class SurveyService {
  fetch(): Promise<ISurvey[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('surveys')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('survey/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('surveys', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(surveyId: string): Promise<ISurvey> {
    return new Promise((resolve, reject) => {
      const cacheKey = `survey_${surveyId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`survey/read/?_id=${surveyId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByManufacturer(mftrId: string): Promise<ISurvey[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    return new Promise((resolve, reject) => {
      const cacheKey = `surveys_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http
            .post(`survey/read_by_manufacturer`, formData)
            .then(({ data }) => {
              if (data.code === 200) {
                cache.set(cacheKey, data.data)
                resolve(data.data)
              } else reject({ message: data.message })
            })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<ISurvey[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `surveys_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`survey/read_by_status`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(survey: ISurvey): Promise<string> {
    const formData = new FormData()
    formData.append('upload_id', defaultUploadId) // default

    Object.keys(survey).forEach((key) => {
      formData.append(key, survey[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('survey/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(survey: ISurvey): Promise<any> {
    const formData = new FormData()

    Object.keys(survey).forEach((key) => {
      formData.append(key, survey[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('survey/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  // QUESTIONS, ANSWERS
  fetchAnswersBySurvey(surveyId: string): Promise<ISurveyAnswer[]> {
    const formData = new FormData()
    formData.append('survey_id', surveyId)

    return new Promise((resolve, reject) => {
      const cacheKey = `survey__answers_${surveyId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post('answer/read_by_survey', formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchQuestionsBySurvey(surveyId: string): Promise<ISurveyQuestion[]> {
    const formData = new FormData()
    formData.append('survey_id', surveyId)

    return new Promise((resolve, reject) => {
      const cacheKey = `survey_questions_${surveyId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post('question/read_by_survey', formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  createQuestion(question: ISurveyQuestion, choices): Promise<string> {
    let formData = new FormData()

    Object.keys(question).forEach((key) => {
      formData.append(key, question[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('question/create', formData).then(({ data: qData }) => {
          if (qData.code === 200) {
            if (choices) {
              formData = new FormData()

              choices.forEach((choice) => {
                formData.append('title[]', choice.title)
                formData.append('index[]', choice.index as string)
                formData.append('status[]', 'active')
                formData.append('question_id[]', qData.data)
              })

              http.post(`/choice/create_bulk`, formData).then(() => {
                cache.reset()
                resolve(qData.data)
              })
            } else {
              cache.reset()
              resolve(qData.data)
            }
          } else reject({ message: qData.message })
        })
      } catch (error) {
        cache.reset()
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  updateQuestion(question: ISurveyQuestion, choices): Promise<any> {
    let formData = new FormData()

    Object.keys(question).forEach((key) => {
      formData.append(key, question[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('question/update', formData).then(({ data: qData }) => {
          if (qData.code === 200) {
            if (choices) {
              formData = new FormData()

              choices.forEach((choice) => {
                formData.append('_id[]', choice._id)
                formData.append('title[]', choice.title)
                formData.append('index[]', choice.index as string)
                formData.append('status[]', 'active')
                formData.append('question_id[]', choice.question_id)
              })
              http.post(`/choice/update_bulk`, formData).then(() => {
                cache.reset()
                resolve(qData.data)
              })
            } else {
              cache.reset()
              resolve(qData.data)
            }
          } else reject({ message: qData.message })
        })
      } catch (error) {
        cache.reset()
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default SurveyService
