import cache from '../utils/cache'
import { http } from '../utils/http'
import { IProduct } from './types/product'

const defaultUploadId = process.env.REACT_APP_DEFAULT_UPLOAD_ID

class ProductService {
  fetch(): Promise<IProduct[]> {
    return new Promise((resolve, reject) => {
      const cachedData = cache.get('products')
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get('product/read_all').then(({ data }) => {
            if (data.code === 200) {
              cache.set('products', data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchById(productId: string): Promise<IProduct> {
    return new Promise((resolve, reject) => {
      const cacheKey = `product_${productId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.get(`product/read/?_id=${productId}`).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data[0])
              resolve(data.data[0])
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByBrand(brandId: string): Promise<IProduct[]> {
    const formData = new FormData()
    formData.append('brand_id', brandId)

    return new Promise((resolve, reject) => {
      const cacheKey = `products_${brandId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`product/read_by_brand`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  /**
   * Fetch products by manufacturer
   *
   * @param mftrId
   * @param free - If 'true', load only products without categories.
   */
  fetchByManufacturer(
    mftrId: string,
    uncategorised = false
  ): Promise<IProduct[]> {
    const formData = new FormData()
    formData.append('manufacturer_id', mftrId)

    const endpoint = `read_by_manufacturer${
      uncategorised ? '_uncategorised' : ''
    }`

    return new Promise((resolve, reject) => {
      const cacheKey = `products_${endpoint}_${mftrId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`product/${endpoint}`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByCategory(categoryId: string): Promise<IProduct[]> {
    const formData = new FormData()
    formData.append('category_id', categoryId)

    return new Promise((resolve, reject) => {
      const cacheKey = `products_${categoryId}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`product/read_by_category`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  fetchByStatus(status: string): Promise<IProduct[]> {
    const formData = new FormData()
    formData.append('status', status)

    return new Promise((resolve, reject) => {
      const cacheKey = `products_${status}`
      const cachedData = cache.get(cacheKey)
      if (cachedData) {
        resolve(cachedData)
      } else {
        try {
          http.post(`product/read_by_status`, formData).then(({ data }) => {
            if (data.code === 200) {
              cache.set(cacheKey, data.data)
              resolve(data.data)
            } else reject({ message: data.message })
          })
        } catch (error) {
          reject({ message: 'An unexpected error occured!' })
          throw error
        }
      }
    })
  }

  create(product: IProduct): Promise<any> {
    const formData = new FormData()
    formData.append('upload_id', defaultUploadId) // default

    Object.keys(product).forEach((key) => {
      formData.append(key, product[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('product/create', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  createBulk(products: IProduct[]): Promise<any> {
    const formData = new FormData()

    products.forEach((product) => {
      formData.append('sku_id[]', product.sku_id)
      formData.append('name[]', product.name)
      formData.append('weight[]', product.weight as any)
      formData.append('size[]', product.size as any)
      formData.append('price[]', product.price as any)
      formData.append('price_rrp[]', product.price_rrp as any)

      formData.append('brand_id[]', product.brand_id)
      formData.append('manufacturer_id[]', product.manufacturer_id)
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('product/create_bulk', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  update(product: IProduct): Promise<any> {
    delete product.brand // not needed for api call

    const formData = new FormData()

    Object.keys(product).forEach((key) => {
      formData.append(key, product[key])
    })

    return new Promise((resolve, reject) => {
      try {
        http.post('product/update', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }

  updateStatus(product): Promise<any> {
    const formData = new FormData()
    formData.append('_id', product._id)
    formData.append('status', product.status)

    return new Promise((resolve, reject) => {
      try {
        http.post('product/set_status', formData).then(({ data }) => {
          if (data.code === 200) {
            cache.reset()
            resolve(data.data)
          } else reject({ message: data.message })
        })
      } catch (error) {
        reject({ message: 'An unexpected error occured!' })
        throw error
      }
    })
  }
}

export default ProductService
