import React from 'react'

import { Container, Row, Col, Card } from 'react-bootstrap'

import Header from 'src/components/Layout/Header/Header'
import NotFound from 'src/components/Error/NotFound'
import StaffForm from 'src/components/Staff/StaffForm'
import UserPasswordForm from 'src/components/User/UserPasswordForm'

import { useSelector } from 'react-redux'
import { IState } from 'src/store'

const Profile: React.FC = () => {
  const user = useSelector((state: IState) => state.user)

  return (
    <>
      <Header title="My Profile" />
      <div className="page-container">
        <Container>
          {!user ? (
            <div className="my-5">
              <NotFound
                title="User profile not found"
                summary="Check the URL and try again."
              />
            </div>
          ) : null}

          {user ? (
            <Row>
              <Col md={8}>
                <Card>
                  <Card.Header>
                    <Card.Title>Profile Details</Card.Title>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <StaffForm type="view" staff={user} />
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card>
                  <Card.Header>
                    <Card.Title>Change Password</Card.Title>
                  </Card.Header>
                  <Card.Body className="pt-0">
                    <UserPasswordForm user={user} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </div>
    </>
  )
}

export default Profile
