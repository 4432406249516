import React from 'react'

import { useSelector } from 'react-redux'

import RetailLocationService from 'src/services/RetailLocationService'
import { sortByKey } from 'src/utils/filters'
import { IState } from 'src/store'

import SearchSelect from './SearchSelect'
import useMounted from 'src/hooks/useMounted'

const retailLocationService = new RetailLocationService()

interface IProps {
  value: any
  retailer?: any
  onChange: (value: any) => void
  className?: string
  isMulti?: boolean
  isSingle?: boolean
  isDisabled?: boolean
  advanced?: boolean
}

const RetailLocationSearchSelect: React.FC<IProps> = ({
  value,
  retailer,
  onChange,
  ...props
}) => {
  const isMounted = useMounted()
  const user = useSelector((state: IState) => state.user)

  const [stores, setStores] = React.useState<any[]>()

  React.useEffect(() => {
    const fetchRetailLocations = (): void => {
      retailLocationService
        .fetchByManufacturer(user.manufacturer_id)
        .then((stores: any) => {
          const tempStores = stores
            .filter((store) => store.status === 'active')
            .map((store) => ({
              value: store._id,
              label: store.name,
              retailer_id: store.retailer_id,
              checked: false,
            }))

          isMounted.current && setStores(sortByKey(tempStores, 'label'))
        })
    }

    fetchRetailLocations()
  }, [isMounted, user.manufacturer_id])

  const storeOptions = React.useMemo(() => {
    if (!stores || !stores.length) return []
    else {
      const firstOption = { value: '*', label: 'All Stores' }
      let tempStores = stores

      if (retailer && retailer.value !== '*') {
        tempStores = tempStores.filter(
          (store: any) => store.retailer_id === retailer.value
        )
        /** Add retailer name to defualt store label */
        firstOption.label = `All ${retailer.label} Stores`
      }

      if (!props.isMulti && !props.isSingle && tempStores.length > 1) {
        /**
         * Add 'All * Stores' to options only
         *  when there's more than one store'.
         */
        return [firstOption, ...tempStores]
      } else return [...tempStores]
    }
  }, [stores, retailer, props.isMulti, props.isSingle])

  return (
    <>
      <SearchSelect
        size="sm"
        options={storeOptions}
        value={value}
        placeholder="Select store"
        onChange={onChange}
        isActive={!props.isMulti && value && value.value !== '*' ? true : false}
        isDisabled={!storeOptions || !storeOptions.length || props.isDisabled}
        {...props}
      />
    </>
  )
}

export default RetailLocationSearchSelect
