import React from 'react'

import { useSelector } from 'react-redux'

import RetailerService from 'src/services/RetailerService'
import { sortByKey } from 'src/utils/filters'
import { IState } from 'src/store'

import SearchSelect from './SearchSelect'
import useMounted from 'src/hooks/useMounted'

const retailerService = new RetailerService()

interface IProps {
  /** Current value */
  value: any
  /** Callback on value change */
  onChange: (value: any) => void
  /** Other props */
  className?: string
  isMulti?: boolean
  isDisabled?: boolean
}

const RetailerSelect: React.FC<IProps> = ({ value, onChange, ...props }) => {
  const isMounted = useMounted()
  const user = useSelector((state: IState) => state.user)

  const [retailerOptions, setRetailerOptions] = React.useState<any[]>()

  React.useEffect(() => {
    const fetchRetailers = (): void => {
      retailerService
        .fetchByManufacturer(user.manufacturer_id)
        .then((retailers) => {
          if (isMounted.current) {
            let tempRetailers = retailers
              .filter((retailer) => retailer.status === 'active')
              .map((retailer) => ({
                value: retailer._id,
                label: retailer.name,
              }))

            tempRetailers = sortByKey(tempRetailers, 'label')

            setRetailerOptions([
              { value: '*', label: 'All Retailers' },
              ...tempRetailers,
            ])
          }
        })
    }

    fetchRetailers()
  }, [user.manufacturer_id, isMounted])

  return (
    <SearchSelect
      size="sm"
      options={retailerOptions}
      value={value}
      placeholder="Select retailer"
      onChange={(selected) => onChange(selected)}
      isActive={value && value.value !== '*' ? true : false}
      isDisabled={!retailerOptions || !retailerOptions.length}
      {...props}
    />
  )
}

export default RetailerSelect
